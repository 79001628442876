import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import SignInScreen from 'components/SignInScreen';
import DialogContainer from 'containers/DialogContainer';

export default function LoginDialog(props: any) {
  const { t } = useTranslation();
  const open = props.open;
  const setOpen = props.setOpen;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <DialogContainer open={open} onClose={handleClose}>
        <Box>
          <Typography variant="h6" textAlign={'center'} paddingTop={4}>
            {t('Get started for free:')}
          </Typography>
          <SignInScreen />
        </Box>
      </DialogContainer>
    </div>
  );
}
