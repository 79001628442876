import { environment } from 'configs/environment';
import emailjs from 'emailjs-com';

export const sendEmail = (fromName: string, toEmail: string, message: string) => {
  emailjs.send(
    environment.emailJSServiceId!,
    environment.emailJSTemplateId!,
    { from_name: fromName, to_email: toEmail, message: message },
    environment.emailJSUserId
  );
  // .then(
  //   (result) => {
  //     alert('Email successfully sent!');
  //   },
  //   (error) => {
  //     alert('Failed to send the email.');
  //   }
  // );
};
