export const environment = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,

  emailJSServiceId: process.env.REACT_APP_EMAILJS_SERVICE_ID,
  emailJSTemplateId: process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
  emailJSUserId: process.env.REACT_APP_EMAILJS_USER_ID,

  solverApiUrl: process.env.REACT_APP_SOLVER_API_URL,
  demoFilesUserEmail: process.env.REACT_APP_DEMO_FILES_USER_EMAIL,
  recaptchaKey: process.env.REACT_APP_RECAPTCHA_KEY,
  stripeKey: process.env.REACT_APP_STRIPE_KEY,
};
