import { useEffect } from 'react';

const useEnterKey = (action: () => void) => {
  useEffect(() => {
    const handleEnterPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        action(); // Trigger the passed action
      }
    };

    // Add the event listener for the "keydown" event
    document.addEventListener('keydown', handleEnterPress);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleEnterPress);
    };
  }, [action]); // Re-run if the action function changes
};

export default useEnterKey;
