import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { DataGrid, GridCallbackDetails, GridColDef, GridSortModel, ptBR } from '@mui/x-data-grid';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { SessionContext } from 'contexts';
import { IDefect, ISolution } from '../Solution';
import dayjs from 'dayjs';
import { useMediaQuery, Chip, Paper, Grid, Stack, Typography, colors, Tooltip } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Error, HighlightAlt, SearchOff } from '@mui/icons-material';

interface ResourceData {
  short: string;
  color: string;
  photoURL: string;
  id: string;
  workload: string;
  workingDays: string;
  idleWindow: string;
  dailyWorkload: string;
  distinctSubjects: string;
  forbiddenCombination: string[];
  availability: number;
  unavailableTimes: string[];
  undesiredTimes: string[];
  updatedAt: string;
  defects: number;
}

function createData(
  short: string,
  color: string,
  photoURL: string,
  id: string,
  workload: string,
  workingDays: string,
  idleWindow: string,
  dailyWorkload: string,
  distinctSubjects: string,
  forbiddenCombination: string[],
  availability: number,
  unavailableTimes: string[],
  undesiredTimes: string[],
  updatedAt: string,
  defects: number
): ResourceData {
  return {
    short,
    color,
    photoURL,
    id,
    workload,
    workingDays,
    idleWindow,
    dailyWorkload,
    distinctSubjects,
    forbiddenCombination,
    availability,
    unavailableTimes,
    undesiredTimes,
    updatedAt,
    defects,
  };
}

export default function ResourcesSoln(props: any) {
  const { t } = useTranslation();
  const type = props.type;
  const defects: IDefect[] = props.defects;
  const history = useHistory();
  const { resources, file, user } = useContext(SessionContext);
  const selected: string = props.selected;
  const setSelected = props.setSelected;
  const solution: ISolution = props.solution;
  const page: number = props.page;
  const setPage = props.setPage;
  const widthLessThan900 = useMediaQuery('(max-width:900px)');

  if (file?.name === undefined) {
    history.push('/files');
    toast.warning('Select a project first!');
  }

  const days = file?.days ? file?.days : [];
  const times = file?.times ? file?.times : [];

  const getDefects = (resource: string) => {
    return defects.filter((defect) => defect.appliesTo === resource);
  };

  const displayResourceDefectsTooltip = (resource: string) => {
    const resourceDefects = defects.filter((defect) => defect.appliesTo === resource);
    if (!resourceDefects) return <></>;
    return (
      <Stack key={resource}>
        <p>{`${t('Defects for')} ${resource}:`}</p>
        {resourceDefects.map((defect, idx) => {
          return (
            <p key={idx} style={{ margin: '4px 0px' }}>
              {t(defect.type)}: {defect.description}
            </p>
          );
        })}
      </Stack>
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: t('Name'),
      flex: 4,
      minWidth: 50,
      renderCell: (params) => {
        return (
          <Chip
            translate="no"
            sx={{
              width: '100%',
              justifyContent: 'flex-start',
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            color={selected === params.value ? 'primary' : 'default'}
            variant={selected === params.value ? 'filled' : 'outlined'}
            label={params.value}
            deleteIcon={
              getDefects(params.value).length > 0 ? (
                <Tooltip title={displayResourceDefectsTooltip(params.value)}>
                  <Error style={{ color: colors.red[500] }} />
                </Tooltip>
              ) : (
                <></>
              )
            }
            onDelete={(event) => {
              handleClick(event, params.id.toString());
            }}
          />
        );
      },
    },
  ];

  let rows: any[] = [];
  resources
    .filter((res) => res.type === type)
    .map(function (resource, index) {
      return rows.push(
        createData(
          resource.short,
          resource.color,
          resource.photoURL,
          resource.name,
          (resource.minWorkload === 0 ? '↓' : resource.minWorkload) +
            ' - ' +
            (resource.maxWorkload === days.length * times.length ? '↑' : resource.maxWorkload),
          (resource.minWorkingDays === 0 ? '↓' : resource.minWorkingDays) +
            ' - ' +
            (resource.maxWorkingDays === days.length ? '↑' : resource.maxWorkingDays),
          (resource.minIdleWindow === 0 ? '↓' : resource.minIdleWindow) +
            ' - ' +
            (resource.maxIdleWindow === times.length - 2 ? '↑' : resource.maxIdleWindow),
          (resource.minDailyWorkload === 0 ? '↓' : resource.minDailyWorkload) +
            ' - ' +
            (resource.maxDailyWorkload === times.length ? '↑' : resource.maxDailyWorkload),
          (resource.minDistinctSubjects === 0 ? '↓' : resource.minDistinctSubjects) +
            ' - ' +
            (resource.maxDistinctSubjects === days.length * times.length ? '↑' : resource.maxDistinctSubjects),
          resource.forbiddenCombination.map((dayNum) => {
            return days[dayNum];
          }),
          resource.unavailableTimes.length * 100 + resource.undesiredTimes.length,
          resource.unavailableTimes,
          resource.undesiredTimes,
          user?.countryCode === 'BR'
            ? dayjs(resource.updatedAt.toDate()).format('DD/MM/YY HH:mm')
            : dayjs(resource.updatedAt.toDate()).format('MM/DD/YY hh:mma'),
          solution.defects.filter((def) => def.appliesTo === resource.name).length
        )
      );
    });

  function chooseLabel() {
    if (type === 'class') {
      return t('classes');
    } else if (type === 'teacher') {
      return t('teachers');
    } else if (type === 'room') {
      return t('rooms');
    } else {
      return t('resources');
    }
  }

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    setSelected(name);
  };

  return (
    <Paper elevation={0} sx={{ width: '100%' }}>
      <Grid item xs={12} sx={{ height: widthLessThan900 ? '24vh' : '54vh', width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          autoPageSize
          density={'compact'}
          initialState={{
            sorting: {
              sortModel: [{ field: 'id', sort: localStorage.getItem('sort-order') === 'desc' ? 'desc' : 'asc' }],
            },
          }}
          localeText={user?.countryCode === 'BR' ? ptBR.components.MuiDataGrid.defaultProps.localeText : undefined}
          page={page}
          onPageChange={(page) => setPage(page)}
          onSortModelChange={(model: GridSortModel, details: GridCallbackDetails) => {
            localStorage.setItem('sort-order', model[0]?.sort as string);
          }}
          onRowClick={(params, event) => handleClick(event, params.id.toString())}
          components={{
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                <HighlightAlt style={{ color: grey[500], textAlign: 'center' }} />
                <Typography color={grey[500]} textAlign="center">
                  {`${t('There are no')} ${chooseLabel()} ${t('registered')}`}
                </Typography>
              </Stack>
            ),
            NoResultsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                <SearchOff style={{ color: grey[500], textAlign: 'center' }} />
                <Typography color={grey[500]} textAlign="center">
                  {t('Local FILTER returns no data')}
                </Typography>
              </Stack>
            ),
          }}
        />
      </Grid>
    </Paper>
  );
}
