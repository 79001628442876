import { TFunction, useTranslation } from 'react-i18next';
import React, { Dispatch, ReactNode, SetStateAction, useContext } from 'react';
import { useMediaQuery, Grid, Tooltip, ToggleButton, Slider, Divider, Typography } from '@mui/material';
import { DoubleArrow } from '@mui/icons-material';
import SelectAllDialog from './Dialog/SelectAllDialog';
import { SessionContext } from 'contexts';
import { darkTheme, lightTheme } from 'styles/material';

type Props = {
  name: string;
  leftIcon: ReactNode;
  label: string;
  labelHelper?: string;
  tooltipComponent: ReactNode;
  selectedMin: number;
  setSelectedMin: Dispatch<SetStateAction<number>>;
  selectedMax: number;
  setSelectedMax: Dispatch<SetStateAction<number>>;
  possibleMin: number;
  possibleMax: number;
  applyToAllSelected?: boolean;
  setApplyToAllSelected?: Dispatch<SetStateAction<boolean>>;
  tooltipApplyToAll: string;
  selectedMore?: string[];
  setSelectedMore?: Dispatch<SetStateAction<string[]>>;
  options?: string[];
  applyToMoreTitle: string;
  applyToMoreMessage: string;
};

export const getAndMoreHelper = (t: TFunction, selectedMore: string[]) => {
  return (
    selectedMore.length > 0 &&
    `(${t('and')} ${selectedMore.length} ${selectedMore.length > 1 ? t('others') : t('other')}) `
  );
};

export default function MinMaxSlider({
  name = '',
  leftIcon,
  label,
  labelHelper = label,
  tooltipComponent,
  selectedMin,
  setSelectedMin,
  selectedMax,
  setSelectedMax,
  possibleMin,
  possibleMax,
  tooltipApplyToAll,
  selectedMore = [],
  setSelectedMore = () => {},
  options = [],
  applyToMoreTitle,
  applyToMoreMessage,
}: Props) {
  const { t } = useTranslation();
  const widthLessThan600 = useMediaQuery('(max-width:600px)');
  const { isDarkMode } = useContext(SessionContext);
  const theme = isDarkMode ? darkTheme : lightTheme;

  const [value, setValue] = React.useState<number[]>([selectedMin, selectedMax]);
  const handleChange = (event: Event, newValue: number | number[]) => {
    setSelectedMin((newValue as number[])[0]);
    setSelectedMax((newValue as number[])[1]);
    setValue(newValue as number[]);
  };

  const [isSelectMoreDialogOpen, setIsSelectMoreDialogOpen] = React.useState(false);

  return (
    <Grid container item xs={12} spacing={1}>
      <SelectAllDialog
        open={isSelectMoreDialogOpen}
        setOpen={setIsSelectMoreDialogOpen}
        title={applyToMoreTitle}
        message={applyToMoreMessage}
        selected={selectedMore}
        setSelected={setSelectedMore}
        options={options}
      />
      <Grid item xs={false} sm={1} md={1}>
        <></>
      </Grid>
      <Grid item container xs={12} sm={4} md={3} justifyContent={'flex-end'}>
        <Tooltip className={'constraint-description'} title={tooltipComponent}>
          <ToggleButton
            value="check"
            size="small"
            color="primary"
            sx={{
              textTransform: 'none',
              justifyContent: widthLessThan600 ? 'center' : 'flex-end',
              paddingRight: 2,
            }}
            style={{ borderWidth: 0, backgroundColor: 'rgba(255, 255, 255, 0)' }}
            selected={Number(selectedMin) === 0 && selectedMax === possibleMax ? false : true}
          >
            {leftIcon}
            {label}
          </ToggleButton>
        </Tooltip>
      </Grid>
      <Grid item xs={8} sm={4} paddingRight={2}>
        <Slider
          sx={{ padding: 0 }}
          getAriaLabel={() => 'Min max range'}
          value={value}
          onChange={handleChange}
          valueLabelDisplay={'auto'}
          // valueLabelDisplay={selectedMin === possibleMin && selectedMax === possibleMax ? 'auto' : 'on'}
          size="small"
          marks
          min={possibleMin}
          max={possibleMax}
          style={{
            color: selectedMin === possibleMin && selectedMax === possibleMax ? '#BDBDBD' : theme.palette.primary.main,
          }}
        />
        {selectedMin === selectedMax && (
          <Typography variant="body2" sx={{ color: theme.palette.primary.main }}>
            {name} {getAndMoreHelper(t, selectedMore)}
            {t('will have exactly')} {selectedMin} {labelHelper}
          </Typography>
        )}
        {selectedMin !== possibleMin && selectedMax === possibleMax && selectedMin !== selectedMax && (
          <Typography variant="body2" sx={{ color: theme.palette.primary.main }}>
            {name} {getAndMoreHelper(t, selectedMore)}
            {t('will have at least')} {selectedMin} {labelHelper}
          </Typography>
        )}
        {selectedMax !== possibleMax && selectedMin === possibleMin && selectedMin !== selectedMax && (
          <Typography variant="body2" sx={{ color: theme.palette.primary.main }}>
            {name} {getAndMoreHelper(t, selectedMore)}
            {t('will have at most')} {selectedMax} {labelHelper}
          </Typography>
        )}
        {selectedMin !== possibleMin && selectedMax !== possibleMax && selectedMin !== selectedMax && (
          <Typography variant="body2" sx={{ color: theme.palette.primary.main }}>
            {name} {getAndMoreHelper(t, selectedMore)}
            {t('will have at least')} {selectedMin} {t('and at most')} {selectedMax} {labelHelper}
          </Typography>
        )}
      </Grid>
      <Grid item sm={3} md={2}>
        <Tooltip title={tooltipApplyToAll} placement="right-end">
          <ToggleButton
            value="check"
            size="small"
            color="primary"
            selected={selectedMore.length > 0}
            onChange={() => {
              setIsSelectMoreDialogOpen(true);
            }}
            fullWidth
            // sx={{ width: 160 }}
          >
            <DoubleArrow />
            {widthLessThan600 ? '' : selectedMore.length > 0 ? `${t('More ')}${selectedMore.length}` : t('More ')}
          </ToggleButton>
        </Tooltip>
      </Grid>
      <Grid item sm={false} md={2}>
        <></>
      </Grid>
      {widthLessThan600 && (
        <Grid item xs={12}>
          <Divider></Divider>
        </Grid>
      )}
    </Grid>
  );
}
