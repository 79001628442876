import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import DialogContainer from 'containers/DialogContainer';

type Props = {
  open: boolean;
  title: string | React.ReactNode;
  message: string | React.ReactNode;
  positiveLabel: string;
  positiveAction: () => void;
  negativeLabel: string;
  negativeAction: () => void;
};

export default function ConfirmationDialog({
  open,
  title,
  message,
  positiveLabel,
  positiveAction,
  negativeLabel,
  negativeAction,
}: Props) {
  return (
    <div>
      <DialogContainer open={open} onClose={negativeAction}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {negativeLabel && (
            <Button autoFocus onClick={negativeAction}>
              {negativeLabel}
            </Button>
          )}
          {positiveLabel && <Button onClick={positiveAction}>{positiveLabel}</Button>}
        </DialogActions>
      </DialogContainer>
    </div>
  );
}
