import { RunningTimeLabelsMap, RunningTimeSecondsMap } from 'pages/Solutions/components/ConfigsDialog';

export interface PriorityOptionsMap {
  [key: string]: string;
}

export const priorityOptionsMap: PriorityOptionsMap = {
  Disabled: 'Disabled',
  'Very low': 'Very low',
  'Very Low': 'Very low',
  Low: 'Low',
  Average: 'Average',
  High: 'High',
  'Very high': 'Very high',
  'Very High': 'Very high',
  Desabilitado: 'Disabled',
  'Muito baixo': 'Very low',
  Baixo: 'Low',
  Normal: 'Average',
  Alto: 'High',
  'Muito alto': 'Very high',
};

export const mapRunningTimeLabelsToSeconds: RunningTimeLabelsMap = {
  '1 minute': 60,
  '5 minutes': 300,
  '10 minutes': 600,
  '30 minutes': 1800,
  '1 hour': 3600,
  '2 hours': 7200,
  '10 hours': 36000,
  '1 minuto': 60,
  '5 minutos': 300,
  '10 minutos': 600,
  '30 minutos': 1800,
  '1 hora': 3600,
  '2 horas': 7200,
  '10 horas': 36000,
};

export const mapRunningTimeSecondsToLabels: RunningTimeSecondsMap = {
  60: '1 minute',
  300: '5 minutes',
  600: '10 minutes',
  1800: '30 minutes',
  3600: '1 hour',
  7200: '2 hours',
  36000: '10 hours',
};

export const CLASS = 'class';
export const TEACHER = 'teacher';
export const ROOM = 'room';
export const SUBJECT = 'subject';
export const LESSON = 'lesson';
export const ASSIGNMENT = 'assignment';
