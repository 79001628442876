import {
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  lime,
  yellow,
  amber,
  orange,
  deepOrange,
  brown,
  grey,
  blueGrey,
} from '@mui/material/colors';

let usedColors: Set<string> = new Set(); // Store previously used colors

const colors: any[] = [
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  lime,
  // yellow,
  amber,
  orange,
  deepOrange,
  // brown,
  // grey,
  // blueGrey,
];

const intensities: number[] = [500, 600, 700, 800, 900];

const getColor = () => {
  const color = colors[Math.floor(Math.random() * colors.length)];
  const intensity = intensities[Math.floor(Math.random() * intensities.length)];
  return color[intensity];
};

export function getRandomColor(previouslyUsedColors: Set<string> = new Set<string>()) {
  let randomColor: string;

  if (previouslyUsedColors.size > colors.length * intensities.length) {
  }

  do {
    randomColor = getColor(); // Get a random color with intensity
  } while (
    (usedColors.has(randomColor) || previouslyUsedColors.has(randomColor)) &&
    previouslyUsedColors.size < colors.length * intensities.length
  ); // Check if this color has been used

  // Add the new color to the used set
  usedColors.add(randomColor);

  // Reset used colors if all have been used
  if (usedColors.size === colors.length * intensities.length) {
    usedColors.clear();
  }

  return randomColor;
}
