import { Box, Grid, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import ActionAreaCard from './ActionAreaCard';
import { features } from 'util/homeUtils';

export default function Features() {
  const { t } = useTranslation();
  return (
    <Box sx={{ backgroundColor: '#ffffff' }} paddingInline={'10%'} paddingTop={'64px'} paddingBottom={'80px'}>
      <Grid container spacing={4} padding={4} justifyContent="space-around">
        <Grid container item xs={12} paddingTop={4} justifyContent="center">
          <Typography variant="h3" color={grey[900]} align="center" display="inline">
            {t('Main Features')}
          </Typography>
        </Grid>
        {features.map((feature, idx) => {
          return (
            <Grid key={idx} item xs={12} sm={6} md={6} lg={3} display="flex" flexWrap="wrap">
              <ActionAreaCard
                imgPath={feature.imgPath}
                title={t(feature.title)}
                message={t(feature.message)}
              ></ActionAreaCard>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
