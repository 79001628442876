import {
  Close,
  Remove,
  LockOpen,
  Lock,
  Error,
  FileUpload,
  DisplaySettings,
  FileDownload,
  ChevronLeft,
  ChevronRight,
} from '@mui/icons-material';
import {
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Button,
  TableBody,
  Card,
  ToggleButton,
  Typography,
  colors,
  IconButton,
  Stack,
  Tooltip,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { TFunction } from 'react-i18next';
import { ISolution } from '../Solution';
import { Dispatch, Fragment, SetStateAction, useContext } from 'react';
import { toast } from 'react-toastify';
import { SessionContext } from 'contexts';
import {
  AVAILABLE_TIME_MARKER,
  DAY_TIME_SEPARATOR,
  SUBJECT_CLASS_SEPARATOR,
  UNAVAILABLE_TIME_MARKER,
  UNDESIRED_TIME_MARKER,
} from 'util/solutionUtils';
import EmptySpaceCell from 'components/Table/EmptySpaceCell';
import LoadingIndicator from 'components/Loading/LoadingIndicator';
import { darkModeScrollBar } from 'util/themeUtils';

type Props = {
  t: TFunction;
  solution: ISolution;
  selectedCells: string[];
  setSelectedCells: Dispatch<SetStateAction<string[]>>;
  selectedTab: number;
  setSelectedTab: Dispatch<SetStateAction<number>>;
  readOnly: boolean;
  handleSelectAllDayClick: (resDayTime: string) => void;
  handleTimetableCellClick: (name: string) => void;
  handleSelectAllDayTimeClick: (name: string) => void;
  handleSelectAllCellsClick: () => void;
  handleSelectAllResources: (name: string) => void;
  getAssignmentCellTextColor: (resourceName: string, cell: string) => string[];
  setDefectsOpen: Dispatch<SetStateAction<boolean>>;
  setLockConfirmationOpen: Dispatch<SetStateAction<boolean>>;
  setConfigsOpen: Dispatch<SetStateAction<boolean>>;
  setImportOpen: Dispatch<SetStateAction<boolean>>;
  setExportOpen: Dispatch<SetStateAction<boolean>>;
};

export default function CompactTimetable({
  t,
  solution,
  selectedCells,
  setSelectedCells,
  selectedTab,
  setSelectedTab,
  readOnly,
  handleSelectAllDayClick,
  handleTimetableCellClick,
  handleSelectAllDayTimeClick,
  handleSelectAllCellsClick,
  handleSelectAllResources,
  getAssignmentCellTextColor,
  setDefectsOpen,
  setLockConfirmationOpen,
  setConfigsOpen,
  setImportOpen,
  setExportOpen,
}: Props) {
  const { file, resources, subjects, isDarkMode } = useContext(SessionContext);
  if (!file) return <LoadingIndicator />;

  const days = file?.days!;
  const times = file?.times!;

  const countDefectsForResource = (resource: string) => {
    return solution.defects.filter((defect) => defect.appliesTo === resource).length;
  };

  const getResourceTooltip = (resource: string) => {
    const resourceDefects = solution.defects.filter((defect) => defect.appliesTo === resource);
    return (
      <Stack key={resource}>
        <p>{`${resource}`}</p>
        {resourceDefects.map((defect, idx) => {
          return (
            <p key={idx} style={{ margin: '4px 0px' }}>
              {t(defect.type)}: {defect.description}
            </p>
          );
        })}
      </Stack>
    );
  };

  const isCellSelected = (name: string) => selectedCells.indexOf(name) !== -1;

  const getResourceTypeLabelFromTab = (selectedTab: number) => {
    return selectedTab === 0 ? t('Class') : selectedTab === 1 ? t('Teacher') : t('Room');
  };

  return (
    <Grid
      id={'table'}
      container
      item
      sm={12}
      justifyContent={'space-evenly'}
      alignContent={'start'}
      sx={{ paddingTop: '0px !important' }}
    >
      <Grid item xs={12}>
        <Paper
          elevation={3}
          sx={{ width: '100%', overflow: 'hidden', overflowX: 'auto', ...(isDarkMode ? darkModeScrollBar : {}) }}
        >
          <TableContainer sx={{ maxHeight: '70vh' }}>
            <Table size="small" padding="none" stickyHeader>
              <TableHead className="compact-timetable">
                <TableRow>
                  <TableCell align="center" sx={{ minWidth: 32, position: 'sticky', left: 0, zIndex: 5 }}>
                    <Stack className="resource-switch" direction={'row'} justifyContent="space-evenly">
                      <Tooltip
                        title={t('Switch to ') + getResourceTypeLabelFromTab(selectedTab - 1 < 0 ? 2 : selectedTab - 1)}
                      >
                        <IconButton
                          id={`prev`}
                          sx={{ width: 15, height: 31 }}
                          size="small"
                          color="primary"
                          onClick={(event) => {
                            setSelectedTab(selectedTab - 1 < 0 ? 2 : selectedTab - 1);
                          }}
                        >
                          <ChevronLeft />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        title={t('Switch to ') + getResourceTypeLabelFromTab(selectedTab + 1 > 2 ? 0 : selectedTab + 1)}
                      >
                        <IconButton
                          id={`next`}
                          sx={{ width: 15, height: 31 }}
                          size="small"
                          color="primary"
                          onClick={() => {
                            setSelectedTab(selectedTab + 1 > 2 ? 0 : selectedTab + 1);
                          }}
                        >
                          <ChevronRight />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </TableCell>
                  {days.map((day, d_index) => (
                    <Fragment key={`header_${d_index}`}>
                      <TableCell
                        translate="no"
                        align="center"
                        sx={{ minWidth: 32 * times.length }}
                        colSpan={times.length}
                      >
                        <Button
                          value="check"
                          id={`check_${d_index}:0`}
                          fullWidth={true}
                          size="small"
                          sx={{ color: 'primary' }}
                          onClick={() => {
                            if (readOnly) {
                              toast.warning(t('Cannot lock timeslots in read only mode'));
                              return;
                            }
                            handleSelectAllDayClick('*:' + d_index + ':*');
                          }}
                        >
                          {days[d_index]}
                        </Button>
                      </TableCell>
                      {d_index !== days.length - 1 && <EmptySpaceCell />}
                    </Fragment>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell align="center" sx={{ minWidth: 80, position: 'sticky', left: 0, zIndex: 5 }}>
                    <Button
                      size="small"
                      sx={{ width: 31, height: 31 }}
                      color="primary"
                      onClick={() => {
                        if (readOnly) {
                          toast.warning(t('Cannot lock timeslots in read only mode'));
                          return;
                        }
                        handleSelectAllCellsClick();
                      }}
                    >
                      {getResourceTypeLabelFromTab(selectedTab)}
                    </Button>
                  </TableCell>
                  {days.map((day, d_index) => (
                    <Fragment key={`time_header_${d_index}`}>
                      {times.map((time, t_index) => (
                        <TableCell
                          key={`header${d_index}_${t_index}`}
                          align="center"
                          sx={{
                            minWidth: 32,
                            // borderRight: t_index + 1 === times.length ? `2px solid ${grey[300]}` : '',
                          }}
                        >
                          <Button
                            value="check"
                            id={`check_${d_index}:0`}
                            translate="no"
                            fullWidth={true}
                            size="small"
                            sx={{ color: 'primary' }}
                            onClick={() => {
                              if (readOnly) {
                                toast.warning(t('Cannot lock timeslots in read only mode'));
                                return;
                              }
                              handleSelectAllDayTimeClick('*:' + d_index + DAY_TIME_SEPARATOR + t_index);
                            }}
                          >
                            {times[t_index]}
                          </Button>
                        </TableCell>
                      ))}
                      {d_index !== days.length - 1 && <EmptySpaceCell />}
                    </Fragment>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {resources
                  .filter((res) =>
                    selectedTab === 0
                      ? res.type === 'class'
                      : selectedTab === 1
                      ? res.type === 'teacher'
                      : res.type === 'room'
                  )
                  .map((res, r_index) => {
                    return (
                      <TableRow key={`res_${r_index}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell
                          // style={{ position: 'sticky', left: 0, background: "white" }}
                          sx={{
                            height: 32,
                            minWidth: 32,
                            position: 'sticky',
                            left: 0,
                            backgroundColor: isDarkMode ? 'black' : 'white',
                            zIndex: 1,
                          }}
                        >
                          <Tooltip title={getResourceTooltip(res.name)} placement="left">
                            <Button
                              id={`check_${0}:${r_index}`}
                              translate="no"
                              fullWidth={true}
                              size="small"
                              color="primary"
                              sx={{
                                height: '100%',
                              }}
                              onClick={() => {
                                if (readOnly) {
                                  toast.warning(t('Cannot lock timeslots in read only mode'));
                                  return;
                                }
                                handleSelectAllResources(res.name + ':*:*');
                              }}
                            >
                              {res.short}{' '}
                              {countDefectsForResource(res.name) > 0 && (
                                <Error fontSize="small" sx={{ color: colors.red[500], marginLeft: 1 }} />
                              )}
                            </Button>
                          </Tooltip>
                        </TableCell>

                        {days.map((day, d_index) => {
                          return (
                            <Fragment key={`day_${d_index}`}>
                              {times.map((time, t_index) => {
                                const isItemSelected = isCellSelected(
                                  res.name + DAY_TIME_SEPARATOR + d_index + DAY_TIME_SEPARATOR + t_index
                                );
                                const cell = getAssignmentCellTextColor(
                                  res.name,
                                  d_index + DAY_TIME_SEPARATOR + t_index
                                );
                                const cellText = cell[0];
                                const cellColor = cell[1];
                                return (
                                  <TableCell
                                    key={`cell_${d_index}_${t_index}`}
                                    align="center"
                                    // sx={{
                                    //   borderRight: t_index + 1 === times.length ? `2px solid ${grey[300]}` : '',
                                    // }}
                                  >
                                    <Card sx={{ height: 32, minWidth: 32 }} elevation={0}>
                                      {cellText === UNAVAILABLE_TIME_MARKER ? (
                                        <ToggleButton
                                          value="check"
                                          id={`check_${d_index}_${t_index}`}
                                          fullWidth
                                          size="small"
                                          sx={{
                                            pointerEvents: 'none',
                                            height: '100%',
                                          }}
                                          selected
                                        >
                                          <Close sx={{ color: grey[600] }} />
                                        </ToggleButton>
                                      ) : cellText === UNDESIRED_TIME_MARKER ? (
                                        <ToggleButton
                                          value="check"
                                          id={`check_${d_index}_${t_index}`}
                                          fullWidth
                                          size="small"
                                          sx={{
                                            pointerEvents: 'none',
                                            height: '100%',
                                          }}
                                          selected
                                        >
                                          <Remove sx={{ color: grey[600] }} />
                                        </ToggleButton>
                                      ) : cellText === AVAILABLE_TIME_MARKER ? (
                                        <ToggleButton
                                          value="check"
                                          id={`check_${d_index}_${t_index}`}
                                          fullWidth
                                          translate="no"
                                          size="small"
                                          sx={{
                                            pointerEvents: 'none',
                                            textTransform: 'none',
                                            fontWeight: 'normal',
                                            height: '100%',
                                          }}
                                          selected={false}
                                        />
                                      ) : (
                                        <Tooltip title={cellText.replaceAll(SUBJECT_CLASS_SEPARATOR, ' • ')}>
                                          <ToggleButton
                                            value="check"
                                            id={`check_${d_index}_${t_index}`}
                                            fullWidth
                                            translate="no"
                                            size="small"
                                            sx={{
                                              textTransform: 'none',
                                              fontWeight: 'normal',
                                              height: '100%',
                                              color: '#ffffff',
                                              '&:hover': {
                                                backgroundColor: cellColor === '' ? 'primary' : cellColor,
                                                filter: 'brightness(1.2)',
                                              },
                                              backgroundColor: cellColor === '' ? 'primary' : cellColor,
                                            }}
                                            onClick={() => {
                                              if (readOnly) {
                                                toast.warning(t('Cannot lock timeslots in read only mode'));
                                                return;
                                              }
                                              handleTimetableCellClick(
                                                res.name + DAY_TIME_SEPARATOR + d_index + DAY_TIME_SEPARATOR + t_index
                                              );
                                            }}
                                            selected={isItemSelected}
                                          >
                                            <Typography fontSize={12} translate="no" noWrap>
                                              {
                                                subjects.find(
                                                  (sub) => sub.name === cellText.split(SUBJECT_CLASS_SEPARATOR)[0]
                                                )?.short
                                              }
                                            </Typography>
                                            {isItemSelected && (
                                              <Lock
                                                sx={{
                                                  fontSize: 12,
                                                  marginLeft: 0.25,
                                                  justifyContent: 'flex-end',
                                                  alignSelf: 'flex-end',
                                                }}
                                              />
                                            )}
                                          </ToggleButton>
                                        </Tooltip>
                                      )}
                                    </Card>
                                  </TableCell>
                                );
                              })}
                              {d_index !== days.length - 1 && <EmptySpaceCell />}
                            </Fragment>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        <Grid container spacing={1} justifyContent="flex-end" alignItems="flex-end" paddingTop={1}>
          <Grid item xs={6} md={2}>
            <Button
              className="defects-button"
              startIcon={<Error />}
              variant="text"
              size="small"
              onClick={() => setDefectsOpen(true)}
              color={solution.defects.length !== 0 ? 'error' : 'primary'}
              fullWidth
            >
              {`${t('Defects')}${solution.defects.length !== 0 ? ` (${solution.defects.length})` : ''}`}
            </Button>
          </Grid>
          <Grid item xs={6} md={2}>
            <Button
              className="lock-button"
              startIcon={<Lock />}
              variant="text"
              size="small"
              disabled={readOnly}
              onClick={() => setLockConfirmationOpen(true)}
              color="primary"
              fullWidth
            >
              {t('Lock') + (selectedCells.length === 0 ? '' : ` (${selectedCells.length})`)}
            </Button>
          </Grid>
          <Grid item xs={6} md={2}>
            <Button
              startIcon={<LockOpen />}
              variant="text"
              size="small"
              disabled={readOnly}
              onClick={() => {
                if (selectedCells.length === 0) toast.warning(t('All assignments are already unlocked'));
                else toast.success(t('All assignments unlocked'));
                setSelectedCells([]);
              }}
              color="primary"
              fullWidth
            >
              {t('Unlock')}
            </Button>
          </Grid>
          <Grid item xs={6} md={2}>
            <Button
              startIcon={<FileUpload />}
              variant="text"
              size="small"
              disabled={readOnly}
              onClick={() => setImportOpen(true)}
              color="primary"
              fullWidth
            >
              {t('Import')}
            </Button>
          </Grid>
          <Grid item xs={6} md={2}>
            <Button
              startIcon={<FileDownload />}
              variant="text"
              size="small"
              disabled={readOnly}
              onClick={() => setExportOpen(true)}
              color="primary"
              fullWidth
            >
              {t('Export')}
            </Button>
          </Grid>
          <Grid item xs={6} md={2}>
            <Button
              className="configs-button"
              startIcon={<DisplaySettings />}
              variant="text"
              size="small"
              disabled={readOnly}
              onClick={() => setConfigsOpen(true)}
              color="primary"
              fullWidth
            >
              {t('Configs')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
