'use client';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { customColors } from 'styles';

type ITeaserActions = {
  handleGetStarted: () => void;
};

export default function TeaserActions({ handleGetStarted }: ITeaserActions) {
  const { t } = useTranslation();

  return (
    <Grid container item spacing={4} xs={12} padding={4}>
      {/* <Grid item xs={2} /> */}
      <Grid item container xs={12} justifyContent="center">
        <Button
          fullWidth
          size="large"
          variant="contained"
          color="secondary"
          sx={{ backgroundColor: customColors.optables, '&:hover': { backgroundColor: customColors.optablesDark } }}
          onClick={handleGetStarted}
        >
          {t('Get started for free')}
        </Button>
      </Grid>
      {/* <Grid item xs={2} /> */}
    </Grid>
  );
}
