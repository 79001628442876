import { SelectChangeEvent, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { grey } from '@mui/material/colors';
import { SelectBaseProps } from 'util/selectUtils';

export default function DarkChipSelect({ current, setCurrent, options, label }: SelectBaseProps) {
  const handleChange = (event: SelectChangeEvent) => {
    setCurrent(event.target.value);
  };

  return (
    <FormControl variant="filled" fullWidth size="small">
      <InputLabel
        sx={{
          input: { color: grey[800] },
          label: { color: grey[800] },
          backgroundColor: 'white',
          borderRadius: 1,
        }}
        id="demo-simple-select-label"
      >
        {label}
      </InputLabel>
      <Select
        translate="no"
        sx={{
          input: { color: grey[800] },
          label: { color: grey[800] },
          backgroundColor: 'white',
          borderRadius: 1,
          paddingBottom: 1,
          '&.Mui-focused': {
            backgroundColor: 'white',
          },
          '&:hover': {
            backgroundColor: 'white',
          },
        }}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={'' + current}
        label={label}
        onChange={handleChange}
      >
        {options.map((option, index) => {
          return (
            <MenuItem key={`${label}_${index}`} value={option} sx={{ backgroundColor: 'white' }}>
              {option}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
