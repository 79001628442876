import { useTranslation } from 'react-i18next';
import AccordionTableDescription from 'components/AccordionTableDescription';
import { Accordion, AccordionSummary, DialogContentText, AccordionDetails, Link } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

export default function ImportLessonsAccordion() {
  const { t } = useTranslation();

  function createData(name: string, description: string) {
    return { name, description };
  }

  const subjectRows = [createData(t('Chemestry'), ''), createData(t('Calculus I'), '')];

  const timesWeekRows = [
    createData('2', t('Represents 2 times over the week')),
    createData('1', t('Means only one time over the week')),
  ];

  const splitRows = [
    createData('1-1', t('Ensures this lesson will be split into two single classes')),
    createData('1-1-2', t('Represents that this lesson can be split into two single lessons or one double lesson')),
  ];

  const daysBetweenLessonsRows = [
    createData('2-3', t('Represents min 2 and max 3 days between class meetings')),
    createData('1-', t('Means min 1 day between lessons and max unrestricted')),
    createData('-1', t('Means min unrestricted and max 1 days between class meetings')),
  ];

  const classRows = [createData(t('9th grade 902'), ''), createData(t('Computer Engineering 1'), '')];

  const teachersRows = [
    createData(t('John Pearson'), t('Represents that John is assigned to this lesson')),
    createData(
      t('George Fonseca+James Chan'),
      t('Represents that both George and James will teach this lesson together')
    ),
    createData(
      t('George Fonseca|James Chan'),
      t('Represents that Tiza will assign either George or James to teach this lesson')
    ),
    createData(
      t('George Fonseca|James Chan+Alice Smith'),
      t('Represents that Tiza will assign either George or James to teach this lesson together with Alice')
    ),
  ];

  const roomsRows = [
    createData(t('A103'), t('Represents that room A103 is assigned to host this lesson')),
    createData(
      'LAB01|LAB02|LAB03',
      t('Represents that Tiza can choose to assign one of the labs 1, 2, or 3 to host this lesson')
    ),
    createData(
      'LAB01|LAB02|LAB03+A103',
      t(
        'Represents that Tiza can choose to assign one of the labs 1, 2, or 3 to host this lesson together with room A103'
      )
    ),
  ];

  const predefinedTimesRows = [
    createData('1:1+3:2', t('Represents Monday (day 1) at the 1st and Wednesday (day 3) at the 2nd time')),
    createData('1:1', t('You can set only one time as predefined and let Tiza choose the others')),
  ];

  const simultaneousWithRows = [
    createData(t('English | 801'), t('Lesson will (not) be simultaneous with lesson of English for class 801')),
    createData(
      'English | 801 + Arts | 802',
      t('Lesson will (not) be simultaneous with both lessons of English for class 801 and subject Arts for class 802')
    ),
  ];

  const occurBeforeRows = [
    createData(`${'English | 801'} : 1`, t('Lesson will occur at least 1 day before lesson of English for class 801')),
    createData(
      `${'English | 801 + Arts | 802'} : 2`,
      t('Lesson will occur at least 2 days before lessons of English for class 801 and Arts for class 802')
    ),
  ];

  const exampleRows = [
    createData(
      t('Times /Week'),
      t('Lessons of Computing and Society will occur 1 time per week. All others occur 2 times per week')
    ),
    createData(
      t('Teacher'),
      t(
        'Lessons of Computer Programming and Calculus can be assigned to either George or James. Note that Lucas and Isabella will be assigned to teach Chemestry together'
      )
    ),
    createData(
      t('Room'),
      t(
        'Lessons of Computer Programming can be in either LAB1 or LAB2. Both rooms A101 and LAB3 will be assigned to host Chemestry lessons together'
      )
    ),
    createData(
      t('Split'),
      t(
        'Lessons of Computer Programming to both classes CE1 and IS1 can be split into single or double lessons. All others must be single'
      )
    ),
    createData(
      t('Days between Meetings'),
      t(
        'Lessons of Computer Programming will have at least 1 day between their classes (eg. Monday, and Tuesday). All others will have at least 2 and at most 3 days between class meetings (eg. Tuesday and Thursday)'
      )
    ),
    createData(
      t('Predefined Times'),
      t(
        'Lessons of Computer Programming to IS1 will occur on Tuesday (day 2) at the 4th time, and on Thursday (day 4) at the 3rd time'
      )
    ),
  ];

  return (
    <div>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2a-content" id="panel2a-header">
          <DialogContentText color={'primary'}>{t('Example')}&nbsp;</DialogContentText>
          <Link href={t('/data/lessons-exampleEN.xlsx')} download>
            {'(download)'}
          </Link>
        </AccordionSummary>
        <AccordionDetails>
          <DialogContentText>
            {t(
              'In this example, 6 lessons, 4 to class CE1 (Computer Engineering 1), and 2 to IS1 (Information Systems) will be imported:'
            )}
          </DialogContentText>
          <img
            src={t('/img/examples/importLessonsEN.png')}
            width={'100%'}
            alt={t('Example of Import Lessons')}
            loading="lazy"
            style={{ border: '1pt solid #bdbdbd', borderRadius: '4pt', padding: '4px' }}
          />
          <AccordionTableDescription data={exampleRows} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
          <DialogContentText color={'primary'}>{t('Column Subject')}</DialogContentText>
        </AccordionSummary>
        <AccordionDetails>
          <DialogContentText>
            {`${t(
              'This column cannot be empty and should contain the name of the subject. If it is not yet registered, it will be created'
            )}. ${t('Ex.')}:`}
          </DialogContentText>
          <AccordionTableDescription data={subjectRows} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2a-content" id="panel2a-header">
          <DialogContentText color={'primary'}>{t('Column Times /Week')}</DialogContentText>
        </AccordionSummary>
        <AccordionDetails>
          <DialogContentText>{t('This column is mandatory and imported as a number. Ex.:')}</DialogContentText>
          <AccordionTableDescription data={timesWeekRows} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2a-content" id="panel2a-header">
          <DialogContentText color={'primary'}>{t('Column Split')}</DialogContentText>
        </AccordionSummary>
        <AccordionDetails>
          <DialogContentText>
            {t(
              'This column is imported as a set of numbers separated by a dash sign (-). Diferent acceptable split options are separated by a + sign. Ex.:'
            )}
          </DialogContentText>
          <AccordionTableDescription data={splitRows} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2a-content" id="panel2a-header">
          <DialogContentText color={'primary'}>{t('Column Days between Meetings')}</DialogContentText>
        </AccordionSummary>
        <AccordionDetails>
          <DialogContentText>
            {t(
              'This column is imported as a pair of min-max values. Either min or max can be omitted if not constrained. Ex.:'
            )}
          </DialogContentText>
          <AccordionTableDescription data={daysBetweenLessonsRows} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
          <DialogContentText color={'primary'}>{t('Column Class')}</DialogContentText>
        </AccordionSummary>
        <AccordionDetails>
          <DialogContentText>
            {`${t(
              'This column is mandatory and should contain the name of the class that attends this subject. You can specify that more than one class attends this lesson together by adding a + sign. If the class is not yet registered, it will be created'
            )}. ${t('Ex.')}:`}
          </DialogContentText>
          <AccordionTableDescription data={classRows} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
          <DialogContentText color={'primary'}>{t('Column Teacher')}</DialogContentText>
        </AccordionSummary>
        <AccordionDetails>
          <DialogContentText>
            {`${t(
              'This column is mandatory and should contain the name of the teachers that can be assigned to lecture this lesson. Teacher option names are separated by a | sign. You can also specify that more than one teacher teaches this lesson together by adding a + sign. If the teacher is not yet registered, it will be created'
            )}. ${t('Ex.')}:`}
          </DialogContentText>
          <AccordionTableDescription data={teachersRows} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
          <DialogContentText color={'primary'}>{t('Column Room')}</DialogContentText>
        </AccordionSummary>
        <AccordionDetails>
          <DialogContentText>
            {`${t(
              'This column should contain the name of the rooms that can be assigned to host this lesson. Room option names are separated by a | sign. You can also specify that more than one room hosts this lesson together by adding a + sign. If the room is not yet registered, it will be created'
            )}. ${t('Ex.')}:`}
          </DialogContentText>
          <AccordionTableDescription data={roomsRows} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2a-content" id="panel2a-header">
          <DialogContentText color={'primary'}>{t('Column Predefined Times')}</DialogContentText>
        </AccordionSummary>
        <AccordionDetails>
          <DialogContentText>
            {t('This column is imported as a set of timeslots (day:time) separated by a + sign. Ex.:')}
          </DialogContentText>
          <AccordionTableDescription data={predefinedTimesRows} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2a-content" id="panel2a-header">
          <DialogContentText color={'primary'}>{t('Column Not/Simultaneous with')}</DialogContentText>
        </AccordionSummary>
        <AccordionDetails>
          <DialogContentText>
            {t('This column is imported as a set of lessons (Subject | Class) separated by a + sign. Ex.:')}
          </DialogContentText>
          <AccordionTableDescription data={simultaneousWithRows} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2a-content" id="panel2a-header">
          <DialogContentText color={'primary'}>{t('Column Occur before')}</DialogContentText>
        </AccordionSummary>
        <AccordionDetails>
          <DialogContentText>
            {t(
              'This column is imported as a set of lessons (Subject | Class) separated by a + sign followed by : and the minimum of days before the lessons. Ex.:'
            )}
          </DialogContentText>
          <AccordionTableDescription data={occurBeforeRows} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
