// Import FirebaseAuth and firebase.
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { getAuth } from 'firebase/auth';
import { db, firebaseApp } from 'services/Firebase/Firebase.conf';
import { getDoc, doc, setDoc } from 'firebase/firestore';
import i18n from 'i18n';
import { languageOptions } from './Drawer/MiniDrawerHome';
import { useHistory } from 'react-router-dom';

function SignInScreen() {
  const history = useHistory();
  const auth = getAuth(firebaseApp);
  auth.useDeviceLanguage();
  //   const verifier = new RecaptchaVerifier(auth, 'recaptcha-container', {});
  // window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {});

  async function createAndRedirectUser() {
    const userDoc = await getDoc(doc(db, 'users/' + auth.currentUser?.email));
    if (!userDoc.exists()) {
      //Save user on database
      await setDoc(doc(db, 'users', auth.currentUser?.email as string), {
        email: auth.currentUser?.email as string,
        name: auth.currentUser?.displayName as string,
        phone: auth.currentUser?.phoneNumber as string,
        photoURL: auth.currentUser?.photoURL as string,
        countryCode: languageOptions.find((option) => option.label === i18n.language.toUpperCase())
          ? languageOptions.find((option) => option.label === i18n.language.toUpperCase())?.code
          : 'EN',
        uid: auth.currentUser?.uid,
      }).then(() => {
        history.push('/files');
      });
    } else {
      history.push('/files');
    }
  }
  const socialLoginFirebase = (authResult: any) => {
    createAndRedirectUser();
    return true;
  };

  // Configure FirebaseUI.
  const uiConfig: firebaseui.auth.Config = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    // signInSuccessUrl: '/files',
    callbacks: {
      signInSuccessWithAuthResult: (authResult) => socialLoginFirebase(authResult),
      signInFailure(error) {
        console.error({ error });
      },
    },
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
      firebase.auth.GithubAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      firebase.auth.PhoneAuthProvider.PROVIDER_ID,
    ],
    tosUrl: () => navigateTo('/terms'),
    privacyPolicyUrl: () => navigateTo('/privacy'),
  };

  const navigateTo = (destination: string) => {
    history.push(destination);
  };

  return <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />;
}

export default SignInScreen;
