import { Card, CardActionArea, CardMedia, CardContent, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

export default function ActionAreaCard(props: any) {
  const imgPath = props.imgPath;
  const title = props.title;
  const message = props.message;
  return (
    <Card>
      <CardActionArea>
        <CardMedia component="img" height="200" image={imgPath} alt={title} loading="lazy" />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" sx={{ color: grey[800] }}>
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ color: grey[600] }}>
            {message}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
