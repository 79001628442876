import { Box, Container, Paper, Typography, List, ListItem } from '@mui/material';
import { customColors } from 'styles';

export default function TermsOfUse() {
  return (
    <Box sx={{ backgroundColor: customColors.blueDark, minHeight: '100vh' }}>
      <Container maxWidth="lg" sx={{ py: 4, mt: 0 }}>
        <Paper sx={{ p: 4 }}>
          <Typography variant="h4" gutterBottom>
            Terms of Use
          </Typography>
          <Typography variant="body1" paragraph>
            Effective Date: June, 13, 2023
          </Typography>
          <Typography variant="body1" paragraph>
            Please read these Terms of Use ("Agreement") carefully before accessing or using our software/application
            ("Software"). This Agreement sets forth the legally binding terms and conditions for your use of the
            Software provided by George Fonseca, PhD.
          </Typography>

          <Typography variant="h6" gutterBottom>
            Agreement Terms:
          </Typography>
          <List>
            <ListItem sx={{ display: 'block' }}>
              <Typography sx={{ fontWeight: 'bold' }}>1. Acceptance of Terms</Typography>
              <Typography>
                By accessing or using the Software, you agree to be bound by this Agreement. If you do not agree to
                these terms, you may not access or use the Software.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'block' }}>
              <Typography sx={{ fontWeight: 'bold' }}>2. Disclaimer of Warranty</Typography>
              <Typography>
                The Software is provided on an "as-is" basis without any warranty, express or implied. We do not make
                any representations or warranties regarding the Software's accuracy, completeness, reliability, or
                suitability for any purpose. You acknowledge that your use of the Software is at your sole risk, and you
                assume all responsibility and liability arising from such use.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'block' }}>
              <Typography sx={{ fontWeight: 'bold' }}>3. Limitation of Liability</Typography>
              <Typography>
                In no event shall we, our directors, officers, employees, or agents be liable for any indirect,
                incidental, special, consequential, or punitive damages, or any loss of profits or revenue, whether
                incurred directly or indirectly, arising from your use of the Software or any content accessed through
                the Software.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'block' }}>
              <Typography sx={{ fontWeight: 'bold' }}>4. Changes to this Agreement</Typography>
              <Typography>
                We reserve the right to modify or replace this Agreement at any time, effective upon posting the updated
                version on our website or within the Software. Your continued use of the Software after any such changes
                constitutes your acceptance of the revised Agreement.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'block' }}>
              <Typography sx={{ fontWeight: 'bold' }}>5. Payment Handling</Typography>
              <Typography>
                Our Software may integrate with third-party software for handling payments. Any payment-related
                information you provide will be processed and handled by the respective third-party payment service
                provider. We do not collect, store, or have access to your sensitive payment details such as credit card
                numbers or bank account information. You acknowledge and agree to review and comply with the terms and
                privacy policies of the third-party payment service provider.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'block' }}>
              <Typography sx={{ fontWeight: 'bold' }}>5. Governing Law</Typography>
              <Typography>
                This Agreement shall be governed by and construed in accordance with the laws of the Brazilian
                government. Any legal action arising out of or relating to this Agreement shall be filed exclusively in
                the courts located within João Monlevade, MG - Brazil, and you hereby consent to the personal
                jurisdiction of such courts.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'block' }}>
              <Typography sx={{ fontWeight: 'bold' }}>6. Contact us</Typography>
              <Typography>
                If you have any questions or concerns regarding this Agreement, please contact us at george.fons 'at'
                gmail.com.
              </Typography>
            </ListItem>
            {/* Add more ListItem components as needed */}
          </List>
        </Paper>
      </Container>
    </Box>
  );
}
