import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import DialogContainer from 'containers/DialogContainer';
import React from 'react';

type Props = {
  open: boolean;
  positiveLabel: string;
  positiveAction: () => void;
  negativeLabel: string;
  negativeAction: () => void;
  title: React.ReactNode | string;
  message: React.ReactNode | string;
};

export default function PlanInfoDialog({
  open,
  positiveLabel,
  positiveAction,
  negativeLabel,
  negativeAction,
  title,
  message,
}: Props) {
  return (
    <div>
      <DialogContainer open={open} onClose={negativeAction}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={negativeAction}>
            {negativeLabel}
          </Button>
          <Button onClick={positiveAction}>{positiveLabel}</Button>
        </DialogActions>
      </DialogContainer>
    </div>
  );
}
