import { InfoOutlined, Refresh } from '@mui/icons-material';
import { DialogContent, Grid, DialogContentText, Button } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import ImportDataGrid from './ImportDataGrid';
import BasicSelect from './Select/BasicSelect';
import { useTranslation } from 'react-i18next';

type Props = {
  type: string;
  message: string;
  importFields: string[];
  setInfoDialogOpen: Dispatch<SetStateAction<boolean>>;
  maxSelectWidth: number;
  gridRef: any;
  handlePaste: () => void;
  clipboardText: string;
  setClipboardText: Dispatch<SetStateAction<string>>;
  columns: string[];
  setColumns: React.Dispatch<React.SetStateAction<string>>[];
  ignoreFirstLine: boolean;
  setIgnoreFirstLine: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function ImportContentTable({
  type,
  message,
  importFields,
  setInfoDialogOpen,
  maxSelectWidth,
  gridRef,
  handlePaste,
  clipboardText,
  setClipboardText,
  columns,
  setColumns,
  ignoreFirstLine,
  setIgnoreFirstLine,
}: Props) {
  const { t } = useTranslation();

  return (
    <DialogContent>
      <Grid container spacing={1} justifyContent="space-between" alignItems={'center'}>
        <Grid item xs={12} md={8}>
          <DialogContentText>{message}</DialogContentText>
        </Grid>
        <Grid item xs={6} md={2}>
          <Button startIcon={<Refresh />} fullWidth size="small" variant={'text'} onClick={() => handlePaste()}>
            {t('Redo paste')}
          </Button>
        </Grid>
        <Grid item xs={6} md={2}>
          <Button
            className="info-button"
            startIcon={<InfoOutlined />}
            variant="text"
            size="small"
            onClick={() => setInfoDialogOpen(true)}
            color="primary"
            fullWidth
          >
            {t('Column Format')}
          </Button>
        </Grid>
      </Grid>
      <Grid className="columns-grid" container spacing={0} paddingTop={2} ref={gridRef}>
        {columns.map((_, idx) => {
          return (
            <Grid key={idx} item width={maxSelectWidth}>
              <BasicSelect
                current={columns[idx]}
                setCurrent={setColumns[idx]}
                options={importFields}
                label={`${t('Column')} ${idx + 1}`}
                maxWidth={maxSelectWidth}
              ></BasicSelect>
            </Grid>
          );
        })}
      </Grid>
      <Grid className="import-list" container spacing={1}>
        <ImportDataGrid
          clipboardText={clipboardText.trim()}
          setClipboardText={setClipboardText}
          ignoreFirstLine={ignoreFirstLine}
          metaColumns={columns}
          dataType={type}
        ></ImportDataGrid>
      </Grid>
    </DialogContent>
  );
}
