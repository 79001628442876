import { IResource } from 'pages/Resources/Resource';
import { CLASS, TEACHER, ROOM } from './configUtils';
import { IConstrBounds } from './validationUtils';
import Lessons from 'pages/Lessons/Lessons';
import { ILesson } from 'pages/Lessons/utils/types';

export const WORKING_TIMES = 'Working Times';
export const WORKING_DAYS = 'Working Days';
export const DAILY_WORKLOAD = 'Daily Workload';
export const IDLE_WINDOW = 'Idle Window';
export const DISTINCT_SUBJECTS = 'Distinct Subjects';
export const FORBIDDEN_COMBINATION = 'Forbidden Combination';
export const UNAVAILABLE_TIMES = 'Unavailable Times';
export const UNDESIRED_TIMES = 'Undesired Times';
export const REST_BETWEEN_DAYS = 'Rest between Days';
export const ROOMS_PER_DAY = 'Rooms per Day';
export const CONSECUTIVE_TIMES = 'Consecutive Times';
export const TRAVEL_TIME = 'Travel Time';
export const DAYS_BETWEEN_MEETINGS = 'Days between Meetings';
export const PREDEFINED_TIMES = 'Predefined Times';
export const SIMULTANEOUS_WITH = 'Simultaneous with';
export const NOT_SIMULTANEOUS_WITH = 'Not Simultaneous with';
export const OCCUR_BEFORE = 'Occur before';
export const SUBJECT_UNAVAILABLE_TIMES = 'Subject Unavailable Times';
export const SUBJECT_UNDESIRED_TIMES = 'Subject Undesired Times';
export const SPLIT = 'Split';

export const STEP = 'Step';
export const SUM = 'Linear';
export const SQUARE_SUM = 'Quadratic';

export const getResourcesConstrAppliesTo = (
  entity: string,
  constrType: string,
  constrBounds: IConstrBounds,
  resources: IResource[]
) => {
  switch (entity) {
    case CLASS:
      switch (constrType) {
        case WORKING_TIMES:
          return resources.filter(
            (res) =>
              res.type === CLASS &&
              (res.minWorkload !== constrBounds.minWorkingTimes || res.maxWorkload !== constrBounds.maxWorkingTimes)
          );
        case WORKING_DAYS:
          return resources.filter(
            (res) =>
              res.type === CLASS &&
              (res.minWorkingDays !== constrBounds.minWorkingDays || res.maxWorkingDays !== constrBounds.maxWorkingDays)
          );
        case IDLE_WINDOW:
          return resources.filter(
            (res) =>
              res.type === CLASS &&
              (res.minIdleWindow !== constrBounds.minIdleWindow || res.maxIdleWindow !== constrBounds.maxIdleWindow)
          );
        case DAILY_WORKLOAD:
          return resources.filter(
            (res) =>
              res.type === CLASS &&
              (res.minDailyWorkload !== constrBounds.minDailyWorkload ||
                res.maxDailyWorkload !== constrBounds.maxDailyWorkload)
          );
        case ROOMS_PER_DAY:
          return resources.filter(
            (res) =>
              res.type === CLASS &&
              (res.minRoomChangesDay !== constrBounds.minRoomsPerDay ||
                res.maxRoomChangesDay !== constrBounds.maxRoomsPerDay)
          );
        case CONSECUTIVE_TIMES:
          return resources.filter(
            (res) =>
              res.type === CLASS &&
              (res.minConsecutiveTimes !== constrBounds.minConsecutiveTimes ||
                res.maxConsecutiveTimes !== constrBounds.maxConsecutiveTimes)
          );
        case DISTINCT_SUBJECTS:
          return resources.filter(
            (res) =>
              res.type === CLASS &&
              (res.minDistinctSubjects !== constrBounds.minDistinctSubjects ||
                res.maxDistinctSubjects !== constrBounds.maxDistinctSubjects)
          );
        case FORBIDDEN_COMBINATION:
          return resources.filter((res) => res.type === CLASS && res.forbiddenCombination.length > 0);
        default:
          return [];
      }
    case TEACHER:
      switch (constrType) {
        case WORKING_TIMES:
          return resources.filter(
            (res) =>
              res.type === TEACHER &&
              (res.minWorkload !== constrBounds.minWorkingTimes || res.maxWorkload !== constrBounds.maxWorkingTimes)
          );
        case WORKING_DAYS:
          return resources.filter(
            (res) =>
              res.type === TEACHER &&
              (res.minWorkingDays !== constrBounds.minWorkingDays || res.maxWorkingDays !== constrBounds.maxWorkingDays)
          );
        case IDLE_WINDOW:
          return resources.filter(
            (res) =>
              res.type === TEACHER &&
              (res.minIdleWindow !== constrBounds.minIdleWindow || res.maxIdleWindow !== constrBounds.maxIdleWindow)
          );
        case DAILY_WORKLOAD:
          return resources.filter(
            (res) =>
              res.type === TEACHER &&
              (res.minDailyWorkload !== constrBounds.minDailyWorkload ||
                res.maxDailyWorkload !== constrBounds.maxDailyWorkload)
          );
        case ROOMS_PER_DAY:
          return resources.filter(
            (res) =>
              res.type === TEACHER &&
              (res.minRoomChangesDay !== constrBounds.minRoomsPerDay ||
                res.maxRoomChangesDay !== constrBounds.maxRoomsPerDay)
          );
        case CONSECUTIVE_TIMES:
          return resources.filter(
            (res) =>
              res.type === TEACHER &&
              (res.minConsecutiveTimes !== constrBounds.minConsecutiveTimes ||
                res.maxConsecutiveTimes !== constrBounds.maxConsecutiveTimes)
          );
        case DISTINCT_SUBJECTS:
          return resources.filter(
            (res) =>
              res.type === TEACHER &&
              (res.minDistinctSubjects !== constrBounds.minDistinctSubjects ||
                res.maxDistinctSubjects !== constrBounds.maxDistinctSubjects)
          );
        case FORBIDDEN_COMBINATION:
          return resources.filter((res) => res.type === TEACHER && res.forbiddenCombination.length > 0);
        default:
          return [];
      }
    case ROOM:
      switch (constrType) {
        case WORKING_TIMES:
          return resources.filter(
            (res) =>
              res.type === ROOM &&
              (res.minWorkload !== constrBounds.minWorkingTimes || res.maxWorkload !== constrBounds.maxWorkingTimes)
          );
        case WORKING_DAYS:
          return resources.filter(
            (res) =>
              res.type === ROOM &&
              (res.minWorkingDays !== constrBounds.minWorkingDays || res.maxWorkingDays !== constrBounds.maxWorkingDays)
          );
        case IDLE_WINDOW:
          return resources.filter(
            (res) =>
              res.type === ROOM &&
              (res.minIdleWindow !== constrBounds.minIdleWindow || res.maxIdleWindow !== constrBounds.maxIdleWindow)
          );
        case DAILY_WORKLOAD:
          return resources.filter(
            (res) =>
              res.type === ROOM &&
              (res.minDailyWorkload !== constrBounds.minDailyWorkload ||
                res.maxDailyWorkload !== constrBounds.maxDailyWorkload)
          );
        case TRAVEL_TIME:
          return resources.filter(
            (res) =>
              res.type === ROOM && res.minTravelTime !== constrBounds.minTravelTime && res.travelTimeRooms.length > 0
          );
        case CONSECUTIVE_TIMES:
          return resources.filter(
            (res) =>
              res.type === ROOM &&
              (res.minConsecutiveTimes !== constrBounds.minConsecutiveTimes ||
                res.maxConsecutiveTimes !== constrBounds.maxConsecutiveTimes)
          );
        case DISTINCT_SUBJECTS:
          return resources.filter(
            (res) =>
              res.type === ROOM &&
              (res.minDistinctSubjects !== constrBounds.minDistinctSubjects ||
                res.maxDistinctSubjects !== constrBounds.maxDistinctSubjects)
          );
        case FORBIDDEN_COMBINATION:
          return resources.filter((res) => res.type === ROOM && res.forbiddenCombination.length > 0);
        default:
          return [];
      }
  }
  return [];
};

export const getLessonsConstrAppliesTo = (constrType: string, constrBounds: IConstrBounds, lessons: ILesson[]) => {
  switch (constrType) {
    case DAYS_BETWEEN_MEETINGS:
      return lessons.filter((l) => {
        return (
          l.minGapLessons !== constrBounds.minDaysBetweenLessons ||
          l.maxGapLessons !== constrBounds.maxDaysBetweenLessons
        );
      });
    case SPLIT:
      return lessons.filter((l) => {
        return l.split.length > 1;
      });
    case PREDEFINED_TIMES:
      return lessons.filter((l) => {
        return l.predefinedTimes.length > 0;
      });
    case SIMULTANEOUS_WITH:
      return lessons.filter((l) => {
        return l.simultaneousWith.length > 0;
      });
    case NOT_SIMULTANEOUS_WITH:
      return lessons.filter((l) => {
        return l.notSimultaneousWith.length > 0;
      });
    case OCCUR_BEFORE:
      return lessons.filter((l) => {
        return l.occurBefore.length > 0;
      });
    default:
      return [];
  }
};
