import {
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  lime,
  yellow,
  amber,
  orange,
  deepOrange,
  brown,
  grey,
  blueGrey,
} from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  Box,
  DialogActions,
  TextField,
  Avatar,
  Button,
} from '@mui/material';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import DialogContainer from 'containers/DialogContainer';
import { getResourceTypeIcon } from 'util/avatarUtil';

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  name: string;
  selectedColor: string;
  setSelectedColor: Dispatch<SetStateAction<string>>;
  short: string;
  setShort: Dispatch<SetStateAction<string>>;
  entity: string;
};
export default function AvatarPropertiesDialog({
  open,
  setOpen,
  name,
  selectedColor,
  setSelectedColor,
  short,
  setShort,
  entity,
}: Props) {
  const { t } = useTranslation();
  const initialShort = useRef(short);
  const initialColor = useRef(selectedColor);

  const colors: any[] = [
    red,
    pink,
    purple,
    deepPurple,
    indigo,
    blue,
    lightBlue,
    cyan,
    teal,
    green,
    lightGreen,
    lime,
    yellow,
    amber,
    orange,
    deepOrange,
    brown,
    grey,
    blueGrey,
  ];

  const intensities: number[] = [100, 200, 300, 400, 500, 600, 700, 800, 900];

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setShort(initialShort.current);
    setSelectedColor(initialColor.current);
    handleClose();
  };

  const handleChangeColor = (color: string) => {
    setSelectedColor(color);
  };

  const [errorMessage, setErrorMessage] = useState('');

  return (
    <div>
      <DialogContainer open={open} onClose={handleCancel}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {t('Avatar Properties')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('Setup the avatar properties for')} {name}:
          </DialogContentText>
          <Grid container spacing={2} paddingTop={2} paddingInline={1}>
            <Grid item xs={2}></Grid>
            <Grid item xs={2}>
              <Avatar
                translate="no"
                sx={{ bgcolor: selectedColor, color: 'white', fontSize: short.length > 3 ? '12px' : '16px' }}
                alt={name}
              >
                {short || getResourceTypeIcon(entity)}
              </Avatar>
            </Grid>
            <Grid item xs={6}>
              <TextField
                error={errorMessage.length > 0}
                size="small"
                fullWidth
                label={t('Short')}
                variant="outlined"
                translate="no"
                defaultValue={short}
                onChange={(e) => {
                  if (e.target.value.length <= 4) {
                    setErrorMessage('');
                    setShort(e.target.value);
                  } else setErrorMessage(t('Short cannot have more than 4 characters.'));
                }}
                helperText={errorMessage}
              />
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
          <Grid container spacing={1} padding={2} paddingTop={4} justifyContent="center" alignItems="center">
            {intensities.map((intensity, index) => {
              return (
                <Box key={`color_${index}_0`}>
                  {colors.map((color, index2) => {
                    return (
                      <Grid key={`color_${index}_${index2}`} item xs={1} style={{ margin: '0px', padding: '0px' }}>
                        {color[intensity] === selectedColor ? (
                          <Box
                            onClick={() => handleChangeColor(color[intensity])}
                            sx={{
                              width: 40,
                              height: 20,
                              border: '2px solid #444444',
                              boxSizing: 'border-box',
                              backgroundColor: color[intensity],
                              '&:hover': {
                                border: '2px solid #444444',
                                boxSizing: 'border-box',
                                backgroundColor: color[intensity],
                                opacity: [0.9, 0.8, 0.7],
                              },
                            }}
                          ></Box>
                        ) : (
                          <Box
                            onClick={() => handleChangeColor(color[intensity])}
                            sx={{
                              width: 40,
                              height: 20,
                              backgroundColor: color[intensity],
                              '&:hover': {
                                border: '2px solid #444444',
                                boxSizing: 'border-box',
                                backgroundColor: color[intensity],
                                opacity: [0.9, 0.8, 0.7],
                              },
                            }}
                          ></Box>
                        )}
                      </Grid>
                    );
                  })}
                </Box>
              );
            })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>{t('Cancel')}</Button>
          <Button onClick={handleClose}>{t('Confirm')}</Button>
        </DialogActions>
      </DialogContainer>
    </div>
  );
}
