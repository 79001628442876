// import ReactPlayer from 'react-player/youtube';
import ReactPlayer from 'react-player/lazy';

import { useTranslation } from 'react-i18next';
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import DialogContainer from 'containers/DialogContainer';

export default function VideoPlayerDialog(props: any) {
  const { t } = useTranslation();
  const open = props.open;
  const setOpen = props.setOpen;
  const title = props.title;
  const message = props.message;
  const videoURL = props.videoURL;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <DialogContainer maxWidth="xl" open={open} onClose={handleClose}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
          <ReactPlayer url={videoURL} />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            {t('Close')}
          </Button>
        </DialogActions>
      </DialogContainer>
    </div>
  );
}
