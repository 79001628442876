import React, { useEffect, useState } from 'react';
import { Alert, Box, Card, CardMedia, DialogContentText, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PermissionDialog from './Dialog/PermissionDialog';
import { Info } from '@mui/icons-material';

interface Props {}

const ImportClipboardAnimation: React.FC<Props> = () => {
  const { t } = useTranslation();

  const [clipboardRequestInfoOpen, setClipboardRequestInfoOpen] = useState(false);
  const [clipboardDeniedInfoOpen, setClipboardDeniedInfoOpen] = useState(false);

  const verifyPermission = async () => {
    const permission = await navigator.permissions.query({ name: 'clipboard-read' } as any);
    if (permission.state === 'denied') {
      setClipboardDeniedInfoOpen(true);
    }
    if (permission.state === 'prompt') {
      setClipboardRequestInfoOpen(true);
    }
  };

  const getContentFromClipboard = async () => {
    try {
      const text = await navigator.clipboard.readText();
      return text;
    } catch (error) {
      console.error('Failed to read clipboard contents: ', error);
      return '';
    }
  };

  useEffect(() => {
    verifyPermission();
    getContentFromClipboard();
  }, []);

  return (
    <Box paddingInline={'15%'}>
      <DialogContentText paddingTop={4} paddingBottom={1}>
        {t('Open your spreadsheet, select the data you want to import, and hit CTRL+C (copy):')}
      </DialogContentText>
      <Card
        sx={{
          width: 'auto',
          boxShadow: 3,
          borderRadius: 2,
          overflow: 'hidden',
          marginBottom: 1,
          // maxWidth: 400, // Adjust this to the desired width
        }}
      >
        {/* GIF file (local or from URL) */}
        <CardMedia
          component="img"
          loading="lazy"
          alt="Animated GIF"
          image="/gif/clipboard-teachers-pt.gif" // Option 2: URL GIF
          sx={{
            width: '100%', // Ensures the gif takes full width of the card
          }}
        />
      </Card>
      {clipboardRequestInfoOpen && (
        <Alert icon={<Info fontSize="inherit" />} severity="info">
          <Stack display="flex">
            <Typography>{t('We need permission to access your clipboard (CTRL+C) to import data!')}</Typography>
            <Typography>{t('Please grant the requested permission in the upper corner of the screen.')}</Typography>
          </Stack>
        </Alert>
      )}
      {clipboardDeniedInfoOpen && (
        <Alert icon={<Info fontSize="inherit" />} severity="error">
          <Stack display="flex">
            <Typography>{t('It seems that you denied the permission to access your clipboard (CTRL+C) =(')}</Typography>
            <Typography>
              {t('Please grant the requested permission in the settings of your browser and try again.')}
            </Typography>
          </Stack>
        </Alert>
      )}
    </Box>
  );
};

export default ImportClipboardAnimation;
