import { Card, CardContent, Typography } from '@mui/material';
import React from 'react';

type Props = {
  title: string;
  message: string | React.ReactNode;
};

export default function CostFunctionCard({ title, message }: Props) {
  return (
    <Card>
      <CardContent>
        <Typography variant="body1" component="div">
          {title}
        </Typography>
        <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
          {message}
        </Typography>
      </CardContent>
    </Card>
  );
}
