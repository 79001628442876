import React, { useRef, ChangeEvent, useState } from 'react';
import { db, storage } from 'services';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { doc, updateDoc } from 'firebase/firestore';
import { IUser } from 'contexts/SessionContext/SessionContext';
import { IconButton, Avatar } from '@mui/material';
import LoadingIndicator from 'components/Loading/LoadingIndicator';

export default function UserAvatarFile(props: any) {
  const user: IUser = props.user;
  const userDoc = doc(db, 'users/' + user?.email);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    const files = event.target.files;
    if (files && files.length > 0) {
      const selectedFile = files[0];
      // Handle the selected file here

      try {
        const storageRef = ref(storage, `images/${user.uid}/profile`);
        await uploadBytes(storageRef, selectedFile);

        const downloadUrl = await getDownloadURL(storageRef);

        updateDoc(userDoc, {
          photoURL: downloadUrl,
        });

        // setAvatarUrl(downloadUrl);
      } catch (error) {
        console.error('Error uploading file:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  if (isLoading) {
    return <LoadingIndicator height="96px" />;
  }

  return (
    <div>
      <input
        type="file"
        accept=".jpg,.jpeg,.png"
        onChange={handleFileChange}
        ref={fileInputRef}
        style={{ display: 'none' }}
      />
      <IconButton onClick={handleFileClick}>
        <Avatar sx={{ width: 96, height: 96 }} alt={user?.name} src={user?.photoURL} />
      </IconButton>
    </div>
  );
}
