import { IResource } from 'pages/Resources/Resource';
import { TFunction } from 'react-i18next';
import { CLASS, ROOM, TEACHER } from './configUtils';
import {
  CONSECUTIVE_TIMES,
  DAILY_WORKLOAD,
  DAYS_BETWEEN_MEETINGS,
  DISTINCT_SUBJECTS,
  FORBIDDEN_COMBINATION,
  getLessonsConstrAppliesTo,
  getResourcesConstrAppliesTo,
  IDLE_WINDOW,
  NOT_SIMULTANEOUS_WITH,
  OCCUR_BEFORE,
  PREDEFINED_TIMES,
  REST_BETWEEN_DAYS,
  ROOMS_PER_DAY,
  SIMULTANEOUS_WITH,
  TRAVEL_TIME,
  UNAVAILABLE_TIMES,
  WORKING_DAYS,
  WORKING_TIMES,
} from './constraintUtils';
import { autoDownloadFile, DAY_TIME_SEPARATOR, SUBJECT_CLASS_SEPARATOR } from './solutionUtils';
import { ISubject } from 'pages/Subjects/Subject';
import { ILesson } from 'pages/Lessons/utils/types';
import { getConstrBounds } from './validationUtils';
import { IFile } from 'pages/Files/File';

const getNameColumnFromType = (type: string) => {
  switch (type) {
    case CLASS:
      return 'Class';
    case TEACHER:
      return 'Teacher';
    case ROOM:
      return 'Room';
    default:
      return 'Name';
  }
};
export const handleDownloadResourcesCSV = (
  t: TFunction,
  type: string,
  resources: IResource[],
  file: IFile,
  fileName: string
) => {
  // UTF-8 BOM to ensure proper encoding for special characters
  const BOM = '\uFEFF';

  const constrBounds = getConstrBounds(file.days.length, file.times.length);
  const hasWorkingTimesConstr = getResourcesConstrAppliesTo(type, WORKING_TIMES, constrBounds, resources).length !== 0;
  const hasWorkingDaysConstr = getResourcesConstrAppliesTo(type, WORKING_DAYS, constrBounds, resources).length !== 0;
  const hasIdleWindowConstr = getResourcesConstrAppliesTo(type, IDLE_WINDOW, constrBounds, resources).length !== 0;
  const hasDailyWorkloadConstr =
    getResourcesConstrAppliesTo(type, DAILY_WORKLOAD, constrBounds, resources).length !== 0;
  const hasRestBetweenDaysConstr =
    getResourcesConstrAppliesTo(type, REST_BETWEEN_DAYS, constrBounds, resources).length !== 0;
  const hasRoomsPerDayConstr = getResourcesConstrAppliesTo(type, ROOMS_PER_DAY, constrBounds, resources).length !== 0;
  const hasConsecutiveTimesConstr =
    getResourcesConstrAppliesTo(type, CONSECUTIVE_TIMES, constrBounds, resources).length !== 0;
  const hasDistinctSubjectsConstr =
    getResourcesConstrAppliesTo(type, DISTINCT_SUBJECTS, constrBounds, resources).length !== 0;
  const hasForbiddenCombinationConstr =
    getResourcesConstrAppliesTo(type, FORBIDDEN_COMBINATION, constrBounds, resources).length !== 0;
  const hasTravelTimesConstr = getResourcesConstrAppliesTo(type, TRAVEL_TIME, constrBounds, resources).length !== 0;

  let content = `${t(getNameColumnFromType(type))},`;
  content += hasWorkingTimesConstr ? `${t(WORKING_TIMES)},` : '';
  content += hasWorkingDaysConstr ? `${t(WORKING_DAYS)},` : '';
  content += hasIdleWindowConstr ? `${t(IDLE_WINDOW)},` : '';
  content += hasDailyWorkloadConstr ? `${t(DAILY_WORKLOAD)},` : '';
  content += hasRestBetweenDaysConstr ? `${t(REST_BETWEEN_DAYS)},` : '';
  content += hasRoomsPerDayConstr ? `${t(ROOMS_PER_DAY)},` : '';
  content += hasConsecutiveTimesConstr ? `${t(CONSECUTIVE_TIMES)},` : '';
  content += hasDistinctSubjectsConstr ? `${t(DISTINCT_SUBJECTS)},` : '';
  content += hasForbiddenCombinationConstr ? `${t(FORBIDDEN_COMBINATION)},` : '';
  content += hasTravelTimesConstr ? `${t(TRAVEL_TIME)},` : '';
  content += `${t(UNAVAILABLE_TIMES)}\n`;

  resources
    .filter((res) => res.type === type)
    .forEach((res) => {
      content += res.name + ',';
      if (hasWorkingTimesConstr) content += res.minWorkload + ' - ' + res.maxWorkload + ',';
      if (hasWorkingDaysConstr) content += res.minWorkingDays + ' - ' + res.maxWorkingDays + ',';
      if (hasIdleWindowConstr) content += res.minIdleWindow + ' - ' + res.maxIdleWindow + ',';
      if (hasDailyWorkloadConstr) content += res.minDailyWorkload + ' - ' + res.maxDailyWorkload + ',';
      if (hasRestBetweenDaysConstr) content += res.minRestBetweenDays + ' - ' + res.maxRestBetweenDays + ',';
      if (hasRoomsPerDayConstr) content += res.minRoomChangesDay + ' - ' + res.maxRoomChangesDay + ',';
      if (hasConsecutiveTimesConstr) content += res.minConsecutiveTimes + ' - ' + res.maxConsecutiveTimes + ',';
      if (hasDistinctSubjectsConstr) content += res.minDistinctSubjects + ' - ' + res.maxDistinctSubjects + ',';
      if (hasForbiddenCombinationConstr) content += res.forbiddenCombination.map((fc) => fc + 1).join(' + ') + ',';
      if (hasTravelTimesConstr)
        res.travelTimeRooms.length > 0
          ? (content += res.travelTimeRooms.join(' + ') + ' : ' + res.minTravelTime + ',')
          : (content += ',');
      content +=
        res.unavailableTimes
          .map((ut) => {
            return (
              Number(ut.split(DAY_TIME_SEPARATOR)[0]) +
              1 +
              DAY_TIME_SEPARATOR +
              (Number(ut.split(DAY_TIME_SEPARATOR)[1]) + 1)
            );
          })
          .join(' + ') + '\n';
    });

  autoDownloadFile(BOM + content, 'csv', fileName);
};

export const handleDownloadSubjectsCSV = (t: TFunction, subjects: ISubject[], fileName: string) => {
  // UTF-8 BOM to ensure proper encoding for special characters
  const BOM = '\uFEFF';

  let content = `${t('Subject')},${t(UNAVAILABLE_TIMES)}\n`;

  subjects.forEach((s) => {
    content += s.name + ',';
    content +=
      s.unavailableTimes
        .map((ut) => {
          return (
            Number(ut.split(DAY_TIME_SEPARATOR)[0]) +
            1 +
            DAY_TIME_SEPARATOR +
            (Number(ut.split(DAY_TIME_SEPARATOR)[1]) + 1)
          );
        })
        .join(' + ') + '\n';
  });

  autoDownloadFile(BOM + content, 'csv', fileName);
};

export const handleDownloadLessonsCSV = (t: TFunction, lessons: ILesson[], file: IFile, fileName: string) => {
  // UTF-8 BOM to ensure proper encoding for special characters
  const BOM = '\uFEFF';

  const constrBounds = getConstrBounds(file.days.length, file.times.length);
  const hasDaysBetweenMeetingsConstr =
    getLessonsConstrAppliesTo(DAYS_BETWEEN_MEETINGS, constrBounds, lessons).length !== 0;
  const hasSimultaneousWithConstr = getLessonsConstrAppliesTo(SIMULTANEOUS_WITH, constrBounds, lessons).length !== 0;
  const hasNotSimultaneousWithConstr =
    getLessonsConstrAppliesTo(NOT_SIMULTANEOUS_WITH, constrBounds, lessons).length !== 0;
  const hasOccurBeforeConstr = getLessonsConstrAppliesTo(OCCUR_BEFORE, constrBounds, lessons).length !== 0;

  let content = `${t('Subject')},${t('Class')},${t('Teacher')},${t('Room')},${t('Times /Week')},${t('Split')},`;
  content += hasDaysBetweenMeetingsConstr ? `${t(DAYS_BETWEEN_MEETINGS)},` : '';
  content += hasSimultaneousWithConstr ? `${t(SIMULTANEOUS_WITH)},` : '';
  content += hasNotSimultaneousWithConstr ? `${t(NOT_SIMULTANEOUS_WITH)},` : '';
  content += hasOccurBeforeConstr ? `${t(OCCUR_BEFORE)},` : '';
  content += `${t(PREDEFINED_TIMES)}\n`;

  lessons.forEach((l) => {
    content += l.subject[0] + ',';
    content += l.classes.join(' + ') + ',';
    content += l.teachers.join(' + ') + ',';
    content += l.rooms.join(' + ') + ',';
    content += l.lessonsWeek + ',';
    content += l.split.join(' + ') + ',';
    if (hasDaysBetweenMeetingsConstr) content += l.minGapLessons + ' - ' + l.maxGapLessons + ',';
    if (hasSimultaneousWithConstr)
      content += l.simultaneousWith.join(' + ').split(SUBJECT_CLASS_SEPARATOR).join(SUBJECT_CLASS_SEPARATOR) + ',';
    if (hasNotSimultaneousWithConstr)
      content += l.notSimultaneousWith.join(' + ').split(SUBJECT_CLASS_SEPARATOR).join(SUBJECT_CLASS_SEPARATOR) + ',';
    if (hasOccurBeforeConstr)
      l.occurBefore.length > 0
        ? (content +=
            l.occurBefore.join(' + ').split(SUBJECT_CLASS_SEPARATOR).join(SUBJECT_CLASS_SEPARATOR) +
            ' ' +
            DAY_TIME_SEPARATOR +
            ' ' +
            l.minGapLessons +
            ',')
        : (content += ',');
    content +=
      l.predefinedTimes
        .map((pt) => {
          return (
            Number(pt.split(DAY_TIME_SEPARATOR)[0]) +
            1 +
            DAY_TIME_SEPARATOR +
            (Number(pt.split(DAY_TIME_SEPARATOR)[1]) + 1)
          );
        })
        .join(' + ') + '\n';
  });

  autoDownloadFile(BOM + content, 'csv', fileName);
};
