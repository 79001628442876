import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { DataGrid, GridColDef, ptBR } from '@mui/x-data-grid';
import { SessionContext } from 'contexts';
import { doc, getDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { db } from 'services';
import dayjs from 'dayjs';
import {
  useMediaQuery,
  Chip,
  Stack,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Grid,
} from '@mui/material';
import { grey, blue, green } from '@mui/material/colors';
import { HighlightAlt, SearchOff } from '@mui/icons-material';
import { DAY_TIME_SEPARATOR } from 'util/solutionUtils';

interface LessonData {
  short: string;
  color: string;
  photoURL: string;

  id: string;
  subject: string[];
  lessonsWeek: number;
  split: string[];
  gapLessons: string;

  classes: string[];
  teachers: string[];
  rooms: string[];

  availability: number;
  predefinedTimes: string[];
  simulatenousWith: string[];
  notSimulatenousWith: string[];
  occurBefore: string[];
  updatedAt: string;
}

function createData(
  short: string,
  color: string,
  photoURL: string,

  id: string,
  subject: string[],
  lessonsWeek: number,
  split: string[],
  gapLessons: string,

  classes: string[],
  teachers: string[],
  rooms: string[],

  availability: number,
  predefinedTimes: string[],
  simulatenousWith: string[],
  notSimulatenousWith: string[],
  occurBefore: string[],
  updatedAt: string
): LessonData {
  return {
    short,
    color,
    photoURL,

    id,
    subject,
    lessonsWeek,
    split,
    gapLessons,

    classes,
    teachers,
    rooms,

    availability,
    predefinedTimes,
    simulatenousWith,
    notSimulatenousWith,
    occurBefore,
    updatedAt,
  };
}

export default function LessonsOfSubject(props: any) {
  const { t } = useTranslation();
  const subjectFilter = props.subjectFilter;
  const history = useHistory();
  const { lessons, file, user, ownerEmail } = useContext(SessionContext);
  const lessonsPath = 'users/' + ownerEmail + '/files/' + user?.selectedFile + '/lessons';
  const widthLessThan900 = useMediaQuery('(max-width:900px)');
  const widthLessThan600 = useMediaQuery('(max-width:600px)');
  const days = file?.days ? file?.days : [];
  const times = file?.times ? file?.times : [];
  const [pageSize, setPageSize] = React.useState<number>(100);

  const strArrayComparator: any = (v1: string[], v2: string[]) => {
    if (v1.length !== v2.length) {
      return v1.length - v2.length;
    } else {
      return ('' + v1[0]).localeCompare('' + v2[0]);
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: t('Name'),
      flex: 1,
      hide: true,
      renderCell: (params) => {
        return <Chip variant="filled" label={params.value} />;
      },
    },
    {
      field: 'classes',
      headerName: file?.studentsSetting !== 0 ? t('Class') : t('Students'),
      flex: 2,
      minWidth: 150,
      sortComparator: strArrayComparator,
      renderCell: (params) => {
        return params.row.classes.map((class_: string, index: number) => {
          if (index === 0) {
            return (
              <Stack translate="no" key={index} direction="row" spacing={0} alignItems={'center'}>
                <Typography fontSize={14}>{params.row.classNum}×</Typography>
                <Chip size="small" variant="outlined" translate="no" label={class_} />
              </Stack>
            );
          } else return <Chip translate="no" key={index} size="small" variant="outlined" label={class_} />;
        });
      },
    },
    {
      field: 'teachers',
      headerName: t('Teachers'),
      flex: 3,
      minWidth: 100,
      sortComparator: strArrayComparator,
      renderCell: (params) => {
        return params.row.teachers.map((teacher: string, index: number) => {
          if (index === 0) {
            return (
              <Stack translate="no" key={index} direction="row" spacing={0} alignItems={'center'}>
                <Typography fontSize={14}>{params.row.teacherNum}×</Typography>
                <Chip size="small" variant="outlined" translate="no" label={teacher} />
              </Stack>
            );
          } else return <Chip size="small" variant="outlined" translate="no" key={index} label={teacher} />;
        });
      },
    },
    {
      field: 'rooms',
      headerName: t('Rooms'),
      flex: 1,
      minWidth: 100,
      hide: widthLessThan600 || file?.roomsSetting === 0,
      sortComparator: strArrayComparator,
      renderCell: (params) => {
        if (params.row.rooms.length === 0) {
          return (
            <Stack translate="no" direction="row" spacing={0} alignItems={'center'}>
              <Typography fontSize={14}>{params.row.roomNum}×</Typography>
            </Stack>
          );
        } else {
          return params.row.rooms.map((room: string, index: number) => {
            if (index === 0) {
              return (
                <Stack translate="no" key={index} direction="row" spacing={0} alignItems={'center'}>
                  <Typography fontSize={14}>{params.row.roomNum}×</Typography>
                  <Chip size="small" variant="outlined" translate="no" label={room} />
                </Stack>
              );
            } else return <Chip size="small" variant="outlined" translate="no" key={index} label={room} />;
          });
        }
      },
    },
    {
      field: 'lessonsWeek',
      headerName: t('Times /Week'),
      flex: 1,
      minWidth: 100,
      hide: widthLessThan900,
    },
    {
      field: 'split',
      headerName: t('Split'),
      flex: 2,
      minWidth: 100,
      hide: true,
      renderCell: (params) => {
        return params.row.split.map((split: string, index: number) => {
          return <Chip variant="filled" label={params.row.split[index]} />;
        });
      },
    },
    {
      field: 'gapLessons',
      headerName: t('Days between Meetings'),
      flex: 1,
      minWidth: 80,
      hide: true,
    },
    {
      field: 'availability',
      headerName: t('Predefined Times'),
      flex: 1,
      minWidth: 100,
      hide: true,
      renderCell: (params) => {
        const cellHeight = 28 / times.length;
        return (
          <TableContainer>
            <Table size="small" padding="none">
              <TableBody>
                {times.map((time, t_index) => {
                  return (
                    <TableRow key={`time_${t_index}`}>
                      {days.map((day, d_index) => {
                        return (
                          <TableCell
                            key={`cell_${d_index}_${t_index}`}
                            sx={{
                              border: 0.5,
                              borderColor: grey[600],
                              height: `${cellHeight}px`,
                              backgroundColor: `${
                                params.row.predefinedTimes.includes('' + d_index + DAY_TIME_SEPARATOR + t_index)
                                  ? blue[200]
                                  : green[200]
                              }`,
                            }}
                            align="center"
                          ></TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        );
      },
    },
    {
      field: 'updatedAt',
      headerName: 'Updated At',
      flex: 2,
      minWidth: 130,
      headerAlign: 'right',
      align: 'right',
      hide: true,
    },
  ];

  let rows: any[] = [];
  lessons
    .filter((lesson) => lesson.subject[0] === subjectFilter)
    .map(function (lesson, index) {
      return rows.push(
        createData(
          lesson.short,
          lesson.color,
          lesson.photoURL,

          lesson.name,
          lesson.subject,
          lesson.lessonsWeek,
          lesson.split,
          (lesson.minGapLessons === 0 ? '↓' : lesson.minGapLessons) +
            ' - ' +
            (lesson.maxGapLessons === days.length - 1 ? '↑' : lesson.maxGapLessons),

          lesson.classes,
          lesson.teachers,
          lesson.rooms,

          lesson.predefinedTimes.length,
          lesson.predefinedTimes,
          lesson.simultaneousWith,
          lesson.notSimultaneousWith,
          lesson.occurBefore,
          user?.countryCode === 'BR'
            ? dayjs(lesson.updatedAt.toDate()).format('DD/MM/YY HH:mm[h]')
            : dayjs(lesson.updatedAt.toDate()).format('MM/DD/YY hh:mma')
        )
      );
    });

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const docRef = doc(db, lessonsPath, name);
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        // Redirect to resource page
        const lessonData = { ...docSnap.data() };
        history.push({ pathname: '/lesson', state: lessonData });
      } else {
        // doc.data() will be undefined in this case
        toast.error(t('No such document'));
      }
    });
  };

  return (
    <Grid item xs={12} sx={{ height: '40vh', width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        // autoPageSize
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[20, 50, 100]}
        // hideFooterPagination
        density={'compact'}
        // loading={rows.length === 0}
        // checkboxSelection
        disableSelectionOnClick
        initialState={{
          sorting: {
            sortModel: [{ field: 'updatedAt', sort: 'desc' }],
          },
        }}
        localeText={user?.countryCode === 'BR' ? ptBR.components.MuiDataGrid.defaultProps.localeText : undefined}
        onRowClick={(params, event) => handleClick(event, params.id.toString())}
        components={{
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              <HighlightAlt style={{ color: grey[500], textAlign: 'center' }} />
              <Typography color={grey[500]} textAlign="center">
                {t('After adding this subject, link lessons to it on the LESSONS page')}
              </Typography>
            </Stack>
          ),
          NoResultsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              <SearchOff style={{ color: grey[500], textAlign: 'center' }} />
              <Typography color={grey[500]} textAlign="center">
                {t('Local FILTER returns no data')}
              </Typography>
            </Stack>
          ),
        }}
      />
    </Grid>
  );
}
