import React, { useContext, useState } from 'react';

import { useHistory } from 'react-router';
import { DataGrid, GridSelectionModel, ptBR } from '@mui/x-data-grid';
import { doc, Timestamp, writeBatch } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { SessionContext } from 'contexts';
import MiniDrawer from 'components/Drawer/MiniDrawer';
import { db } from 'services';
import DraggableDialog from 'components/Dialog/DraggableDialog';
import SimpleBackdrop from 'components/Loading/SimpleBackdrop';
import { ISubject } from 'pages/Subjects/Subject';
import { Download, HighlightAlt, QuestionMark, SearchOff } from '@mui/icons-material';
import VideoPlayerDialog from 'components/Dialog/VideoPlayerDialog';
import { Grid, Stack, Typography, Paper, Divider, Button } from '@mui/material';
import { grey } from '@mui/material/colors';
import { EMPTY_LESSONS_KEY, useLessonsOnboarding } from './utils/useLessonsOnboarding';
import Onboarding from 'components/Onboarding';
import PageContainer from 'containers/PageContainer';
import { createData, LessonDataGrid, useDataGrid } from './utils/useDataGrid';
import ImportStepperDialog from 'components/Dialog/ImportStepperDialog';
import useEnterKey from 'hooks/useEnterKey';
import { darkTheme, lightTheme } from 'styles/material';
import { darkModeScrollBar } from 'util/themeUtils';
import TooltipIconButton from 'components/Button/TooltipIconButton';
import { handleDownloadLessonsCSV } from 'util/exportUtil';

export default function Lessons() {
  const { t } = useTranslation();
  const history = useHistory();
  const { lessons, subjects, file, user, ownerEmail, shareMode, isDarkMode } = useContext(SessionContext);
  if (!file) {
    history.push('/files');
    toast.warning(t('Select a project first'));
  }
  const theme = isDarkMode ? darkTheme : lightTheme;
  const fileId = file?.id || file?.name;

  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([]);
  const lessonsPath = 'users/' + ownerEmail + '/files/' + fileId + '/lessons';
  const days = file?.days ? file?.days : [];
  const [pageSize, setPageSize] = React.useState<number>(100);
  const { runEmptyLessonsOnboarding, emptyLessonsSteps } = useLessonsOnboarding(t, file!);
  const { columns } = useDataGrid(t, file!, user!, lessons);

  const readOnly = ownerEmail !== user?.email && shareMode === 'read';

  const rows: LessonDataGrid[] = [];
  lessons.map(function (lesson) {
    const subject: ISubject[] = subjects.filter((subject) => subject.name === lesson.subject[0]);
    if (subject.length === 0) return 0;
    return rows.push(
      createData(
        subject[0].short,
        subject[0].color,
        subject[0].photoURL,

        lesson.name,
        lesson.subject,
        lesson.lessonsWeek,
        lesson.split,
        (lesson.minGapLessons === 0 ? '↓' : lesson.minGapLessons) +
          ' - ' +
          (lesson.maxGapLessons === days.length - 1 ? '↑' : lesson.maxGapLessons),

        lesson.classes,
        lesson.teachers,
        lesson.rooms,

        lesson.predefinedTimes.length,
        lesson.predefinedTimes,
        lesson.simultaneousWith,
        lesson.notSimultaneousWith,
        lesson.occurBefore,
        (lesson.updatedAt as unknown as Timestamp).toDate()
      )
    );
  });

  const handleClick = (name: string) => {
    history.push(`/lessons/${name}`);
  };

  const [loadingOpen, setLoadingOpen] = React.useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);

  const checkDeleteConfirmation = () => {
    if (deleteConfirmed) {
      setLoadingOpen(true);
      setDeleteConfirmed(false);
      const batch = writeBatch(db);
      selectionModel.forEach((selectedId) => {
        batch.delete(doc(db, lessonsPath, selectedId.toString()));
      });
      // Update file stats
      batch.update(doc(db, `users/${ownerEmail}/files/${user?.selectedFile}`), {
        updatedAt: new Date(),
      });
      batch.commit().then(() => {
        setLoadingOpen(false);
        toast.success(selectionModel.length + t(' lessons deleted sucessfully'));
      });
    }
  };

  checkDeleteConfirmation();

  const [importOpen, setImportOpen] = useState(false);
  const [videoPlayerDialogOpen, setVideoPlayerDialogOpen] = useState(false);

  const handleImportClick = async () => {
    setImportOpen(true);
  };

  useEnterKey(handleCreate);
  function handleCreate() {
    history.push(`/lessons/_NEW_`);
  }

  return (
    <PageContainer>
      <Onboarding steps={emptyLessonsSteps} run={runEmptyLessonsOnboarding} storageFlagKey={EMPTY_LESSONS_KEY} />
      <DraggableDialog
        open={deleteConfirmationOpen}
        setOpen={setDeleteConfirmationOpen}
        setConfirmed={setDeleteConfirmed}
        title={t('Warning')}
        message={selectionModel.length + t(' lessons will be permanently deleted. Are you sure?')}
      />
      {loadingOpen && <SimpleBackdrop open={loadingOpen} setOpen={setLoadingOpen} />}
      {videoPlayerDialogOpen && (
        <VideoPlayerDialog
          open={videoPlayerDialogOpen}
          setOpen={setVideoPlayerDialogOpen}
          videoURL={t('video_url_help_lessons')}
        ></VideoPlayerDialog>
      )}
      <ImportStepperDialog open={importOpen} setOpen={setImportOpen} title={t('Import lessons')} type={'lesson'} />
      <MiniDrawer />
      <Grid container justifyContent="center">
        <Paper elevation={3} sx={{ width: '100%', maxWidth: 'lg' }}>
          <Grid container item xs={12} justifyContent={'space-between'} padding={2} spacing={1} flexDirection={'row'}>
            <Grid item flexGrow={1}>
              <Typography variant="body1" gutterBottom color={theme.palette.primary.main}>
                {'6. ' + t('Lessons')}
              </Typography>
              <Divider color={theme.palette.primary.main} />
            </Grid>
            <TooltipIconButton
              tooltip={t('Watch a helpful video')}
              Icon={QuestionMark}
              color={theme.palette.primary.main}
              onClick={() => setVideoPlayerDialogOpen(true)}
            />
            <TooltipIconButton
              tooltip={t('Download')}
              Icon={Download}
              color={theme.palette.primary.main}
              onClick={() => handleDownloadLessonsCSV(t, lessons, file!, file!.name + '_lessons')}
            />
            {selectionModel.length !== 0 ? (
              <Grid item xs={3} md={2}>
                <Button
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled={readOnly}
                  onClick={() => setDeleteConfirmationOpen(true)}
                  color="error"
                >
                  {`${t('Delete')} (${selectionModel.length})`}
                </Button>
              </Grid>
            ) : (
              <Grid item xs={3} md={2}>
                <Button
                  className="import-button"
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled={readOnly}
                  onClick={() => handleImportClick()}
                  color="primary"
                >
                  {t('Import')}
                </Button>
              </Grid>
            )}
            <Grid item xs={3} md={2}>
              <Button
                className="create-button"
                variant="contained"
                size="small"
                fullWidth
                disabled={readOnly}
                onClick={handleCreate}
                color="primary"
              >
                {t('Create')}
              </Button>
            </Grid>
            {/* </Grid> */}
          </Grid>
          <Grid className="lessons-list" item xs={12} sx={{ height: '80vh', width: '100%' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              density={'compact'}
              checkboxSelection
              disableSelectionOnClick
              onSelectionModelChange={(ids) => {
                setSelectionModel(ids);
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'subject', sort: 'asc' }],
                },
              }}
              localeText={user?.countryCode === 'BR' ? ptBR.components.MuiDataGrid.defaultProps.localeText : undefined}
              onRowClick={(params) => handleClick(params.id.toString())}
              components={{
                NoRowsOverlay: () => (
                  <Stack height="100%" alignItems="center" justifyContent="center">
                    <HighlightAlt style={{ color: grey[500], textAlign: 'center' }} />
                    <Typography color={grey[500]} textAlign="center">
                      {t('Click on IMPORT or CREATE to add lessons')}
                    </Typography>
                  </Stack>
                ),
                NoResultsOverlay: () => (
                  <Stack height="100%" alignItems="center" justifyContent="center">
                    <SearchOff style={{ color: grey[500], textAlign: 'center' }} />
                    <Typography color={grey[500]} textAlign="center">
                      {t('Local FILTER returns no data')}
                    </Typography>
                  </Stack>
                ),
              }}
              sx={{
                '& .MuiDataGrid-row:hover': {
                  cursor: 'pointer',
                },
                ...(isDarkMode ? darkModeScrollBar : {}),
              }}
            />
          </Grid>
          <Grid item container xs={12} spacing={1} justifyContent={'center'} paddingY={1}>
            <Grid item xs={2}>
              <Button
                variant="outlined"
                size="small"
                fullWidth
                onClick={() => history.push('/subjects')}
                color="primary"
              >
                {t('Back')}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="outlined"
                size="small"
                fullWidth
                onClick={() => history.push('/solutions')}
                color="primary"
              >
                {t('Next')}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </PageContainer>
  );
}
