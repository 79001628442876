import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import DialogContainer from 'containers/DialogContainer';
import { useTranslation } from 'react-i18next';

export default function DraggableDialog(props: any) {
  const { t } = useTranslation();
  const open = props.open;
  const setOpen = props.setOpen;
  const setConfirmed = props.setConfirmed;
  const title = props.title;
  const message = props.message;
  const confirmLabel = props.confirmLabel;

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    setConfirmed(true);
    setOpen(false);
  };

  return (
    <div>
      <DialogContainer open={open} onClose={handleClose}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            {t('Cancel')}
          </Button>
          <Button onClick={handleConfirm}>{confirmLabel || t('Confirm')}</Button>
        </DialogActions>
      </DialogContainer>
    </div>
  );
}
