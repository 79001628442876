import { FormatQuote } from '@mui/icons-material';
import { Card, CardContent, Typography, Avatar, Box } from '@mui/material';
import { TFunction } from 'react-i18next';
import { customColors } from 'styles';

type Props = {
  t: TFunction<'translation', undefined>;
  imgPath: string;
  personName: string;
  position: string;
  message: string;
};
export default function TestmonialCard({ t, imgPath, personName, position, message }: Props) {
  return (
    <Card
      sx={{
        maxWidth: 400,
        paddingBlock: '16px',
        paddingInline: '28px',
        marginTop: '32px',
        borderRadius: '12px',
        border: '1px solid #e0e0e0',
      }}
    >
      <Box display="flex" alignItems="center" mb={2}>
        <Avatar src={imgPath} sx={{ width: 56, height: 56 }}></Avatar>
        <Box ml={2}>
          <Typography variant="h6" fontWeight="bold">
            {personName}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {t(position)}
          </Typography>
        </Box>
      </Box>
      <CardContent sx={{ paddingInline: 0 }}>
        <FormatQuote sx={{ color: customColors.optables, height: 20, transform: 'scaleX(-1)' }} />
        <Typography variant="body2" color="textSecondary" component="span">
          {t(message)}
        </Typography>
        <FormatQuote sx={{ color: customColors.optables, height: 20 }} />
      </CardContent>
    </Card>
  );
}
