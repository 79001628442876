import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { doc, updateDoc } from 'firebase/firestore';
import { useHistory } from 'react-router-dom';
import { SessionContext } from 'contexts';
import { auth } from 'services';
import { db } from 'services';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import {
  useMediaQuery,
  SelectChangeEvent,
  Box,
  CssBaseline,
  Toolbar,
  Grid,
  Stack,
  Typography,
  Tooltip,
  Select,
  MenuItem,
  IconButton,
  Avatar,
  Menu,
} from '@mui/material';
import { DarkMode, LightMode } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import { darkTheme, lightTheme } from 'styles/material';

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function MiniDrawerFiles() {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const widthLessThan600 = useMediaQuery('(max-width:600px)');

  const { authUser, user, isDarkMode, setIsDarkMode } = React.useContext(SessionContext);
  const theme = isDarkMode ? darkTheme : lightTheme;
  const userDoc = doc(db, `users/${authUser?.email}`);
  const history = useHistory();

  const languageOptions = [
    { code: 'BR', label: 'PT', description: 'Português (PT)' },
    { code: 'US', label: 'EN', description: 'English (US)' },
    { code: 'ES', label: 'ES', description: 'Español (ES)' },
    { code: 'DE', label: 'DE', description: 'Deutsch (DE)' },
  ];

  function signOut() {
    auth
      .signOut()
      .then(() => {
        history.push('/');
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [currentCountryCode, setCurrentCountryCode] = React.useState<string | undefined>(
    languageOptions.find((option) => option.label === i18n.language.toUpperCase())
      ? languageOptions.find((option) => option.label === i18n.language.toUpperCase())?.code
      : 'US'
  );

  React.useEffect(() => {
    setCurrentCountryCode(user?.countryCode);
    if (user?.countryCode === 'US') i18n.changeLanguage('en');
    else if (user?.countryCode === 'BR') i18n.changeLanguage('pt');
  }, [user?.email, user?.countryCode, i18n]);

  const handleCountryCodeSelectChange = (event: SelectChangeEvent) => {
    if (event.target.value === 'US') {
      i18n.changeLanguage('en');
      toast.success(
        'Language set to ' + languageOptions.find((option) => option.code === event.target.value)?.description
      );
    } else if (event.target.value === 'BR') {
      i18n.changeLanguage('pt');
      toast.success(
        'Idioma definido como ' + languageOptions.find((option) => option.code === event.target.value)?.description
      );
    } else if (event.target.value === 'ES') {
      toast.warning('Aún no disponible en ESPAÑOL.. Utilice la función del navegador para traducir la página');
    } else if (event.target.value === 'DE') {
      toast.warning('Noch nicht auf DEUTSCH verfügbar.. Bitte benutzen Sie die Übersetzungsfunktion des Browsers');
    }
    updateDoc(userDoc, {
      countryCode: event.target.value,
    });
    setCurrentCountryCode(event.target.value);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={false} color="primary">
        <Toolbar variant="dense">
          <Grid container item xs={12} spacing={1} justifyContent={'space-between'} paddingRight={2}>
            <Grid item xs={9} sm={8} lg={4}>
              <Stack direction="row" alignItems={'center'} spacing={1}>
                {<img height={20} width={20} src="/img/logoWhite.png" alt="Tiza logo" loading="lazy" />}
                {!widthLessThan600 && <Typography color="#ffffff">{'Tiza'}</Typography>}
              </Stack>
            </Grid>
            <Grid item xs={3} sm={2} lg={1}>
              <Tooltip placement="left-end" title={t('Language')}>
                <Select
                  id="demo-select-small"
                  value={'' + currentCountryCode}
                  size="small"
                  fullWidth
                  variant="standard"
                  onChange={handleCountryCodeSelectChange}
                  sx={{
                    outline: 'none',
                    color: widthLessThan600 ? theme.palette.primary.main : '#ffffff',
                    paddingBottom: '1px',
                  }}
                >
                  {languageOptions.map((option, index) => {
                    return (
                      <MenuItem key={`${index}`} value={option.code} sx={{ minWidth: '100%' }}>
                        <Box
                          alignContent="center"
                          alignItems="center"
                          display="flex"
                          sx={{
                            '& > img': { mr: 1, flexShrink: 0 },
                            color: widthLessThan600 ? theme.palette.primary.main : 'default',
                          }}
                        >
                          <img
                            loading="lazy"
                            width="26"
                            height="auto"
                            src={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                            alt="Country flag"
                          />
                          {option.label}
                        </Box>
                      </MenuItem>
                    );
                  })}
                </Select>
              </Tooltip>
            </Grid>
          </Grid>
          <Tooltip title={t('Switch to light/dark mode')}>
            <IconButton
              sx={{ marginInline: 2, color: grey[100] }}
              size="small"
              onClick={() => setIsDarkMode((isDarkMode) => !isDarkMode)}
            >
              {isDarkMode ? <DarkMode /> : <LightMode />}
            </IconButton>
          </Tooltip>
          {user && (
            <div>
              <Tooltip title={user?.name === undefined ? '' : user?.name} placement="right-end">
                <IconButton
                  size="small"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <Avatar sx={{ width: 32, height: 32 }} alt={user?.name} src={user?.photoURL} />
                </IconButton>
              </Tooltip>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    history.push('/settings');
                  }}
                >
                  {t('Profile')}
                </MenuItem>
                <MenuItem onClick={signOut}>{t('Sign Out')}</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
