import OnboardingContent from 'components/OnboardingContent';
import { useEffect, useState } from 'react';
import { TFunction } from 'react-i18next';

export const EMPTY_SUBJECTS_KEY = 'onboarding-empty-list';
export const useSubjectsOnboarding = (t: TFunction<'translation', undefined>) => {
  const [runEmptySubjectsOnboarding, setRunEmptySubjectsOnboarding] = useState(false);
  useEffect(() => {
    if (localStorage.getItem(EMPTY_SUBJECTS_KEY) !== 'true') {
      setRunEmptySubjectsOnboarding(true);
    }
  }, []);

  const emptySubjectSteps = [
    {
      target: '.subjects-list',
      disableBeacon: true,
      content: (
        <OnboardingContent
          title={t('List of subjects')}
          message={t('All subjects of your project are displayed here')}
        />
      ),
    },
    {
      target: '.import-button',
      content: (
        <OnboardingContent
          title={t('Import subjects')}
          message={t(
            'You can import subjects from any spreasheet software, just head to your existing spreadsheet, select the data, hit CTRL+C, then click this button'
          )}
        />
      ),
    },
    {
      target: '.create-button',
      content: (
        <OnboardingContent title={t('Add subject')} message={t('To add a subject manually, click CREATE button')} />
      ),
    },
  ];

  return { runEmptySubjectsOnboarding, emptySubjectSteps };
};
