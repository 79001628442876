import { Alert, Box, DialogContentText, Grid, Typography } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { TFunction } from 'react-i18next';
import ExportOptionCard from './Card/ExportOptionCard';
import { Info } from '@mui/icons-material';

type Props = {
  t: TFunction;
  selectedFormat: string;
  setSelectedFormat: Dispatch<SetStateAction<string>>;
  setInfoDialogOpen: Dispatch<SetStateAction<boolean>>;
};

export default function ImportMethodSelection({ t, selectedFormat, setSelectedFormat, setInfoDialogOpen }: Props) {
  return (
    <Box paddingInline={'10%'}>
      <DialogContentText paddingTop={4} paddingBottom={1}>
        {t('Select the import method:')}
      </DialogContentText>
      <Grid container spacing={2} paddingTop={1} alignItems="flex-start">
        <Grid container item spacing={1} direction={'row'} alignItems={'center'}>
          <Grid item>
            <ExportOptionCard
              title={t('Clipboard')}
              image={'/img/exportFormats/clipboard.png'}
              isSelected={selectedFormat === t('Clipboard')}
              setSelected={setSelectedFormat}
            />
          </Grid>
          <Grid item>
            <ExportOptionCard
              title={t('XLS/XLSX file')}
              image={'/img/exportFormats/xls.png'}
              isSelected={selectedFormat === t('XLS/XLSX file')}
              setSelected={setSelectedFormat}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Alert icon={<Info fontSize="inherit" />} severity="info">
            <Box display="flex" alignItems="center">
              <Typography>{t('Please ensure your data matches the ')}</Typography>
              <Typography
                onClick={() => setInfoDialogOpen(true)}
                sx={{
                  paddingInline: 0.5,
                  color: 'primary.main',
                  cursor: 'pointer',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                {t('expected input format')}
              </Typography>
              <Typography>{t(' before importing.')}</Typography>
            </Box>
          </Alert>
        </Grid>
      </Grid>
    </Box>
  );
}
