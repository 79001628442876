import React from 'react';

type Props = {
  color?: string;
  width: string;
  height: string;
};

export default function Step({ width, height, color = 'white' }: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 180 180" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M54 104C50.6863 104 48 106.686 48 110C48 113.314 50.6863 116 54 116V104ZM54 116L102 116V104L54 104V116Z"
        fill={color}
      />
      <path
        d="M145.993 64.7998C149.307 64.855 152.037 62.2139 152.093 58.9006C152.148 55.5874 149.507 52.8567 146.193 52.8015L145.993 64.7998ZM98.0001 63.9999L145.993 64.7998L146.193 52.8015L98.2001 52.0016L98.0001 63.9999Z"
        fill={color}
      />
      <line x1="104" y1="60" x2="104" y2="116" stroke={color} strokeWidth="12" />
      <path
        d="M38 30C38 26.6863 35.3137 24 32 24C28.6863 24 26 26.6863 26 30L38 30ZM38 150L38 30L26 30L26 150L38 150Z"
        fill={color}
      />
      <path
        d="M146 156C149.314 156 152 153.314 152 150C152 146.686 149.314 144 146 144V156ZM26 156H146V144H26V156Z"
        fill={color}
      />
      <path d="M52 49L32 29" stroke={color} strokeWidth="12" strokeLinecap="round" />
      <path d="M146 150L126 130" stroke={color} strokeWidth="12" strokeLinecap="round" />
      <path
        d="M7.75736 45.7574C5.41421 48.1005 5.41421 51.8995 7.75736 54.2426C10.1005 56.5858 13.8995 56.5858 16.2426 54.2426L7.75736 45.7574ZM16.2426 54.2426L36.2426 34.2426L27.7574 25.7574L7.75736 45.7574L16.2426 54.2426Z"
        fill={color}
      />
      <path d="M12 49L32 29" stroke={color} strokeWidth="12" strokeLinecap="round" />
      <path d="M126 170L146 150" stroke={color} strokeWidth="12" strokeLinecap="round" />
    </svg>
  );
}
