import {
  Avatar,
  Chip,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { grey, blue, green } from '@mui/material/colors';
import { GridColDef } from '@mui/x-data-grid';
import { IUser } from 'contexts/SessionContext/SessionContext';
import dayjs from 'dayjs';
import { IFile } from 'pages/Files/File';
import { TFunction } from 'react-i18next';
import {
  DAYS_BETWEEN_MEETINGS,
  getLessonsConstrAppliesTo,
  NOT_SIMULTANEOUS_WITH,
  OCCUR_BEFORE,
  SIMULTANEOUS_WITH,
} from 'util/constraintUtils';
import { DAY_TIME_SEPARATOR } from 'util/solutionUtils';
import { ILesson } from './types';
import { getConstrBounds } from 'util/validationUtils';

export interface LessonDataGrid {
  short: string;
  color: string;
  photoURL: string;

  id: string;
  subject: string[];
  lessonsWeek: number;
  split: string[];
  gapLessons: string;

  classes: string[];
  teachers: string[];
  rooms: string[];

  availability: number;
  predefinedTimes: string[];
  simultaneousWith: string[];
  notSimultaneousWith: string[];
  occurBefore: string[];
  updatedAt: Date;
}

export function createData(
  short: string,
  color: string,
  photoURL: string,

  id: string,
  subject: string[],
  lessonsWeek: number,
  split: string[],
  gapLessons: string,

  classes: string[],
  teachers: string[],
  rooms: string[],

  availability: number,
  predefinedTimes: string[],
  simultaneousWith: string[],
  notSimultaneousWith: string[],
  occurBefore: string[],
  updatedAt: Date
): LessonDataGrid {
  return {
    short,
    color,
    photoURL,

    id,
    subject,
    lessonsWeek,
    split,
    gapLessons,

    classes,
    teachers,
    rooms,

    availability,
    predefinedTimes,
    simultaneousWith,
    notSimultaneousWith,
    occurBefore,
    updatedAt,
  };
}

const strArrayComparator: any = (v1: string[], v2: string[]) => {
  if (v1.length !== v2.length) {
    return v1.length - v2.length;
  } else {
    return ('' + v1[0]).localeCompare('' + v2[0]);
  }
};

export const useDataGrid = (t: TFunction<'translation', undefined>, file: IFile, user: IUser, lessons: ILesson[]) => {
  const widthLessThan600 = useMediaQuery('(max-width:600px)');
  const widthLessThan900 = useMediaQuery('(max-width:900px)');
  const constrBounds = getConstrBounds(file.days.length, file.times.length);

  const columns: GridColDef[] = [
    {
      field: 'short',
      headerName: ' ',
      minWidth: 32,
      flex: 0.0001,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      hide: true,
      renderCell: (params) => {
        return (
          <Grid item container alignItems="center" justifyContent="center">
            <Avatar
              translate="no"
              sx={{
                width: 28,
                height: 28,
                fontSize: params.row.short.length > 3 ? '8px' : '12px',
                bgcolor: params.row.color,
              }}
              alt={params.value}
              src={params.row.photoURL}
            >
              {params.row.short}
            </Avatar>
          </Grid>
        );
      },
    },
    {
      field: 'id',
      headerName: t('Name'),
      flex: 1,
      hide: true,
      renderCell: (params) => {
        return <Chip translate="no" variant="outlined" size="small" label={params.value} />;
      },
    },
    {
      field: 'subject',
      headerName: t('Subject'),
      flex: 4,
      minWidth: 150,
      sortComparator: strArrayComparator,
      renderCell: (params) => {
        return params.row.subject.map((sub: string) => {
          return (
            <Chip
              translate="no"
              key={sub}
              variant="outlined"
              size="small"
              sx={{ backgroundColor: params.row.color, color: 'white', border: '0px' }}
              label={sub}
            />
          );
        });
      },
    },
    {
      field: 'classes',
      headerName: file?.studentsSetting !== 0 ? t('Class') : t('Students'),
      flex: 2,
      minWidth: 150,
      sortComparator: strArrayComparator,
      renderCell: (params) => {
        return params.row.classes.map((class_: string, index: number) => {
          if (index === 0) {
            return (
              <Stack translate="no" key={index} direction="row" spacing={0} alignItems={'center'}>
                <Chip variant="outlined" size="small" label={class_} />
              </Stack>
            );
          } else return <Chip translate="no" key={index} variant="outlined" size="small" label={class_} />;
        });
      },
    },
    {
      field: 'teachers',
      headerName: t('Teacher'),
      flex: 4,
      minWidth: 150,
      // hide: widthLessThan900,
      sortComparator: strArrayComparator,
      renderCell: (params) => {
        return params.row.teachers.map((teacher: string, index: number) => {
          if (index === 0) {
            return (
              <Stack translate="no" key={index} direction="row" spacing={0} alignItems={'center'}>
                <Chip variant="outlined" size="small" label={teacher} />
              </Stack>
            );
          } else return <Chip translate="no" key={index} variant="outlined" size="small" label={teacher} />;
        });
      },
    },
    {
      field: 'rooms',
      headerName: t('Room'),
      flex: 2,
      minWidth: 100,
      // hide: widthLessThan900 || file?.roomsSetting === 0,
      sortComparator: strArrayComparator,
      renderCell: (params) => {
        return params.row.rooms.map((room: string, index: number) => {
          if (index === 0) {
            return (
              <Stack translate="no" key={index} direction="row" spacing={0} alignItems={'center'}>
                <Chip variant="outlined" size="small" label={room} />
              </Stack>
            );
          } else return <Chip translate="no" key={index} variant="outlined" size="small" label={room} />;
        });
      },
    },
    {
      field: 'lessonsWeek',
      headerName: t('Times'),
      flex: 1,
      minWidth: 100,
      hide: widthLessThan600,
    },
    {
      field: 'split',
      headerName: t('Split'),
      flex: 2,
      minWidth: 100,
      // hide: true,
      renderCell: (params) => {
        return params.row.split.map((split: string, index: number) => {
          return <Chip key={index} variant="outlined" size="small" label={params.row.split[index]} />;
        });
      },
    },
    {
      field: 'gapLessons',
      headerName: t(DAYS_BETWEEN_MEETINGS),
      flex: 2,
      minWidth: 50,
      hide:
        true ||
        widthLessThan900 ||
        getLessonsConstrAppliesTo(DAYS_BETWEEN_MEETINGS, constrBounds, lessons).length === 0,
    },
    {
      field: 'simultaneousWith',
      headerName: t(SIMULTANEOUS_WITH),
      flex: 2,
      minWidth: 100,
      hide:
        true || widthLessThan900 || getLessonsConstrAppliesTo(SIMULTANEOUS_WITH, constrBounds, lessons).length === 0,
      renderCell: (params) => {
        return params.row.simultaneousWith.map((s: string, index: number) => {
          return <Chip key={index} variant="outlined" size="small" label={params.row.simultaneousWith[index]} />;
        });
      },
    },
    {
      field: 'notSimultaneousWith',
      headerName: t(NOT_SIMULTANEOUS_WITH),
      flex: 2,
      minWidth: 100,
      hide:
        true ||
        widthLessThan900 ||
        getLessonsConstrAppliesTo(NOT_SIMULTANEOUS_WITH, constrBounds, lessons).length === 0,
      renderCell: (params) => {
        return params.row.notSimultaneousWith.map((s: string, index: number) => {
          return <Chip key={index} variant="outlined" size="small" label={params.row.notSimultaneousWith[index]} />;
        });
      },
    },
    {
      field: 'occurBefore',
      headerName: t(OCCUR_BEFORE),
      flex: 2,
      minWidth: 100,
      hide: widthLessThan900 || getLessonsConstrAppliesTo(OCCUR_BEFORE, constrBounds, lessons).length === 0,
      renderCell: (params) => {
        return params.row.occurBefore.map((s: string, index: number) => {
          return <Chip key={index} variant="outlined" size="small" label={params.row.occurBefore[index]} />;
        });
      },
    },
    {
      field: 'availability',
      headerName: t('Predefined Times'),
      flex: 1,
      minWidth: 160,
      // hide: widthLessThan900,
      renderCell: (params) => {
        const cellHeight = 28 / file!.times.length;
        return (
          <TableContainer>
            <Table translate="no" size="small" padding="none">
              <TableBody>
                {file.times.map((_, t_index) => {
                  return (
                    <TableRow key={`time_${t_index}`}>
                      {file.days.map((_, d_index) => {
                        return (
                          <TableCell
                            key={`cell_${d_index}_${t_index}`}
                            sx={{
                              border: 0.5,
                              borderColor: grey[600],
                              height: `${cellHeight}px`,
                              backgroundColor: `${
                                params.row.predefinedTimes.includes(d_index + DAY_TIME_SEPARATOR + t_index)
                                  ? blue[200]
                                  : green[200]
                              }`,
                            }}
                            align="center"
                          ></TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        );
      },
    },
    {
      field: 'updatedAt',
      headerName: t('Updated At'),
      flex: 1,
      minWidth: 130,
      headerAlign: 'right',
      align: 'right',
      hide: true,
      renderCell: (params) => {
        return (
          <Typography fontSize={14}>
            {user?.countryCode === 'BR'
              ? dayjs(params.row.updatedAt).format('DD/MM/YY HH:mm[h]')
              : dayjs(params.row.updatedAt).format('MM-DD-YY hh:mma')}
          </Typography>
        );
      },
    },
  ];

  return { columns };
};
