import { Stripe, loadStripe } from '@stripe/stripe-js';
import { environment } from 'configs/environment';

let stripePromisse: Stripe | null;

const initializeStripe = async () => {
  if (!stripePromisse && environment.stripeKey) {
    stripePromisse = await loadStripe(environment.stripeKey);
  }
  return stripePromisse;
};

export default initializeStripe;
