import { SelectChangeEvent, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { ShareUser } from 'pages/Files/File';

type Props = {
  shared: ShareUser;
  current: string;
  options: string[];
  label: string;
  maxWidth?: number;
  handleChange: (event: SelectChangeEvent, shared: ShareUser) => void;
};

export default function ShareModeSelect({ current, shared, options, label, handleChange }: Props) {
  return (
    <FormControl fullWidth size="small">
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={'' + current}
        label={label}
        fullWidth
        onChange={(event) => handleChange(event, shared)}
      >
        {options.map((option, index) => {
          return (
            <MenuItem key={`${label}_${index}`} value={option}>
              {option}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
