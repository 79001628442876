import React from 'react';

type Props = {
  color?: string;
  width: string;
  height: string;
};

export default function Quadratic({ width, height, color = 'white' }: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 180 180" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M38 30C38 26.6863 35.3137 24 32 24C28.6863 24 26 26.6863 26 30L38 30ZM38 150L38 30L26 30L26 150L38 150Z"
        fill={color}
      />
      <path
        d="M146 156C149.314 156 152 153.314 152 150C152 146.686 149.314 144 146 144V156ZM26 156H146V144H26V156Z"
        fill={color}
      />
      <path d="M52 49L32 29" stroke={color} strokeWidth="12" strokeLinecap="round" />
      <path d="M146 150L126 130" stroke={color} strokeWidth="12" strokeLinecap="round" />
      <path
        d="M62 113.5C116.4 113.5 134.333 63.8333 136.5 39"
        stroke={color}
        fill="none"
        strokeWidth="12"
        strokeLinecap="round"
      />
      <path
        d="M7.75736 45.7574C5.41421 48.1005 5.41421 51.8995 7.75736 54.2426C10.1005 56.5858 13.8995 56.5858 16.2426 54.2426L7.75736 45.7574ZM16.2426 54.2426L36.2426 34.2426L27.7574 25.7574L7.75736 45.7574L16.2426 54.2426Z"
        fill="none"
      />
      <path d="M12 49L32 29" stroke={color} strokeWidth="12" strokeLinecap="round" />
      <path d="M126 170L146 150" stroke={color} strokeWidth="12" strokeLinecap="round" />
    </svg>
  );
}
