import { Autocomplete, Box, TextField } from '@mui/material';
import { countries } from 'util/countries';

type Props = {
  disabled: boolean;
  label: string;
  country: string;
  setCountry: React.Dispatch<React.SetStateAction<string>>;
  setCountryCode: React.Dispatch<React.SetStateAction<string>>;
};

export default function CountrySelect({ disabled, label, country, setCountry, setCountryCode }: Props) {
  return (
    <Autocomplete
      id="country-select-demo"
      fullWidth
      options={countries}
      size="small"
      defaultValue={
        country
          ? countries[
              countries.indexOf(
                countries.filter(function (c) {
                  return c.label === country;
                })[0]
              )
            ]
          : countries[29]
      }
      autoHighlight
      disabled={disabled}
      onChange={(e, value) => {
        setCountry(value?.label as string);
        setCountryCode(value?.code as string);
      }}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=""
          />
          {option.label}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="standard"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}
