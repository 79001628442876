import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter } from 'react-router-dom';
import { Analytics } from '@vercel/analytics/react';
import { ErrorBoundary } from 'react-error-boundary';
import { Routes } from 'routes';
import { GlobalStyle } from 'styles';
import { SessionProvider } from 'contexts';
import { CssBaseline } from '@mui/material';
import ErrorBoundaryDialog from 'components/Dialog/ErrorBoundaryDialog';

const App = () => {
  return (
    <>
      <Analytics />
      <ToastContainer autoClose={4000} position="bottom-left" theme="dark" />
      <CssBaseline />
      <GlobalStyle />
      <BrowserRouter>
        <ErrorBoundary
          FallbackComponent={ErrorBoundaryDialog}
          // @TODO do some error logging
          onReset={() => {}}
        >
          <SessionProvider>
            <Routes />
          </SessionProvider>
        </ErrorBoundary>
      </BrowserRouter>
    </>
  );
};

export { App };
