import { useTranslation } from 'react-i18next';
import { SessionContext } from 'contexts';
import { useContext, useEffect, useState } from 'react';
import { ShareUser } from 'pages/Files/File';
import ShareModeSelect from '../Select/ShareModeSelect';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { toast } from 'react-toastify';
import { collection, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from 'services';
import { SharedWithMe } from 'contexts/SessionContext/SessionContext';
import { Delete, WorkspacePremium } from '@mui/icons-material';
import { BASIC, FREE } from 'configs/planFeatures';
import PlanInfoDialog from './PlanInfoDialog';
import { useHistory } from 'react-router-dom';
import { customColors } from 'styles';
import { sendEmail } from 'util/emailUtils';
import DialogContainer from 'containers/DialogContainer';

interface ModeOptionsMap {
  [key: string]: string;
}

export default function FileShareDialog(props: any) {
  const { t } = useTranslation();
  const { user, files, userPlan } = useContext(SessionContext);
  const open = props.open;
  const setOpen = props.setOpen;
  const clickedFile = props.clickedFile;
  const file = files.find((file) => file.name === clickedFile.name);
  const history = useHistory();

  const [sharedWith, setSharedWith] = useState(file?.sharedWith);
  const [showUserPlanDialog, setShowUserPlanDialog] = useState(false);

  useEffect(() => {
    setSharedWith(file?.sharedWith);
  }, [file]);

  const handleConfirm = () => {
    if (userPlan === FREE || userPlan === BASIC) {
      setShowUserPlanDialog(true);
      setOpen(false);
      return;
    }
    if (file && user) {
      const oldFileSharedWith = file.sharedWith;
      const oldSharedWith = sharedWith;
      //Update file
      const fileDoc = doc(db, 'users/' + user?.email + '/files/' + (file?.id || file?.name));

      updateDoc(fileDoc, {
        sharedWith: sharedWith,
      })
        .then(() => {
          //Update sharings
          sharedWith?.forEach(async (share) => {
            const sharingDocRef = doc(db, 'sharings/' + share.user);
            getDoc(sharingDocRef).then(async (docSnap) => {
              if (docSnap.exists()) {
                //Update sharings of this user
                const sharingDoc = docSnap.data().sharedWithMe as SharedWithMe[];
                const updatedFilesSharedWithUser = sharingDoc.filter(
                  (shared) => shared.file !== (file?.id || file?.name)
                );
                updatedFilesSharedWithUser?.push({ user: user?.email, file: file?.id || file?.name, mode: share.mode });
                updateDoc(sharingDocRef, {
                  sharedWithMe: updatedFilesSharedWithUser,
                })
                  .then(() => {
                    // toast.success(t('Project shared successfully with ') + share.user);
                  })
                  .catch((error) => {
                    console.error(error);
                    toast.error(t('Error sharing project with ') + share.user);
                  });
              } else {
                const sharingRef = collection(db, 'sharings');
                const newSharing = [
                  { user: user?.email, file: file?.id || file?.name, mode: share.mode } as SharedWithMe,
                ];
                await setDoc(doc(sharingRef, share.user), { sharedWithMe: newSharing })
                  .then(() => {
                    // toast.success(t('Project shared successfully with ') + share.user);
                  })
                  .catch((error) => {
                    console.error(error);
                    toast.error(t('Error sharing project with ') + share.user);
                  });
              }
            });
          });

          //Remove sharing with users that are not in the new list
          oldFileSharedWith?.forEach(async (oldShare) => {
            if (!sharedWith?.find((newShare) => newShare.user === oldShare.user)) {
              const sharingDocRef = doc(db, 'sharings/' + oldShare.user);
              getDoc(sharingDocRef).then(async (docSnap) => {
                if (docSnap.exists()) {
                  const sharingDoc = docSnap.data().sharedWithMe as SharedWithMe[];
                  const updatedSharing = sharingDoc.filter((shared) => shared.file !== (file?.id || file?.name));
                  updateDoc(sharingDocRef, {
                    sharedWithMe: updatedSharing,
                  }).catch((error) => {
                    toast.error(t('Error unsharing project: ') + error);
                  });
                }
              });
            }
          });
        })
        .catch((error) => {
          toast.error(t('Error sharing project: ') + error);
        });

      oldFileSharedWith
        ?.filter((sharing) => !oldSharedWith?.includes(sharing))
        .forEach((sharing) => {
          toast.success(t(`Project successfully unshared in ${sharing.mode} mode with `) + sharing.user);
        });
      oldSharedWith
        ?.filter((sharing) => !oldFileSharedWith?.includes(sharing))
        .forEach((sharing) => {
          sendEmail(
            'Tiza app',
            sharing.user,
            `${user.name} ${t('has shared the timetabling project')} ${file.name} ${t(
              `in ${sharing.mode} mode with you. Create an account for free at https://www.tiza.app to access it.`
            )}`
          );
          toast.success(t(`Project successfully shared in ${sharing.mode} mode with `) + sharing.user);
        });
    }

    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const modeMap: ModeOptionsMap = {
    read: 'Read',
    write: 'Write',
  };
  modeMap['read'] = 'Read';
  modeMap['write'] = 'Write';

  const modeReverseMap: ModeOptionsMap = {
    Read: 'read',
    Write: 'write',
    Leitor: 'read',
    Editor: 'write',
  };

  const handleDeleteClick = (event: React.MouseEvent<unknown>, shared: ShareUser) => {
    setSharedWith(sharedWith?.filter((share) => share.user !== shared.user));
  };

  const handleChange = (event: SelectChangeEvent, shared: ShareUser) => {
    setSharedWith(
      sharedWith?.map((share) => {
        return shared.user === share.user ? { ...share, mode: modeReverseMap[event.target.value] } : share;
      })
    );
  };

  const validateEmail = (email: string) => {
    const emailPattern = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    return emailPattern.test(email);
  };

  const handleAddShare = () => {
    const emailInput = document?.getElementById('name') as HTMLInputElement;
    const emails = emailInput?.value.split(',');
    const newShares: ShareUser[] = [];

    emails.forEach((email: string) => {
      const trimmedEmail = email.trim();
      const repeatedEmail = sharedWith?.find((share) => share.user === trimmedEmail);
      if (repeatedEmail) {
        toast.warning(t('Project already shared with user: ') + trimmedEmail);
        return;
      }
      if (!validateEmail(trimmedEmail)) {
        toast.warning(t('Invalid email: ') + trimmedEmail);
        return;
      }
      if (user?.email === trimmedEmail) {
        toast.warning(t('User is already the owner of this project: ') + trimmedEmail);
        return;
      }
      newShares.push({ user: trimmedEmail, mode: 'read' });
    });

    setSharedWith(sharedWith?.concat(newShares));
    emailInput.value = '';
  };

  const handleNavToUpgradePlan = () => {
    setShowUserPlanDialog(false);
    history.push('/settings');
  };

  return (
    <div>
      <PlanInfoDialog
        open={showUserPlanDialog}
        positiveLabel={t('See plan options')}
        negativeLabel={t('Cancel')}
        positiveAction={handleNavToUpgradePlan}
        negativeAction={() => {
          setShowUserPlanDialog(false);
        }}
        title={
          <>
            <WorkspacePremium sx={{ fontSize: 20, color: customColors.premium, marginRight: 1 }} />
            {t('Premium tier feature')}
          </>
        }
        message={t('This feature is only available in the Premium plan. Please upgrade your plan to start using now!')}
      />

      <DialogContainer open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>{t('Share project')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('Provide the user emails to share this project with')}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={t('Emails (separeted by comma)')}
            fullWidth
            required
            variant="standard"
            translate="no"
            defaultValue={''}
          />
          <Button onClick={handleAddShare}>{t('Add')}</Button>
          {sharedWith?.map((shared) => (
            <Grid
              key={shared.user}
              container
              flexDirection={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Grid item xs={8}>
                <p>{shared.user}</p>
              </Grid>
              <Grid item xs={4} container flexDirection={'row'} justifyContent={'flex-end'} alignItems={'center'}>
                <Grid item flex={1}>
                  {/* <p>{shared.mode}</p> */}
                  <ShareModeSelect
                    shared={shared}
                    current={t(modeMap[shared.mode])}
                    handleChange={handleChange}
                    options={[t('Write'), t('Read')]}
                    label={t('Mode')}
                  />
                </Grid>
                <Grid item>
                  <IconButton aria-label="delete" onClick={(event) => handleDeleteClick(event, shared)}>
                    <Delete />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('Cancel')}</Button>
          <Button disabled={sharedWith?.length === 0 && file?.sharedWith?.length === 0} onClick={handleConfirm}>
            {t('Share')}
          </Button>
        </DialogActions>
      </DialogContainer>
    </div>
  );
}
