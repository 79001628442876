import { DialogTitle, DialogContent, DialogContentText } from '@mui/material';
import { grey } from '@mui/material/colors';
import { SessionContext } from 'contexts';
import React, { useContext } from 'react';

export default function OnboardingContent(props: any) {
  const title = props.title;
  const message = props.message;
  const { isDarkMode } = useContext(SessionContext);

  return (
    <React.Fragment>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ color: isDarkMode ? grey[600] : null }}>{message}</DialogContentText>
      </DialogContent>
    </React.Fragment>
  );
}
