import PriorityAverage from 'components/Icons/PriorityAverage';
import PriorityDisabled from 'components/Icons/PriorityDisabled';
import PriorityHigh from 'components/Icons/PriorityHigh';
import PriorityLow from 'components/Icons/PriorityLow';
import PriorityVeryHigh from 'components/Icons/PriorityVeryHigh';
import PriorityVeryLow from 'components/Icons/PriorityVeryLow';

export const getPriorityIcon = (index: number, color: string) => {
  switch (index) {
    case 0:
      return (
        <PriorityDisabled width={'20px'} height={'20px'} color={color} style={{ padding: '2px', marginRight: '8px' }} />
      );
    case 1:
      return (
        <PriorityVeryLow width={'20px'} height={'20px'} color={color} style={{ padding: '2px', marginRight: '8px' }} />
      );
    case 2:
      return (
        <PriorityLow width={'20px'} height={'20px'} color={color} style={{ padding: '2px', marginRight: '8px' }} />
      );
    case 3:
      return (
        <PriorityAverage width={'20px'} height={'20px'} color={color} style={{ padding: '2px', marginRight: '8px' }} />
      );
    case 4:
      return (
        <PriorityHigh width={'20px'} height={'20px'} color={color} style={{ padding: '2px', marginRight: '8px' }} />
      );
    case 5:
      return (
        <PriorityVeryHigh width={'20px'} height={'20px'} color={color} style={{ padding: '2px', marginRight: '8px' }} />
      );
    default:
      return (
        <PriorityAverage width={'20px'} height={'20px'} color={color} style={{ padding: '2px', marginRight: '8px' }} />
      );
  }
};
