import { Grid, Typography, Button, Box, colors } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { BASIC, FREE, PREMIUM } from 'configs/planFeatures';
import PricingCard from './PricingCard';
import { customColors, theme } from 'styles';

type Props = {
  currentCountryCode: string | undefined;
  handleGetStarted: () => void;
};

export default function Pricing({ currentCountryCode, handleGetStarted }: Props) {
  const { t } = useTranslation();
  return (
    <Grid container item xs={12} justifyContent="center">
      <Box sx={{ backgroundColor: 'white' }} paddingInline={'10%'} paddingTop={'64px'} paddingBottom={'80px'}>
        <Grid container spacing={4} padding={4} justifyContent="space-around">
          <Grid container item xs={12} paddingTop={4} justifyContent="center">
            <Typography variant="h3" color={grey[900]} align="center" component="div">
              {t('Pricing plans')}
            </Typography>
          </Grid>
          <PricingCard
            t={t}
            plan={FREE}
            currentCountryCode={currentCountryCode!}
            ButtonComponent={
              <Button
                fullWidth
                sx={{ backgroundColor: colors.grey[900], '&:hover': { backgroundColor: colors.grey[800] } }}
                variant="contained"
                onClick={handleGetStarted}
              >
                {t('Get started')}
              </Button>
            }
          />
          <PricingCard
            t={t}
            plan={BASIC}
            currentCountryCode={currentCountryCode!}
            ButtonComponent={
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                sx={{
                  backgroundColor: customColors.optables,
                  '&:hover': { backgroundColor: customColors.optablesDark },
                }}
                onClick={handleGetStarted}
              >
                {t('Subscribe')}
              </Button>
            }
            highlighted
          />
          <PricingCard
            t={t}
            plan={PREMIUM}
            currentCountryCode={currentCountryCode!}
            ButtonComponent={
              <Button
                fullWidth
                sx={{ backgroundColor: colors.grey[900], '&:hover': { backgroundColor: colors.grey[800] } }}
                variant="contained"
                onClick={handleGetStarted}
              >
                {t('Subscribe')}
              </Button>
            }
          />
        </Grid>
      </Box>
    </Grid>
  );
}
