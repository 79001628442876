import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { db } from 'services';
import { Timestamp, collection, doc, getDoc, getDocs, query, writeBatch } from 'firebase/firestore';
import { IFile } from 'pages/Files/File';
import { SessionContext } from 'contexts';
import DemoFileCard from '../Card/DemoFileCard';
import { toast } from 'react-toastify';
import FormDialog from './FormDialog';
import SimpleBackdrop from '../Loading/SimpleBackdrop';
import { IResource } from 'pages/Resources/Resource';
import { ISubject } from 'pages/Subjects/Subject';
import { ILesson } from 'pages/Lessons/utils/types';
import { ISolution } from 'pages/Solutions/Solution';
import { useHistory } from 'react-router-dom';
import { Dialog, DialogTitle, DialogContent, DialogContentText, Grid, DialogActions, Button } from '@mui/material';
import { PaperComponent } from 'util/dialogUtils';
import { darkModeScrollBar } from 'util/themeUtils';
import DialogContainer from 'containers/DialogContainer';
import { environment } from 'configs/environment';

export default function DemoProjectsDialog(props: any) {
  const { t } = useTranslation();
  const history = useHistory();
  const open = props.open;
  const setOpen = props.setOpen;
  const title = props.title;
  const message = props.message;

  const { user, files, authUser, isDarkMode } = useContext(SessionContext);
  const filesPath = `users/${authUser?.email}/files`;
  const userDoc = doc(db, `users/${authUser?.email}`);

  const [loadingOpen, setLoadingOpen] = useState(false);
  const [demoFiles, setDemoFiles] = useState<IFile[]>([]);
  const demoUser = 'george@ufop.edu.br';

  useEffect(() => {
    const filesPath = '/users/' + demoUser + '/files/';

    const fetchDemoFiles = async () => {
      const q = query(collection(db, filesPath));
      const querySnapshot = await getDocs(q);
      const fetchedDemoFiles: IFile[] = [];
      querySnapshot.forEach((doc) => {
        fetchedDemoFiles.push(doc.data() as IFile);
      });
      setDemoFiles(fetchedDemoFiles);
    };

    fetchDemoFiles();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const [copyNameDialogOpen, setCopyNameDialogOpen] = useState(false);
  const [copyNameDialogConfirmed, setCopyNameDialogConfirmed] = useState(false);
  const [clickedFileName, setClickedFileName] = useState('');
  const [copyFileName, setCopyFileName] = useState('');

  const handleCopyClick = (event: React.MouseEvent<unknown>, fileName: string) => {
    setClickedFileName(fileName);
    setCopyFileName(t('Copy of ') + fileName);
    setCopyNameDialogOpen(true);
  };

  const handleDownloadClick = (event: React.MouseEvent<unknown>, fileName: string) => {
    event.preventDefault();

    // Construct the file URL
    const fileUrl = `/demo/${fileName}.xlsx`;

    console.log(fileUrl);
    // Create a temporary anchor element
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileName;

    // Append to the document and trigger click
    document.body.appendChild(link);
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  };

  async function copy() {
    try {
      setCopyNameDialogConfirmed(false);
      //Validation and newName adjustments
      const newName: string = copyFileName;
      if (files.map((file) => file.name).includes(newName)) {
        toast.error(t('Project ') + newName + t(' already registered!'));
        return;
      }
      setLoadingOpen(true);
      // Get file and its collections
      const demoFilePath = `users/${demoUser}/files`;
      const docRef = doc(db, demoFilePath, clickedFileName);
      getDoc(docRef).then(async (docSnap) => {
        if (docSnap.exists()) {
          const file = docSnap.data() as IFile;
          const newFile = { ...file };
          const resourcesSnap = await getDocs(query(collection(db, `${demoFilePath}/${clickedFileName}/resources`)));
          const resources = resourcesSnap.docs.map((doc) => {
            return Object.assign({ ...doc.data() }, { id: doc.id });
          }) as unknown as IResource[];
          const subjectsSnap = await getDocs(query(collection(db, `${demoFilePath}/${clickedFileName}/subjects`)));
          const subjects = subjectsSnap.docs.map((doc) => {
            return Object.assign({ ...doc.data() }, { id: doc.id });
          }) as unknown as ISubject[];
          const lessonsSnap = await getDocs(query(collection(db, `${demoFilePath}/${clickedFileName}/lessons`)));
          const lessons = lessonsSnap.docs.map((doc) => {
            return Object.assign({ ...doc.data() }, { id: doc.id });
          }) as unknown as ILesson[];
          const solutionsSnap = await getDocs(query(collection(db, `${demoFilePath}/${clickedFileName}/solutions`)));
          const solutions = solutionsSnap.docs.map((doc) => {
            return Object.assign({ ...doc.data() }, { id: doc.id });
          }) as unknown as ISolution[];
          newFile.name = newName;
          newFile.createdAt = new Date();
          newFile.updatedAt = new Date();
          const batch = writeBatch(db);
          batch.set(doc(db, filesPath, newName), newFile);
          //Copy resources, subjects, lessons, and solutions
          const newFilePath = filesPath + '/' + newName;
          resources.forEach(async (resource: any) => {
            batch.set(doc(db, newFilePath + '/resources', resource.name), resource);
          });
          subjects.forEach(async (subject: any) => {
            batch.set(doc(db, newFilePath + '/subjects', subject.name), subject);
          });
          lessons.forEach(async (lesson: any) => {
            batch.set(doc(db, newFilePath + '/lessons', lesson.name), lesson);
          });
          solutions.forEach(async (solution: any) => {
            batch.set(doc(db, newFilePath + '/solutions', solution.name), solution);
          });
          //Update selected file
          batch.update(userDoc, {
            selectedFile: newName,
          });

          batch
            .commit()
            .then(() => {
              setLoadingOpen(false);
              toast.success(file?.name + t(' copied sucessfully'));
              history.push('/files');
            })
            .catch(() => {
              setLoadingOpen(false);
              toast.error(t('Project is too large to be copied'));
              history.push('/files');
            });
        } else {
          toast.error(t('No such document'));
        }
      });
    } catch (error: any) {
      toast.error(t('An error has occurred'));
      console.error({ error });
    }
    setOpen(false);
  }

  copyNameDialogConfirmed && copy();

  return (
    <div>
      {loadingOpen && <SimpleBackdrop open={loadingOpen} setOpen={setLoadingOpen} />}
      {copyNameDialogOpen && (
        <FormDialog
          open={copyNameDialogOpen}
          setOpen={setCopyNameDialogOpen}
          setConfirmed={setCopyNameDialogConfirmed}
          title={t('Copy Project')}
          message={t('Choose a name to the copy of the project. This name cannot be changed later:')}
          fieldLabel={t('Project')}
          inputText={copyFileName}
          setInputText={setCopyFileName}
        />
      )}
      <DialogContainer maxWidth="lg" fullWidth open={open} onClose={handleClose}>
        <DialogTitle style={{ cursor: 'move' }} color={'primary'} id="draggable-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
          <Grid container justifyContent="center">
            <Grid
              container
              justifyContent="flex-start"
              maxWidth={'lg'}
              alignItems="flex-start"
              paddingTop={1}
              paddingBottom={8}
              spacing={4}
            >
              {demoFiles
                .sort(
                  (file1, file2) =>
                    (file2.updatedAt as unknown as Timestamp).toMillis() -
                    (file1.updatedAt as unknown as Timestamp).toMillis()
                )
                .map((file, index) => {
                  return (
                    <Grid item key={index}>
                      <DemoFileCard
                        file={file}
                        user={user}
                        shared={{ user: environment.demoFilesUserEmail!, file: file.name, mode: 'read' }}
                        onCopyClick={handleCopyClick}
                        onDownloadClick={handleDownloadClick}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            {t('Close')}
          </Button>
        </DialogActions>
      </DialogContainer>
    </div>
  );
}
