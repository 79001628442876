import { LinearProgressProps, Box, LinearProgress, Typography, linearProgressClasses } from '@mui/material';
import { SessionContext } from 'contexts';
import * as React from 'react';
import { darkTheme, lightTheme } from 'styles/material';

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

interface LinearWithValueLabelProps {
  initialProgress: number;
  maxTime: number;
}

export default function LinearWithValueLabel({ initialProgress, maxTime }: LinearWithValueLabelProps) {
  const { isDarkMode } = React.useContext(SessionContext);
  const theme = isDarkMode ? darkTheme : lightTheme;
  const [progress, setProgress] = React.useState(initialProgress);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress: number) => prevProgress + (100 / maxTime) * 0.5);
    }, 500);
    return () => {
      clearInterval(timer);
    };
  }, [maxTime, progress]);

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgressWithLabel
        value={progress > 100 ? 100 : progress}
        sx={{
          height: 12,
          borderRadius: 6,
          [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
          },
          [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 6,
            backgroundColor: theme.palette.primary.main,
          },
        }}
      />
    </Box>
  );
}
