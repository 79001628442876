import { HighlightAlt } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

type Props = { label: string };

export default function EmptyConstraints({ label }: Props) {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center" paddingBottom={2}>
      <HighlightAlt style={{ color: grey[500], textAlign: 'center' }} />
      <Typography color={grey[500]} textAlign="center">
        {label}
      </Typography>
    </Stack>
  );
}
