import React from 'react';
import { Box } from '@mui/material';
import ReactPlayer from 'react-player';

type Props = {
  videoUrl: string;
  frameSrc: string;
  frameWidth?: string | number;
};
const VideoInFrame = ({ videoUrl, frameSrc, frameWidth = 840 }: Props) => {
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        maxWidth: `${frameWidth}px`, // Frame width
        margin: '0 auto', // Center the container
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {/* Frame Image */}
      <img
        src={frameSrc} // Path to your frame image
        alt="Frame"
        style={{
          width: '100%', // Make image responsive
          height: 'auto',
        }}
        loading="lazy"
      />

      {/* Video Player inside the frame */}
      <Box
        sx={{
          position: 'absolute',
          top: '3%', // Adjust based on the image's screen area
          left: '9%', // Adjust to fit the left side of the screen
          width: '81%', // Adjust to fit the width of the frame screen
          height: '82%', // Aspect ratio to fit the screen area
          borderRadius: '10px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        }}
      >
        <ReactPlayer
          url={videoUrl}
          width="100%" // Responsive width
          height="100%" // Responsive height
          controls
        />
      </Box>
    </Box>
  );
};

export default VideoInFrame;
