import OnboardingContent from 'components/OnboardingContent';
import { useEffect, useState } from 'react';
import { TFunction } from 'react-i18next';

export const SUBJECT_CREATION_KEY = 'onboarding-resource-creation';
export const useSubjectOnboarding = (t: TFunction<'translation', undefined>, updateSubject: boolean) => {
  const [runSubjectCreationOnboarding, setRunSubjectCreationOnboarding] = useState(false);
  useEffect(() => {
    if (localStorage.getItem(SUBJECT_CREATION_KEY) !== 'true') {
      setRunSubjectCreationOnboarding(true);
    }
  }, []);

  const subjectCreationSteps = [
    // {
    //   target: '.related-lessons',
    //   disableBeacon: true,
    //   content: (
    //     <OnboardingContent
    //       title={t('Related lessons')}
    //       message={t('You can see info on the registered lessons of this subject')}
    //     />
    //   ),
    // },
    {
      target: '.availability-form',
      content: (
        <OnboardingContent
          title={t('Availability')}
          message={t(
            'You can check the slots in which this subject should not be taught, forbidding assignments at these times'
          )}
        />
      ),
    },
    {
      target: updateSubject ? '.update-button' : '.save-button',
      content: (
        <OnboardingContent
          title={(updateSubject ? t('Update ') : t('Save ')) + t('subject')}
          message={
            (updateSubject
              ? t("When you're done, click UPDATE to update the ")
              : t("When you're done, click SAVE to create the ")) + t('subject')
          }
        />
      ),
    },
  ];

  return { runSubjectCreationOnboarding, subjectCreationSteps };
};
