import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { SessionContext } from 'contexts';

import MiniDrawerHome from 'components/Drawer/MiniDrawerHome';
import i18n from 'i18n';
import { Container, Grid, ThemeProvider, useMediaQuery } from '@mui/material';
import Teaser from './components/Teaser';
import Features from './components/Features';
import Testmonials from './components/Testmonials';
import Pricing from './components/Pricing';
import Contact from './components/Contact';
import Footer from './components/Footer';
import { customColors } from 'styles';
import LoginDialog from 'components/Dialog/LoginDialog';
import { lightTheme } from 'styles/material';
import VideoInFrame from './components/VideoInFrame';

export default function Home() {
  const history = useHistory();
  const { authUser } = useContext(SessionContext);
  const widthLessThan600 = useMediaQuery('(max-width:600px)');

  // Redirect if user is logged in
  authUser?.email !== undefined && history.push('/files');

  function handleGetStarted() {
    if (authUser?.email === undefined) {
      setAuthOpen(true);
      // history.push('/signin');
    } else {
      history.push('/files');
    }
  }

  const [authOpen, setAuthOpen] = useState(false);

  const languageOptions = [
    { code: 'BR', label: 'PT', description: 'Português (PT)' },
    { code: 'US', label: 'EN', description: 'English (US)' },
    { code: 'ES', label: 'ES', description: 'Español (ES)' },
    { code: 'DE', label: 'DE', description: 'Deutsch (DE)' },
  ];

  const [currentCountryCode, setCurrentCountryCode] = React.useState<string | undefined>(
    languageOptions.find((option) => option.label === i18n.language.toUpperCase())
      ? languageOptions.find((option) => option.label === i18n.language.toUpperCase())?.code
      : 'PT'
  );

  return (
    <ThemeProvider theme={lightTheme}>
      <Container
        maxWidth={false}
        style={{
          backgroundColor: customColors.blueDark,
          padding: '0px',
        }}
      >
        <LoginDialog open={authOpen} setOpen={setAuthOpen} title={''} message={''} />
        <MiniDrawerHome
          currentCountryCode={currentCountryCode}
          setCurrentCountryCode={setCurrentCountryCode}
          languageOptions={languageOptions}
          setAuthOpen={setAuthOpen}
        />
        <section id="teaser">
          <Grid container paddingInline={4} paddingTop={23} paddingBottom={26} alignItems="center">
            <Grid item lg={1}></Grid>
            <Teaser handleGetStarted={handleGetStarted} />
            <Grid item lg={1}></Grid>
            <Grid item xs={12} lg={5} padding={2} justifyContent={'center'}>
              <VideoInFrame frameSrc="/img/other/laptop2.png" videoUrl="https://youtu.be/5Ho_jtnCVfQ" />
            </Grid>
            <Grid item lg={1}></Grid>
          </Grid>
        </section>
        <section id="features">
          <Grid container item xs={12} justifyContent="center" paddingTop={4}>
            <Features />
          </Grid>
        </section>
        <section id="testmonials">
          <Testmonials />
        </section>
        <section id="pricing">
          <Pricing currentCountryCode={currentCountryCode} handleGetStarted={handleGetStarted} />
        </section>
        <section id="contact">
          <Contact />
        </section>
        <Footer />
      </Container>
    </ThemeProvider>
  );
}
