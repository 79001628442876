import {
  SelectChangeEvent,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Box,
  Chip,
  MenuItem,
  Checkbox,
  ListItemText,
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import { SessionContext } from 'contexts';
import { Dispatch, SetStateAction, useContext } from 'react';
import { darkTheme, lightTheme } from 'styles/material';
import { darkModeScrollBar } from 'util/themeUtils';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

type Props = {
  single?: boolean;
  label: string;
  names: string[];
  index: number;
  personName: string[][];
  setPersonName: Dispatch<SetStateAction<string[][]>>;
  disabled?: boolean;
  chipColor?: string;
  InputProps?: any;
  onChangeFn?: () => void;
};

export default function LessonResourceMultipleSelectChip({
  single = false,
  label,
  names,
  index,
  personName,
  setPersonName,
  disabled = false,
  chipColor = '',
  InputProps = {},
  onChangeFn,
}: Props) {
  const { isDarkMode } = useContext(SessionContext);
  const theme = isDarkMode ? darkTheme : lightTheme;

  const handleChange = (event: SelectChangeEvent<(typeof personName)[typeof index]>) => {
    if (onChangeFn) {
      onChangeFn();
      return;
    }
    const {
      target: { value },
    } = event;

    // Update the specific index in the 2D array
    setPersonName((prev) => {
      const updatedPersonName = [...prev];
      updatedPersonName[index] = typeof value === 'string' ? value.split(',') : value;
      return updatedPersonName;
    });
  };

  return (
    <div>
      <FormControl fullWidth size="small">
        <InputLabel id="demo-multiple-chip-label">{label}</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple={!single}
          required
          value={personName[index]} // Set value based on the specific index
          onChange={handleChange}
          disabled={disabled}
          input={<OutlinedInput id="select-multiple-chip" label={label} />}
          inputProps={InputProps}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
              },
              sx: {
                ...(isDarkMode ? darkModeScrollBar : {}),
              },
            },
          }}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {(selected as string[]).map((value) => (
                <Chip
                  key={value}
                  label={value}
                  size="small"
                  sx={chipColor ? { backgroundColor: chipColor, color: 'white', border: '0px' } : {}}
                  variant="outlined"
                  translate="no"
                />
              ))}
            </Box>
          )}
        >
          {names.map((name) => (
            <MenuItem translate="no" key={name} value={name} style={getStyles(name, personName[index], theme)}>
              {single ? (
                <ListItemText primary={name} />
              ) : (
                <>
                  <Checkbox
                    sx={{ width: 18, height: 18, paddingRight: 2 }}
                    checked={personName[index].includes(name)}
                  />
                  <ListItemText primary={name} />
                </>
              )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
