const customColors = {
  whiteDark: '#FFFFFF',
  white: '#E1E1E6',
  opaque: '#41414D',
  purple: '#6633cc',
  purpleDark: '#5A4B81',
  green: '#01B075',
  orange: '#E89E64',
  pink: '#FF79C6',
  cyan: '#78D1E1',
  red: '#d32f2e',
  yellow: '#e7de79',
  optablesDark: '#ffb309',
  optables: '#ffcb2b',
  black: '#16181A',
  dark: '#202125',
  darkGreen: '#29A990',
  light: '#EDEDF0',
  blue: '#0D47A1',
  homeBlue: '#0D47A1',
  homeBlue2: '#103870',
  blueDark: '#051E34',
  lightBlue: '#20CAF3',
  header: '#202125',
  icons: '#EDEDF0',
  mar: '#0D90AD',
  marDark: '#064856',
  grey: '#EDEBE9',
  premium: '#ffcb2b',
  basic: '#cccccc',
  lightText: '#757575',
};

export { customColors };
