import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Breakpoint } from '@mui/material';
import DialogContainer from 'containers/DialogContainer';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  title: string;
  message: string | React.ReactNode;
  maxWidth?: string;
};

export default function InfoDialog({ open, setOpen, title, message, maxWidth = 'lg' }: Props) {
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <DialogContainer maxWidth={maxWidth as Breakpoint} open={open} onClose={handleClose}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            {t('Close')}
          </Button>
        </DialogActions>
      </DialogContainer>
    </div>
  );
}
