import { SelectChangeEvent, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { SelectBaseProps } from 'util/selectUtils';
import { useContext } from 'react';
import { SessionContext } from 'contexts';
import { customColors } from 'styles';
import { getPriorityIcon } from 'util/priorityUtils';

type Props = SelectBaseProps & {
  backgroundColors?: string[];
  colors?: string[];
};

export default function PrioritySelect({ current, setCurrent, options, backgroundColors, colors, label }: Props) {
  const { isDarkMode } = useContext(SessionContext);
  const handleChange = (event: SelectChangeEvent) => {
    setCurrent(event.target.value);
  };

  return (
    <FormControl fullWidth size="small">
      <InputLabel id="demo-select-small">{label}</InputLabel>
      <Select labelId="demo-select-small" id="demo-select-small" value={current} label={label} onChange={handleChange}>
        {options.map((option, index) => {
          return (
            <MenuItem key={`${label}_${index}`} value={option}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {getPriorityIcon(index, isDarkMode ? 'white' : customColors.lightText)}
                <span>{option}</span>
              </div>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
