import { BASIC, FREE, planFeatures } from 'configs/planFeatures';
import { IFile } from 'pages/Files/File';
import { ILesson } from 'pages/Lessons/utils/types';
import { IResource } from 'pages/Resources/Resource';
import ResourceExcelTimetable from 'pages/Solutions/components/ResourceExcelTimetable';
import ResourceTypeExcelTimetable from 'pages/Solutions/components/ResourceTypeExcelTimetable';
import { ISolution } from 'pages/Solutions/Solution';
import { ISubject } from 'pages/Subjects/Subject';
import { renderToStaticMarkup } from 'react-dom/server';
import { TFunction } from 'react-i18next';

export const RESOURCE_JOINT_IMPORT_SEPARATOR = '+';
export const RESOURCE_OPTIONS_IMPORT_SEPARATOR = '|';
export const RESOURCE_OPTIONS_SEPARATOR = ' | ';
export const SUBJECT_CLASS_SEPARATOR = ' | ';
export const PLUS_SEPARATOR = ' + ';
export const PERIOD_SEPARATOR = ' . ';
export const DAY_TIME_SEPARATOR = ':';
export const UNAVAILABLE_TIME_MARKER = '#';
export const UNDESIRED_TIME_MARKER = '=';
export const AVAILABLE_TIME_MARKER = '.';
export const EXCEL_UNAVAILABLE_TIME_MARKER = ' X ';
export const EXCEL_UNDESIRED_TIME_MARKER = ' - ';
export const TIZA = 'Tiza';
export const COMPACT = 'Compact';
export const INDIVIDUAL_TABLES = 'Individual Tables';
export const OPTIMAL = 'Optimal';
export const FAST = 'Fast';
export const PROCESSING = 'Processing';
export const OUTDATED = 'Outdated';
export const EMPTY = 'Empty';
export const UP_TO_DATE = 'Up to Date';

export const cellIsUnavailableOrUndesired = (cellText: string) => {
  return cellText === UNAVAILABLE_TIME_MARKER || cellText === UNDESIRED_TIME_MARKER;
};

export const getRuntimeSuggestion = (numLessons: number) => {
  if (numLessons < 50) {
    return 60;
  } else if (numLessons >= 50 && numLessons < 200) {
    return 300;
  } else {
    return 3600;
  }
};

export const arraysHaveSameElements = (arr1: any[], arr2: any[]) => {
  if (arr1.length !== arr2.length) return false;

  let sortedArr1 = arr1.slice().sort();
  let sortedArr2 = arr2.slice().sort();

  return sortedArr1.every((value, index) => value === sortedArr2[index]);
};

export function getPlanRuntimeSuggestion(userPlan: string, numLessons: number) {
  const runtimeSuggestion = getRuntimeSuggestion(numLessons);
  if (userPlan === FREE && runtimeSuggestion > planFeatures.free.maxRuntime) {
    return planFeatures.free.maxRuntime;
  } else if (userPlan === BASIC && runtimeSuggestion > planFeatures.basic.maxRuntime)
    return planFeatures.basic.maxRuntime;
  else {
    return runtimeSuggestion;
  }
}

export const autoDownloadFile = (text: string, extension: string, fileName: string) => {
  var textFile: any = null,
    makeTextFile = function (text: any) {
      var data = new Blob([text], { type: 'text/plain' });
      // If we are replacing a previously generated file we need to
      // manually revoke the object URL to avoid memory leaks.
      if (textFile !== null) {
        window.URL.revokeObjectURL(textFile);
      }
      textFile = window.URL.createObjectURL(data);
      // returns a URL you can use as a href
      return textFile;
    };

  var create = document.createElement(extension);
  // var create = document.getElementById('name');
  create?.addEventListener('click', function () {
    var link = document.createElement('a');
    link.setAttribute('download', fileName + '.' + extension);
    link.href = makeTextFile(text);
    document.body.appendChild(link);

    // wait for the link to be added to the document
    window.requestAnimationFrame(function () {
      var event = new MouseEvent('click');
      link.dispatchEvent(event);
      document.body.removeChild(link);
    });
  });
  create.click();
};

export const handleExportCsv = async (t: TFunction, solution: ISolution, resources: IResource[]) => {
  // UTF-8 BOM to ensure proper encoding for special characters
  const BOM = '\uFEFF';

  var content = `${t('Subject')},${t('Class')},${t('Teachers')},${t('Rooms')},${t('Timeslots')}\n`;

  solution.assignments.forEach((assignment) => {
    content += assignment.lessonRef.split(SUBJECT_CLASS_SEPARATOR)[0] + ',';
    let assignmentClasses: string[] = [];
    let assignmentTeachers: string[] = [];
    let assignmentRooms: string[] = [];

    assignment.resources.forEach((assignmentResource) => {
      resources.find((resource) => resource.name === assignmentResource)?.type === 'class' &&
        assignmentClasses.push(assignmentResource);
      resources.find((resource) => resource.name === assignmentResource)?.type === 'teacher' &&
        assignmentTeachers.push(assignmentResource);
      resources.find((resource) => resource.name === assignmentResource)?.type === 'room' &&
        assignmentRooms.push(assignmentResource);
    });

    content += assignmentClasses.join('+') + ',';
    content += assignmentTeachers.join('+') + ',';
    content += assignmentRooms.join('+') + ',';

    content += assignment.timeslots
      .map((assignmentTimeslot) => {
        const day = +assignmentTimeslot.split(DAY_TIME_SEPARATOR)[0] + 1;
        const time = +assignmentTimeslot.split(DAY_TIME_SEPARATOR)[1] + 1;
        return day + DAY_TIME_SEPARATOR + time;
      })
      .join('+');

    content += '\n';
  });

  // Add BOM to the content and trigger the download
  autoDownloadFile(BOM + content, 'csv', solution.name);
};

export function handleExportXls(
  t: TFunction,
  file: IFile,
  solution: ISolution,
  resources: IResource[],
  getAssignmentCellTextColor: (resourceName: string, cell: string) => string[]
) {
  // Excel - working with file warning
  var uri = 'data:application/vnd.ms-excel;base64,',
    template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
    base64 = function (s: any) {
      return window.btoa(unescape(encodeURIComponent(s)));
    },
    format = function (s: any, c: any) {
      return s.replace(/{(\w+)}/g, function (m: any, p: any) {
        return c[p];
      });
    };

  var htmlString = '';
  if (solution.display !== 'Compact') {
    let resourceTypes: string[] = ['class', 'teacher', 'room'];
    resourceTypes.forEach((resourceType) => {
      resources
        .filter((res) => res.type === resourceType)
        .forEach((resource) => {
          htmlString += renderToStaticMarkup(
            ResourceExcelTimetable({ t, resourceName: resource.name, getAssignmentCellTextColor })
          );
        });
    });
  } else {
    let resourceTypes: string[] = ['class', 'teacher'];
    resources.find((res) => res.type === 'room') !== undefined && resourceTypes.push('room');
    resourceTypes.forEach((resourceType) => {
      htmlString += renderToStaticMarkup(ResourceTypeExcelTimetable({ t, resourceType, getAssignmentCellTextColor }));
    });
  }

  var toExcel = `<div>${htmlString}</div>`;
  var ctx = {
    worksheet: file.name || '',
    table: toExcel,
  };
  var link = document.createElement('a');
  link.download = `${file?.name} ${solution.name}.xls`;
  link.href = uri + base64(format(template, ctx));
  link.click();
}

export const getInfeasibilitySources = (
  t: TFunction,
  numDays: number,
  numTimes: number,
  resources: IResource[],
  subjects: ISubject[],
  lessons: ILesson[]
) => {
  const infeasWarnings: string[] = [];
  const numSlots = numDays * numTimes;
  for (const resource of resources) {
    let numResourceMandatoryAssignments = 0;
    for (const lesson of lessons) {
      const isResourceAssignedToLesson =
        (lesson.classes.includes(resource.name) && lesson.classNum === lesson.classes.length) ||
        (lesson.teachers.includes(resource.name) && lesson.teacherNum === lesson.teachers.length) ||
        (lesson.rooms.includes(resource.name) && lesson.roomNum === lesson.rooms.length);
      if (isResourceAssignedToLesson) {
        numResourceMandatoryAssignments += Number(lesson.lessonsWeek);
      }
    }
    const resourceAvailableSlots = numSlots - resource.unavailableTimes.length;
    if (resourceAvailableSlots < numResourceMandatoryAssignments) {
      infeasWarnings.push(
        `${resource.name} ${t('has only')} ${resourceAvailableSlots} ${t('available timeslots')} ${t(
          'but is required to attend in'
        )} ${numResourceMandatoryAssignments} ${t('timeslots')}`
      );
    }
  }
  for (const subject of subjects) {
    const subjectAvailableSlots = numSlots - subject.unavailableTimes.length;
    for (const lesson of lessons) {
      if (lesson.subject.includes(subject.name) && subjectAvailableSlots < lesson.lessonsWeek) {
        infeasWarnings.push(
          `${subject.name} ${t('has only')} ${subjectAvailableSlots} ${t('available timeslots')} ${t(
            'but is required'
          )} ${lesson.lessonsWeek} ${t('times/week for class')} ${lesson.classes.join(' ')}`
        );
      }
    }
  }

  return infeasWarnings;
};
