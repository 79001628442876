import { SelectChangeEvent, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Dispatch, SetStateAction, useContext } from 'react';
import { SessionContext } from 'contexts';
import { customColors } from 'styles';
import { getPriorityIcon } from 'util/priorityUtils';
import { ISolution } from 'pages/Solutions/Solution';
import { CLASS, LESSON, priorityOptionsMap, ROOM, TEACHER } from 'util/configUtils';
import {
  WORKING_TIMES,
  WORKING_DAYS,
  IDLE_WINDOW,
  DAILY_WORKLOAD,
  ROOMS_PER_DAY,
  CONSECUTIVE_TIMES,
  DISTINCT_SUBJECTS,
  FORBIDDEN_COMBINATION,
  TRAVEL_TIME,
  UNAVAILABLE_TIMES,
  SUBJECT_UNAVAILABLE_TIMES,
  DAYS_BETWEEN_MEETINGS,
  PREDEFINED_TIMES,
  SIMULTANEOUS_WITH,
  NOT_SIMULTANEOUS_WITH,
  OCCUR_BEFORE,
} from 'util/constraintUtils';
import { useTranslation } from 'react-i18next';

const getSolutionConstraintValue = (entity: string, constrType: string, solution: ISolution) => {
  switch (entity) {
    case CLASS:
      switch (constrType) {
        case UNAVAILABLE_TIMES:
          return solution.unavailableTimesClasses;
        case WORKING_TIMES:
          return solution.workloadClasses;
        case WORKING_DAYS:
          return solution.workingDaysClasses;
        case IDLE_WINDOW:
          return solution.idleWindowClasses;
        case DAILY_WORKLOAD:
          return solution.dailyWorkloadClasses;
        case ROOMS_PER_DAY:
          return solution.roomChangesDayClasses;
        case CONSECUTIVE_TIMES:
          return solution.consecutiveTimesClasses;
        case DISTINCT_SUBJECTS:
          return solution.distinctSubjectsClasses;
        case FORBIDDEN_COMBINATION:
          return solution.forbiddenCombinationClasses;
      }
      break;
    case TEACHER:
      switch (constrType) {
        case UNAVAILABLE_TIMES:
          return solution.unavailableTimesTeachers;
        case WORKING_TIMES:
          return solution.workloadTeachers;
        case WORKING_DAYS:
          return solution.workingDaysTeachers;
        case IDLE_WINDOW:
          return solution.idleWindowTeachers;
        case DAILY_WORKLOAD:
          return solution.dailyWorkloadTeachers;
        case ROOMS_PER_DAY:
          return solution.roomChangesDayTeachers;
        case CONSECUTIVE_TIMES:
          return solution.consecutiveTimesTeachers;
        case DISTINCT_SUBJECTS:
          return solution.distinctSubjectsTeachers;
        case FORBIDDEN_COMBINATION:
          return solution.forbiddenCombinationTeachers;
      }
      break;
    case ROOM:
      switch (constrType) {
        case UNAVAILABLE_TIMES:
          return solution.unavailableTimesRooms;
        case WORKING_TIMES:
          return solution.workloadRooms;
        case WORKING_DAYS:
          return solution.workingDaysRooms;
        case IDLE_WINDOW:
          return solution.idleWindowRooms;
        case DAILY_WORKLOAD:
          return solution.dailyWorkloadRooms;
        case CONSECUTIVE_TIMES:
          return solution.consecutiveTimesRooms;
        case TRAVEL_TIME:
          return solution.travelTimeRooms;
      }
      break;
    case LESSON:
      switch (constrType) {
        case SUBJECT_UNAVAILABLE_TIMES:
          return solution.unavailableTimesSubjects;
        case DAYS_BETWEEN_MEETINGS:
          return solution.daysBetweenLessons;
        case PREDEFINED_TIMES:
          return solution.predefinedTimes;
        case SIMULTANEOUS_WITH:
          return solution.simultaneousWith;
        case NOT_SIMULTANEOUS_WITH:
          return solution.notSimultaneousWith;
        case OCCUR_BEFORE:
          return solution.occurBefore;
      }
  }
  return 'Banana';
};

const getUpdatedSolution = (newValue: string, entity: string, constrType: string, solution: ISolution) => {
  const newSolution = { ...solution };
  switch (entity) {
    case CLASS:
      switch (constrType) {
        case UNAVAILABLE_TIMES:
          newSolution.unavailableTimesClasses = newValue;
          break;
        case WORKING_TIMES:
          newSolution.workloadClasses = newValue;
          break;
        case WORKING_DAYS:
          newSolution.workingDaysClasses = newValue;
          break;
        case IDLE_WINDOW:
          newSolution.idleWindowClasses = newValue;
          break;
        case DAILY_WORKLOAD:
          newSolution.dailyWorkloadClasses = newValue;
          break;
        case ROOMS_PER_DAY:
          newSolution.roomChangesDayClasses = newValue;
          break;
        case CONSECUTIVE_TIMES:
          newSolution.consecutiveTimesClasses = newValue;
          break;
        case DISTINCT_SUBJECTS:
          newSolution.distinctSubjectsClasses = newValue;
          break;
        case FORBIDDEN_COMBINATION:
          newSolution.forbiddenCombinationClasses = newValue;
          break;
      }
      break;
    case TEACHER:
      switch (constrType) {
        case UNAVAILABLE_TIMES:
          newSolution.unavailableTimesTeachers = newValue;
          break;
        case WORKING_TIMES:
          newSolution.workloadTeachers = newValue;
          break;
        case WORKING_DAYS:
          newSolution.workingDaysTeachers = newValue;
          break;
        case IDLE_WINDOW:
          newSolution.idleWindowTeachers = newValue;
          break;
        case DAILY_WORKLOAD:
          newSolution.dailyWorkloadTeachers = newValue;
          break;
        case ROOMS_PER_DAY:
          newSolution.roomChangesDayTeachers = newValue;
          break;
        case CONSECUTIVE_TIMES:
          newSolution.consecutiveTimesTeachers = newValue;
          break;
        case DISTINCT_SUBJECTS:
          newSolution.distinctSubjectsTeachers = newValue;
          break;
        case FORBIDDEN_COMBINATION:
          newSolution.forbiddenCombinationTeachers = newValue;
          break;
      }
      break;
    case ROOM:
      switch (constrType) {
        case UNAVAILABLE_TIMES:
          newSolution.unavailableTimesRooms = newValue;
          break;
        case WORKING_TIMES:
          newSolution.workloadRooms = newValue;
          break;
        case WORKING_DAYS:
          newSolution.workingDaysRooms = newValue;
          break;
        case IDLE_WINDOW:
          newSolution.idleWindowRooms = newValue;
          break;
        case DAILY_WORKLOAD:
          newSolution.dailyWorkloadRooms = newValue;
          break;
        case CONSECUTIVE_TIMES:
          newSolution.consecutiveTimesRooms = newValue;
          break;
        case TRAVEL_TIME:
          newSolution.travelTimeRooms = newValue;
          break;
      }
      break;
    case LESSON:
      switch (constrType) {
        case SUBJECT_UNAVAILABLE_TIMES:
          newSolution.unavailableTimesSubjects = newValue;
          break;
        case DAYS_BETWEEN_MEETINGS:
          newSolution.daysBetweenLessons = newValue;
          break;
        case PREDEFINED_TIMES:
          newSolution.predefinedTimes = newValue;
          break;
        case SIMULTANEOUS_WITH:
          newSolution.simultaneousWith = newValue;
          break;
        case NOT_SIMULTANEOUS_WITH:
          newSolution.notSimultaneousWith = newValue;
          break;
        case OCCUR_BEFORE:
          newSolution.occurBefore = newValue;
          break;
      }
      break;
  }
  return newSolution;
};

type Props = {
  solution: ISolution;
  setSolution: Dispatch<SetStateAction<ISolution>>;
  options: string[];
  entity: string;
  label: string;
  backgroundColors?: string[];
  colors?: string[];
};

export default function ConstraintPrioritySelect({
  solution,
  setSolution,
  options,
  backgroundColors,
  colors,
  entity,
  label,
}: Props) {
  const { isDarkMode } = useContext(SessionContext);
  const { t } = useTranslation();

  const handleChange = (event: SelectChangeEvent) => {
    setSolution(getUpdatedSolution(priorityOptionsMap[event.target.value], entity, label, solution));
  };

  return (
    <FormControl fullWidth size="small">
      <InputLabel id="demo-select-small">{t(label)}</InputLabel>
      <Select
        labelId="demo-select-small"
        id="demo-select-small"
        value={t(getSolutionConstraintValue(entity, label, solution))}
        label={t(label)}
        onChange={handleChange}
      >
        {options.map((option, index) => {
          return (
            <MenuItem key={`${label}_${index}`} value={option}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {getPriorityIcon(index, isDarkMode ? 'white' : customColors.lightText)}
                <span>{option}</span>
              </div>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
