import { SessionContext } from 'contexts';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Joyride from 'react-joyride';
import { darkTheme, lightTheme } from 'styles/material';

export default function Onboarding(props: any) {
  const steps = props.steps;
  const run = props.run;
  const storageFlagKey = props.storageFlagKey;
  const { t } = useTranslation();
  const { isDarkMode } = useContext(SessionContext);
  const theme = isDarkMode ? darkTheme : lightTheme;

  return (
    <Joyride
      steps={steps}
      continuous={true}
      showProgress={true}
      run={run}
      showSkipButton
      locale={{
        next: t('NEXT'),
        back: t('BACK'),
        close: t('CLOSE'),
        last: t('LAST'),
        skip: t('SKIP'),
        open: t('Open the dialog'),
      }}
      callback={(data) => {
        if (data.action === 'reset') {
          localStorage.setItem(storageFlagKey, 'true');
        }
      }}
      styles={{
        options: {
          primaryColor: theme.palette.primary.main,
          // width: 600,
          zIndex: 999999,
        },
        buttonNext: {
          fontSize: theme.typography.button.fontSize,
          fontFamily: theme.typography.button.fontFamily,
        },
        buttonSkip: {
          fontSize: theme.typography.button.fontSize,
          fontFamily: theme.typography.button.fontFamily,
          color: theme.palette.primary.main,
        },
        buttonBack: {
          fontSize: theme.typography.button.fontSize,
          fontFamily: theme.typography.button.fontFamily,
        },
      }}
    />
  );
}
