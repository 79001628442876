/* eslint-disable eqeqeq */
import React, { useContext, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { doc, setDoc, deleteDoc, updateDoc, writeBatch, Timestamp } from '@firebase/firestore';
import Paper from '@mui/material/Paper';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash/isEqual';
import { Location } from 'history';

import { db } from 'services';
import { SessionContext } from 'contexts';
import DraggableDialog from 'components/Dialog/DraggableDialog';
import ValueSelectConfirmDialog from 'components/Dialog/ValueSelectConfirmDialog';
import PlanInfoDialog from 'components/Dialog/PlanInfoDialog';
import { BASIC, FREE, planFeatures } from 'configs/planFeatures';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import MinMaxSlider from 'components/MinMaxSlider';
import VideoPlayerDialog from 'components/Dialog/VideoPlayerDialog';
import {
  ArrowBack,
  FormatLineSpacing,
  Lock,
  Link,
  LinkOff,
  BrowseGallery,
  WorkspacePremium,
  QuestionMark,
  Delete,
  Remove,
} from '@mui/icons-material';
import {
  SelectChangeEvent,
  Box,
  Grid,
  Typography,
  Tooltip,
  IconButton,
  Divider,
  Button,
  FormControl,
  Accordion,
  AccordionSummary,
  DialogContentText,
  AccordionDetails,
  InputLabel,
  Select,
  MenuItem,
  ToggleButton,
  Tabs,
  Tab,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
} from '@mui/material';
import MultipleSelectChip from 'components/Select/MultipleSelectChip';
import Onboarding from 'components/Onboarding';
import { LESSON_CREATION_KEY, useLessonOnboarding } from './utils/useLessonOnboarding';
import { ILesson } from './utils/types';
import { customColors } from 'styles';
import { DAY_TIME_SEPARATOR, OUTDATED, RESOURCE_OPTIONS_SEPARATOR, SUBJECT_CLASS_SEPARATOR } from 'util/solutionUtils';
import {
  getClassHelperText,
  getNotSimultaneousWithHelperText,
  getOccurBeforeHelperText,
  getRoomHelperText,
  getSimultaneousWithHelperText,
  getSubjectHelperText,
  getTeacherHelperText,
} from './utils/helperTextUtils';
import { LoadingButton } from '@mui/lab';
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog';
import PageContainer from 'containers/PageContainer';
import MiniDrawer from 'components/Drawer/MiniDrawer';
import { getRandomColor } from 'styles/randomColor';
import { darkTheme, lightTheme } from 'styles/material';
import { darkModeScrollBar } from 'util/themeUtils';
import LessonResourceMultipleSelectChip from 'components/Select/LessonResourceMultipleSelectChip';
import { grey } from '@mui/material/colors';
import TooltipIconButton from 'components/Button/TooltipIconButton';

interface RouteParams {
  lesson_name: string;
}

export default function Lesson() {
  const { t } = useTranslation();
  const history = useHistory();
  const { user, file, resources, subjects, lessons, solutions, ownerEmail, shareMode, userPlan, isDarkMode } =
    useContext(SessionContext);
  if (!file) {
    history.push('/files');
    toast.warning(t('Select a project first'));
  }
  const theme = isDarkMode ? darkTheme : lightTheme;
  const { lesson_name } = useParams<RouteParams>();
  const fileId = file?.id || file?.name;
  const lessonsPath = 'users/' + ownerEmail + '/files/' + fileId + '/lessons';
  const solutionsPath = 'users/' + ownerEmail + '/files/' + fileId + '/solutions';
  const days = file ? file.days : [];
  const times = file ? file.times : [];
  const priorityOptions = ['Very low', 'Low', 'Average', 'High', 'Very high'];

  const emptyLesson: ILesson = {
    color: getRandomColor(),
    photoURL: '',
    name: '',
    short: '',

    subject: [],
    lessonsWeek: 2,
    split: ['1-1', '2'],
    minGapLessons: 0,
    maxGapLessons: days.length - 1,

    classes: [],
    teachers: [],
    rooms: [],

    priority: priorityOptions[2],
    predefinedTimes: [],
    simultaneousWith: [],
    notSimultaneousWith: [],
    occurBefore: [],
    occurBeforeMinDays: 0,

    createdAt: Timestamp.now(),
    updatedAt: Timestamp.now(),
  };

  const lesson = lessons.find((lesson) => lesson.name === lesson_name) || emptyLesson;
  const updateLesson = lesson ? lesson.name !== '' : false;
  const [showPlanDialog, setShowPlanDialog] = useState(false);
  const [lessonNumLimitReached, setLessonNumLimitReached] = useState(false);
  const { runLessonCreationOnboarding, lessonCreationSteps } = useLessonOnboarding(t, file!);
  const [selectedForDaysBetweenLessons, setSelectedForDaysBetweenLessons] = useState<string[]>([]);
  const [hasAttemptedToSave, setHasAttemptedToSave] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCopy, setIsLoadingCopy] = useState(false);

  const readOnly = ownerEmail !== user?.email && shareMode === 'read';

  const classes = resources
    .filter((resource) => {
      return resource.type === 'class';
    })
    .map((class_) => class_.name);

  const teachers = resources
    .filter((resource) => {
      return resource.type === 'teacher';
    })
    .map((teacher) => teacher.name);

  const rooms = resources
    .filter((resource) => {
      return resource.type === 'room';
    })
    .map((room) => room.name);

  const subjects_ = subjects.map((subject) => subject.name);
  const lessons_ = lessons.map((lesson) => lesson.name);

  const previousName = lesson ? lesson.name : '';

  const getNewLesson = () => {
    const name = selectedSubject[0] + SUBJECT_CLASS_SEPARATOR + selectedClasses.join(SUBJECT_CLASS_SEPARATOR);
    let predefinedTimes: string[] = [];
    for (let i = 0; i < selectedCells.length; i++) {
      predefinedTimes.push(selectedCells[i]);
    }

    const newLesson: ILesson = {
      color: lesson.color,
      photoURL: lesson.photoURL,
      name: name,
      short: '',

      subject: selectedSubject,
      lessonsWeek: selectedLessonsWeek,
      split: selectedSplit,
      minGapLessons: +selectedMinGapLessons,
      maxGapLessons: +selectedMaxGapLessons,

      classes:
        selectedClasses.length === 0
          ? []
          : selectedClasses.map((resOptions) => {
              return resOptions.join(RESOURCE_OPTIONS_SEPARATOR);
            }),
      teachers:
        selectedTeachers.length === 0
          ? []
          : selectedTeachers.map((resOptions) => {
              return resOptions.join(RESOURCE_OPTIONS_SEPARATOR);
            }),
      rooms:
        selectedRooms.length === 0
          ? []
          : selectedRooms.map((resOptions) => {
              return resOptions.join(RESOURCE_OPTIONS_SEPARATOR);
            }),

      priority: subjects.find((sub) => sub.name === selectedSubject[0])?.priority ?? priorityOptions[2],
      predefinedTimes: predefinedTimes,
      simultaneousWith: selectedSimultaneousWith,
      notSimultaneousWith: selectedNotSimultaneousWith,
      occurBefore: selectedOccurBefore,
      occurBeforeMinDays: +selectedOccurBeforeMinDays,

      createdAt: lesson.createdAt,
      updatedAt: lesson.updatedAt,
    };
    return newLesson;
  };

  async function submitForm() {
    if (userPlan === FREE && lessons.length + 1 > planFeatures.free.maxLessons) {
      setLessonNumLimitReached(true);
    } else if (userPlan === BASIC && lessons.length + 1 > planFeatures.basic.maxLessons) {
      setLessonNumLimitReached(true);
    } else {
      setIsLoading(true);
      try {
        const newLesson = getNewLesson();
        newLesson.updatedAt = Timestamp.now();
        const batch = writeBatch(db);
        batch.set(doc(db, lessonsPath, newLesson.name), newLesson);
        // Delete old record and update references if name has changed
        if (previousName !== '' && previousName !== newLesson.name) {
          batch.delete(doc(db, lessonsPath, previousName));
        }

        //Check if Constraints Apply to All
        if (selectedForDaysBetweenLessons.length > 0) {
          lessons
            .filter((lesson) => selectedForDaysBetweenLessons.includes(lesson.name))
            .forEach((lesson) =>
              batch.update(doc(db, lessonsPath + '/' + lesson.name), {
                minGapLessons: selectedMinGapLessons,
                maxGapLessons: selectedMaxGapLessons,
                updatedAt: Timestamp.now(),
              })
            );
        }
        // if (selectedTimeSplit) {
        //   lessons.forEach((lesson) =>
        //     batch.update(doc(db, lessonsPath + '/' + lesson.name), {
        //       lessonsWeek: selectedLessonsWeek,
        //       split: selectedSplit,
        //       updatedAt: new Date(),
        //     })
        //   );
        // }
        //Set all solutions to outdated
        solutions.forEach((solution) => {
          batch.update(doc(db, `${solutionsPath}/${solution.name}`), {
            status: OUTDATED,
          });
        });
        batch.update(doc(db, `users/${ownerEmail}/files/${user?.selectedFile}`), {
          updatedAt: new Date(),
        });
        batch
          .commit()
          .then(() => {
            updateLesson
              ? toast.success(selectedSubject[0] + t(' updated sucessfully'))
              : toast.success(selectedSubject[0] + t(' created sucessfully'));
            history.push('/lessons');
          })
          .catch(() => {
            toast.error(t('An error has ocurred'));
            history.push('/lessons');
          });
      } catch (error: any) {
        toast.error(`${error?.message?.split(':').slice(-1)[0].trim() ?? t('An error has occurred')}`);
        console.error({ error });
      }
    }
  }

  const [selectedClasses, setSelectedClasses] = React.useState<string[][]>(
    lesson.classes.length === 0
      ? [[]]
      : lesson.classes.map((res) => {
          return res.split(RESOURCE_OPTIONS_SEPARATOR);
        })
  );

  const [copyLessonOpen, setCopyLessonOpen] = useState(false);
  const [copyLessonConfirmed, setCopyLessonConfirmed] = useState(false);

  function checkCopyLessonConfirmation() {
    if (copyLessonConfirmed) {
      setCopyLessonConfirmed(false);
      copy();
    }
  }
  checkCopyLessonConfirmation();

  function copy() {
    if (userPlan === FREE && lessons.length + 1 > planFeatures.free.maxLessons) {
      setLessonNumLimitReached(true);
    } else if (userPlan === BASIC && lessons.length + 1 > planFeatures.basic.maxLessons) {
      setLessonNumLimitReached(true);
    } else {
      setIsLoadingCopy(true);
      try {
        //Validation and newName adjustments
        const newClass = '' + localStorage.getItem('copyClass');
        if (newClass === '') toast.error(t('An error has ocurred'));
        const newName: string = lesson.subject[0].trim() + SUBJECT_CLASS_SEPARATOR + newClass;
        if (lessons.map((lesson) => lesson.name).includes(newName)) {
          toast.error(newName + t(' already registered'));
          return;
        }
        const newLesson = { ...lesson };
        newLesson.name = newName;
        newLesson.classes = [newClass];
        newLesson.createdAt = Timestamp.now();
        newLesson.updatedAt = Timestamp.now();
        setDoc(doc(db, lessonsPath, newName), newLesson).then(() => {
          //Set all solutions to outdated
          solutions.forEach((solution) => {
            updateDoc(doc(db, `${solutionsPath}/${solution.name}`), {
              status: 'Outdated',
            });
          });
          toast.success(lesson.subject[0].trim() + t(' copied sucessfully'));
          history.push('/lessons');
        });
        updateDoc(doc(db, `users/${ownerEmail}/files/${user?.selectedFile}`), {
          updatedAt: new Date(),
        });
      } catch (error: any) {
        toast.error(`${error?.message?.split(':').slice(-1)[0].trim() ?? t('An error has occurred')}`);
        console.error({ error });
      }
    }
  }

  const [selectedSubject, setSelectedSubject] = React.useState<string[]>(lesson.subject);
  const [selectedLessonsWeek, setSelectedLessonsWeek] = React.useState<number>(lesson.lessonsWeek);
  const handleChangeLessonsWeek = (event: SelectChangeEvent) => {
    setSelectedLessonsWeek(event.target.value as unknown as number);
    setSelectedSplit([]);
  };

  const [selectedSplit, setSelectedSplit] = React.useState<string[]>(lesson.split);
  const [selectedMinGapLessons, setSelectedMinGapLessons] = React.useState<number>(lesson.minGapLessons);
  const [selectedMaxGapLessons, setSelectedMaxGapLessons] = React.useState<number>(lesson.maxGapLessons);
  const [selectedTeachers, setSelectedTeachers] = React.useState<string[][]>(
    lesson.teachers.length === 0 && !updateLesson
      ? [[]]
      : lesson.teachers.map((res) => {
          return res.split(RESOURCE_OPTIONS_SEPARATOR);
        })
  );
  const [selectedRooms, setSelectedRooms] = React.useState<string[][]>(
    lesson.rooms.map((res) => {
      return res.split(RESOURCE_OPTIONS_SEPARATOR);
    })
  );
  const [selectedSimultaneousWith, setSelectedSimultaneousWith] = React.useState<string[]>(lesson.simultaneousWith);
  const [selectedNotSimultaneousWith, setSelectedNotSimultaneousWith] = React.useState<string[]>(
    lesson.notSimultaneousWith
  );
  const [selectedOccurBefore, setSelectedOccurBefore] = React.useState<string[]>(lesson.occurBefore);
  const [selectedOccurBeforeMinDays, setSelectedOccurBeforeMinDays] = React.useState<number>(lesson.occurBeforeMinDays);
  const handleChangeOccurBeforeMinDays = (event: SelectChangeEvent) => {
    setSelectedOccurBeforeMinDays(event.target.value as unknown as number);
  };
  //#endregion

  // @TODO review
  const copyClassOptions = resources
    .filter(
      (res) =>
        res.type === 'class' &&
        !selectedClasses.some((classArray) => classArray.includes(res.name)) &&
        !lessons
          .filter((lesson) => lesson.subject[0] === selectedSubject[0])
          .map((les) => les.classes[0])
          .includes(res.name)
    )
    .map((res) => res.name);
  const [selectedCopyClass, setSelectedCopyClass] = React.useState<string>(
    copyClassOptions.length === 0 ? '' : copyClassOptions[0]
  );

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        key={`simple-tabpanel-${index}`}
        id={`simple-tabpanel-${index}`}
        style={{ maxWidth: '100%', width: '100%' }}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }

  const [selectedTab, setSelectedTab] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  //#region States for and Tables

  const [selectedCells, setSelectedCells] = React.useState<readonly string[]>(lesson.predefinedTimes);

  const cells = useMemo(() => {
    let updatedCells: string[] = [];
    times.forEach((_, t_index) => {
      days.forEach((_, d_index) => {
        updatedCells.push(d_index + DAY_TIME_SEPARATOR + t_index);
      });
    });
    return updatedCells;
  }, [times, days]);

  const isSelected = (name: string) => selectedCells.indexOf(name) !== -1;

  const handleSelectAllDayClick = (event: React.MouseEvent<unknown>, name: string) => {
    let newSelectedCells = [...selectedCells];
    cells.forEach((element) => {
      let cellDay = element.split(DAY_TIME_SEPARATOR)[0];
      let nameDay = name.split(DAY_TIME_SEPARATOR)[0];
      if (cellDay === nameDay) {
        const selectedIndex = selectedCells.indexOf(element);
        if (selectedIndex === -1) {
          newSelectedCells.push(element);
          contSelectedAtDay[nameDay as unknown as number]++;
          contSelected++;
        } else {
          newSelectedCells = newSelectedCells.filter((item) => item !== element);
          contSelectedAtDay[nameDay as unknown as number]--;
          contSelected--;
        }
      }
    });
    setSelectedCells(newSelectedCells);
  };

  const handleSelectAllTimeClick = (event: React.MouseEvent<unknown>, name: string) => {
    let newSelected = [...selectedCells];
    cells.forEach((element) => {
      let cellTime = element.split(DAY_TIME_SEPARATOR)[1];
      let nameTime = name.split(DAY_TIME_SEPARATOR)[1];
      if (cellTime === nameTime) {
        const selectedIndex = selectedCells.indexOf(element);
        if (selectedIndex === -1) {
          newSelected.push(element);
          contSelectedAtTime[nameTime as unknown as number]++;
          contSelected++;
        } else {
          newSelected = newSelected.filter((item) => item !== element);
          contSelectedAtTime[nameTime as unknown as number]--;
          contSelected--;
        }
      }
    });
    setSelectedCells(newSelected);
  };

  const handleSelectAllClick = (event: React.MouseEvent<unknown>, name: string) => {
    let newSelected = [...selectedCells];
    cells.forEach((element) => {
      let cellTime = element.split(DAY_TIME_SEPARATOR)[1];
      let cellDay = element.split(DAY_TIME_SEPARATOR)[0];
      const selectedIndex = selectedCells.indexOf(element);
      if (selectedIndex === -1) {
        newSelected.push(element);
        contSelectedAtDay[cellDay as unknown as number]++;
        contSelectedAtTime[cellTime as unknown as number]++;
        contSelected++;
      } else {
        newSelected = newSelected.filter((item) => item !== element);
        contSelectedAtDay[cellDay as unknown as number]--;
        contSelectedAtTime[cellTime as unknown as number]--;
        contSelected--;
      }
    });
    setSelectedCells(newSelected);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selectedCells.indexOf(name);
    let newSelected: readonly string[] = [];
    let nameDay = name.split(DAY_TIME_SEPARATOR)[0];
    let nameTime = name.split(DAY_TIME_SEPARATOR)[1];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedCells, name);
      contSelectedAtDay[nameDay as unknown as number]++;
      contSelectedAtTime[nameTime as unknown as number]++;
      contSelected++;
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedCells.slice(1));
      contSelectedAtDay[nameDay as unknown as number]--;
      contSelectedAtTime[nameTime as unknown as number]--;
      contSelected--;
    } else if (selectedIndex === selectedCells.length - 1) {
      newSelected = newSelected.concat(selectedCells.slice(0, -1));
      contSelectedAtDay[nameDay as unknown as number]--;
      contSelectedAtTime[nameTime as unknown as number]--;
      contSelected--;
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selectedCells.slice(0, selectedIndex), selectedCells.slice(selectedIndex + 1));
      contSelectedAtDay[nameDay as unknown as number]--;
      contSelectedAtTime[nameTime as unknown as number]--;
      contSelected--;
    }
    setSelectedCells(newSelected);
  };

  let { contSelectedAtDay, contSelectedAtTime, contSelected } = useMemo(() => {
    const contSelectedAtDay: number[] = [];
    const contSelectedAtTime: number[] = [];
    let contSelected = 0;

    days.forEach((_) => {
      contSelectedAtDay.push(0);
    });

    times.forEach((_) => {
      contSelectedAtTime.push(0);
    });

    cells.forEach((element) => {
      const cellDay = element.split(DAY_TIME_SEPARATOR)[0];
      const cellTime = element.split(DAY_TIME_SEPARATOR)[1];
      const selectedIndex = selectedCells.indexOf(element);

      if (selectedIndex !== -1) {
        contSelectedAtDay[parseInt(cellDay)]++;
        contSelectedAtTime[parseInt(cellTime)]++;
        contSelected++;
      }
    });

    return {
      contSelectedAtDay,
      contSelectedAtTime,
      contSelected,
    };
  }, [days, times, cells, selectedCells]);

  const rowCount: number = times.length;
  const colCount: number = days.length;
  const allCount: number = days.length * times.length;

  //#endregion

  const [jointClassesConfirmationOpen, setJointClassesConfirmationOpen] = useState(false);
  const [jointClassesConfirmed, setJointClassesConfirmed] = useState(false);
  const checkJointClassesConfirmed = () => {
    if (jointClassesConfirmed) {
      setJointClassesConfirmed(false);
      submitForm();
    }
  };
  checkJointClassesConfirmed();

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);
  const checkDeleteConfirmed = () => {
    if (deleteConfirmed) {
      setDeleteConfirmed(false);
      deleteDoc(doc(db, lessonsPath, lesson.name));
      toast.success(lesson.name + ' deleted sucessfully!');
      history.push('/lessons');
      // Update file stats
      updateDoc(doc(db, `users/${ownerEmail}/files/${user?.selectedFile}`), {
        updatedAt: new Date(),
      });
    }
  };
  checkDeleteConfirmed();

  const [toAllConfirmationOpen, setToAllConfirmationOpen] = useState(false);
  const [toAllConfirmed, setToAllConfirmed] = useState(false);

  const checkToAllConfirmation = () => {
    if (toAllConfirmed) {
      setToAllConfirmed(false);
      submitForm();
    }
  };
  checkToAllConfirmation();

  const splitOptions: string[] = useMemo(() => {
    const numSplitOptions: number[][] = [];

    //#region Split options processing
    // Source: https://acervolima.com/encontre-todas-as-combinacoes-que-somam-um-determinado-numero/
    // Javascript program to find out
    // all combinations of positive
    // numbers that add upto given
    // number

    /* arr - array to store the
      combination
      index - next location in array
      num - given number
      reducedNum - reduced number */
    function findCombinationsUtil(
      numSplitOptions: number[][],
      arr: number[],
      index: number,
      num: number,
      reducedNum: number
    ) {
      // Base condition
      if (reducedNum < 0) return;

      // If combination is
      // found, print it
      if (reducedNum === 0) {
        let numSplitOption = [];
        for (let i = 0; i < index; i++) numSplitOption.push(arr[i]);
        numSplitOptions.push(numSplitOption);
        return;
        // let splitOption = '';
        // for (let i = 0; i < index; i++) splitOption = splitOption + '' + arr[i] + '-';
        // splitOption = splitOption.substring(0, splitOption.length - 1);
        // splitOptions.push(splitOption);
        // return;
      }

      // Find the previous number
      // stored in arr[]. It helps
      // in maintaining increasing
      // order
      let prev = index === 0 ? 1 : arr[index - 1];

      // note loop starts from
      // previous number i.e. at
      // array location index - 1
      for (let k = prev; k <= num; k++) {
        // next element of
        // array is k
        arr[index] = k;

        // call recursively with
        // reduced number
        findCombinationsUtil(numSplitOptions, arr, index + 1, num, reducedNum - k);
      }
    }

    /* Function to find out all
    combinations of positive
    numbers that add upto given
    number. It uses findCombinationsUtil() */
    function findCombinations(numSplitOptions: number[][], n: number) {
      // array to store the combinations
      // It can contain max n elements
      let arr: number[] = [];

      // find all combinations
      findCombinationsUtil(numSplitOptions, arr, 0, n, n);
    }
    // Driver Code
    findCombinations(numSplitOptions, selectedLessonsWeek);

    // Filter to remove invalid split options and conversion to string
    const splitOptions: string[] = [];
    for (let i = 0; i < numSplitOptions.length; ++i) {
      const res = numSplitOptions[i].filter((num) => num <= times.length);
      const sumDurations = numSplitOptions[i].reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      const isDoable = sumDurations + numSplitOptions[i].length - days.length <= days.length * times.length;
      if (res.length === numSplitOptions[i].length && isDoable) {
        // add splitOption as string
        let strSplitOption = '';
        numSplitOptions[i].forEach((num) => (strSplitOption = strSplitOption + num + '-'));
        strSplitOption = strSplitOption.substring(0, strSplitOption.length - 1);
        splitOptions.push(strSplitOption);
      }
    }

    return splitOptions.reverse();
  }, [selectedLessonsWeek, times]);

  //#endregion

  const getLessonClasses = (lesson: ILesson) => {
    const classes: string[] = [];
    lesson.classes.forEach((class_) => {
      classes.push(...class_.split(RESOURCE_OPTIONS_SEPARATOR));
    });
    return classes;
  };

  const existingClassForSubject = (lessons: ILesson[], subjects: string[], class_: string) => {
    for (const lesson of lessons) {
      if (lesson.subject[0] === subjects[0]) {
        const lessonClasses = getLessonClasses(lesson);
        if (lessonClasses.includes(class_)) {
          return class_;
        }
      }
    }
  };

  const exisintgSubjectForOneOfSelectedClasses = (
    lessons: ILesson[],
    subjects: string[],
    selectedClasses: string[][]
  ) => {
    const existingClasses: string[] = [];
    for (const selectedClass of selectedClasses) {
      for (const class_ of selectedClass) {
        const existingClass = existingClassForSubject(lessons, subjects, class_);
        if (existingClass) existingClasses.push(existingClass);
      }
    }
    return existingClasses;
  };

  const validateInput = () => {
    const lessonNames: string[] = lessons.map((lesson) => lesson.name);
    const name = selectedSubject[0] + SUBJECT_CLASS_SEPARATOR + selectedClasses.toString();
    const existingClasses = exisintgSubjectForOneOfSelectedClasses(
      lessons.filter((l) => l.name !== lesson.name),
      selectedSubject,
      selectedClasses
    );
    if (previousName !== name && lessonNames.includes(name)) {
      toast.error(`${selectedSubject[0]} ${t('is already offered to class')} ${selectedClasses.toString()}`);
      return false;
    } else if (existingClasses.length > 0) {
      toast.error(`${selectedSubject[0]} ${t('is already offered to class(es)')} ${existingClasses.join(' ')}`);
      return false;
    } else if (selectedSubject.length === 0) {
      toast.error(t('Subject cannot be empty'));
      return false;
    } else if (selectedClasses.length === 0) {
      toast.error(t('Class cannot be empty'));
      return false;
      // } else if (selectedSplit === '1-1' && +selectedLessonsWeek !== 2) {
    } else if (+selectedSplit.length === 0) {
      toast.error(t('Split Options cannot be empty'));
      return false;
    } else if (
      selectedClasses.find((classes) => {
        return classes.length === 0;
      })
    ) {
      const missingClassIndex = selectedClasses.findIndex((classes) => {
        return classes.length === 0;
      });
      if (missingClassIndex === 0) toast.error(`${t('Select a class to the lesson')}`);
      else toast.error(`${t('Select a class to be the Class')} ${missingClassIndex + 1} ${t('on this lesson')}`);
      return false;
    } else if (
      selectedTeachers.find((teachers) => {
        return teachers.length === 0;
      })
    ) {
      const missingTeacherIndex = selectedTeachers.findIndex((teachers) => {
        return teachers.length === 0;
      });
      if (missingTeacherIndex === 0) toast.error(`${t('Select a teacher to the lesson')}`);
      else toast.error(`${t('Select a teacher to be the Teacher')} ${missingTeacherIndex + 1} ${t('on this lesson')}`);
      return false;
    } else if (
      selectedRooms.find((rooms) => {
        return rooms.length === 0;
      })
    ) {
      const missingRoomIndex = selectedRooms.findIndex((rooms) => {
        return rooms.length === 0;
      });
      if (missingRoomIndex === 0) toast.error(`${t('Select a room to the lesson')}`);
      else toast.error(`${t('Select a room to be the Room')} ${missingRoomIndex + 1} ${t('on this lesson')}`);
      return false;
    } else if (selectedCells.length > selectedLessonsWeek) {
      toast.error(t('You selected more predefined times than the number of times per week of this lesson'));
      return false;
    } else if (selectedOccurBefore.length !== 0 && selectedOccurBeforeMinDays === 0) {
      toast.error(
        t('You must select how many days, at least, the lessons of ') +
          name +
          t('should occur before any lesson of ') +
          selectedOccurBefore.join(', ')
      );
      return false;
    } else if (selectedOccurBefore.length === 0 && selectedOccurBeforeMinDays > 0) {
      toast.error(
        `${t('You must select the lesson(s) that require ')}${name} ${t('to occur ')}${selectedOccurBeforeMinDays} ${t(
          'days before'
        )}`
      );
      return false;
    } else {
      return true;
    }
  };

  if (file?.roomsSetting === 0) lessonCreationSteps.splice(4, 1);

  const handleNavToUpgradePlan = () => {
    setLessonNumLimitReached(false);
    history.push('/settings');
  };

  const [videoPlayerDialogOpen, setVideoPlayerDialogOpen] = useState(false);

  function handleSave() {
    setHasAttemptedToSave(true);
    if (!validateInput()) return;
    if (selectedForDaysBetweenLessons.length > 0) setToAllConfirmationOpen(true);
    else if (!jointClassesConfirmed && selectedClasses.length > 1) setJointClassesConfirmationOpen(true);
    else submitForm();
  }

  function getApplyToMoreConfirmationMessage() {
    return (
      <Stack>
        <Typography paddingBottom={2}>{`${t('The following constraints will apply to other lessons')}:`}</Typography>
        {selectedForDaysBetweenLessons.length > 0 && (
          <Typography>{` - ${t('Days between Meetings')}: ${selectedForDaysBetweenLessons.join(', ')}`}</Typography>
        )}
        <Typography paddingTop={2}>{`${t('Are you sure?')}`}</Typography>
      </Stack>
    );
  }

  // #region Leave confirmation
  const checkHasChanges = () => {
    return !isEqual(lesson, getNewLesson());
  };

  const [showLeaveConfirmationDialog, setShowLeaveConfirmationDialog] = useState(false);
  const [nextLocation, setNextLocation] = useState<Location<unknown>>();
  const [isNavigationConfirmed, setIsNavigationConfirmed] = useState(false);
  const handleNavigation = (location: Location<unknown>) => {
    setNextLocation(location); // Store the next location
    setShowLeaveConfirmationDialog(true); // Show the dialog
    return false; // Block the navigation initially
  };
  // #endregion

  const resourceAppearsInAnyOtherSelection = (resourceName: string, selection: string[][], index: number) => {
    for (let i = 0; i < selection.length; ++i) {
      if (i === index) continue;
      if (
        selection[i].find((cell) => {
          return cell === resourceName;
        })
      )
        return true;
    }
    return false;
  };

  return (
    <PageContainer>
      <Onboarding run={runLessonCreationOnboarding} steps={lessonCreationSteps} storageFlagKey={LESSON_CREATION_KEY} />
      <Prompt
        when={updateLesson && checkHasChanges() && !isNavigationConfirmed}
        message={(location) => {
          handleNavigation(location);
          return false; // Block the navigation until the user decides
        }}
      />
      <ConfirmationDialog
        open={showLeaveConfirmationDialog}
        positiveLabel={readOnly ? '' : t('Save changes')}
        positiveAction={() => {
          handleSave();
          setIsNavigationConfirmed(true);
        }}
        negativeLabel={t('Discard changes')}
        negativeAction={() => {
          setIsNavigationConfirmed(true); // Confirm navigation before closing the dialog
          setShowLeaveConfirmationDialog(false); // Close the dialog

          // Ensure navigation happens after state updates are completed
          setTimeout(() => {
            if (nextLocation) {
              history.push(nextLocation.pathname); // Programmatically navigate after discarding
            }
          }, 0); // Small delay to ensure state update is processed
        }}
        title={t('Unsaved changes')}
        message={
          readOnly
            ? `${t('You are in read only mode. You cannot save changes to')} ${lesson.name}`
            : `${lesson.name} ${t('has unsaved changes. Would you like to save them?')}`
        }
      />
      <PlanInfoDialog
        open={showPlanDialog}
        positiveLabel={t('Upgrade plan')}
        negativeLabel={t('Back')}
        positiveAction={handleNavToUpgradePlan}
        negativeAction={() => {
          setShowPlanDialog(false);
        }}
        title={
          <>
            <WorkspacePremium sx={{ fontSize: 20, color: customColors.basic, marginRight: 1 }} />
            {t('Basic tier feature')}
          </>
        }
        message={t(
          'This feature is only available in the Basic and Premium plans. Please upgrade your plan to start using now!'
        )}
      />
      <DraggableDialog
        open={jointClassesConfirmationOpen}
        setOpen={setJointClassesConfirmationOpen}
        setConfirmed={setJointClassesConfirmed}
        title={t('Warning')}
        message={`${t('Classes')} ${selectedClasses.join(' ')} ${t('will have lessons of')} ${selectedSubject[0]} ${t(
          'with the same teacher and room AT THE SAME TIME. Are you sure?'
        )}`}
      />
      <DraggableDialog
        open={deleteConfirmationOpen}
        setOpen={setDeleteConfirmationOpen}
        setConfirmed={setDeleteConfirmed}
        title={t('Warning')}
        message={lesson.name + t(' will be permanently deleted from this project. Are you sure?')}
      />
      {videoPlayerDialogOpen && (
        <VideoPlayerDialog
          open={videoPlayerDialogOpen}
          setOpen={setVideoPlayerDialogOpen}
          videoURL={t('video_url_help_lesson')}
        ></VideoPlayerDialog>
      )}
      {lessonNumLimitReached && (
        <PlanInfoDialog
          open={lessonNumLimitReached}
          positiveLabel={t('Upgrade plan')}
          negativeLabel={t('Back')}
          positiveAction={handleNavToUpgradePlan}
          negativeAction={() => {
            setLessonNumLimitReached(false);
          }}
          title={t('Plan limit reached')}
          message={t(
            'You have reached the limit of lessons for this project in your plan! Please consider upgrading your plan to create more lessons.'
          )}
        />
      )}
      {toAllConfirmationOpen && (
        <DraggableDialog
          open={toAllConfirmationOpen}
          setOpen={setToAllConfirmationOpen}
          setConfirmed={setToAllConfirmed}
          title={t('Warning')}
          message={getApplyToMoreConfirmationMessage()}
        />
      )}
      {copyLessonOpen && (
        <ValueSelectConfirmDialog
          open={copyLessonOpen}
          setOpen={setCopyLessonOpen}
          setConfirmed={setCopyLessonConfirmed}
          value={selectedCopyClass}
          setValue={setSelectedCopyClass}
          valueOptions={copyClassOptions}
          title={t('Copy Lesson')}
          selectLabel={t('Class')}
          message={`${t('Select the')} ${file?.studentsSetting !== 0 ? t('class') : t('student')} ${t(
            'to which the copied lesson will be taught:'
          )}`}
        />
      )}
      <MiniDrawer />
      <Grid container justifyContent="center" paddingBottom={8}>
        <Paper elevation={3} sx={{ width: '100%', maxWidth: 'lg' }}>
          <Grid container item xs={12} justifyContent={'space-between'} padding={2} spacing={1} flexDirection={'row'}>
            <Grid item container xs={12} spacing={1} padding={1} paddingRight={0}>
              <Grid item flexGrow={1}>
                <Typography variant="body1" gutterBottom color={theme.palette.primary.main}>
                  <Tooltip title={t('Back')}>
                    <IconButton
                      style={{ height: 24, width: 24, marginRight: 4 }}
                      color="primary"
                      onClick={() => history.push('/lessons')}
                    >
                      <ArrowBack style={{ height: 20, width: 20 }} />
                    </IconButton>
                  </Tooltip>
                  {updateLesson ? t('Update ') + t('lesson') : t('New lesson')}
                </Typography>
                <Divider color={theme.palette.primary.main} />
              </Grid>
              <TooltipIconButton
                tooltip={t('Watch a helpful video')}
                Icon={QuestionMark}
                color={theme.palette.primary.main}
                onClick={() => setVideoPlayerDialogOpen(true)}
              />
              {updateLesson ? (
                <>
                  <Grid item xs={2} md={2}>
                    <Button
                      variant="outlined"
                      size="small"
                      disabled={readOnly}
                      onClick={() => setDeleteConfirmationOpen(true)}
                      color="error"
                      fullWidth
                    >
                      {t('Delete')}
                    </Button>
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <LoadingButton
                      variant="outlined"
                      size="small"
                      disabled={readOnly}
                      onClick={() => setCopyLessonOpen(true)}
                      color="primary"
                      fullWidth
                      startIcon={null}
                      loadingPosition="start"
                      loading={isLoadingCopy}
                    >
                      {t('Copy')}
                    </LoadingButton>
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <LoadingButton
                      className={'update-button'}
                      variant="contained"
                      size="small"
                      disabled={readOnly}
                      onClick={handleSave}
                      color="primary"
                      fullWidth
                      startIcon={null}
                      loadingPosition="start"
                      loading={isLoading}
                    >
                      {t('Update')}
                    </LoadingButton>
                  </Grid>
                </>
              ) : (
                <Grid item xs={3} md={2}>
                  <LoadingButton
                    className={'save-button'}
                    variant="contained"
                    size="small"
                    disabled={readOnly}
                    onClick={handleSave}
                    color="primary"
                    fullWidth
                    startIcon={null}
                    loadingPosition="start"
                    loading={isLoading}
                  >
                    {t('Save')}
                  </LoadingButton>
                </Grid>
              )}
            </Grid>

            <Grid item xs={12}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<GridExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <DialogContentText>{t('Participants')}</DialogContentText>
                </AccordionSummary>
                <AccordionDetails className="lesson-definition">
                  <Grid item container xs={12} spacing={1} padding={1}>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      marginTop={1}
                      sx={{ textAlign: 'right', paddingRight: 2, color: isDarkMode ? grey[100] : grey[800] }}
                    >
                      {t('Subject')}
                    </Grid>
                    <Grid className={'lesson-class'} item xs={9} sm={8}>
                      <FormControl fullWidth size="small">
                        <MultipleSelectChip
                          single={true}
                          label={t('Subject')}
                          names={subjects_}
                          personName={selectedSubject}
                          chipColor={subjects.find((sub) => sub.name === selectedSubject[0])?.color}
                          setPersonName={setSelectedSubject}
                          InputProps={{
                            autoFocus: true, // Use the autoFocus attribute
                          }}
                        ></MultipleSelectChip>
                      </FormControl>
                      {getSubjectHelperText(t, selectedSubject, hasAttemptedToSave, theme)}
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={false} sm={1}>
                      <></>
                    </Grid>
                  </Grid>

                  {/* Classes */}
                  <Divider />
                  <Grid item container xs={12} spacing={0} paddingTop={2}>
                    {selectedClasses.map((_, index) => {
                      return (
                        <Grid key={index} item container xs={12} spacing={1} padding={1}>
                          <Grid
                            item
                            xs={2}
                            sm={2}
                            marginTop={1}
                            sx={{ textAlign: 'right', paddingRight: 2, color: isDarkMode ? grey[100] : grey[800] }}
                          >
                            {index === 0 && (selectedClasses.length > 1 ? t('Classes') : t('Class'))}
                          </Grid>
                          <Grid className={'lesson-class'} item xs={9} sm={8}>
                            <FormControl fullWidth size="small">
                              <LessonResourceMultipleSelectChip
                                single={true}
                                label={
                                  file?.studentsSetting !== 0
                                    ? `${t('Class')} ${index > 0 ? Number(index + 1) : ''}`
                                    : `${t('Student')} ${index > 0 ? Number(index + 1) : ''}`
                                }
                                names={classes.filter((class_) => {
                                  return (
                                    !resourceAppearsInAnyOtherSelection(class_, selectedClasses, index) &&
                                    !existingClassForSubject(
                                      lessons.filter((l) => l.name !== lesson.name),
                                      selectedSubject,
                                      class_
                                    )
                                  );
                                })}
                                index={index}
                                personName={selectedClasses}
                                setPersonName={setSelectedClasses}
                              />
                            </FormControl>
                            {getClassHelperText(t, index, selectedClasses, hasAttemptedToSave, theme)}
                          </Grid>
                          <Grid item xs={2}>
                            {index !== 0 && (
                              <Tooltip placement="right" title={t('Remove the demand for class')}>
                                <Button
                                  variant="text"
                                  color="error"
                                  onClick={() => {
                                    const updatedClasses = selectedClasses.filter((_, i) => i !== index);
                                    setSelectedClasses(updatedClasses);
                                  }}
                                >
                                  <Delete />
                                </Button>
                              </Tooltip>
                            )}
                          </Grid>
                          <Grid item xs={false} sm={1}></Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                  <Grid container paddingBottom={1}>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      marginTop={1}
                      sx={{ textAlign: 'right', paddingRight: 2, color: isDarkMode ? grey[100] : grey[800] }}
                    >
                      {selectedClasses.length === 0 && t('Class')}
                    </Grid>
                    <Grid item xs={10}>
                      <Button
                        variant="text"
                        onClick={() => {
                          setSelectedClasses([...selectedClasses, []]);
                        }}
                      >
                        {t('Add more classes')}
                      </Button>
                    </Grid>
                  </Grid>

                  {/* Teachers */}
                  <Divider />
                  <Grid item container xs={12} spacing={0} paddingTop={2}>
                    {selectedTeachers.map((_, index) => {
                      return (
                        <Grid key={index} item container xs={12} spacing={1} padding={1}>
                          <Grid
                            item
                            xs={2}
                            sm={2}
                            marginTop={1}
                            sx={{ textAlign: 'right', paddingRight: 2, color: isDarkMode ? grey[100] : grey[800] }}
                          >
                            {index === 0 && (selectedTeachers.length > 1 ? t('Teachers') : t('Teacher'))}
                          </Grid>
                          <Grid className={'lesson-teacher'} item xs={8} sm={8}>
                            <FormControl fullWidth size="small">
                              <LessonResourceMultipleSelectChip
                                single={false}
                                label={
                                  file?.teachersSetting === 2
                                    ? `${t('Teacher')} ${index > 0 ? Number(index + 1) : ''}`
                                    : `${t('Teacher Options')} ${index > 0 ? Number(index + 1) : ''}`
                                }
                                names={teachers.filter((teacher) => {
                                  return !resourceAppearsInAnyOtherSelection(teacher, selectedTeachers, index);
                                })}
                                index={index}
                                personName={selectedTeachers}
                                setPersonName={setSelectedTeachers}
                              />
                            </FormControl>
                            {getTeacherHelperText(t, index, selectedTeachers, hasAttemptedToSave, theme)}
                          </Grid>
                          <Grid className={'joint-teachers'} item xs={2}>
                            <Tooltip placement="right" title={t('Remove the demand for teacher')}>
                              <Button
                                variant="text"
                                color="error"
                                onClick={() => {
                                  setSelectedTeachers(selectedTeachers.filter((_, i) => i !== index));
                                }}
                              >
                                <Delete />
                              </Button>
                            </Tooltip>
                          </Grid>
                          <Grid item xs={false} sm={1}>
                            <></>
                          </Grid>
                        </Grid>
                      );
                    })}
                    <Grid container paddingBottom={2}>
                      <Grid
                        item
                        xs={2}
                        sm={2}
                        marginTop={0.5}
                        sx={{ textAlign: 'right', paddingRight: 2, color: isDarkMode ? grey[100] : grey[800] }}
                      >
                        {selectedTeachers.length === 0 && t('Teacher')}
                      </Grid>
                      <Grid item xs={10}>
                        <Button
                          className={'add-teachers'}
                          variant="text"
                          onClick={() => {
                            setSelectedTeachers([...selectedTeachers, []]);
                          }}
                        >
                          {selectedTeachers.length === 0 ? t('Add teacher') : t('Add more teachers')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Rooms */}
                  <Divider />
                  <Grid item container xs={12} spacing={0} paddingTop={2}>
                    {selectedRooms.map((_, index) => {
                      return (
                        <Grid key={index} item container xs={12} spacing={1} padding={1}>
                          <Grid
                            item
                            xs={2}
                            sm={2}
                            marginTop={1}
                            sx={{ textAlign: 'right', paddingRight: 2, color: isDarkMode ? grey[100] : grey[800] }}
                          >
                            {index === 0 && (selectedRooms.length > 1 ? t('Rooms') : t('Room'))}
                          </Grid>
                          {file?.roomsSetting !== 0 && (
                            <Grid className={'lesson-room'} item xs={9} sm={8}>
                              <FormControl fullWidth size="small">
                                <LessonResourceMultipleSelectChip
                                  label={t('Room Options')}
                                  names={rooms.filter((room) => {
                                    return !resourceAppearsInAnyOtherSelection(room, selectedRooms, index);
                                  })}
                                  index={index}
                                  personName={selectedRooms}
                                  setPersonName={setSelectedRooms}
                                  // disabled={selectedRoomNum === 0}
                                />
                              </FormControl>
                              {getRoomHelperText(t, index, selectedRooms, hasAttemptedToSave, theme)}
                            </Grid>
                          )}
                          {file?.roomsSetting !== 0 && (
                            <Grid item xs={2}>
                              <Tooltip placement="right" title={t('Remove the demand for room')}>
                                <Button
                                  variant="text"
                                  color="error"
                                  onClick={() => {
                                    setSelectedRooms(selectedRooms.filter((_, i) => i !== index));
                                  }}
                                >
                                  <Delete />
                                </Button>
                              </Tooltip>
                            </Grid>
                          )}
                          {file?.roomsSetting !== 0 && (
                            <Grid item xs={false} sm={1}>
                              <></>
                            </Grid>
                          )}
                        </Grid>
                      );
                    })}
                    <Grid container>
                      <Grid
                        item
                        xs={2}
                        sm={2}
                        marginTop={0.5}
                        sx={{ textAlign: 'right', paddingRight: 2, color: isDarkMode ? grey[100] : grey[800] }}
                      >
                        {selectedRooms.length === 0 && t('Room')}
                      </Grid>
                      <Grid item xs={10}>
                        <Button
                          variant="text"
                          onClick={() => {
                            setSelectedRooms([...selectedRooms, []]);
                          }}
                        >
                          {selectedRooms.length === 0 ? t('Add room') : t('Add more rooms')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<GridExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <DialogContentText>{t('Distribution of class meetings')}</DialogContentText>
                </AccordionSummary>
                <AccordionDetails className="distribution">
                  {/* Lessons per Week and Split */}
                  <Grid item container xs={12} spacing={1} padding={1} flexDirection={'row'}>
                    <Grid item xs={false} sm={1}>
                      <></>
                    </Grid>
                    <Grid item container xs={6} sm={5} justifyContent={'flex-end'}>
                      <Tooltip placement="top-end" title={t('Sets the required number of times for this lesson')}>
                        <FormControl fullWidth size="small">
                          <InputLabel id="days-select-label">{t('Times per Week')}</InputLabel>
                          <Select
                            size="small"
                            labelId="lessons-week-select-label"
                            id="lessons-week-select"
                            defaultValue={'' + selectedLessonsWeek}
                            onChange={handleChangeLessonsWeek}
                            label={t('Times per Week')}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  ...(isDarkMode ? darkModeScrollBar : {}),
                                },
                              },
                            }}
                          >
                            {Array.from({ length: days.length * times.length + 1 }).map((_, index) => {
                              return (
                                <MenuItem key={`lessonsWeek_${index}`} value={`${index}`}>
                                  {index === 0 ? `↓` : index}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={6} sm={5} className="split-options">
                      <Tooltip
                        placement="right-end"
                        title={
                          <div>
                            {t('Sets the way the class meetings of this lesson must be divided')}
                            <hr />
                            {t(
                              'For example, 1-1-2 means two single-time (1) class meetings and one double-time class meeting (2). You can set several suitable options:'
                            )}
                            <p />
                            <img
                              src={'/img/examples/splitLesson.png'}
                              width={'100%'}
                              alt={t('Example of Split option')}
                              loading="lazy"
                            />
                          </div>
                        }
                      >
                        <Box>
                          <MultipleSelectChip
                            single={false}
                            label={t('Split Options')}
                            names={splitOptions}
                            personName={selectedSplit}
                            setPersonName={setSelectedSplit}
                          ></MultipleSelectChip>
                        </Box>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={false} sm={1}>
                      <></>
                    </Grid>
                    {/* Gap between Lessons */}
                    <Grid item xs={12} marginTop={4}>
                      <MinMaxSlider
                        name={selectedSubject[0]}
                        leftIcon={<FormatLineSpacing sx={{ transform: 'rotate(90deg)', marginRight: 1 }} />}
                        label={t('Days between Meetings')}
                        labelHelper={t('days between each of its class meetings')}
                        tooltipComponent={
                          <div>
                            {t('Limits the number of days between class meetings of this lesson')}
                            <hr />
                            {`${t(
                              'For example, if Min is set to 2, there will be at least 2 days between the class meetings of this lesson, hence if a class occurs on Tuesday, another class of this lesson can only occur on Thursday or Friday:'
                            )}`}
                            <p />
                            <img
                              src={'/img/examples/daysBetweenLessons.png'}
                              width={'100%'}
                              alt={t('Example of Days between Meetings constraint')}
                              loading="lazy"
                            />
                          </div>
                        }
                        selectedMin={selectedMinGapLessons}
                        setSelectedMin={setSelectedMinGapLessons}
                        selectedMax={selectedMaxGapLessons}
                        setSelectedMax={setSelectedMaxGapLessons}
                        possibleMin={0}
                        possibleMax={days.length - 1}
                        tooltipApplyToAll={t('Apply to all lessons')}
                        selectedMore={selectedForDaysBetweenLessons}
                        setSelectedMore={setSelectedForDaysBetweenLessons}
                        options={lessons
                          .filter(
                            (lesson) =>
                              !selectedForDaysBetweenLessons.includes(lesson.name) &&
                              lesson.name !== subjects[0] + SUBJECT_CLASS_SEPARATOR + classes[0]
                          )
                          .map((res) => res.name)}
                        applyToMoreTitle={t('Apply this constraint to more lessons')}
                        applyToMoreMessage={`${t(`Select the lessons to which you want to apply`)} ${t(
                          'Days between Meetings'
                        )} ${t('constraint values along with')} ${lesson.name}:`}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12}>
              <Accordion className="advanced-lessons">
                <AccordionSummary
                  expandIcon={<GridExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <DialogContentText>{t('Advanced')}</DialogContentText>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid item xs={12} sx={{ paddingTop: '0px !important' }}>
                    <Box sx={{ width: '100%' }}>
                      <Tabs value={selectedTab} onChange={handleChange} variant="scrollable" allowScrollButtonsMobile>
                        <Tab label={t('Predefined Times')} />
                        <Tab className={'relations'} label={t('Relations')} />
                      </Tabs>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <TabPanel value={selectedTab} index={0}>
                      <TableContainer className={'predefined-times'} component={Paper}>
                        <Table size="small" padding="none">
                          <TableHead>
                            <TableRow>
                              <TableCell align="center" style={{ minWidth: '90px' }}>
                                <ToggleButton
                                  value="check"
                                  id={`check_${0}_${0}`}
                                  fullWidth={true}
                                  selected={allCount > 0 && contSelected === allCount}
                                  size="small"
                                  color="primary"
                                  onClick={(event) => {
                                    if (userPlan === FREE) {
                                      setShowPlanDialog(true);
                                      return;
                                    }
                                    handleSelectAllClick(event, '**');
                                  }}
                                >
                                  {t('Time')}
                                </ToggleButton>
                              </TableCell>
                              {days.map((day, d_index) => {
                                return (
                                  <TableCell key={`day_${d_index}`} align="center" style={{ minWidth: '90px' }}>
                                    <ToggleButton
                                      value="check"
                                      translate="no"
                                      id={`check_${d_index}:0`}
                                      fullWidth={true}
                                      selected={rowCount > 0 && contSelectedAtDay[d_index] === rowCount}
                                      size="small"
                                      color="primary"
                                      onClick={(event) => {
                                        if (userPlan === FREE) {
                                          setShowPlanDialog(true);
                                          return;
                                        }
                                        handleSelectAllDayClick(event, d_index + ':*');
                                      }}
                                    >
                                      {days[d_index]}
                                    </ToggleButton>
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {times.map((time, t_index) => {
                              return (
                                <TableRow
                                  key={`time_${t_index}`}
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                  <TableCell>
                                    <ToggleButton
                                      value="check"
                                      translate="no"
                                      id={`check_${0}:${t_index}`}
                                      fullWidth={true}
                                      selected={colCount > 0 && contSelectedAtTime[t_index] === colCount}
                                      size="small"
                                      color="primary"
                                      onClick={(event) => {
                                        if (userPlan === FREE) {
                                          setShowPlanDialog(true);
                                          return;
                                        }
                                        handleSelectAllTimeClick(event, '*:' + t_index);
                                      }}
                                    >
                                      {times[t_index]}
                                    </ToggleButton>
                                  </TableCell>

                                  {days.map((day, d_index) => {
                                    const isItemSelected = isSelected('' + d_index + DAY_TIME_SEPARATOR + t_index);
                                    return (
                                      <TableCell key={`cell_${d_index}_${t_index}`} align="center">
                                        <ToggleButton
                                          value="check"
                                          id={`check_${d_index}_${t_index}`}
                                          fullWidth
                                          size="small"
                                          color="primary"
                                          onClick={(event) => {
                                            if (userPlan === FREE) {
                                              setShowPlanDialog(true);
                                              return;
                                            }
                                            handleClick(event, '' + d_index + DAY_TIME_SEPARATOR + t_index);
                                          }}
                                          aria-checked={isItemSelected}
                                          selected={isItemSelected}
                                        >
                                          {isItemSelected ? <Lock /> : <Remove />}
                                        </ToggleButton>
                                      </TableCell>
                                    );
                                  })}
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      {selectedCells.length > selectedLessonsWeek && (
                        <Typography variant="body2" color="error">
                          {t('You selected more predefined times than the number of times per week of this lesson')}
                        </Typography>
                      )}
                    </TabPanel>
                    <TabPanel value={selectedTab} index={1}>
                      {/* Relations */}
                      {/* Simultaneous With */}
                      <Grid container spacing={2} paddingTop={2}>
                        <Grid container item spacing={1}>
                          <Grid item xs={1}>
                            <Tooltip
                              title={
                                <div>
                                  {t('Enforces this lesson to occur at the same times of selected lessons')}
                                  <hr />
                                  {t(
                                    'This option is well suited to schedule optative subjects. For example, you can enforce lessons of Spanish and French to occur at the same time'
                                  )}
                                  {/* <p />
                          <img
                            src={'/img/examples/workingTimes.png'}
                            width={'100%'}
                            alt={t('Example of Working Times constraint')}
                          /> */}
                                </div>
                              }
                            >
                              <ToggleButton
                                value="check"
                                fullWidth
                                size="small"
                                color="primary"
                                style={{ borderWidth: 0, backgroundColor: 'rgba(255, 255, 255, 0)' }}
                                selected={selectedSimultaneousWith.length === 0 ? false : true}
                              >
                                <Link />
                              </ToggleButton>
                            </Tooltip>
                          </Grid>
                          <Grid item xs={11} sm={10}>
                            <FormControl fullWidth size="small">
                              <MultipleSelectChip
                                label={t('Simultaneous with')}
                                names={lessons_.filter(
                                  (lesson) =>
                                    lesson !== selectedSubject[0] + SUBJECT_CLASS_SEPARATOR + selectedClasses[0]
                                )}
                                personName={selectedSimultaneousWith}
                                setPersonName={setSelectedSimultaneousWith}
                                onChangeFn={
                                  userPlan === FREE
                                    ? () => {
                                        setShowPlanDialog(true);
                                      }
                                    : undefined
                                }
                              ></MultipleSelectChip>
                            </FormControl>
                            {selectedSimultaneousWith.length > 0 && <Typography></Typography>}
                            {getSimultaneousWithHelperText(
                              t,
                              selectedSubject.length > 0 && selectedClasses.length > 0
                                ? selectedSubject[0] + SUBJECT_CLASS_SEPARATOR + selectedClasses[0]
                                : '',
                              selectedSimultaneousWith,
                              theme
                            )}
                          </Grid>
                          <Grid item xs={false} sm={1}>
                            <></>
                          </Grid>
                        </Grid>

                        {/* Not Simultaneous With */}
                        <Grid container item spacing={1}>
                          <Grid item xs={1} sm={1}>
                            <Tooltip
                              title={
                                <div>
                                  {t('Enforces this lesson to occur at different times of selected lessons')}
                                  <hr />
                                  {t(
                                    'This option is well suited to couple of teachers who have children. For example, you can enforce lessons of John to not overlap with lessons of Mary'
                                  )}
                                </div>
                              }
                            >
                              <ToggleButton
                                value="check"
                                fullWidth
                                size="small"
                                color="primary"
                                style={{ borderWidth: 0, backgroundColor: 'rgba(255, 255, 255, 0)' }}
                                selected={selectedNotSimultaneousWith.length === 0 ? false : true}
                              >
                                <LinkOff />
                              </ToggleButton>
                            </Tooltip>
                          </Grid>
                          <Grid item xs={11} sm={10}>
                            <FormControl fullWidth size="small">
                              <MultipleSelectChip
                                label={t('Not Simultaneous with')}
                                names={lessons_.filter(
                                  (lesson) =>
                                    lesson !== selectedSubject[0] + SUBJECT_CLASS_SEPARATOR + selectedClasses[0]
                                )}
                                personName={selectedNotSimultaneousWith}
                                setPersonName={setSelectedNotSimultaneousWith}
                                onChangeFn={
                                  userPlan === FREE
                                    ? () => {
                                        setShowPlanDialog(true);
                                      }
                                    : undefined
                                }
                              ></MultipleSelectChip>
                            </FormControl>
                            {getNotSimultaneousWithHelperText(
                              t,
                              selectedSubject.length > 0 && selectedClasses.length > 0
                                ? selectedSubject[0] + SUBJECT_CLASS_SEPARATOR + selectedClasses[0]
                                : '',
                              selectedNotSimultaneousWith,
                              theme
                            )}
                          </Grid>
                          <Grid item xs={false} sm={1}>
                            <></>
                          </Grid>
                        </Grid>

                        {/* Occur Before */}
                        <Grid container item spacing={1}>
                          <Grid item xs={1} sm={1}>
                            <Tooltip
                              title={
                                <div>
                                  {t('Enforces this lesson to occurr before the selected lessons')}
                                  <hr />
                                  {t(
                                    'This option is well suited to schedule theorical lessons before practice. You can set the Min of days separating them. For example, you can enforce theorical lessons of Chemestry to occur at least 2 days before the practical lessons'
                                  )}
                                </div>
                              }
                            >
                              <ToggleButton
                                value="check"
                                fullWidth
                                size="small"
                                color="primary"
                                style={{ borderWidth: 0, backgroundColor: 'rgba(255, 255, 255, 0)' }}
                                selected={selectedOccurBefore.length === 0 ? false : true}
                              >
                                <BrowseGallery />
                              </ToggleButton>
                            </Tooltip>
                          </Grid>
                          <Grid item container xs={11} spacing={1}>
                            <Grid item xs={9} sm={9}>
                              <FormControl fullWidth size="small">
                                <MultipleSelectChip
                                  label={t('Occur before')}
                                  names={lessons_.filter(
                                    (lesson) =>
                                      lesson !== selectedSubject[0] + SUBJECT_CLASS_SEPARATOR + selectedClasses[0]
                                  )}
                                  personName={selectedOccurBefore}
                                  setPersonName={setSelectedOccurBefore}
                                  onChangeFn={
                                    userPlan === FREE
                                      ? () => {
                                          setShowPlanDialog(true);
                                        }
                                      : undefined
                                  }
                                ></MultipleSelectChip>
                              </FormControl>
                            </Grid>
                            <Grid item xs={2} sm={2}>
                              <FormControl fullWidth size="small">
                                <InputLabel id="days-select-label">{t('Min Days Gap')}</InputLabel>
                                <Select
                                  size="small"
                                  labelId="days-gap-label"
                                  id="days-gap-select"
                                  defaultValue={'' + selectedOccurBeforeMinDays}
                                  onChange={handleChangeOccurBeforeMinDays}
                                  label={t('Min Days Gap')}
                                  sx={{
                                    color: +selectedOccurBeforeMinDays !== 0 ? 'default' : '#fff',
                                  }}
                                >
                                  {Array.from({ length: days.length }).map((_, index) => {
                                    return (
                                      <MenuItem key={`lessonsWeek_${index}`} value={`${index}`}>
                                        {index === 0 ? `↓` : index}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>
                            {getOccurBeforeHelperText(
                              t,
                              selectedSubject.length > 0 && selectedClasses.length > 0
                                ? selectedSubject[0] + SUBJECT_CLASS_SEPARATOR + selectedClasses[0]
                                : '',
                              selectedOccurBefore,
                              selectedOccurBeforeMinDays,
                              hasAttemptedToSave,
                              theme
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </TabPanel>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </PageContainer>
  );
}
