import { Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Box, Theme } from '@mui/system';
import { TFunction } from 'react-i18next';

const getOrAndString = () => {};
export const getClassHelperText = (
  t: TFunction,
  index: number,
  selectedClasses: string[][],
  hasAttemptedToSave: boolean,
  theme: Theme
) => {
  if (selectedClasses[index].length === 1)
    return (
      <Typography variant="body2" sx={{ color: theme.palette.primary.main }}>
        {selectedClasses[index].join(' ')} {t('will attend this lesson')}{' '}
        {selectedClasses.length > 1 &&
          selectedClasses.filter((classes, idx) => {
            return idx !== index && classes.length > 0;
          }).length > 0 &&
          t('together with ')}
        {selectedClasses
          .filter((classes, idx) => {
            return idx !== index && classes.length > 0;
          })
          .join(t(' and '))}
      </Typography>
    );
  else if (hasAttemptedToSave)
    return (
      <Typography variant="body2" sx={{ color: theme.palette.error.main }}>
        {t('Select the class to this lesson')}
      </Typography>
    );
  else return <></>;
};

export const getTeacherHelperText = (
  t: TFunction,
  index: number,
  selectedTeachers: string[][],
  hasAttemptedToSave: boolean,
  theme: Theme
) => {
  if (selectedTeachers[index].length === 1)
    return (
      <Typography variant="body2" sx={{ color: theme.palette.primary.main }}>
        {selectedTeachers[index].join(' ')} {t('will teach this lesson')}{' '}
        {selectedTeachers.length > 1 &&
          selectedTeachers.filter((teachers, idx) => {
            return idx !== index && teachers.length > 0;
          }).length > 0 &&
          t('together with ')}
        {selectedTeachers
          .filter((teachers, idx) => {
            return idx !== index && teachers.length > 0;
          })
          .join(t(' and '))
          .split(',')
          .join(t(' or '))}
        {/* {`${selectedTeachers[0]} ${t('will teach this lesson')}`} */}
      </Typography>
    );
  else if (selectedTeachers[index].length > 1)
    return (
      <Typography variant="body2" sx={{ color: theme.palette.primary.main }}>
        {`${
          selectedTeachers[index].length === 2
            ? t('A teacher will be chosen between')
            : t('A teacher will be chosen among')
        } ${selectedTeachers[index].join(t(' or '))} ${t('to teach this lesson')}`}{' '}
        {selectedTeachers.length > 1 &&
          selectedTeachers.filter((teachers, idx) => {
            return idx !== index && teachers.length > 0;
          }).length > 0 &&
          t('together with ')}
        {selectedTeachers
          .filter((teachers, idx) => {
            return idx !== index && teachers.length > 0;
          })
          .join(t(' and '))
          .split(',')
          .join(t(' or '))}
      </Typography>
    );
  else if (hasAttemptedToSave)
    return (
      <Typography variant="body2" sx={{ color: theme.palette.error.main }}>
        {t('Select the teacher options to this lesson')}
      </Typography>
    );
  else return <></>;
};

export const getRoomHelperText = (
  t: TFunction,
  index: number,
  selectedRooms: string[][],
  hasAttemptedToSave: boolean,
  theme: Theme
) => {
  if (selectedRooms[index].length === 1)
    return (
      <Typography variant="body2" sx={{ color: theme.palette.primary.main }}>
        {selectedRooms[index].join(' ')} {t('will host this lesson')}{' '}
        {selectedRooms.length > 1 &&
          selectedRooms.filter((rooms, idx) => {
            return idx !== index && rooms.length > 0;
          }).length > 0 &&
          t('together with ')}
        {selectedRooms
          .filter((rooms, idx) => {
            return idx !== index && rooms.length > 0;
          })
          .join(t(' and '))
          .split(',')
          .join(t(' or '))}
        {/* {`${selectedRooms[0]} ${t('will teach this lesson')}`} */}
      </Typography>
    );
  else if (selectedRooms[index].length > 1)
    return (
      <Typography variant="body2" sx={{ color: theme.palette.primary.main }}>
        {`${
          selectedRooms[index].length === 2 ? t('A room will be chosen between') : t('A room will be chosen among')
        } ${selectedRooms[index].join(t(' or '))} ${t('to host this lesson')}`}{' '}
        {selectedRooms.length > 1 &&
          selectedRooms.filter((rooms, idx) => {
            return idx !== index && rooms.length > 0;
          }).length > 0 &&
          t('together with ')}
        {selectedRooms
          .filter((rooms, idx) => {
            return idx !== index && rooms.length > 0;
          })
          .join(t(' and '))
          .split(',')
          .join(t(' or '))}
      </Typography>
    );
  else if (hasAttemptedToSave)
    return (
      <Typography variant="body2" sx={{ color: theme.palette.error.main }}>
        {t('Select the room options to this lesson')}
      </Typography>
    );
  else return <></>;
};

export const getSimultaneousWithHelperText = (
  t: TFunction,
  lessonName: string,
  selectedSimultaneousWith: string[],
  theme: Theme
) => {
  if (selectedSimultaneousWith.length > 0)
    return (
      <Typography variant="body2" sx={{ color: theme.palette.primary.main }}>
        {t('All class meetings of ')} {lessonName === '' ? 'this lesson' : lessonName}
        {t(' will occur at the same times as the meetings of ')} {selectedSimultaneousWith.join(', ')}
      </Typography>
    );
};

export const getNotSimultaneousWithHelperText = (
  t: TFunction,
  lessonName: string,
  selectedNotSimultaneousWith: string[],
  theme: Theme
) => {
  if (selectedNotSimultaneousWith.length > 0)
    return (
      <Typography variant="body2" sx={{ color: theme.palette.primary.main }}>
        {t('All class meetings of ')} {lessonName === '' ? 'this lesson' : lessonName}{' '}
        {t('will occur at different times than the meetings of ')} {selectedNotSimultaneousWith.join(', ')}
      </Typography>
    );
};

export const getOccurBeforeHelperText = (
  t: TFunction,
  lessonName: string,
  selectedOccurBefore: string[],
  minDaysGap: number,
  hasAttmeptedToSave: boolean,
  theme: Theme
) => {
  if (selectedOccurBefore.length > 0 && minDaysGap > 0)
    return (
      <Typography paddingLeft={1} variant="body2" sx={{ color: theme.palette.primary.main }}>
        {t('All class meetings of ')} {lessonName === '' ? 'this lesson' : lessonName} {t('will occur at least ')}
        {minDaysGap} {t(' days before any class meeting of ')} {selectedOccurBefore.join(', ')}
      </Typography>
    );
  else if (selectedOccurBefore.length > 0 && Number(minDaysGap) === 0)
    return (
      <Typography
        paddingLeft={1}
        variant="body2"
        sx={{ color: hasAttmeptedToSave ? theme.palette.error.main : grey[600] }}
      >
        {t('You must select how many days, at least, the class meetings of ')}{' '}
        {lessonName === '' ? 'this lesson' : lessonName} {t('should occur before any class meeting of ')}{' '}
        {selectedOccurBefore.join(', ')}
      </Typography>
    );
  else if (selectedOccurBefore.length === 0 && minDaysGap > 0)
    return (
      <Typography
        paddingLeft={1}
        variant="body2"
        sx={{ color: hasAttmeptedToSave ? theme.palette.error.main : grey[600] }}
      >
        {t('You must select the lesson(s) that require ')} {lessonName === '' ? 'this lesson' : lessonName}{' '}
        {t('to occur ')} {minDaysGap} {t('days before')}
      </Typography>
    );
};

export const getSubjectHelperText = (
  t: TFunction,
  selectedSubject: string[],
  hasAttemptedToSave: boolean,
  theme: Theme
) => {
  if (selectedSubject.length > 0)
    return (
      <Typography variant="body2" sx={{ color: theme.palette.primary.main }}>
        {selectedSubject.join('') + t(' will be taugh in this lesson')}
      </Typography>
    );
  else if (hasAttemptedToSave) {
    return (
      <Typography variant="body2" sx={{ color: theme.palette.error.main }}>
        {t('You must set the subject of this lesson')}
      </Typography>
    );
  } else return <Box height={20}></Box>;
};
