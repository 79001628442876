import { DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button } from '@mui/material';
import DialogContainer from 'containers/DialogContainer';
import { useTranslation } from 'react-i18next';

export default function FormDialog(props: any) {
  const { t } = useTranslation();
  const open = props.open;
  const setOpen = props.setOpen;
  const title = props.title;
  const message = props.message;
  const fieldLabel = props.fieldLabel;
  const setConfirmed = props.setConfirmed;

  const inputText = props.inputText;
  const setInputText = props.setInputText;

  const handleOpen = () => {
    setConfirmed(true);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <DialogContainer open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={fieldLabel}
            fullWidth
            required
            variant="standard"
            translate="no"
            defaultValue={inputText}
            onChange={(e) => setInputText(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('Cancel')}</Button>
          <Button onClick={handleOpen}>{t('Confirm')}</Button>
        </DialogActions>
      </DialogContainer>
    </div>
  );
}
