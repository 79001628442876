import { PeopleAlt, CoPresent, MapsHomeWork, AutoStories, Book } from '@mui/icons-material';

export const getInitials = (string: string, maxLength: number) => {
  if (string.length <= maxLength) {
    return string.toUpperCase();
  }
  var names = string.trim().split(' ');
  if (names.length === 1) {
    return names[0].substring(0, maxLength).toUpperCase();
  }
  var initials: string = '';
  var midChars: number = 0;
  for (let i = 0; i < names.length; i++) {
    if (i === 0 || i === names.length - 1) initials += names[i].charAt(0).toUpperCase();
    else if (names[i].charAt(0) === names[i].charAt(0).toUpperCase() && midChars + 2 < maxLength) {
      initials += names[i].substring(0, 1).toUpperCase();
      midChars++;
    }
  }
  return initials;
};

export const getResourceTypeIcon = (type: string) => {
  switch (type) {
    case 'class':
      return <PeopleAlt />;
    case 'teacher':
      return <CoPresent />;
    case 'room':
      return <MapsHomeWork />;
    case 'subject':
      return <Book />;
    case 'lesson':
      return <AutoStories />;
    case 'default':
      return <></>;
  }
};
