import { colors, Container, ThemeProvider } from '@mui/material';
import { SessionContext } from 'contexts';
import React, { useContext } from 'react';
import { darkTheme, lightTheme } from 'styles/material';

type Props = {
  children: React.ReactNode[];
};

export default function PageContainer({ children }: Props) {
  const { isDarkMode } = useContext(SessionContext);
  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <Container
        maxWidth={false}
        style={{
          padding: '56px 8px 8px 65px',
          minHeight: '100vh',
          backgroundColor: isDarkMode ? colors.grey[800] : colors.grey[200],
        }}
      >
        {children}
      </Container>
    </ThemeProvider>
  );
}
