import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
type Props = {
  error: any;
  resetErrorBoundary: any;
};

export default function ErrorBoundaryDialog({ error, resetErrorBoundary }: Props) {
  const { t } = useTranslation();

  return (
    <Dialog open aria-labelledby="error-dialog-title" aria-describedby="error-dialog-description">
      <DialogTitle id="error-dialog-title">{t('Oops! Something went wrong')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="error-dialog-description">{t('An unexpected error occurred:')}</DialogContentText>
        <DialogContentText color="error" style={{ whiteSpace: 'pre-wrap' }}>
          {error.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={resetErrorBoundary} color="primary">
          {t('Retry')}
        </Button>
        <Button onClick={() => (window.location.href = '/')} color="primary">
          {t('Go to home')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
