import { Box, Container, Paper, Typography, List, ListItem } from '@mui/material';
import { customColors } from 'styles';

export default function PrivacyPolicy() {
  return (
    <Box sx={{ backgroundColor: customColors.blueDark, minHeight: '100vh' }}>
      <Container maxWidth="lg" sx={{ py: 4, mt: 0 }}>
        <Paper sx={{ p: 4 }}>
          <Typography variant="h4" gutterBottom>
            Privacy Police
          </Typography>
          <Typography variant="body1" paragraph>
            Effective Date: June, 13, 2023
          </Typography>
          <Typography variant="body1" paragraph>
            Please read this Privacy Policy ("Policy") carefully before using our software/application ("Software").
            This Policy explains how we collect, use, and protect your personal information. By using the Software, you
            consent to the practices described in this Policy.
          </Typography>

          <Typography variant="h6" gutterBottom>
            Agreement Terms:
          </Typography>
          <List>
            <ListItem sx={{ display: 'block' }}>
              <Typography sx={{ fontWeight: 'bold' }}>1. Personal Data Collection</Typography>
              <Typography>
                When you use the Software, we may collect certain information from you, including your profile picture,
                name, and email address ("Personal Data"). We collect this information solely for the purpose of
                providing you with the requested services and improving our Software.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'block' }}>
              <Typography sx={{ fontWeight: 'bold' }}>2. Data Usage and Research</Typography>
              <Typography>
                We may use the Personal Data collected from you for research purposes. By using the Software, you grant
                us the right to use, analyze, and process the data, including but not limited to, conducting statistical
                analysis, improving our Software, and developing new features. We will handle your Personal Data in
                accordance with applicable privacy laws.{' '}
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'block' }}>
              <Typography sx={{ fontWeight: 'bold' }}>3. Security Measures</Typography>
              <Typography>
                We implement reasonable security measures to safeguard your Personal Data against unauthorized access,
                alteration, disclosure, or destruction. However, please be aware that no method of transmission over the
                internet or electronic storage is completely secure, and we cannot guarantee absolute security.{' '}
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'block' }}>
              <Typography sx={{ fontWeight: 'bold' }}>4. Third-Pary Links</Typography>
              <Typography>
                The Software may contain links to third-party websites or services that are not owned or controlled by
                us. We have no control over and assume no responsibility for the content, privacy policies, or practices
                of any third-party websites or services. We encourage you to review the terms and privacy policies of
                any third-party websites or services you visit.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'block' }}>
              <Typography sx={{ fontWeight: 'bold' }}>5. Data deletion and user rights</Typography>
              <Typography>
                You have the right to request the deletion of your Personal Data collected by the Software. If you wish
                to have your data removed, please follow the instructions below:
                <ul>
                  <li>Send an email to george.fons 'at' gmail.com with the subject "Data Deletion Request".</li>
                  <li>Include your full name and the email address associated with your account.</li>
                  <li>Specify that you wish to delete your Personal Data from our systems.</li>
                </ul>
                Upon receiving your request, we will process the deletion of your data within 30 days and confirm the
                action via email. Please note that some data may be retained for legal or administrative purposes, where
                applicable.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'block' }}>
              <Typography sx={{ fontWeight: 'bold' }}>6. Contact us</Typography>
              <Typography>
                If you have any questions or concerns regarding this Policy, please contact us at george.fons 'at'
                gmail.com.
              </Typography>
            </ListItem>
            {/* Add more ListItem components as needed */}
          </List>
        </Paper>
      </Container>
    </Box>
  );
}
