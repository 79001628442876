import { SelectChangeEvent, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SelectBaseProps } from 'util/selectUtils';

type Props = SelectBaseProps & {
  maxWidth?: number;
};

export default function BasicSelect({ current, setCurrent, options, label, maxWidth }: Props) {
  const { t } = useTranslation();
  const handleChange = (event: SelectChangeEvent) => {
    setCurrent(event.target.value);
  };

  return (
    <FormControl fullWidth size="small">
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={'' + current}
        label={label}
        sx={{ maxWidth: maxWidth ? maxWidth : 'inherit' }}
        onChange={handleChange}
        renderValue={(value) => (value === t("Don't import") ? `⚠️ ${value}` : `${value}`)}
      >
        {options.map((option, index) => {
          return (
            <MenuItem key={`${label}_${index}`} value={option}>
              {option}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
