import React from 'react';

type Props = {
  color?: string;
  width: string;
  height: string;
  style?: any;
};

export default function PriorityDisabled({ width, height, color = 'white', style = {} }: Props) {
  return (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox="0 0 168 168"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M148.648 147.567C151.498 149.563 155.449 148.881 157.226 145.889C164.03 134.431 168.026 121.489 168.843 108.128C169.789 92.6655 166.44 77.2425 159.168 63.5644C151.895 49.8862 140.981 38.4857 127.633 30.6232C114.285 22.7606 99.0231 18.7424 83.534 19.0128C68.0449 19.2832 52.9322 23.8315 39.8669 32.1551C26.8015 40.4787 16.2923 52.2531 9.50132 66.1768C2.7103 80.1004 -0.0980679 95.6308 1.38671 111.051C2.66973 124.376 7.11523 137.17 14.3151 148.383C16.195 151.311 20.1672 151.855 22.946 149.761V149.761C25.7248 147.667 26.2536 143.73 24.4126 140.777C18.5898 131.439 14.9889 120.854 13.9287 109.843C12.6666 96.7362 15.0538 83.5354 20.8261 71.7003C26.5985 59.8652 35.5313 49.8569 46.6368 42.7818C57.7424 35.7068 70.5882 31.8407 83.7539 31.6109C96.9197 31.3811 109.893 34.7965 121.238 41.4797C132.584 48.1628 141.861 57.8533 148.042 69.4797C154.224 81.1062 157.071 94.2157 156.267 107.359C155.592 118.4 152.362 129.104 146.869 138.64C145.132 141.655 145.798 145.571 148.648 147.567V147.567Z"
        fill={color}
      />
      <path d="M85 35L85 27" stroke={color} strokeWidth="12" strokeLinecap="round" />
      <path d="M34.3516 57.9463L28.9998 51.9999" stroke={color} strokeWidth="12" strokeLinecap="round" />
      <path d="M135 58.6567L140.657 52.9999" stroke={color} strokeWidth="12" strokeLinecap="round" />
      <path d="M9 99H17" stroke={color} strokeWidth="12" strokeLinecap="round" />
      <path d="M152 99L160 99" stroke={color} strokeWidth="12" strokeLinecap="round" />
    </svg>
  );
}
