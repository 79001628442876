import { useTranslation } from 'react-i18next';
import AccordionTableDescription from 'components/AccordionTableDescription';
import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionSummary, DialogContentText, AccordionDetails, Link } from '@mui/material';

export default function ImportAssignmentsAccordion() {
  const { t } = useTranslation();

  function createData(name: string, description: string) {
    return { name, description };
  }

  const subjectRows = [createData(t('Chemestry'), ''), createData(t('Calculus I'), '')];

  const classRows = [createData(t('9th grade 902'), ''), createData(t('Computer Engineering 1'), '')];

  const teachersRows = [
    createData(t('John Pearson'), t('Represents that John is assigned to this lesson')),
    createData(
      t('George Fonseca+James Chan'),
      t('Represents that George and James are assigned to lecture together this lesson')
    ),
  ];

  const roomsRows = [
    createData(t('A103'), t('Represents that room A103 is assigned to host this lesson')),
    createData(t('LAB1A+LAB1B'), t('Represents that labs 1A and 1B will be jointly hosting the lesson')),
  ];

  const timeslotsRows = [
    createData('1:1+3:2', t('Represents Monday (day 1) at the 1st and Wednesday (day 3) at the 2nd time')),
    createData('4:3', t('Means Thusday (day 4) at the 3rd time')),
  ];

  const exampleRows = [
    createData(
      t('Teachers'),
      t(
        'Lessons of Chemestry will have Lucas and Isabella lecturing together. The others were assigned to a single teacher'
      )
    ),
    createData(t('Rooms'), t('Each lesson has been assigned to a single room')),
    createData(
      t('Timeslots'),
      t(
        'Lessons of Computer Programming will occur on Monday (day 1) at the 1st time, and on Wednesday (day 3) at the 2nd time. The other assignments follow the same pattern'
      )
    ),
  ];

  return (
    <div>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2a-content" id="panel2a-header">
          <DialogContentText color={'primary'}>{t('Example')}&nbsp;</DialogContentText>
          <Link href={t('/data/assignments-exampleEN.xlsx')} download>
            {'(download)'}
          </Link>
        </AccordionSummary>
        <AccordionDetails>
          <DialogContentText>{t('In this example, six assignments will be imported:')}</DialogContentText>
          <img
            src={t('/img/examples/importAssignmentsEN.png')}
            width={'100%'}
            alt={t('Example of Import Assignments')}
            style={{ border: '1pt solid #bdbdbd', borderRadius: '4pt', padding: '4px' }}
            loading="lazy"
          />
          <AccordionTableDescription data={exampleRows} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
          <DialogContentText color={'primary'}>{t('Column Subject')}</DialogContentText>
        </AccordionSummary>
        <AccordionDetails>
          <DialogContentText>
            {`${t(
              'This column cannot be empty and should contain the name of the subject. If it is not yet registered, it will be created'
            )}. ${t('Ex.')}:`}
          </DialogContentText>
          <AccordionTableDescription data={subjectRows} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
          <DialogContentText color={'primary'}>{t('Column Class')}</DialogContentText>
        </AccordionSummary>
        <AccordionDetails>
          <DialogContentText>
            {`${t(
              'This column is mandatory and should contain the name of the class that attends this subject. If the class is not yet registered, it will be created'
            )}. ${t('Ex.')}:`}
          </DialogContentText>
          <AccordionTableDescription data={classRows} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
          <DialogContentText color={'primary'}>{t('Column Teachers')}</DialogContentText>
        </AccordionSummary>
        <AccordionDetails>
          <DialogContentText>
            {`${t(
              'This column should contain the name of the teachers that were assigned to this lesson. When more than one teacher lecture together, their names are separated by a + sign. If the teacher is not yet registered, it will be created'
            )}. ${t('Ex.')}:`}
          </DialogContentText>
          <AccordionTableDescription data={teachersRows} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
          <DialogContentText color={'primary'}>{t('Column Rooms')}</DialogContentText>
        </AccordionSummary>
        <AccordionDetails>
          <DialogContentText>
            {`${t(
              'This column should contain the name of the rooms that were assigned to this lesson. When more than one room host the lesson jointly, their names are separated by a + sign. If the room is not yet registered, it will be created'
            )}. ${t('Ex.')}:`}
          </DialogContentText>
          <AccordionTableDescription data={roomsRows} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2a-content" id="panel2a-header">
          <DialogContentText color={'primary'}>{t('Column Timeslots')}</DialogContentText>
        </AccordionSummary>
        <AccordionDetails>
          <DialogContentText>
            {t(
              'This column represents the timeslots in which the lesson will occur and is imported as a set of timeslots (day:time) separated by a + sign. Ex.:'
            )}
          </DialogContentText>
          <AccordionTableDescription data={timeslotsRows} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
