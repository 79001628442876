import { Dispatch, SetStateAction, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { SessionContext } from 'contexts';
import { ISolution } from '../Solution';
import RuntimeSelect from 'components/Select/RuntimeSelect';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import {
  Paper,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  Accordion,
  AccordionSummary,
  DialogContentText,
  AccordionDetails,
  Grid,
  Typography,
  DialogActions,
  Button,
  TextField,
  Stack,
  Alert,
  List,
  ListItem,
  IconButton,
  Tooltip,
  Badge,
} from '@mui/material';
import { grey, blue } from '@mui/material/colors';
import { PaperComponent } from 'util/dialogUtils';
import { CLASS, LESSON, ROOM, TEACHER } from 'util/configUtils';
import { DAY_TIME_SEPARATOR, getInfeasibilitySources, OPTIMAL, PROCESSING } from 'util/solutionUtils';
import { getConstrBounds, validateNameInput } from 'util/validationUtils';
import EmptyConstraints from './EmptyConstraints';
import { Info, Tune } from '@mui/icons-material';
import { Timestamp } from 'firebase/firestore';
import AdvancedConstraintsDialog from 'components/Dialog/AdvancedConstraintsDialog';
import {
  CONSECUTIVE_TIMES,
  DAILY_WORKLOAD,
  DAYS_BETWEEN_MEETINGS,
  DISTINCT_SUBJECTS,
  FORBIDDEN_COMBINATION,
  IDLE_WINDOW,
  NOT_SIMULTANEOUS_WITH,
  OCCUR_BEFORE,
  PREDEFINED_TIMES,
  REST_BETWEEN_DAYS,
  ROOMS_PER_DAY,
  SIMULTANEOUS_WITH,
  SUBJECT_UNAVAILABLE_TIMES,
  TRAVEL_TIME,
  UNAVAILABLE_TIMES,
  WORKING_DAYS,
  WORKING_TIMES,
} from 'util/constraintUtils';
import ConstraintPrioritySelect from 'components/Select/ConstraintPrioritySelect';

export interface SolverTypesMap {
  [key: string]: string;
}

export interface DisplayTypesMap {
  [key: string]: string;
}

export interface RunningTimeSecondsMap {
  [key: number]: string;
}

export interface RunningTimeLabelsMap {
  [key: string]: number;
}

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  title: string;
  message: string;
  solution: ISolution;
  setSolution: Dispatch<SetStateAction<ISolution>>;
  setConfirmed: Dispatch<SetStateAction<boolean>>;
  priorityOptions: string[];
  userPlan: string;
  runtimeSuggestion: number;
  previousName: string;
};

export default function ConfigsDialog({
  open,
  setOpen,
  title,
  message,
  solution,
  setSolution,
  setConfirmed,
  priorityOptions,
  userPlan,
  runtimeSuggestion,
  previousName,
}: Props) {
  const { t } = useTranslation();
  const widthLessThan900 = useMediaQuery('(max-width:900px)');
  const { file, resources, subjects, lessons, solutions } = useContext(SessionContext);
  const days = file ? file.days : [];
  const times = file ? file.times : [];

  const handleClose = () => {
    setOpen(false);
  };

  const handleDefault = () => {
    // setSolver(t('Optimal'));
    const newSolution = { ...solution };
    newSolution.name = nameRef.current ? nameRef.current.value : '';
    newSolution.updatedAt = Timestamp.now();
    // newSolution.solver = solverTypesMap[solver];
    newSolution.runningTime = runtimeSuggestion;
    newSolution.clashesClasses = priorityOptions[5];
    newSolution.workloadClasses = priorityOptions[5];
    newSolution.workingDaysClasses = priorityOptions[3];
    newSolution.idleWindowClasses = priorityOptions[3];
    newSolution.dailyWorkloadClasses = priorityOptions[3];
    newSolution.distinctSubjectsClasses = priorityOptions[3];
    newSolution.forbiddenCombinationClasses = priorityOptions[3];
    newSolution.unavailableTimesClasses = priorityOptions[5];
    newSolution.restBetweenDaysClasses = priorityOptions[3];
    newSolution.roomChangesDayClasses = priorityOptions[3];
    newSolution.consecutiveTimesClasses = priorityOptions[3];

    newSolution.clashesTeachers = priorityOptions[5];
    newSolution.workloadTeachers = priorityOptions[5];
    newSolution.workingDaysTeachers = priorityOptions[3];
    newSolution.idleWindowTeachers = priorityOptions[3];
    newSolution.dailyWorkloadTeachers = priorityOptions[3];
    newSolution.distinctSubjectsTeachers = priorityOptions[3];
    newSolution.forbiddenCombinationTeachers = priorityOptions[3];
    newSolution.unavailableTimesTeachers = priorityOptions[3];
    newSolution.restBetweenDaysTeachers = priorityOptions[3];
    newSolution.roomChangesDayTeachers = priorityOptions[3];
    newSolution.consecutiveTimesTeachers = priorityOptions[3];

    newSolution.clashesRooms = priorityOptions[5];
    newSolution.workloadRooms = priorityOptions[5];
    newSolution.workingDaysRooms = priorityOptions[3];
    newSolution.idleWindowRooms = priorityOptions[3];
    newSolution.dailyWorkloadRooms = priorityOptions[3];
    newSolution.distinctSubjectsRooms = priorityOptions[3];
    newSolution.forbiddenCombinationRooms = priorityOptions[3];
    newSolution.unavailableTimesRooms = priorityOptions[5];
    newSolution.consecutiveTimesRooms = priorityOptions[3];
    newSolution.travelTimeRooms = priorityOptions[3];

    newSolution.assignTimes = priorityOptions[5];
    newSolution.assignResources = priorityOptions[5];
    newSolution.split = priorityOptions[5];
    newSolution.daysBetweenLessons = priorityOptions[5];
    newSolution.predefinedTimes = priorityOptions[5];
    newSolution.simultaneousWith = priorityOptions[5];
    newSolution.notSimultaneousWith = priorityOptions[5];
    newSolution.occurBefore = priorityOptions[5];
    newSolution.unavailableTimesSubjects = priorityOptions[5];

    newSolution.customConstraints = [];

    setSolution(newSolution);
    toast.success(t('Default configs have been restored'));
  };

  const validateInput = () => {
    const newName = nameRef.current ? nameRef.current.value : '';
    const existingSolutionNames = solutions.map((solution) => solution.name);
    return validateNameInput(t, newName, previousName, existingSolutionNames);
  };

  const handleConfirm = () => {
    if (!validateInput()) {
      return;
    }

    const newSolution = { ...solution };
    newSolution.name = nameRef.current ? nameRef.current.value : '';
    newSolution.status = PROCESSING;
    newSolution.updatedAt = Timestamp.now();

    setSolution(newSolution);
    setOpen(false);
    setConfirmed(true);
  };

  // const backgroundColors = [grey[100], green[100], green[300], green[500], green[700], green[900]];
  const backgroundColors = [grey[100], blue[100], blue[300], blue[500], blue[700], blue[900]];
  const textColors = [grey[700], grey[700], grey[50], grey[50], grey[50], grey[50]];

  type ConstraintTypeDisplay = {
    type: string;
    display: boolean;
  };

  const constraintTypesClasses: ConstraintTypeDisplay[] = useMemo(() => {
    if (!file) return [];
    const BOUNDS = getConstrBounds(file!.days.length, file!.times.length);
    const constraintTypesClasses: ConstraintTypeDisplay[] = [
      { type: WORKING_TIMES, display: false },
      { type: WORKING_DAYS, display: false },
      { type: DAILY_WORKLOAD, display: false },
      { type: IDLE_WINDOW, display: false },
      { type: DISTINCT_SUBJECTS, display: false },
      { type: FORBIDDEN_COMBINATION, display: false },
      { type: UNAVAILABLE_TIMES, display: false },
      { type: REST_BETWEEN_DAYS, display: false },
      { type: ROOMS_PER_DAY, display: false },
      { type: CONSECUTIVE_TIMES, display: false },
    ];

    const hasWorkingTimesConst = resources.find(
      (res) =>
        res.type === CLASS && (res.minWorkload !== BOUNDS.minWorkingTimes || res.maxWorkload !== BOUNDS.maxWorkingTimes)
    );
    const hasWorkingDaysConst = resources.find(
      (res) =>
        res.type === CLASS &&
        (res.minWorkingDays !== BOUNDS.minWorkingDays || res.maxWorkingDays !== BOUNDS.maxWorkingDays)
    );
    const hasIdleWindow = resources.find(
      (res) =>
        res.type === CLASS && (res.minIdleWindow !== BOUNDS.minIdleWindow || res.maxIdleWindow !== BOUNDS.maxIdleWindow)
    );
    const hasDailyWorkloadConst = resources.find(
      (res) =>
        res.type === CLASS &&
        (res.minDailyWorkload !== BOUNDS.minDailyWorkload || res.maxDailyWorkload !== BOUNDS.maxDailyWorkload)
    );
    const hasDistinctSubjects = resources.find(
      (res) =>
        res.type === CLASS &&
        (res.minDistinctSubjects !== BOUNDS.minDistinctSubjects ||
          res.maxDistinctSubjects !== BOUNDS.maxDistinctSubjects)
    );
    const hasForbiddenCombination = resources.find(
      (res) => res.type === CLASS && res.forbiddenCombination.length !== 0
    );
    const hasUnavailableTimes = resources.find((res) => res.type === CLASS && res.unavailableTimes.length !== 0);
    const hasRestBetweenDays = resources.find(
      (res) =>
        res.type === CLASS &&
        (res.minRestBetweenDays !== BOUNDS.minRestBetweenDays || res.maxRestBetweenDays !== BOUNDS.maxRestBetweenDays)
    );
    const hasRoomsPerDayConstr = resources.find(
      (res) =>
        res.type === CLASS &&
        (res.minRoomChangesDay !== BOUNDS.minRoomsPerDay || res.maxRoomChangesDay !== BOUNDS.maxRoomsPerDay)
    );
    const hasConsecutiveTimesConstr = resources.find(
      (res) =>
        res.type === CLASS &&
        (res.minConsecutiveTimes !== BOUNDS.minConsecutiveTimes ||
          res.maxConsecutiveTimes !== BOUNDS.maxConsecutiveTimes)
    );
    if (hasWorkingDaysConst) constraintTypesClasses.find((c) => c.type === WORKING_DAYS)!.display = true;
    if (hasWorkingTimesConst) constraintTypesClasses.find((c) => c.type === WORKING_TIMES)!.display = true;
    if (hasDailyWorkloadConst) constraintTypesClasses.find((c) => c.type === DAILY_WORKLOAD)!.display = true;
    if (hasIdleWindow) constraintTypesClasses.find((c) => c.type === IDLE_WINDOW)!.display = true;
    if (hasDistinctSubjects) constraintTypesClasses.find((c) => c.type === DISTINCT_SUBJECTS)!.display = true;
    if (hasForbiddenCombination) constraintTypesClasses.find((c) => c.type === FORBIDDEN_COMBINATION)!.display = true;
    if (hasUnavailableTimes) constraintTypesClasses.find((c) => c.type === UNAVAILABLE_TIMES)!.display = true;
    if (hasRestBetweenDays) constraintTypesClasses.find((c) => c.type === REST_BETWEEN_DAYS)!.display = true;
    if (hasRoomsPerDayConstr) constraintTypesClasses.find((c) => c.type === ROOMS_PER_DAY)!.display = true;
    if (hasConsecutiveTimesConstr) constraintTypesClasses.find((c) => c.type === CONSECUTIVE_TIMES)!.display = true;
    return constraintTypesClasses;
  }, [file, resources]);

  const constraintTypesTeachers = useMemo(() => {
    if (!file) return [];
    const BOUNDS = getConstrBounds(file!.days.length, file!.times.length);
    const constraintTypesTeachers: ConstraintTypeDisplay[] = [
      { type: WORKING_TIMES, display: false },
      { type: WORKING_DAYS, display: false },
      { type: DAILY_WORKLOAD, display: false },
      { type: IDLE_WINDOW, display: false },
      { type: DISTINCT_SUBJECTS, display: false },
      { type: FORBIDDEN_COMBINATION, display: false },
      { type: UNAVAILABLE_TIMES, display: false },
      { type: REST_BETWEEN_DAYS, display: false },
      { type: ROOMS_PER_DAY, display: false },
      { type: CONSECUTIVE_TIMES, display: false },
    ];

    const hasWorkingTimesConst = resources.find(
      (res) =>
        res.type === TEACHER &&
        (res.minWorkload !== BOUNDS.minWorkingTimes || res.maxWorkload !== BOUNDS.maxWorkingTimes)
    );
    const hasWorkingDaysConst = resources.find(
      (res) =>
        res.type === TEACHER &&
        (res.minWorkingDays !== BOUNDS.minWorkingDays || res.maxWorkingDays !== BOUNDS.maxWorkingDays)
    );
    const hasIdleWindow = resources.find(
      (res) =>
        res.type === TEACHER &&
        (res.minIdleWindow !== BOUNDS.minIdleWindow || res.maxIdleWindow !== BOUNDS.maxIdleWindow)
    );
    const hasDailyWorkloadConst = resources.find(
      (res) =>
        res.type === TEACHER &&
        (res.minDailyWorkload !== BOUNDS.minDailyWorkload || res.maxDailyWorkload !== BOUNDS.maxDailyWorkload)
    );
    const hasDistinctSubjects = resources.find(
      (res) =>
        res.type === TEACHER &&
        (res.minDistinctSubjects !== BOUNDS.minDistinctSubjects ||
          res.maxDistinctSubjects !== BOUNDS.maxDistinctSubjects)
    );
    const hasForbiddenCombination = resources.find(
      (res) => res.type === TEACHER && res.forbiddenCombination.length !== 0
    );
    const hasUnavailableTimes = resources.find((res) => res.type === TEACHER && res.unavailableTimes.length !== 0);
    const hasRestBetweenDays = resources.find(
      (res) =>
        res.type === TEACHER &&
        (res.minRestBetweenDays !== BOUNDS.minRestBetweenDays || res.maxRestBetweenDays !== BOUNDS.maxRestBetweenDays)
    );
    const hasRoomsPerDayConstr = resources.find(
      (res) =>
        res.type === TEACHER &&
        (res.minRoomChangesDay !== BOUNDS.minRoomsPerDay || res.maxRoomChangesDay !== BOUNDS.maxRoomsPerDay)
    );
    const hasConsecutiveTimesConstr = resources.find(
      (res) =>
        res.type === TEACHER &&
        (res.minConsecutiveTimes !== BOUNDS.minConsecutiveTimes ||
          res.maxConsecutiveTimes !== BOUNDS.maxConsecutiveTimes)
    );
    if (hasWorkingDaysConst) constraintTypesTeachers.find((c) => c.type === WORKING_DAYS)!.display = true;
    if (hasWorkingTimesConst) constraintTypesTeachers.find((c) => c.type === WORKING_TIMES)!.display = true;
    if (hasDailyWorkloadConst) constraintTypesTeachers.find((c) => c.type === DAILY_WORKLOAD)!.display = true;
    if (hasIdleWindow) constraintTypesTeachers.find((c) => c.type === IDLE_WINDOW)!.display = true;
    if (hasDistinctSubjects) constraintTypesTeachers.find((c) => c.type === DISTINCT_SUBJECTS)!.display = true;
    if (hasForbiddenCombination) constraintTypesTeachers.find((c) => c.type === FORBIDDEN_COMBINATION)!.display = true;
    if (hasUnavailableTimes) constraintTypesTeachers.find((c) => c.type === UNAVAILABLE_TIMES)!.display = true;
    if (hasRestBetweenDays) constraintTypesTeachers.find((c) => c.type === REST_BETWEEN_DAYS)!.display = true;
    if (hasRoomsPerDayConstr) constraintTypesTeachers.find((c) => c.type === ROOMS_PER_DAY)!.display = true;
    if (hasConsecutiveTimesConstr) constraintTypesTeachers.find((c) => c.type === CONSECUTIVE_TIMES)!.display = true;
    return constraintTypesTeachers;
  }, [file, resources]);

  const constraintTypesRooms = useMemo(() => {
    if (!file) return [];
    const BOUNDS = getConstrBounds(file!.days.length, file!.times.length);
    const constraintTypesRooms: ConstraintTypeDisplay[] = [
      { type: WORKING_TIMES, display: false },
      { type: WORKING_DAYS, display: false },
      { type: DAILY_WORKLOAD, display: false },
      { type: IDLE_WINDOW, display: false },
      { type: DISTINCT_SUBJECTS, display: false },
      { type: FORBIDDEN_COMBINATION, display: false },
      { type: UNAVAILABLE_TIMES, display: false },
      { type: CONSECUTIVE_TIMES, display: false },
      { type: TRAVEL_TIME, display: false },
    ];

    const hasWorkingTimesConst = resources.find(
      (res) =>
        res.type === ROOM && (res.minWorkload !== BOUNDS.minWorkingTimes || res.maxWorkload !== BOUNDS.maxWorkingTimes)
    );
    const hasWorkingDaysConst = resources.find(
      (res) =>
        res.type === ROOM &&
        (res.minWorkingDays !== BOUNDS.minWorkingDays || res.maxWorkingDays !== BOUNDS.maxWorkingDays)
    );
    const hasIdleWindow = resources.find(
      (res) =>
        res.type === ROOM && (res.minIdleWindow !== BOUNDS.minIdleWindow || res.maxIdleWindow !== BOUNDS.maxIdleWindow)
    );
    const hasDailyWorkloadConst = resources.find(
      (res) =>
        res.type === ROOM &&
        (res.minDailyWorkload !== BOUNDS.minDailyWorkload || res.maxDailyWorkload !== BOUNDS.maxDailyWorkload)
    );
    const hasDistinctSubjects = resources.find(
      (res) =>
        res.type === ROOM &&
        (res.minDistinctSubjects !== BOUNDS.minDistinctSubjects ||
          res.maxDistinctSubjects !== BOUNDS.maxDistinctSubjects)
    );
    const hasForbiddenCombination = resources.find((res) => res.type === ROOM && res.forbiddenCombination.length !== 0);
    const hasUnavailableTimes = resources.find((res) => res.type === ROOM && res.unavailableTimes.length !== 0);
    const hasConsecutiveTimesConstr = resources.find(
      (res) =>
        res.type === ROOM &&
        (res.minConsecutiveTimes !== BOUNDS.minConsecutiveTimes ||
          res.maxConsecutiveTimes !== BOUNDS.maxConsecutiveTimes)
    );
    const hasTravelTimeConstr = resources.find(
      (res) => res.type === ROOM && res.minTravelTime !== BOUNDS.minTravelTime
    );
    if (hasWorkingTimesConst) constraintTypesRooms.find((c) => c.type === WORKING_TIMES)!.display = true;
    if (hasWorkingDaysConst) constraintTypesRooms.find((c) => c.type === WORKING_DAYS)!.display = true;
    if (hasDailyWorkloadConst) constraintTypesRooms.find((c) => c.type === DAILY_WORKLOAD)!.display = true;
    if (hasIdleWindow) constraintTypesRooms.find((c) => c.type === IDLE_WINDOW)!.display = true;
    if (hasDistinctSubjects) constraintTypesRooms.find((c) => c.type === DISTINCT_SUBJECTS)!.display = true;
    if (hasForbiddenCombination) constraintTypesRooms.find((c) => c.type === FORBIDDEN_COMBINATION)!.display = true;
    if (hasUnavailableTimes) constraintTypesRooms.find((c) => c.type === UNAVAILABLE_TIMES)!.display = true;
    if (hasConsecutiveTimesConstr) constraintTypesRooms.find((c) => c.type === CONSECUTIVE_TIMES)!.display = true;
    if (hasTravelTimeConstr) constraintTypesRooms.find((c) => c.type === TRAVEL_TIME)!.display = true;

    return constraintTypesRooms;
  }, [file, resources]);

  const constraintTypesLessons = useMemo(() => {
    if (!file) return [];
    const BOUNDS = getConstrBounds(file!.days.length, file!.times.length);
    const constraintTypesLessons: ConstraintTypeDisplay[] = [
      { type: DAYS_BETWEEN_MEETINGS, display: false },
      { type: PREDEFINED_TIMES, display: false },
      { type: SIMULTANEOUS_WITH, display: false },
      { type: NOT_SIMULTANEOUS_WITH, display: false },
      { type: OCCUR_BEFORE, display: false },
      { type: SUBJECT_UNAVAILABLE_TIMES, display: false },
    ];

    const hasDaysBetweenLessons = lessons.find(
      (lesson) =>
        lesson.minGapLessons !== BOUNDS.minDaysBetweenLessons || lesson.maxGapLessons !== BOUNDS.maxDaysBetweenLessons
    );
    const hasPredefinedTimesConstr = lessons.find((lesson) => lesson.predefinedTimes.length !== 0);
    const hasSimultaneousWithConstr = lessons.find((lesson) => lesson.simultaneousWith.length !== 0);
    const hasNotSimultaneousWithConstr = lessons.find((lesson) => lesson.notSimultaneousWith.length !== 0);
    const hasOccurBeforeConstr = lessons.find((lesson) => lesson.occurBefore.length !== 0);
    const hasUnavailableTimesConstr = subjects.find((subject) => subject.unavailableTimes.length !== 0);

    if (hasDaysBetweenLessons) constraintTypesLessons.find((c) => c.type === DAYS_BETWEEN_MEETINGS)!.display = true;
    if (hasPredefinedTimesConstr) constraintTypesLessons.find((c) => c.type === PREDEFINED_TIMES)!.display = true;
    if (hasSimultaneousWithConstr) constraintTypesLessons.find((c) => c.type === SIMULTANEOUS_WITH)!.display = true;
    if (hasNotSimultaneousWithConstr)
      constraintTypesLessons.find((c) => c.type === NOT_SIMULTANEOUS_WITH)!.display = true;
    if (hasOccurBeforeConstr) constraintTypesLessons.find((c) => c.type === OCCUR_BEFORE)!.display = true;
    if (hasUnavailableTimesConstr)
      constraintTypesLessons.find((c) => c.type === SUBJECT_UNAVAILABLE_TIMES)!.display = true;

    return constraintTypesLessons;
  }, [t, file, lessons, subjects]);

  const [solver, setSolver] = useState<string>(t(solution.solver));
  // const [runningTimeLabel, setRunningTimeLabel] = useState<string>(mapRunningTimeSecondsToLabels[solution.runningTime]);

  function getRunningTimeLabels() {
    return [t('1 minute'), t('5 minutes'), t('10 minutes'), t('30 minutes'), t('1 hour'), t('2 hours')];
  }

  const nameRef = useRef<HTMLInputElement>(null);

  const infeasWarnings: string[] = useMemo(() => {
    return getInfeasibilitySources(t, days.length, times.length, resources, subjects, lessons);
  }, [days.length, lessons, resources, subjects, t, times.length]);

  const [advancedSettinsDialogOpen, setAdvancedSettinsDialogOpen] = useState(false);
  const [constraintType, setConstraintType] = useState<string>();
  const [entity, setEntity] = useState<string>();

  const getIndividualEntityCustomConstrCountMap = (solution: ISolution) => {
    const customConstrCountMap = new Map();
    if (!solution.customConstraints) return customConstrCountMap;
    solution.customConstraints.forEach((customConstr) => {
      if (customConstrCountMap.has(customConstr.entity + DAY_TIME_SEPARATOR + customConstr.type)) {
        customConstrCountMap.set(
          customConstr.entity + DAY_TIME_SEPARATOR + customConstr.type,
          customConstrCountMap.get(customConstr.entity + DAY_TIME_SEPARATOR + customConstr.type) + 1
        );
      } else {
        customConstrCountMap.set(customConstr.entity + DAY_TIME_SEPARATOR + customConstr.type, 1);
      }
    });
    return customConstrCountMap;
  };

  useEffect(() => {
    if (solution.customConstraints) {
      const customConstrMap = getIndividualEntityCustomConstrCountMap(solution);
      setCustomConstrCountMap(customConstrMap);
    }
  }, [solution]);

  const [customConstrCountMap, setCustomConstrCountMap] = useState(getIndividualEntityCustomConstrCountMap(solution));

  return (
    <div>
      {constraintType && entity && (
        <AdvancedConstraintsDialog
          open={advancedSettinsDialogOpen}
          positiveLabel={t('Confirm')}
          negativeLabel={t('Cancel')}
          negativeAction={() => {
            setEntity(undefined);
            setConstraintType(undefined);
            setAdvancedSettinsDialogOpen(false);
          }}
          constraintType={constraintType}
          entity={entity}
          solution={solution}
          setSolution={setSolution}
          colors={textColors}
          backgroundColors={backgroundColors}
        />
      )}
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<GridExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <DialogContentText>{t('Overall')}</DialogContentText>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2} paddingTop={1} alignItems="flex-start">
                <Grid item xs={12} md={8}>
                  <TextField
                    id="name"
                    inputRef={nameRef}
                    required
                    fullWidth
                    size="small"
                    label={t('Name')}
                    variant="outlined"
                    translate="no"
                    defaultValue={solution.name}
                  />
                </Grid>
                {/* ALGORITHM */}
                {/* <Grid item xs={12} md={4}>
                  <BasicSelect
                    current={solver}
                    setCurrent={setSolver}
                    options={[t('Optimal'), t('Fast')]}
                    label={t('Algorithm')}
                  ></BasicSelect>
                </Grid> */}
                <Grid className="running-time" item xs={12} md={4}>
                  {/* RUNNING TIME */}
                  <RuntimeSelect
                    solution={solution}
                    setSolution={setSolution}
                    options={getRunningTimeLabels()}
                    label={t('Max Running Time')}
                    userPlan={userPlan}
                  ></RuntimeSelect>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          {infeasWarnings.length > 0 && (
            <Alert icon={<Info fontSize="inherit" />} severity="error">
              <Stack display="flex">
                <Typography>{t('Your data input has the following issues:')}</Typography>
                <List dense>
                  {infeasWarnings.map((infeasWarning, idx) => {
                    return (
                      <ListItem key={idx}>
                        <Typography>• {infeasWarning}</Typography>;
                      </ListItem>
                    );
                  })}
                </List>
                <Typography>{t('It is highly recommended to check them before creating the timetables.')}</Typography>
              </Stack>
            </Alert>
          )}
          {solution.status === OPTIMAL && (
            <Alert icon={<Info fontSize="inherit" />} severity="info">
              <Stack display="flex">
                <Typography>{t('The current timetable is the best possible for this input data.')}</Typography>
                <Typography>
                  {t(
                    'If it is not the ideal solution, please review your input requirements or priorities in the Advanced settings below.'
                  )}
                </Typography>
              </Stack>
            </Alert>
          )}
          <Accordion className="advanced-configs" defaultExpanded={solution.status === OPTIMAL}>
            <AccordionSummary expandIcon={<GridExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <DialogContentText>{t('Advanced')}</DialogContentText>
            </AccordionSummary>
            <AccordionDetails>
              <DialogContentText paddingTop={1}>{message}</DialogContentText>
              <Grid container spacing={2} paddingTop={1} alignItems="flex-start">
                <Grid container item xs={12} sm={6} lg={3} spacing={1}>
                  <Paper elevation={3} sx={{ display: 'inline-block', width: '100%', padding: 1 }}>
                    <Typography>{file?.studentsSetting !== 0 ? t('Classes') : t('Students')}</Typography>
                    {!constraintTypesClasses.find((constr) => constr.display) ? (
                      <EmptyConstraints label={t('There is no constraints for classes')} />
                    ) : (
                      constraintTypesClasses.map(({ type, display }, index) => {
                        if (!display) return <div key={index}></div>;
                        return (
                          <Grid key={index} container paddingTop={2} spacing={1}>
                            <Grid item xs={10}>
                              <ConstraintPrioritySelect
                                solution={solution}
                                setSolution={setSolution}
                                options={priorityOptions.map((opt) => t(opt))}
                                backgroundColors={backgroundColors}
                                colors={textColors}
                                entity={CLASS}
                                label={type}
                              ></ConstraintPrioritySelect>
                            </Grid>
                            <Grid item xs={2}>
                              <Tooltip title={t('Setup individual priority for each class')}>
                                <Badge
                                  badgeContent={
                                    customConstrCountMap.get(CLASS + DAY_TIME_SEPARATOR + type)
                                      ? customConstrCountMap.get(CLASS + DAY_TIME_SEPARATOR + type)
                                      : null
                                  }
                                  color="primary"
                                  overlap="circular"
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                  }}
                                >
                                  <IconButton
                                    onClick={() => {
                                      setEntity(CLASS);
                                      setConstraintType(type);
                                      setAdvancedSettinsDialogOpen(true);
                                    }}
                                  >
                                    <Tune />
                                  </IconButton>
                                </Badge>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        );
                      })
                    )}
                  </Paper>
                </Grid>
                <Grid container item xs={12} sm={6} lg={3} spacing={1}>
                  <Paper elevation={3} sx={{ display: 'inline-block', width: '100%', padding: 1 }}>
                    <Typography>{t('Teachers')}</Typography>
                    {!constraintTypesTeachers.find((constr) => constr.display) ? (
                      <EmptyConstraints label={t('There is no constraints for teachers')} />
                    ) : (
                      constraintTypesTeachers.map(({ type, display }, index) => {
                        if (!display) return <div key={index}></div>;
                        return (
                          <Grid key={index} container paddingTop={2} spacing={1}>
                            <Grid item xs={10}>
                              <ConstraintPrioritySelect
                                solution={solution}
                                setSolution={setSolution}
                                options={priorityOptions.map((opt) => t(opt))}
                                backgroundColors={backgroundColors}
                                colors={textColors}
                                entity={TEACHER}
                                label={type}
                              ></ConstraintPrioritySelect>
                            </Grid>
                            <Grid item xs={2}>
                              <Tooltip title={t('Setup individual priority for each teacher')}>
                                <Badge
                                  badgeContent={
                                    customConstrCountMap.get(TEACHER + DAY_TIME_SEPARATOR + type)
                                      ? customConstrCountMap.get(TEACHER + DAY_TIME_SEPARATOR + type)
                                      : null
                                  }
                                  color="primary"
                                  overlap="circular"
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                  }}
                                >
                                  <IconButton
                                    onClick={() => {
                                      setEntity(TEACHER);
                                      setConstraintType(type);
                                      setAdvancedSettinsDialogOpen(true);
                                    }}
                                  >
                                    <Tune />
                                  </IconButton>
                                </Badge>
                                {/* <IconButton
                                  onClick={() => {
                                    setEntity(TEACHER);
                                    setConstraintType(type);
                                    setAdvancedSettinsDialogOpen(true);
                                  }}
                                >
                                  <Tune />
                                </IconButton> */}
                              </Tooltip>
                            </Grid>
                          </Grid>
                        );
                      })
                    )}
                  </Paper>
                </Grid>
                <Grid container item xs={12} sm={6} lg={3} spacing={1}>
                  <Paper elevation={3} sx={{ display: 'inline-block', width: '100%', padding: 1 }}>
                    <Typography>{t('Rooms')}</Typography>
                    {!constraintTypesRooms.find((constr) => constr.display) ? (
                      <EmptyConstraints label={t('There is no constraints for rooms')} />
                    ) : (
                      constraintTypesRooms.map(({ type, display }, index) => {
                        if (!display) return <div key={index}></div>;
                        return (
                          <Grid key={index} container paddingTop={2} spacing={1}>
                            <Grid item xs={10}>
                              <ConstraintPrioritySelect
                                solution={solution}
                                setSolution={setSolution}
                                options={priorityOptions.map((opt) => t(opt))}
                                backgroundColors={backgroundColors}
                                colors={textColors}
                                entity={ROOM}
                                label={type}
                              ></ConstraintPrioritySelect>
                            </Grid>
                            <Grid item xs={2}>
                              <Tooltip title={t('Setup individual priority for each room')}>
                                <Badge
                                  badgeContent={
                                    customConstrCountMap.get(ROOM + DAY_TIME_SEPARATOR + type)
                                      ? customConstrCountMap.get(ROOM + DAY_TIME_SEPARATOR + type)
                                      : null
                                  }
                                  color="primary"
                                  overlap="circular"
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                  }}
                                >
                                  <IconButton
                                    onClick={() => {
                                      setEntity(ROOM);
                                      setConstraintType(type);
                                      setAdvancedSettinsDialogOpen(true);
                                    }}
                                  >
                                    <Tune />
                                  </IconButton>
                                </Badge>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        );
                      })
                    )}
                  </Paper>
                </Grid>
                <Grid container item xs={12} sm={6} lg={3} spacing={1}>
                  <Paper elevation={3} sx={{ display: 'inline-block', width: '100%', padding: 1 }}>
                    <Typography>{t('Lessons')}</Typography>
                    {!constraintTypesLessons.find((constr) => constr.display) ? (
                      <EmptyConstraints label={t('There is no constraints for lessons')} />
                    ) : (
                      constraintTypesLessons.map(({ type, display }, index) => {
                        if (!display) return <div key={index}></div>;
                        return (
                          <Grid key={index} container paddingTop={2} spacing={1}>
                            <Grid item xs={10}>
                              <ConstraintPrioritySelect
                                solution={solution}
                                setSolution={setSolution}
                                options={priorityOptions.map((opt) => t(opt))}
                                backgroundColors={backgroundColors}
                                colors={textColors}
                                entity={LESSON}
                                label={type}
                              ></ConstraintPrioritySelect>
                            </Grid>
                            <Grid item xs={2}>
                              <Tooltip title={t('Setup individual priority for each lesson')}>
                                <Badge
                                  badgeContent={
                                    customConstrCountMap.get(LESSON + DAY_TIME_SEPARATOR + type)
                                      ? customConstrCountMap.get(LESSON + DAY_TIME_SEPARATOR + type)
                                      : null
                                  }
                                  color="primary"
                                  overlap="circular"
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                  }}
                                >
                                  <IconButton
                                    onClick={() => {
                                      setEntity(LESSON);
                                      setConstraintType(type);
                                      setAdvancedSettinsDialogOpen(true);
                                    }}
                                  >
                                    <Tune />
                                  </IconButton>
                                </Badge>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        );
                      })
                    )}
                  </Paper>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleDefault}>
            {widthLessThan900 ? t('Default') : t('Restore Default')}
          </Button>
          <Button autoFocus onClick={handleClose}>
            {t('Cancel')}
          </Button>
          <Button className="confirm-button" onClick={handleConfirm}>
            {t('Confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
