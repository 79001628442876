import { Breakpoint, Dialog } from '@mui/material';
import { SessionContext } from 'contexts';
import React, { useContext } from 'react';
import { PaperComponent } from 'util/dialogUtils';
import { darkModeScrollBar } from 'util/themeUtils';

type Props = {
  open: boolean;
  onClose: () => void;
  fullWidth?: boolean;
  maxWidth?: false | Breakpoint;
  children: React.ReactNode | React.ReactNode[];
};

export default function DialogContainer({ open, onClose, fullWidth = false, maxWidth, children }: Props) {
  const { isDarkMode } = useContext(SessionContext);
  return (
    <Dialog
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      open={open}
      onClose={onClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog"
      sx={isDarkMode ? darkModeScrollBar : {}}
    >
      {children}
    </Dialog>
  );
}
