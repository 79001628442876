import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SessionContext } from 'contexts';
import { darken, lighten, Grid, Stack, Typography, styled, Tooltip, colors, Box } from '@mui/material';
import { grey } from '@mui/material/colors';
import { GridColDef, DataGrid, ptBR, GridRenderCellParams } from '@mui/x-data-grid';
import { Check, HighlightAlt, SearchOff, WarningAmber } from '@mui/icons-material';
import { useImportUtils } from 'util/importUtils';
import { getConstrBounds } from 'util/validationUtils';
import { CLASS, LESSON, ROOM, TEACHER } from 'util/configUtils';
import { NOT_SIMULTANEOUS_WITH, OCCUR_BEFORE, SIMULTANEOUS_WITH } from 'util/constraintUtils';
import { RESOURCE_JOINT_IMPORT_SEPARATOR, SUBJECT_CLASS_SEPARATOR } from 'util/solutionUtils';
interface ImportData {
  id: string;
  id0: string;
  id1: string;
  id2: string;
  id3: string;
  id4: string;
  id5: string;
  id6: string;
  id7: string;
  status?: string;
}

function createData(
  id: string,
  id0: string,
  id1: string,
  id2: string,
  id3: string,
  id4: string,
  id5: string,
  id6: string,
  id7: string,
  status: string = 'None'
): ImportData {
  return {
    id,
    id0,
    id1,
    id2,
    id3,
    id4,
    id5,
    id6,
    id7,
    status,
  };
}

interface ImportDataGridProps {
  clipboardText: string;
  setClipboardText: (clipboardText: string) => void;
  ignoreFirstLine?: boolean;
  metaColumns: string[];
  dataType: string;
}

export default function ImportDataGrid({
  clipboardText,
  setClipboardText,
  ignoreFirstLine = true,
  metaColumns,
  dataType,
}: ImportDataGridProps) {
  const { t } = useTranslation();
  const { user, file, resources, subjects, lessons, isDarkMode } = useContext(SessionContext);
  const [pageSize, setPageSize] = React.useState<number>(100);
  const columnNames = [
    `${t('Id')}`,
    `${t('Column')} 1`,
    `${t('Column')} 2`,
    `${t('Column')} 3`,
    `${t('Column')} 4`,
    `${t('Column')} 5`,
    `${t('Column')} 6`,
    `${t('Column')} 7`,
    `${t('Column')} 8`,
  ];

  const getExistingIds = () => {
    switch (dataType) {
      case 'teacher':
      case 'class':
      case 'room':
        return resources.map((resource) => resource.name);
      case 'subject':
        return subjects.map((subject) => subject.name);
      case 'lesson':
        return lessons.map((lesson) => lesson.name);
      case 'assignment':
      default:
        return [];
    }
  };

  const days = file?.days ? file?.days : [];
  const times = file?.times ? file?.times : [];

  const {
    handleMinMaxConstraint,
    handleArrayOfDays,
    handleArrayOfTimeslots,
    handleName,
    resetWarnings,
    handleLessonsWeek,
    handleSplit,
    handleLessonResource,
    handleSimultaneousWith,
    handleOccurBefore,
    validateNameInput,
  } = useImportUtils(t, [], dataType, days, times, file, getExistingIds());

  const getLessonNamesToImport = (clipboardText: string, columns: string[]) => {
    const lessonNamesToImport: string[] = [];
    const subjectIdx = columns.findIndex((c) => c === t('Subject'));
    const classIdx = columns.findIndex((c) => c === t('Class'));
    if (subjectIdx === -1 || classIdx === -1) return lessonNamesToImport;

    clipboardText.split('\n').forEach((line, index) => {
      const cells = line.split('\t');
      lessonNamesToImport.push(cells[subjectIdx].trim() + SUBJECT_CLASS_SEPARATOR + cells[classIdx]);
    });
    return lessonNamesToImport;
  };

  const existingLessonNames = lessons.map((l) => l.name);
  const lessonNamesToImport = getLessonNamesToImport(clipboardText, metaColumns);

  const getColumnDataByLabel = (params: GridRenderCellParams<any, any, any>, label: string) => {
    const searchColumnIdx = metaColumns.findIndex((metaColumn) => metaColumn === label);
    if (searchColumnIdx < 0) return;
    return params.row['id' + searchColumnIdx];
  };

  function getWarningMessage(
    content: string,
    line: number,
    metaColumn: string,
    params: GridRenderCellParams<any, any, any>
  ) {
    const CONSTR_BOUNDS = getConstrBounds(days.length, times.length);
    switch (metaColumn) {
      case t('Name'):
        return handleName(content, line, metaColumn, []);
      case t('Class'):
      case t('Student'):
        if (dataType === LESSON) return handleLessonResource(content, line, metaColumn, CLASS, []);
        else return handleName(content, line, metaColumn, []);
      case t('Teacher'):
        if (dataType === LESSON) return handleLessonResource(content, line, metaColumn, TEACHER, []);
        else return handleName(content, line, metaColumn, []);
      case t('Room'):
        if (dataType === LESSON) return handleLessonResource(content, line, metaColumn, ROOM, []);
        else return handleName(content, line, metaColumn, []);
      case t('Subject'):
        return handleName(content, line, metaColumn, [], getColumnDataByLabel(params, t('Class')));
      case t('Working Times'):
        return handleMinMaxConstraint(
          content,
          line,
          metaColumn,
          [],
          CONSTR_BOUNDS.minWorkingTimes,
          CONSTR_BOUNDS.maxWorkingTimes
        );
      case t('Working Days'):
        return handleMinMaxConstraint(
          content,
          line,
          metaColumn,
          [],
          CONSTR_BOUNDS.minWorkingDays,
          CONSTR_BOUNDS.maxWorkingDays
        );
      case t('Idle Window'):
        return handleMinMaxConstraint(
          content,
          line,
          metaColumn,
          [],
          CONSTR_BOUNDS.minIdleWindow,
          CONSTR_BOUNDS.maxIdleWindow
        );
      case t('Daily Workload'):
        return handleMinMaxConstraint(
          content,
          line,
          metaColumn,
          [],
          CONSTR_BOUNDS.minDailyWorkload,
          CONSTR_BOUNDS.maxDailyWorkload
        );
      case t('Rest between Days'):
        return handleMinMaxConstraint(
          content,
          line,
          metaColumn,
          [],
          CONSTR_BOUNDS.minRestBetweenDays,
          CONSTR_BOUNDS.maxRestBetweenDays
        );
      case t('Rooms per Day'):
        return handleMinMaxConstraint(
          content,
          line,
          metaColumn,
          [],
          CONSTR_BOUNDS.minRoomsPerDay,
          CONSTR_BOUNDS.maxRoomsPerDay
        );
      case t('Consecutive Times'):
        return handleMinMaxConstraint(
          content,
          line,
          metaColumn,
          [],
          CONSTR_BOUNDS.minConsecutiveTimes,
          CONSTR_BOUNDS.maxConsecutiveTimes
        );
      case t('Distinct Subjects'):
        return handleMinMaxConstraint(
          content,
          line,
          metaColumn,
          [],
          CONSTR_BOUNDS.minDistinctSubjects,
          CONSTR_BOUNDS.maxDistinctSubjects
        );
      case t('Forbidden Combination'):
        return handleArrayOfDays(content, line, metaColumn, []);
      case t('Unavailable Times'):
      case t('Predefined Times'):
        return handleArrayOfTimeslots(content, line, metaColumn, []);
      case t('Times /Week'):
        return handleLessonsWeek(content, line, metaColumn, []);
      case t('Split'):
        return handleSplit(content, line, metaColumn, [], Number(getColumnDataByLabel(params, t('Times /Week'))));
      case t('Days between Lessons'):
      case t('Days between Meetings'):
        return handleMinMaxConstraint(
          content,
          line,
          metaColumn,
          [],
          CONSTR_BOUNDS.minDaysBetweenLessons,
          CONSTR_BOUNDS.maxDaysBetweenLessons
        );
      case t(SIMULTANEOUS_WITH):
      case t(NOT_SIMULTANEOUS_WITH):
        return handleSimultaneousWith(content, line, metaColumn, existingLessonNames, lessonNamesToImport, []);
      case t(OCCUR_BEFORE):
        return handleOccurBefore(content, line, metaColumn, existingLessonNames, lessonNamesToImport, [], [0]);
    }
    return;
  }

  function renderCellWithWarnings(params: GridRenderCellParams<any, any, any>, metaColumn: string) {
    if (metaColumn === t("Don't import")) return <Box sx={{ color: colors.grey[500] }}>{params.value}</Box>;

    const warningMessage = getWarningMessage(params.value, Number(params.id), metaColumn, params);
    resetWarnings();

    const isLineIgnored = ignoreFirstLine && Number(params.id) === 0;

    if (isLineIgnored) return <Box>{params.value}</Box>;

    if (warningMessage && !isLineIgnored)
      return (
        <Tooltip title={warningMessage}>
          <Stack
            direction={'row'}
            sx={{
              gap: '2px',
              backgroundColor: colors.orange[50],
              width: '100%',
              display: 'flex',
              borderRadius: 1,
              paddingInline: 1,
              color: isDarkMode ? grey[900] : null,
            }}
          >
            <WarningAmber color="warning" fontSize="small" />
            {params.value}
          </Stack>
        </Tooltip>
      );
    return (
      <Tooltip title={t('No warnings/errors')}>
        <Stack
          direction={'row'}
          sx={{
            gap: '2px',
            backgroundColor: colors.green[50],
            width: '100%',
            display: 'flex',
            borderRadius: 1,
            paddingInline: 1,
            color: isDarkMode ? grey[900] : null,
          }}
        >
          <Check color="success" fontSize="small" />
          {params.value}
        </Stack>
      </Tooltip>
    );
  }

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: t('Id'),
      hide: true,
    },
    {
      field: 'id0',
      headerName: columnNames[0],
      flex: 1,
      renderCell: (params) => renderCellWithWarnings(params, metaColumns[0]),
    },
    {
      field: 'id1',
      headerName: columnNames[1],
      flex: 1,
      renderCell: (params) => renderCellWithWarnings(params, metaColumns[1]),
    },
    {
      field: 'id2',
      headerName: columnNames[2],
      flex: 1,
      renderCell: (params) => renderCellWithWarnings(params, metaColumns[2]),
    },
    {
      field: 'id3',
      headerName: columnNames[3],
      flex: 1,
      renderCell: (params) => renderCellWithWarnings(params, metaColumns[3]),
    },
    {
      field: 'id4',
      headerName: columnNames[4],
      flex: 1,
      renderCell: (params) => renderCellWithWarnings(params, metaColumns[4]),
    },
    {
      field: 'id5',
      headerName: columnNames[5],
      flex: 1,
      renderCell: (params) => renderCellWithWarnings(params, metaColumns[5]),
    },
    {
      field: 'id6',
      headerName: columnNames[6],
      flex: 1,
      renderCell: (params) => renderCellWithWarnings(params, metaColumns[6]),
    },
    {
      field: 'id7',
      headerName: columnNames[7],
      flex: 1,
      renderCell: (params) => renderCellWithWarnings(params, metaColumns[7]),
    },
  ];

  let rows: ImportData[] = [];

  if (clipboardText === '') {
    navigator.clipboard
      .readText()
      .then((text) => {
        setClipboardText(text);
      })
      .catch((err) => {
        console.error('Failed to read clipboard contents: ', err);
      });
  }

  clipboardText.split('\n').map(function (clipboardLine, index) {
    const cells = clipboardLine.split('\t');

    return rows.push(
      createData(
        '' + index,
        cells[0],
        cells.length > 1 ? cells[1] : '',
        cells.length > 2 ? cells[2] : '',
        cells.length > 3 ? cells[3] : '',
        cells.length > 4 ? cells[4] : '',
        cells.length > 5 ? cells[5] : '',
        cells.length > 6 ? cells[6] : '',
        cells.length > 7 ? cells[7] : '',
        index === 0 && ignoreFirstLine ? 'Rejected' : 'None'
      )
    );
  });

  const getBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

  const getSelectedBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

  const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .super-app-theme--Rejected': {
      backgroundColor: getBackgroundColor(grey[500], theme.palette.mode),
      '&:hover': {
        backgroundColor: getBackgroundColor(grey[500], theme.palette.mode),
      },
      '&.Mui-selected': {
        backgroundColor: getSelectedBackgroundColor(grey[500], theme.palette.mode),
        '&:hover': {
          backgroundColor: getSelectedHoverBackgroundColor(grey[500], theme.palette.mode),
        },
      },
    },
  }));

  return (
    <Grid item xs={12} sx={{ height: '60vh', width: '100%' }}>
      <StyledDataGrid
        rows={rows}
        columns={columns}
        // autoPageSize
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[20, 50, 100]}
        headerHeight={0}
        density={'compact'}
        disableSelectionOnClick
        initialState={{
          sorting: {
            sortModel: [{ field: 'updatedAt', sort: 'desc' }],
          },
        }}
        scrollbarSize={4}
        getRowClassName={(params) => `super-app-theme--${params.row.status}`}
        // onRowClick={(params, event) => handleClick(event, params.id.toString())}
        localeText={user?.countryCode === 'BR' ? ptBR.components.MuiDataGrid.defaultProps.localeText : undefined}
        components={{
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              <HighlightAlt style={{ color: grey[500], textAlign: 'center' }} />
              <Typography color={grey[500]} textAlign="center">
                {t('There is not data in the CLIPBOARD')}
              </Typography>
            </Stack>
          ),
          NoResultsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              <SearchOff style={{ color: grey[500], textAlign: 'center' }} />
              <Typography color={grey[500]} textAlign="center">
                {t('Local FILTER returns no data')}
              </Typography>
            </Stack>
          ),
        }}
      />
    </Grid>
  );
}
