import {
  Avatar,
  Chip,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { grey, green, red, amber } from '@mui/material/colors';
import { GridColDef } from '@mui/x-data-grid';
import { IUser } from 'contexts/SessionContext/SessionContext';
import dayjs from 'dayjs';
import { IFile } from 'pages/Files/File';
import { TFunction } from 'react-i18next';
import { DAY_TIME_SEPARATOR } from 'util/solutionUtils';

interface SubjectData {
  short: string;
  color: string;
  photoURL: string;
  id: string;
  priority: string;
  numlinkedLessons: number;
  sumLessonsWeek: number;
  availability: number;
  unavailableTimes: string[];
  undesiredTimes: string[];
  updatedAt: Date;
}

export function createData(
  short: string,
  color: string,
  photoURL: string,
  id: string,
  priority: string,
  numlinkedLessons: number,
  sumLessonsWeek: number,
  availability: number,
  unavailableTimes: string[],
  undesiredTimes: string[],
  updatedAt: Date
): SubjectData {
  return {
    short,
    color,
    photoURL,
    id,
    priority,
    numlinkedLessons,
    sumLessonsWeek,
    availability,
    unavailableTimes,
    undesiredTimes,
    updatedAt,
  };
}

const strArrayComparator: any = (v1: string[], v2: string[]) => {
  if (v1.length !== v2.length) {
    return v1.length - v2.length;
  } else {
    return ('' + v1[0]).localeCompare('' + v2[0]);
  }
};

export const useDataGrid = (t: TFunction<'translation', undefined>, file: IFile, user: IUser) => {
  const widthLessThan900 = useMediaQuery('(max-width:900px)');
  const widthLessThan600 = useMediaQuery('(max-width:600px)');

  const columns: GridColDef[] = [
    {
      field: 'short',
      headerName: ' ',
      minWidth: 32,
      flex: 0.0001,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      hide: true,
      renderCell: (params) => {
        return (
          <Grid item container alignItems="center" justifyContent="center">
            <Avatar
              translate="no"
              sx={{
                width: 28,
                height: 28,
                fontSize: params.row.short.length > 3 ? '8px' : '12px',
                bgcolor: params.row.color,
              }}
              alt={params.value}
              src={params.row.photoURL}
            >
              {params.row.short}
            </Avatar>
          </Grid>
        );
      },
    },
    {
      field: 'id',
      headerName: t('Name'),
      flex: 3,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <Chip
            translate="no"
            variant="outlined"
            size="small"
            sx={{ backgroundColor: params.row.color, color: 'white', border: '0px' }}
            label={params.value}
          />
        );
      },
    },
    {
      field: 'priority',
      headerName: t('Priority'),
      flex: 1,
      minWidth: 100,
      hide: widthLessThan900,
    },
    {
      field: 'numlinkedLessons',
      headerName: t('Lessons'),
      flex: 1,
      type: 'number',
      minWidth: 100,
      hide: widthLessThan900,
    },
    {
      field: 'sumLessonsWeek',
      headerName: t('Times'),
      flex: 1,
      type: 'number',
      minWidth: 100,
      hide: widthLessThan900,
    },
    {
      field: 'availability',
      headerName: t('Availability'),
      flex: 1,
      minWidth: 100,
      // hide: widthLessThan600,
      renderCell: (params) => {
        const cellHeight = 28 / file!.times.length;
        return (
          <TableContainer>
            <Table translate="no" size="small" padding="none">
              <TableBody>
                {file!.times.map((_, t_index) => {
                  return (
                    <TableRow key={`time_${t_index}`}>
                      {file!.days.map((_, d_index) => {
                        return (
                          <TableCell
                            key={`cell_${d_index}_${t_index}`}
                            sx={{
                              border: 0.5,
                              borderColor: grey[600],
                              height: `${cellHeight}px`,
                              backgroundColor: `${
                                params.row.unavailableTimes.includes('' + d_index + DAY_TIME_SEPARATOR + t_index)
                                  ? red[200]
                                  : params.row.undesiredTimes.includes('' + d_index + DAY_TIME_SEPARATOR + t_index)
                                  ? amber[200]
                                  : green[200]
                              }`,
                            }}
                            align="center"
                          ></TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        );
      },
    },
    {
      field: 'updatedAt',
      headerName: t('Updated At'),
      flex: 1,
      minWidth: 130,
      headerAlign: 'right',
      align: 'right',
      hide: widthLessThan600,
      renderCell: (params) => {
        return (
          <Typography fontSize={14}>
            {user?.countryCode === 'BR'
              ? dayjs(params.row.updatedAt).format('DD/MM/YY HH:mm[h]')
              : dayjs(params.row.updatedAt).format('MM-DD-YY hh:mma')}
          </Typography>
        );
      },
    },
  ];

  return { columns };
};
