import { useTranslation } from 'react-i18next';
import AccordionTableDescription from 'components/AccordionTableDescription';
import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionSummary, Link, Typography, AccordionDetails, DialogContentText } from '@mui/material';

export default function ImportClassesAccordion() {
  const { t } = useTranslation();

  function createData(name: string, description: string) {
    return { name, description };
  }

  const nameRows = [createData(t('5th grade 502'), ''), createData(t('Information Systems 3rd term'), '')];

  const minMaxRows = [
    createData('2-3', t('Represents min 2 and max 3')),
    createData('4-', t('Means min 4 and max unrestricted')),
    createData('-1', t('Means min unrestricted and max 1')),
  ];

  const forbiddenCombinationRows = [
    createData('1+5', t('Represents Monday (day 1) and Friday (day 5)')),
    createData('1+3+5', t('Means Monday, Wednesday, and Friday')),
  ];

  const unavailableTimesRows = [
    createData('1:1+1:2', t('Represents Monday (day 1) at 1st and 2nd times')),
    createData('1:1+2:1+3:1+4:1+5:1', t('Means Monday until Friday at the 1st time')),
    createData('*:1', t('The * mark here represents all days on the 1st time')),
  ];

  const exampleRows = [
    createData(t('Working Days'), t('Classes EC5 and EC6 will have lessons in at least 5 days')),
    createData(t('Daily Workload'), t('All classes will have at least 4 and at most 5 lessons per day')),
    createData(t('Idle Window'), t('All classes will have idle windows of at most 0 timeslots (no idle windows)')),
    createData(t('Unavailable Times'), t('All classes are unavailable at the 5th time from Monday until Friday')),
  ];

  return (
    <div>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography color={'primary'}>{t('Example')}&nbsp;</Typography>
          <Link href={t('/data/classes-exampleEN.xlsx')} download>
            {'(download)'}
          </Link>
        </AccordionSummary>
        <AccordionDetails>
          <DialogContentText>{t('In this example, classes EC1 until EC6 will be imported:')}</DialogContentText>
          <img
            src={t('/img/examples/importClassesEN.png')}
            width={'100%'}
            alt={t('Example of Import Classes')}
            style={{ border: '1pt solid #bdbdbd', borderRadius: '4pt', padding: '4px' }}
            loading="lazy"
          />
          <AccordionTableDescription data={exampleRows} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography color={'primary'}>{t('Column Class')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DialogContentText>
            {`${t('This column cannot be empty and must be a text that uniquelly identifies the class')}. ${t('Ex.')}:`}
          </DialogContentText>
          <AccordionTableDescription data={nameRows} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography color={'primary'}>{t('Columns Working Times until Distinct Subjects')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DialogContentText>
            {t(
              'These columns are imported as a pair of min-max values. Either min or max can be omitted if not constrained. Ex.:'
            )}
          </DialogContentText>
          <AccordionTableDescription data={minMaxRows} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography color={'primary'}>{t('Column Forbidden Combination')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DialogContentText>
            {t('This column is imported as a set of numbers separated by a + sign. Ex.:')}
          </DialogContentText>
          <AccordionTableDescription data={forbiddenCombinationRows} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography color={'primary'}>{t('Column Unavailable Times')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DialogContentText>
            {t('These columns are imported as a set of timeslots (day:time) separated by a + sign. Ex.:')}
          </DialogContentText>
          <AccordionTableDescription data={unavailableTimesRows} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
