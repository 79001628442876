import OnboardingContent from 'components/OnboardingContent';
import { useEffect, useState } from 'react';
import { TFunction } from 'react-i18next';

export const FILE_CREATION_KEY = 'onboarding-file-creation';
export const useFileOnboarding = (t: TFunction<'translation', undefined>) => {
  const [runFileCreationOnboarding, setRunFileCreationOnboarding] = useState(false);
  useEffect(() => {
    if (localStorage.getItem(FILE_CREATION_KEY) !== 'true') {
      setRunFileCreationOnboarding(true);
    }
  }, []);

  const fileCreationSteps = [
    {
      target: '.file-data-form',
      disableBeacon: true,
      content: (
        <OnboardingContent
          title={t('Name and description')}
          message={t('Setup the name and description of the project')}
        />
      ),
    },
    {
      target: '.file-days-times-form',
      content: (
        <OnboardingContent
          title={t('Days and times')}
          message={t('Setup the number of days and the number of times per day in this project')}
        />
      ),
    },
    {
      target: '.next-button',
      content: (
        <OnboardingContent
          title={t('Next')}
          message={t("When you're done, click NEXT to create the project and proceed to setup the classes")}
        />
      ),
    },
  ];

  return { runFileCreationOnboarding, fileCreationSteps };
};
