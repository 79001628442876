import { useTranslation } from 'react-i18next';
import AccordionTableDescription from 'components/AccordionTableDescription';
import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionSummary, Link, Typography, AccordionDetails, DialogContentText } from '@mui/material';

export default function ImportSubjectsAccordion() {
  const { t } = useTranslation();

  function createData(name: string, description: string) {
    return { name, description };
  }

  const nameRows = [createData(t('Physics'), ''), createData(t('Databases'), '')];

  const unavailableTimesRows = [
    createData('1:1+1:2', t('Represents Monday (day 1) at 1st and 2nd times')),
    createData('1:1+2:1+3:1+4:1+5:1', t('Means Monday until Friday at the 1st time')),
    createData('*:1', t('The * mark here represents all days on the 1st time')),
  ];

  const exampleRows = [
    createData(t('Unavailable Times'), t('All subjects have the 5th time from Monday until Friday as unavailable')),
    createData(
      t('Ignored Columns'),
      t('Columns 1, 3, and 4 bring information that is not important to build the timetables and will be ignored')
    ),
  ];

  return (
    <div>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography color={'primary'}>{t('Example')}&nbsp;</Typography>
          <Link href={t('/data/subjects-exampleEN.xlsx')} download>
            {'(download)'}
          </Link>
        </AccordionSummary>
        <AccordionDetails>
          <DialogContentText>{t('In this example, six subjects will be imported:')}</DialogContentText>
          <img
            src={t('/img/examples/importSubjectsEN.png')}
            width={'100%'}
            alt={t('Example of Import Subjects')}
            style={{ border: '1pt solid #bdbdbd', borderRadius: '4pt', padding: '4px' }}
            loading="lazy"
          />
          <AccordionTableDescription data={exampleRows} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography color={'primary'}>{t('Column Subject')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DialogContentText>
            {`${t('This column cannot be empty and must be a text that uniquelly identifies the subject')}. ${t(
              'Ex.'
            )}:`}
          </DialogContentText>
          <AccordionTableDescription data={nameRows} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography color={'primary'}>{t('Column Unavailable Times')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DialogContentText>
            {t('These columns are imported as a set of timeslots (day:time) separated by a + sign. Ex.:')}
          </DialogContentText>
          <AccordionTableDescription data={unavailableTimesRows} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
