import { Grid, Tooltip, IconButton, SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import React from 'react';
import { theme } from 'styles';

type Props = {
  tooltip: string | React.ReactNode;
  color: string;
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  onClick: () => void;
};

export default function TooltipIconButton({ tooltip, Icon, color, onClick }: Props) {
  return (
    <Grid item alignSelf={'center'}>
      <Tooltip title={tooltip}>
        <IconButton size="small" style={{ color: color }} onClick={onClick}>
          <Icon />
        </IconButton>
      </Tooltip>
    </Grid>
  );
}
