import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import ImportMethodSelection from 'components/ImportMethodSelection';
import { useTranslation } from 'react-i18next';
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import FileUpload from 'components/FileUpload';
import ImportContentTable from 'components/ImportContentTable';
import { IImportWarning, MAX_IMPORT_COLS, useImportUtils } from 'util/importUtils';
import { SessionContext } from 'contexts';
import { useContainerDimensions } from 'hooks/useContainerDimensions';
import { toast } from 'react-toastify';
import { writeBatch, doc, Timestamp } from 'firebase/firestore';
import { db } from 'services';
import SimpleBackdrop from 'components/Loading/SimpleBackdrop';
import ImportClipboardAnimation from 'components/ImportClipboardAnimation';
import { ILesson } from '../utils/types';
import {
  PERIOD_SEPARATOR,
  PLUS_SEPARATOR,
  RESOURCE_JOINT_IMPORT_SEPARATOR,
  RESOURCE_OPTIONS_SEPARATOR,
  SUBJECT_CLASS_SEPARATOR,
} from 'util/solutionUtils';
import { IResource } from 'pages/Resources/Resource';
import { getRandomColor } from 'styles/randomColor';
import { CLASS, ROOM, TEACHER } from 'util/configUtils';
import { getConstrBounds } from 'util/validationUtils';
import { NOT_SIMULTANEOUS_WITH, OCCUR_BEFORE, SIMULTANEOUS_WITH } from 'util/constraintUtils';
import { useHistory } from 'react-router-dom';

type Props = {
  type: string;
  setInfoDialogOpen: Dispatch<SetStateAction<boolean>>;
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export default function ImportLessonsStepper({ type, setInfoDialogOpen, activeStep, setActiveStep, setOpen }: Props) {
  const { t } = useTranslation();
  const steps = [t('Choose import method'), t('Input data'), t('Data validation')];
  const [selectedFormat, setSelectedFormat] = useState<string>(t('Clipboard'));
  const { user, file, resources, subjects, solutions, lessons, ownerEmail } = React.useContext(SessionContext);
  const history = useHistory();
  if (!file) {
    history.push('/files');
    toast.warning(t('Select a project first'));
  }
  const days = file!.days;
  const times = file!.times;
  let warnings: IImportWarning[] = [];
  const gridRef = useRef<any>();
  let { width } = useContainerDimensions(gridRef);
  let maxSelectWidth = width !== 0 ? Math.floor(width / 8) : 172;
  const [fileData, setFileData] = useState<any[]>([]);
  const [clipboardText, setClipboardText] = useState<string>('');
  const [ignoreFirstLine, setIgnoreFirstLine] = useState(true);
  const [loadingOpen, setLoadingOpen] = useState(false);

  const fileId = file?.id || file?.name;
  const resourcesPath = 'users/' + ownerEmail + '/files/' + fileId + '/resources';
  const subjectsPath = 'users/' + ownerEmail + '/files/' + fileId + '/subjects';
  const lessonsPath = 'users/' + ownerEmail + '/files/' + fileId + '/lessons';
  const solutionsPath = 'users/' + ownerEmail + '/files/' + fileId + '/solutions';
  const priorityOptions = ['Very low', 'Low', 'Average', 'High', 'Very high'];
  const constrBounds = getConstrBounds(days.length, times.length);

  const existingIds = lessons.map((lesson) => lesson.name);

  const {
    getInitials,
    handleName,
    handleMinMaxConstraint,
    handleArrayOfTimeslots,
    handleLessonResource,
    handleLessonsWeek,
    handleSplit,
    getDefaultSplit,
    handleIdentifierInput,
    handleMinMaxInput,
    handleTimeslotsInput,
    handleLessonResourceInput,
    handleLessonsWeekInput,
    fillMinMaxMissingFields,
    handleSimultaneousWith,
    handleOccurBefore,
    validateNameInput,
  } = useImportUtils(t, warnings, 'lesson', days, times, file, existingIds);

  const handlePaste = async () => {
    const newClipboardText = await getContentFromClipboard();
    if (newClipboardText !== '') toast.success(t('Content successfully loaded from clipboard'));
    setClipboardText(newClipboardText);
  };

  const getContentFromClipboard = async () => {
    try {
      const text = await navigator.clipboard.readText();
      return text;
    } catch (error) {
      console.error('Failed to read clipboard contents: ', error);
      return '';
    }
  };
  const getLessonNamesToImport = (clipboardText: string, columns: string[]) => {
    const lessonNamesToImport: string[] = [];
    const subjectIdx = columns.findIndex((c) => c === t('Subject'));
    const classIdx = columns.findIndex((c) => c === t('Class'));
    if (subjectIdx == undefined || classIdx === undefined) return lessonNamesToImport;

    clipboardText.split('\n').forEach((line, index) => {
      const cells = line.split('\t');
      lessonNamesToImport.push(cells[subjectIdx].trim() + SUBJECT_CLASS_SEPARATOR + cells[classIdx]);
    });
    return lessonNamesToImport;
  };

  const lessonResourceImportFields = [
    `${file?.studentsSetting !== 0 ? t('Class') : t('Students')}`,
    t('Teacher'),
    t('Room'),
  ];

  const lessonsWeekInputFields = [t('Times /Week')];

  const minMaxImportFields = [t('Days between Meetings')];

  const timeslotsImportFields = [t('Predefined Times')];

  const importFields = [
    t("Don't import"),
    t('Subject'),
    lessonsWeekInputFields[0],
    t('Split'),
    minMaxImportFields[0],
    ...lessonResourceImportFields,
    timeslotsImportFields[0],
    t(SIMULTANEOUS_WITH),
    t(NOT_SIMULTANEOUS_WITH),
    t(OCCUR_BEFORE),
  ];

  const [column1, setColumn1] = useState<string>(importFields[0]);
  const [column2, setColumn2] = useState<string>(importFields[0]);
  const [column3, setColumn3] = useState<string>(importFields[0]);
  const [column4, setColumn4] = useState<string>(importFields[0]);
  const [column5, setColumn5] = useState<string>(importFields[0]);
  const [column6, setColumn6] = useState<string>(importFields[0]);
  const [column7, setColumn7] = useState<string>(importFields[0]);
  const [column8, setColumn8] = useState<string>(importFields[0]);
  const columns: string[] = [column1, column2, column3, column4, column5, column6, column7, column8];
  const setColumns: React.Dispatch<React.SetStateAction<string>>[] = [
    setColumn1,
    setColumn2,
    setColumn3,
    setColumn4,
    setColumn5,
    setColumn6,
    setColumn7,
    setColumn8,
  ];

  useEffect(() => {
    const clipboardRowNames = clipboardText.split('\r\n')[0].split('\t');
    const lowerCaseImportFields = importFields.map((field) => field.toLowerCase());
    clipboardRowNames.forEach((clipboardRowName, idx) => {
      if (idx > MAX_IMPORT_COLS - 1) return;
      if (lowerCaseImportFields.includes(clipboardRowName.trim().toLowerCase())) {
        setColumns[idx](clipboardRowName);
      }
    });
  }, [clipboardText]);

  const validateMandatoryFields = () => {
    const subjectColumn = columns.find((column) => column === t('Subject'));
    if (!subjectColumn) {
      toast.error(`${t('One of the columns must be set as Subject')}`);
      return false;
    }
    const timesWeekColumn = columns.find((column) => column === t('Times /Week'));
    if (!timesWeekColumn) {
      toast.error(`${t('One of the columns must be set as Times /Week')}`);
      return false;
    }
    const classColumn = columns.find((column) => column === t('Class'));
    if (!classColumn) {
      toast.error(`${t('One of the columns must be set as Class')}`);
      return false;
    }
    const teacherColumn = columns.find((column) => column === t('Teacher'));
    if (!teacherColumn) {
      toast.error(`${t('One of the columns must be set as Teacher')}`);
      return false;
    }
    return true;
  };

  const hasRepeatedColumns = () => {
    for (let i = 0; i < columns.length; ++i) {
      for (let j = i + 1; j < columns.length; ++j) {
        if (columns[i] === columns[j] && columns[i] !== t("Don't import")) {
          toast.error(`${t('Cannot have more than one column with value')} ${columns[i]}`);
          return true;
        }
      }
    }
    return false;
  };

  const hasAtLeastTwoRows = () => {
    if (clipboardText.split('\n').length < 2) {
      toast.error(t('Data must have at least one row beyond the header'));
      return false;
    }
    return true;
  };

  const handleProceed = () => {
    if (!validateMandatoryFields()) return;
    if (hasRepeatedColumns()) return;
    if (!hasAtLeastTwoRows()) return;

    const lessonsToImport: ILesson[] = [];

    clipboardText.split('\n').forEach((line, index) => {
      if ((index === 0 && ignoreFirstLine) || line === '') return;

      const cells = line.split('\t');

      // Handle name
      let subject: string[] = [];
      columns.forEach((column, i) => handleIdentifierInput(column, cells[i], handleName, index, subject));

      // Handle times /week
      let lessonsWeek: number[] = [];
      const lessonsWeekInputConfig = {
        'Times /Week': { field: lessonsWeek },
        'Horários /Semana': { field: lessonsWeek },
      };
      columns.forEach((column, i) =>
        handleLessonsWeekInput(
          column,
          cells[i],
          handleLessonsWeek,
          index,
          lessonsWeekInputFields,
          lessonsWeekInputConfig
        )
      );

      // Process new or existing resources
      let classes: string[] = [],
        teachers: string[] = [],
        rooms: string[] = [];
      const lessonResourceImportConfig = {
        Class: { field: classes },
        Teacher: { field: teachers },
        Room: { field: rooms },
        Turma: { field: classes },
        Professor: { field: teachers },
        Sala: { field: rooms },
      };

      columns.forEach((column, i) => {
        handleLessonResourceInput(
          column,
          cells[i],
          handleLessonResource,
          index,
          lessonResourceImportFields,
          lessonResourceImportConfig
        );
      });

      // Process split
      let split: string[] = [];
      columns.forEach((column, i) => {
        if (column === t('Split')) {
          handleSplit(cells[i], index, column, split, lessonsWeek[0]);
        }
      });

      // Handle min-max input
      let daysBetweenLessons: number[] = [];

      const minMaxInputConfig = {
        'Days between Meetings': { field: daysBetweenLessons, min: 0, max: days.length - 1 },
        'Days between Lessons': { field: daysBetweenLessons, min: 0, max: days.length - 1 },
        'Dias entre Encontros': { field: daysBetweenLessons, min: 0, max: days.length - 1 },
        'Dias entre Aulas': { field: daysBetweenLessons, min: 0, max: days.length - 1 },
      };

      columns.forEach((column, i) => {
        handleMinMaxInput(column, cells[i], handleMinMaxConstraint, index, minMaxImportFields, minMaxInputConfig);
      });

      fillMinMaxMissingFields(daysBetweenLessons, 0, days.length - 1);

      // Handle array of timeslots input
      let predefinedTimes: string[] = [];
      const timeslotsInputConfig = {
        'Predefined Times': { field: predefinedTimes },
        'Horários Predefinidos': { field: predefinedTimes },
      };

      columns.forEach((column, i) => {
        handleTimeslotsInput(
          column,
          cells[i],
          handleArrayOfTimeslots,
          index,
          timeslotsImportFields,
          timeslotsInputConfig
        );
      });

      const existingLessonNames = lessons.map((l) => l.name);
      const lessonNamesToImport = getLessonNamesToImport(clipboardText, columns);

      // Process simultaneous with
      let simultaneousWith: string[] = [];
      columns.forEach((column, i) => {
        if (column === t(SIMULTANEOUS_WITH)) {
          handleSimultaneousWith(cells[i], index, column, existingLessonNames, lessonNamesToImport, simultaneousWith);
        }
      });

      // Process notSimultaneous with
      let notSimultaneousWith: string[] = [];
      columns.forEach((column, i) => {
        if (column === t(NOT_SIMULTANEOUS_WITH)) {
          handleSimultaneousWith(
            cells[i],
            index,
            column,
            existingLessonNames,
            lessonNamesToImport,
            notSimultaneousWith
          );
        }
      });

      // Process simultaneous with
      let occurBefore: string[] = [];
      let occurBeforeMinDays: number[] = [0];
      columns.forEach((column, i) => {
        if (column === t(OCCUR_BEFORE)) {
          handleOccurBefore(
            cells[i],
            index,
            column,
            existingLessonNames,
            lessonNamesToImport,
            occurBefore,
            occurBeforeMinDays
          );
        }
      });

      const lessonToImport = {
        color: '',
        photoURL: '',
        name: subject + SUBJECT_CLASS_SEPARATOR + classes.join(SUBJECT_CLASS_SEPARATOR),
        short: '',

        subject: subject,
        lessonsWeek: lessonsWeek.length > 0 ? lessonsWeek[0] : 1,
        split: split.length > 0 ? split : getDefaultSplit(lessonsWeek),
        minGapLessons: daysBetweenLessons.length > 0 ? daysBetweenLessons[0] : 0,
        maxGapLessons: daysBetweenLessons.length > 1 ? daysBetweenLessons[1] : days.length - 1,

        classes: classes,
        teachers: teachers.length === 0 || teachers[0] === '' ? [] : teachers,
        rooms: rooms.length === 0 || rooms[0] === '' ? [] : rooms,

        priority: priorityOptions[2],
        predefinedTimes: predefinedTimes,
        simultaneousWith: simultaneousWith,
        notSimultaneousWith: notSimultaneousWith,
        occurBefore: occurBefore,
        occurBeforeMinDays: occurBeforeMinDays[0],

        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
      };
      let addLesson = true;
      if (lessonToImport.subject.length !== 1) {
        warnings.push({
          line: index + 1,
          column: t('Subject'),
          description: t('Lesson has an empty Subject and will be ignored'),
          type: 'error',
        });
        addLesson = false;
      }
      if (lessonToImport.classes.length === 0) {
        warnings.push({
          line: index + 1,
          column: t('Class'),
          description: t('Lesson has no class'),
          type: 'warning',
        });
      }
      if (lessonToImport.teachers.length === 0) {
        warnings.push({
          line: index + 1,
          column: t('Teacher'),
          description: t('Lesson has no teachers'),
          type: 'warning',
        });
      }
      if (lessons.find((lesson) => lesson.name === lessonToImport.name) !== undefined) {
        warnings.push({
          line: index + 1,
          column: t('Subject'),
          description: `${t('Lesson')} ${lessonToImport.name} ${t('already exists and will be ignored')}`,
          type: 'error',
        });
        addLesson = false;
      }
      addLesson && lessonsToImport.push(lessonToImport);
    });

    setLoadingOpen(true);
    const batch = writeBatch(db);
    lessonsToImport.forEach((lessonToImport) => {
      // Add subject if it does not exists
      if (subjects.find((subject) => subject.name === lessonToImport.subject[0]) === undefined) {
        const subjectToImport = {
          color: getRandomColor(new Set(subjects.map((obj) => obj.color))),
          photoURL: '',
          name: lessonToImport.subject[0],
          short: getInitials(lessonToImport.subject[0], 4),
          priority: priorityOptions[2],
          unavailableTimes: [],
          undesiredTimes: [],
          createdAt: new Date(),
          updatedAt: new Date(),
        };
        batch.set(doc(db, subjectsPath, subjectToImport.name), subjectToImport);
      }

      // Add class if it does not exists
      lessonToImport.classes.forEach((class_) => {
        if (resources.find((resource) => resource.name === class_) === undefined) {
          const resourceToImport: IResource = {
            color: getRandomColor(new Set(resources.filter((res) => res.type === CLASS).map((obj) => obj.color))),
            photoURL: '',
            name: class_,
            short: getInitials(class_, 4),
            type: CLASS,
            minWorkload: constrBounds.minWorkingTimes,
            maxWorkload: constrBounds.maxWorkingTimes,
            minWorkingDays: constrBounds.minWorkingDays,
            maxWorkingDays: constrBounds.maxWorkingDays,
            minIdleWindow: constrBounds.minIdleWindow,
            maxIdleWindow: constrBounds.maxIdleWindow,
            minDailyWorkload: constrBounds.minDailyWorkload,
            maxDailyWorkload: constrBounds.maxDailyWorkload,
            minRestBetweenDays: constrBounds.minRestBetweenDays,
            maxRestBetweenDays: constrBounds.maxRestBetweenDays,
            minRoomChangesDay: constrBounds.minRoomsPerDay,
            maxRoomChangesDay: constrBounds.maxRoomsPerDay,
            minDistinctSubjects: constrBounds.minDistinctSubjects,
            maxDistinctSubjects: constrBounds.maxDistinctSubjects,
            minConsecutiveTimes: constrBounds.minConsecutiveTimes,
            maxConsecutiveTimes: constrBounds.maxConsecutiveTimes,
            travelTimeRooms: [],
            minTravelTime: 0,
            forbiddenCombination: [],
            priority: priorityOptions[2],
            unavailableTimes: [],
            undesiredTimes: [],
            createdAt: Timestamp.now(),
            updatedAt: Timestamp.now(),
          };
          batch.set(doc(db, resourcesPath, resourceToImport.name), resourceToImport);
        }
      });
      // Add teacher if it does not exists
      lessonToImport.teachers.forEach((teacherOptions) => {
        teacherOptions.split(RESOURCE_OPTIONS_SEPARATOR).forEach((teacher) => {
          if (teacher !== '' && !resources.find((resource) => resource.name === teacher)) {
            const resourceToImport: IResource = {
              color: getRandomColor(new Set(resources.filter((res) => res.type === TEACHER).map((obj) => obj.color))),
              photoURL: '',
              name: teacher,
              short: getInitials(teacher, 4),
              type: TEACHER,
              minWorkload: constrBounds.minWorkingTimes,
              maxWorkload: constrBounds.maxWorkingTimes,
              minWorkingDays: constrBounds.minWorkingDays,
              maxWorkingDays: constrBounds.maxWorkingDays,
              minIdleWindow: constrBounds.minIdleWindow,
              maxIdleWindow: constrBounds.maxIdleWindow,
              minDailyWorkload: constrBounds.minDailyWorkload,
              maxDailyWorkload: constrBounds.maxDailyWorkload,
              minRestBetweenDays: constrBounds.minRestBetweenDays,
              maxRestBetweenDays: constrBounds.maxRestBetweenDays,
              minRoomChangesDay: constrBounds.minRoomsPerDay,
              maxRoomChangesDay: constrBounds.maxRoomsPerDay,
              minDistinctSubjects: constrBounds.minDistinctSubjects,
              maxDistinctSubjects: constrBounds.maxDistinctSubjects,
              minConsecutiveTimes: constrBounds.minConsecutiveTimes,
              maxConsecutiveTimes: constrBounds.maxConsecutiveTimes,
              travelTimeRooms: [],
              minTravelTime: 0,
              priority: priorityOptions[2],
              forbiddenCombination: [],
              unavailableTimes: [],
              undesiredTimes: [],
              createdAt: Timestamp.now(),
              updatedAt: Timestamp.now(),
            };
            batch.set(doc(db, resourcesPath, resourceToImport.name), resourceToImport);
          }
        });
      });
      // Add room if it does not exists
      lessonToImport.rooms.forEach((roomOptions) => {
        roomOptions.split(RESOURCE_OPTIONS_SEPARATOR).forEach((room) => {
          if (room != '' && !resources.find((resource) => resource.name === room)) {
            const resourceToImport: IResource = {
              color: getRandomColor(new Set(resources.filter((res) => res.type === ROOM).map((obj) => obj.color))),
              photoURL: '',
              name: room,
              short: getInitials(room, 4),
              type: ROOM,
              minWorkload: constrBounds.minWorkingTimes,
              maxWorkload: constrBounds.maxWorkingTimes,
              minWorkingDays: constrBounds.minWorkingDays,
              maxWorkingDays: constrBounds.maxWorkingDays,
              minIdleWindow: constrBounds.minIdleWindow,
              maxIdleWindow: constrBounds.maxIdleWindow,
              minDailyWorkload: constrBounds.minDailyWorkload,
              maxDailyWorkload: constrBounds.maxDailyWorkload,
              minRestBetweenDays: constrBounds.minRestBetweenDays,
              maxRestBetweenDays: constrBounds.maxRestBetweenDays,
              minRoomChangesDay: constrBounds.minRoomsPerDay,
              maxRoomChangesDay: constrBounds.maxRoomsPerDay,
              minDistinctSubjects: constrBounds.minDistinctSubjects,
              maxDistinctSubjects: constrBounds.maxDistinctSubjects,
              minConsecutiveTimes: constrBounds.minConsecutiveTimes,
              maxConsecutiveTimes: constrBounds.maxConsecutiveTimes,
              travelTimeRooms: [],
              minTravelTime: 0,
              priority: priorityOptions[2],
              forbiddenCombination: [],
              unavailableTimes: [],
              undesiredTimes: [],
              createdAt: Timestamp.now(),
              updatedAt: Timestamp.now(),
            };

            batch.set(doc(db, resourcesPath, resourceToImport.name), resourceToImport);
          }
        });
      });
      // Add lesson
      lessonToImport.createdAt = Timestamp.now();
      lessonToImport.updatedAt = Timestamp.now();
      lessonToImport.priority =
        subjects.find((sub) => sub.name === lessonToImport.subject[0])?.priority ?? priorityOptions[2];
      batch.set(doc(db, lessonsPath, lessonToImport.name), lessonToImport);
      //Set solutions to outdated
      solutions.forEach((solution) => {
        batch.update(doc(db, `${solutionsPath}/${solution.name}`), {
          status: 'Outdated',
        });
      });
    });
    // Update file stats
    batch.update(doc(db, `users/${ownerEmail}/files/${user?.selectedFile}`), {
      updatedAt: new Date(),
    });
    batch
      .commit()
      .then(() => {
        setLoadingOpen(false);
        lessonsToImport.length > 0
          ? toast.success(`${lessonsToImport.length} ${t('lessons imported successfully')}`)
          : toast.warning(t(`No lesson imported`));
      })
      .catch(() => {
        setLoadingOpen(false);
        toast.warning(t('Data import limit per operation exceeded, please input less data at a time'));
      });

    resetStepper();
  };

  const resetStepper = () => {
    setOpen(false);
    setActiveStep(0);
  };

  const convertFileDataToClipboard = (fileData: any[]) => {
    const clipboardData = fileData
      .map((row) => row.map((cell: string[]) => (cell === null ? '' : cell)).join('\t'))
      .join('\r\n');
    return clipboardData;
  };

  const handleNext = async () => {
    if (activeStep === 1) {
      if (selectedFormat === 'Clipboard') setClipboardText(await getContentFromClipboard());
      else {
        setClipboardText(convertFileDataToClipboard(fileData));
      }
    }
    if (activeStep === 2) {
      handleProceed();
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box sx={{ width: '100%' }}>
      {loadingOpen && <SimpleBackdrop open={loadingOpen} setOpen={setLoadingOpen} />}
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <React.Fragment>
        {activeStep === 0 && (
          <ImportMethodSelection
            t={t}
            selectedFormat={selectedFormat}
            setSelectedFormat={setSelectedFormat}
            setInfoDialogOpen={setInfoDialogOpen}
          />
        )}
        {activeStep === 1 &&
          (selectedFormat === t('Clipboard') ? <ImportClipboardAnimation /> : <FileUpload setFileData={setFileData} />)}
        {activeStep === 2 && (
          <ImportContentTable
            type={type}
            message={t('Set the data to import for each column:')}
            importFields={importFields}
            setInfoDialogOpen={setInfoDialogOpen}
            maxSelectWidth={maxSelectWidth}
            gridRef={gridRef}
            handlePaste={handlePaste}
            clipboardText={clipboardText}
            setClipboardText={setClipboardText}
            columns={columns}
            setColumns={setColumns}
            ignoreFirstLine={ignoreFirstLine}
            setIgnoreFirstLine={setIgnoreFirstLine}
          />
        )}
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Button onClick={() => setOpen(false)} sx={{ mr: 1 }}>
            {t('Close')}
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
          <Button disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
            {t('Back')}
          </Button>
          <Button onClick={handleNext}>{activeStep === steps.length - 1 ? t('Confirm') : t('Next')}</Button>
        </Box>
      </React.Fragment>
    </Box>
  );
}
