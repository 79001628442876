import {
  Avatar,
  Chip,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { grey, green, red, amber } from '@mui/material/colors';
import { GridColDef } from '@mui/x-data-grid';
import { IUser } from 'contexts/SessionContext/SessionContext';
import dayjs from 'dayjs';
import { IFile } from 'pages/Files/File';
import { TFunction } from 'react-i18next';
import { ROOM, TEACHER } from 'util/configUtils';
import {
  CONSECUTIVE_TIMES,
  DAILY_WORKLOAD,
  DISTINCT_SUBJECTS,
  FORBIDDEN_COMBINATION,
  getResourcesConstrAppliesTo,
  IDLE_WINDOW,
  REST_BETWEEN_DAYS,
  ROOMS_PER_DAY,
  TRAVEL_TIME,
  WORKING_DAYS,
  WORKING_TIMES,
} from 'util/constraintUtils';
import { IResource } from '../Resource';
import { getConstrBounds } from 'util/validationUtils';

interface ResourceData {
  short: string;
  color: string;
  photoURL: string;
  id: string;
  priority: string;
  workload: string;
  workingDays: string;
  idleWindow: string;
  dailyWorkload: string;
  restBetweenDays: string;
  roomChangesDay: string;
  consecutiveTimes: string;
  distinctSubjects: string;
  travelTime: string[];
  forbiddenCombination: string[];
  availability: number;
  unavailableTimes: string[];
  undesiredTimes: string[];
  updatedAt: Date;
  type: string;
}

export function createData(
  short: string,
  color: string,
  photoURL: string,
  id: string,
  priority: string,
  workload: string,
  workingDays: string,
  idleWindow: string,
  dailyWorkload: string,
  restBetweenDays: string,
  roomChangesDay: string,
  consecutiveTimes: string,
  distinctSubjects: string,
  travelTime: string[],
  forbiddenCombination: string[],
  availability: number,
  unavailableTimes: string[],
  undesiredTimes: string[],
  updatedAt: Date,
  type: string
): ResourceData {
  return {
    short,
    color,
    photoURL,
    id,
    priority,
    workload,
    workingDays,
    idleWindow,
    restBetweenDays,
    roomChangesDay,
    dailyWorkload,
    consecutiveTimes,
    distinctSubjects,
    travelTime,
    forbiddenCombination,
    availability,
    unavailableTimes,
    undesiredTimes,
    updatedAt,
    type,
  };
}

export const useDataGrid = (
  t: TFunction<'translation', undefined>,
  file: IFile,
  user: IUser,
  type: string,
  resources: IResource[]
) => {
  const widthLessThan900 = useMediaQuery('(max-width:900px)');
  const widthLessThan600 = useMediaQuery('(max-width:600px)');
  const constrBounds = getConstrBounds(file.days.length, file.times.length);

  const columns: GridColDef[] = [
    {
      field: 'short',
      headerName: ' ',
      minWidth: 32,
      flex: 0.0001,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      hide: true,
      renderCell: (params) => {
        return (
          <Grid item container alignItems="center" justifyContent="center">
            <Avatar
              translate="no"
              sx={{
                width: 28,
                height: 28,
                fontSize: params.row.short.length > 3 ? '8px' : '12px',
                bgcolor: params.row.color,
              }}
              alt={params.value}
              src={params.row.photoURL}
            >
              {params.row.short}
            </Avatar>
          </Grid>
        );
      },
    },
    {
      field: 'id',
      headerName: t('Name'),
      flex: 2,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <Chip
            translate="no"
            variant="outlined"
            size="small"
            sx={{ backgroundColor: params.row.color, color: 'white', border: '0px' }}
            label={params.value}
          />
        );
      },
    },
    {
      field: 'priority',
      headerName: t('Priority'),
      flex: 1,
      minWidth: 100,
      hide: widthLessThan900,
    },
    {
      field: 'workload',
      headerName: t(WORKING_TIMES),
      flex: 1,
      minWidth: 100,
      hide: widthLessThan900 || getResourcesConstrAppliesTo(type, WORKING_TIMES, constrBounds, resources).length === 0,
    },
    {
      field: 'workingDays',
      headerName: t(WORKING_DAYS),
      flex: 1,
      minWidth: 100,
      hide: widthLessThan900 || getResourcesConstrAppliesTo(type, WORKING_DAYS, constrBounds, resources).length === 0,
    },
    {
      field: 'idleWindow',
      headerName: t(IDLE_WINDOW),
      flex: 1,
      minWidth: 100,
      hide: widthLessThan900 || getResourcesConstrAppliesTo(type, IDLE_WINDOW, constrBounds, resources).length === 0,
    },
    {
      field: 'dailyWorkload',
      headerName: t(DAILY_WORKLOAD),
      flex: 1,
      minWidth: 100,
      hide: widthLessThan900 || getResourcesConstrAppliesTo(type, DAILY_WORKLOAD, constrBounds, resources).length === 0,
    },
    {
      field: 'restBetweenDays',
      headerName: t(REST_BETWEEN_DAYS),
      flex: 1,
      minWidth: 100,
      hide:
        widthLessThan900 || getResourcesConstrAppliesTo(type, REST_BETWEEN_DAYS, constrBounds, resources).length === 0,
      hideable: type !== ROOM,
    },
    {
      field: 'roomChangesDay',
      headerName: t(ROOMS_PER_DAY),
      flex: 1,
      minWidth: 100,
      hide: widthLessThan900 || getResourcesConstrAppliesTo(type, ROOMS_PER_DAY, constrBounds, resources).length === 0,
      hideable: type !== ROOM,
    },
    {
      field: 'consecutiveTimes',
      headerName: t(CONSECUTIVE_TIMES),
      flex: 1,
      minWidth: 100,
      hide:
        widthLessThan900 || getResourcesConstrAppliesTo(type, CONSECUTIVE_TIMES, constrBounds, resources).length === 0,
    },
    {
      field: 'distinctSubjects',
      headerName: t(DISTINCT_SUBJECTS),
      flex: 1,
      minWidth: 100,
      hide:
        widthLessThan900 || getResourcesConstrAppliesTo(type, DISTINCT_SUBJECTS, constrBounds, resources).length === 0,
      hideable: type === TEACHER,
    },
    {
      field: 'travelTime',
      headerName: t(TRAVEL_TIME),
      flex: 1,
      minWidth: 100,
      hide: widthLessThan900 || getResourcesConstrAppliesTo(type, TRAVEL_TIME, constrBounds, resources).length === 0,
      hideable: type === ROOM,
      renderCell: (params) => {
        return params.row.travelTime.map((room: string) => {
          return <Chip translate="no" key={room} variant="outlined" label={room} />;
        });
      },
    },
    {
      field: 'forbiddenCombination',
      headerName: t(FORBIDDEN_COMBINATION),
      flex: 2,
      minWidth: 150,
      hide:
        widthLessThan900 ||
        getResourcesConstrAppliesTo(type, FORBIDDEN_COMBINATION, constrBounds, resources).length === 0,
      hideable: type !== ROOM,
      renderCell: (params) => {
        return params.row.forbiddenCombination.map((day: string) => {
          return <Chip translate="no" key={day} variant="outlined" size="small" label={day} />;
        });
      },
    },
    {
      field: 'availability',
      headerName: t('Availability'),
      flex: 1,
      minWidth: 100,
      // hide: widthLessThan600,
      renderCell: (params) => {
        const cellHeight = 28 / file.times.length;
        return (
          <TableContainer>
            <Table size="small" aria-label="Unavialable Times" padding="none">
              <TableBody>
                {file.times.map((_, t_index) => {
                  return (
                    <TableRow key={`time_${t_index}`}>
                      {file.days.map((_, d_index) => {
                        return (
                          <TableCell
                            key={`cell_${d_index}_${t_index}`}
                            sx={{
                              border: 0.5,
                              borderColor: grey[600],
                              height: `${cellHeight}px`,
                              backgroundColor: `${
                                params.row.unavailableTimes.includes('' + d_index + ':' + t_index)
                                  ? red[200]
                                  : params.row.undesiredTimes.includes('' + d_index + ':' + t_index)
                                  ? amber[200]
                                  : green[200]
                              }`,
                            }}
                            align="center"
                          ></TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        );
      },
    },
    {
      field: 'updatedAt',
      headerName: t('Updated At'),
      flex: 1,
      minWidth: 130,
      headerAlign: 'right',
      align: 'right',
      hide: widthLessThan600,
      renderCell: (params) => {
        return (
          <Typography fontSize={14}>
            {user?.countryCode === 'BR'
              ? dayjs(params.row.updatedAt).format('DD/MM/YY HH:mm[h]')
              : dayjs(params.row.updatedAt).format('MM/DD/YY hh:mma')}
          </Typography>
        );
      },
    },
  ];

  return { columns };
};
