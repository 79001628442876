import OnboardingContent from 'components/OnboardingContent';
import { IFile } from 'pages/Files/File';
import { useEffect, useState } from 'react';
import { TFunction } from 'react-i18next';

export const SOLUTION_CREATION_KEY = 'onboarding-solution-creation';
export const useSolutionOnboarding = (t: TFunction<'translation', undefined>, file: IFile) => {
  const [runSolutionCreationOnboarding, setRunSolutionCreationOnboarding] = useState(false);
  useEffect(() => {
    if (localStorage.getItem(SOLUTION_CREATION_KEY) !== 'true') {
      setRunSolutionCreationOnboarding(true);
    }
  }, []);

  const solutionCreationSteps = [
    {
      target: '.running-time',
      content: (
        <OnboardingContent
          title={t('Maximum running time')}
          message={t(
            'You can set the maximum allowed runtime. The longer Tiza runs, the more likely to find good timetables. Basic and Premium plans allow longer runtimes.'
          )}
        />
      ),
    },
    {
      target: '.advanced-configs',
      content: (
        <OnboardingContent
          title={t('Advanced settings')}
          message={t('You can change the priority of each requirement for classes, teachers, rooms, and lessons.')}
        />
      ),
    },
    {
      target: '.confirm-button',
      content: <OnboardingContent title={t('Generate')} message={t('Click here to generate a new timetable')} />,
    },
  ];

  return { runSolutionCreationOnboarding, solutionCreationSteps };
};
