import { darkTheme, lightTheme } from 'styles/material';

export const prefersDarkMode = () => {
  const localPrefersDarkMode = localStorage.getItem('user-theme');
  return localPrefersDarkMode
    ? localPrefersDarkMode === 'dark'
    : window.matchMedia('(prefers-color-scheme: dark)').matches;
};

export const getPreferedTheme = () => {
  return prefersDarkMode() ? darkTheme : lightTheme;
};

export const darkModeScrollBar = {
  /* Webkit-based browsers */
  '&::-webkit-scrollbar': {
    width: '12px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#1e1e1e',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#9F9F9F',
    borderRadius: '10px',
    border: '3px solid #1e1e1e',
  },
  /* Firefox-specific styles */
  // scrollbarWidth: 'thin',
  scrollbarColor: '#9F9F9F #1e1e1e',
  '&:hover': {
    scrollbarColor: '#c0c0c0 #1e1e1e',
  },
};
