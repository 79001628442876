import OnboardingContent from 'components/OnboardingContent';
import { IFile } from 'pages/Files/File';
import { useEffect, useState } from 'react';
import { TFunction } from 'react-i18next';

export const EMPTY_LESSONS_KEY = 'onboarding-empty-list';
export const useLessonsOnboarding = (t: TFunction<'translation', undefined>, file: IFile) => {
  const [runEmptyLessonsOnboarding, setRunEmptyLessonsOnboarding] = useState(false);

  useEffect(() => {
    if (localStorage.getItem(EMPTY_LESSONS_KEY) !== 'true') {
      setRunEmptyLessonsOnboarding(true);
    }
  }, []);

  const emptyLessonsSteps = [
    {
      target: '.lessons-list',
      disableBeacon: true,
      content: (
        <OnboardingContent title={t('List of lessons')} message={t('All lessons of your project are displayed here')} />
      ),
    },
    {
      target: '.import-button',
      content: (
        <OnboardingContent
          title={t('Import lessons')}
          message={t(
            'You can import lessons from any spreasheet software, just head to your existing spreadsheet, select the data, hit CTRL+C, then click this button'
          )}
        />
      ),
    },
    {
      target: '.create-button',
      content: (
        <OnboardingContent title={t('Add lesson')} message={t('To add a lesson manually, click CREATE button')} />
      ),
    },
  ];
  return { runEmptyLessonsOnboarding, emptyLessonsSteps };
};
