import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { DataGrid, GridSelectionModel, ptBR } from '@mui/x-data-grid';
import { doc, deleteDoc, Timestamp } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import MiniDrawer from 'components/Drawer/MiniDrawer';
import { SessionContext } from 'contexts';
import { db } from 'services';
import DraggableDialog from 'components/Dialog/DraggableDialog';
import VideoPlayerDialog from 'components/Dialog/VideoPlayerDialog';
import { HighlightAlt, QuestionMark, SearchOff } from '@mui/icons-material';
import { Typography, Grid, Paper, Divider, Button, Stack } from '@mui/material';
import { grey } from '@mui/material/colors';
import { EMPTY_SOLUTIONS_KEY, useSolutionsOnboarding } from './utils/useSolutionsOnboarding';
import Onboarding from 'components/Onboarding';
import { createData, useDataGrid } from './utils/useDataGrid';
import useEnterKey from 'hooks/useEnterKey';
import PageContainer from 'containers/PageContainer';
import dayjs from 'dayjs';
import ptBRDayJS from 'dayjs/locale/pt-br';
import { darkTheme, lightTheme } from 'styles/material';
import { PROCESSING } from 'util/solutionUtils';
import TooltipIconButton from 'components/Button/TooltipIconButton';

export default function Solutions() {
  const { t } = useTranslation();
  const history = useHistory();
  const { solutions, lessons, user, file, ownerEmail, shareMode, isDarkMode } = useContext(SessionContext);
  if (!file) {
    history.push('/files');
    toast.warning(t('Select a project first'));
  }
  const theme = isDarkMode ? darkTheme : lightTheme;
  const [pageSize, setPageSize] = React.useState<number>(100);
  const { columns } = useDataGrid(t, file!, user!);

  const readOnly = ownerEmail !== user?.email && shareMode === 'read';

  const fileId = file?.id || file?.name;

  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([]);
  const solutionsPath = 'users/' + ownerEmail + '/files/' + fileId + '/solutions';
  const { runEmptySolutionsOnboarding, emptySolutionSteps } = useSolutionsOnboarding(t);

  let rows: any[] = [];
  solutions.map(function (solution, index) {
    return rows.push(
      createData(
        solution.name,
        solution.status,
        (solution.cost === 0 ? 100 : (solution.lowerBound / solution.cost) * 100).toFixed(1) + '%',
        ((solution.assignments.length / lessons.length) * 100).toFixed(1) + '%',
        (solution.assignments.length === 0
          ? 0
          : ((solution.numConstraints - solution.defects.length) / solution.numConstraints) * 100
        ).toFixed(1) + '%',
        solution.runningTime,
        (solution.updatedAt as unknown as Timestamp).toDate()
      )
    );
  });

  const handleClick = (name: string) => {
    const solutionIsProcessing = solutions.find((solution) => solution.name === name)?.status === PROCESSING;
    if (solutionIsProcessing) {
      toast.warning(t('Timetable is still processing, please wait'));
      return;
    }
    history.push(`/solutions/${name}`);
  };

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);

  const checkDeleteConfirmation = () => {
    if (deleteConfirmed) {
      selectionModel.forEach((selectedId) => {
        deleteDoc(doc(db, solutionsPath, selectedId.toString()));
      });
      toast.success(selectionModel.length + t(' timetables deleted sucessfully'));
      setDeleteConfirmed(false);
    }
  };

  checkDeleteConfirmation();

  const [videoPlayerDialogOpen, setVideoPlayerDialogOpen] = useState(false);

  useEnterKey(handleCreate);
  function handleCreate() {
    history.push(
      `/solutions/${t('Auto')} ${
        user?.countryCode === 'BR'
          ? dayjs(new Date().toString()).locale(ptBRDayJS).format('DD [de] MMMM [de] YYYY [às] HH:mm:ss[h]')
          : dayjs(new Date().toString()).format('MMMM DD,YY[ at ]hh:mm:ssa')
      }`
    );
  }

  return (
    <PageContainer>
      <Onboarding steps={emptySolutionSteps} run={runEmptySolutionsOnboarding} storageFlagKey={EMPTY_SOLUTIONS_KEY} />
      <VideoPlayerDialog
        open={videoPlayerDialogOpen}
        setOpen={setVideoPlayerDialogOpen}
        videoURL={t('video_url_help_solutions')}
      ></VideoPlayerDialog>
      <DraggableDialog
        open={deleteConfirmationOpen}
        setOpen={setDeleteConfirmationOpen}
        setConfirmed={setDeleteConfirmed}
        title={t('Warning')}
        message={selectionModel.length + t(' timetables will be permanently deleted. Are you sure?')}
      />
      <MiniDrawer />
      <Grid container justifyContent="center">
        <Paper elevation={3} sx={{ width: '100%', maxWidth: 'lg' }}>
          <Grid container item xs={12} justifyContent={'space-between'} padding={2} spacing={1} flexDirection={'row'}>
            <Grid item flexGrow={1}>
              <Typography variant="body1" gutterBottom color={theme.palette.primary.main}>
                {'7. ' + t('Timetables')}
              </Typography>
              <Divider color={theme.palette.primary.main} />
            </Grid>
            <TooltipIconButton
              tooltip={t('Watch a helpful video')}
              Icon={QuestionMark}
              color={theme.palette.primary.main}
              onClick={() => setVideoPlayerDialogOpen(true)}
            />
            {selectionModel.length !== 0 && (
              <Grid item xs={3} md={2}>
                <Button
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled={readOnly}
                  onClick={() => setDeleteConfirmationOpen(true)}
                  color="error"
                >
                  {`${t('Delete')} (${selectionModel.length})`}
                </Button>
              </Grid>
            )}
            <Grid item xs={3} md={2}>
              <Button
                className="create-button"
                variant="contained"
                size="small"
                fullWidth
                disabled={readOnly}
                onClick={handleCreate}
                color="primary"
              >
                {t('Create')}
              </Button>
            </Grid>
          </Grid>
          <Grid className="solutions-list" item xs={12} sx={{ height: '80vh', width: '100%' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              density={'compact'}
              checkboxSelection
              disableSelectionOnClick
              onSelectionModelChange={(ids) => {
                setSelectionModel(ids);
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'updatedAt', sort: 'desc' }],
                },
              }}
              localeText={user?.countryCode === 'BR' ? ptBR.components.MuiDataGrid.defaultProps.localeText : undefined}
              onRowClick={(params) => handleClick(params.id.toString())}
              components={{
                NoRowsOverlay: () => (
                  <Stack height="100%" alignItems="center" justifyContent="center">
                    <HighlightAlt style={{ color: grey[500], textAlign: 'center' }} />
                    <Typography color={grey[500]} textAlign="center">
                      {t('Click on CREATE to add a new timetable')}
                    </Typography>
                  </Stack>
                ),
                NoResultsOverlay: () => (
                  <Stack height="100%" alignItems="center" justifyContent="center">
                    <SearchOff style={{ color: grey[500], textAlign: 'center' }} />
                    <Typography color={grey[500]} textAlign="center">
                      {t('Local FILTER returns no data')}
                    </Typography>
                  </Stack>
                ),
              }}
              sx={{
                '& .MuiDataGrid-row:hover': {
                  cursor: 'pointer',
                },
              }}
            />
          </Grid>
          <Grid item container xs={12} spacing={1} justifyContent={'center'} paddingY={1}>
            <Grid item xs={2}>
              <Button
                variant="outlined"
                size="small"
                fullWidth
                onClick={() => history.push('/lessons')}
                color="primary"
              >
                {t('Back')}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                style={{ visibility: 'hidden' }}
                variant="outlined"
                size="small"
                fullWidth
                onClick={() => history.push('/settings')}
                color="primary"
              >
                {t('Next')}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </PageContainer>
  );
}
