import { WorkspacePremium } from '@mui/icons-material';
import { SelectChangeEvent, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { BASIC, COURTESY, PREMIUM } from 'configs/planFeatures';
import { ISolution } from 'pages/Solutions/Solution';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { customColors } from 'styles';
import { mapRunningTimeLabelsToSeconds, mapRunningTimeSecondsToLabels } from 'util/configUtils';

type Props = {
  solution: ISolution;
  setSolution: Dispatch<SetStateAction<ISolution>>;
  options: string[];
  label: string;
  maxWidth?: number;
  userPlan: string;
};

export default function RuntimeSelect({ solution, setSolution, options, label, maxWidth, userPlan }: Props) {
  const { t } = useTranslation();

  const handleChange = (event: SelectChangeEvent) => {
    const newSolution = { ...solution, runningTime: mapRunningTimeLabelsToSeconds[event.target.value] };
    setSolution(newSolution);

    // setCurrent(event.target.value);
  };

  const isPremiumOnly = (option: string) => {
    return [t('30 minutes'), t('1 hour'), t('2 hours')].includes(option);
  };

  const isBasicOnly = (option: string) => {
    return [t('5 minutes'), t('10 minutes')].includes(option);
  };

  const isFree = (option: string) => {
    return [t('1 minute')].includes(option);
  };

  const planCoverFeature = (option: string, plan: string) => {
    if (plan === COURTESY) return true;
    if (isFree(option)) return true;
    if (isBasicOnly(option) && plan === BASIC) return true;
    if (isPremiumOnly(option) && plan === PREMIUM) return true;
    return false;
  };

  return (
    <FormControl fullWidth size="small">
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={t(mapRunningTimeSecondsToLabels[solution.runningTime])}
        label={label}
        sx={{ maxWidth: maxWidth ? maxWidth : 'inherit' }}
        onChange={handleChange}
      >
        {options.map((option, index) => {
          return (
            <MenuItem disabled={!planCoverFeature(option, userPlan)} key={index} value={option}>
              {isFree(option) && <WorkspacePremium sx={{ visibility: 'hidden', fontSize: 18, marginRight: 1 }} />}
              {isPremiumOnly(option) && (
                <WorkspacePremium sx={{ fontSize: 18, color: customColors.premium, marginRight: 1 }} />
              )}
              {isBasicOnly(option) && (
                <WorkspacePremium sx={{ fontSize: 18, color: customColors.basic, marginRight: 1 }} />
              )}
              {option}
              {!planCoverFeature(option, userPlan) && t(' (Upgrade plan)')}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
