import { Chip, Typography, useMediaQuery } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import LinearWithValueLabel from 'components/LinearWithValueLabel';
import { IUser } from 'contexts/SessionContext/SessionContext';
import dayjs from 'dayjs';
import { IFile } from 'pages/Files/File';
import { TFunction } from 'react-i18next';
import { EMPTY, OUTDATED, PROCESSING } from 'util/solutionUtils';

interface FileData {
  id: string;
  status: string;
  cost: string;
  numAssignments: string;
  defects: string;
  runningTime: number;
  updatedAt: Date;
}

export function createData(
  id: string,
  status: string,
  cost: string,
  numAssignments: string,
  defects: string,
  runningTime: number,
  updatedAt: Date
): FileData {
  return {
    id,
    status,
    cost,
    numAssignments,
    defects,
    runningTime,
    updatedAt,
  };
}

export const useDataGrid = (t: TFunction<'translation', undefined>, file: IFile, user: IUser) => {
  const widthLessThan900 = useMediaQuery('(max-width:900px)');
  const widthLessThan600 = useMediaQuery('(max-width:600px)');

  const getInitialProgress = (params: GridRenderCellParams<FileData, any, any>) => {
    const now = new Date().getTime();
    const updatedAt = Number(localStorage.getItem(params.row.id));
    if (params.row.status === PROCESSING) {
      return ((now - updatedAt) / 1000 / params.row.runningTime) * 100;
    }
    return 0;
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: t('Name'),
      flex: 7,
      minWidth: 120,
      renderCell: (params) => {
        return <Chip translate="no" variant="outlined" size="small" label={params.value} />;
      },
    },
    {
      field: 'status',
      headerName: t('Status'),
      flex: 2,
      minWidth: 100,
      renderCell: (params) => {
        return params.row.status === PROCESSING ? (
          <LinearWithValueLabel initialProgress={getInitialProgress(params)} maxTime={params.row.runningTime} />
        ) : (
          <Chip
            translate="no"
            size="small"
            variant="outlined"
            color={
              params.row.status === EMPTY
                ? 'error'
                : params.row.status === OUTDATED || params.row.status === PROCESSING
                ? 'warning'
                : 'success'
            }
            label={t(params.row.status)}
          />
        );
      },
    },
    {
      field: 'numAssignments',
      headerName: t('Assignments'),
      flex: 1,
      minWidth: 100,
      hide: widthLessThan900,
      headerAlign: 'right',
      align: 'right',
    },
    {
      field: 'defects',
      headerName: t('Constraints met'),
      flex: 1,
      minWidth: 150,
      hide: widthLessThan900,
      headerAlign: 'right',
      align: 'right',
    },
    {
      field: 'cost',
      headerName: t('Opt closeness'),
      flex: 1,
      minWidth: 150,
      hide: widthLessThan600,
      headerAlign: 'right',
      align: 'right',
    },
    {
      field: 'updatedAt',
      headerName: t('Updated At'),
      flex: 2,
      minWidth: 130,
      headerAlign: 'right',
      align: 'right',
      hide: widthLessThan900,
      renderCell: (params) => {
        return (
          <Typography fontSize={14}>
            {user?.countryCode === 'BR'
              ? dayjs(params.row.updatedAt).format('DD/MM/YY HH:mm[h]')
              : dayjs(params.row.updatedAt).format('MM-DD-YY hh:mma')}
          </Typography>
        );
      },
    },
  ];

  return { columns };
};
