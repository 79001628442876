import { Close, Remove, LockOpen, Lock, Error, FileUpload, DisplaySettings, FileDownload } from '@mui/icons-material';
import {
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Button,
  TableBody,
  Card,
  ToggleButton,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { TFunction } from 'react-i18next';
import { ISolution } from '../Solution';
import { Dispatch, SetStateAction, useContext } from 'react';
import { toast } from 'react-toastify';
import { SessionContext } from 'contexts';
import {
  AVAILABLE_TIME_MARKER,
  DAY_TIME_SEPARATOR,
  SUBJECT_CLASS_SEPARATOR,
  UNAVAILABLE_TIME_MARKER,
  UNDESIRED_TIME_MARKER,
} from 'util/solutionUtils';
import { darkModeScrollBar } from 'util/themeUtils';

type Props = {
  t: TFunction;
  solution: ISolution;
  selectedRes: string;
  selectedCells: string[];
  setSelectedCells: Dispatch<SetStateAction<string[]>>;
  readOnly: boolean;
  handleSelectAllClick: () => void;
  handleSelectAllDayClick: (resDayTime: string) => void;
  handleSelectAllTimeClick: (resDayTime: string) => void;
  handleTimetableCellClick: (name: string) => void;
  getAssignmentCellTextColor: (resourceName: string, cell: string) => string[];
  setDefectsOpen: Dispatch<SetStateAction<boolean>>;
  setLockConfirmationOpen: Dispatch<SetStateAction<boolean>>;
  setConfigsOpen: Dispatch<SetStateAction<boolean>>;
  setImportOpen: Dispatch<SetStateAction<boolean>>;
  setExportOpen: Dispatch<SetStateAction<boolean>>;
};

export default function IndividualTimetable({
  t,
  solution,
  selectedRes,
  selectedCells,
  setSelectedCells,
  readOnly,
  handleSelectAllClick,
  handleSelectAllDayClick,
  handleSelectAllTimeClick,
  handleTimetableCellClick,
  getAssignmentCellTextColor,
  setDefectsOpen,
  setLockConfirmationOpen,
  setConfigsOpen,
  setImportOpen,
  setExportOpen,
}: Props) {
  const { file, isDarkMode } = useContext(SessionContext);
  const days = file?.days!;
  const times = file?.times!;

  const isCellSelected = (name: string) => selectedCells.indexOf(name) !== -1;

  return (
    <Grid item xs={12} paddingTop={0}>
      <Paper elevation={3} sx={{ width: '100%', overflow: 'hidden', ...(isDarkMode ? darkModeScrollBar : {}) }}>
        <TableContainer sx={{ maxHeight: '60vh' }}>
          <Table id="ind_table" size="small" padding="none" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={{ minWidth: 80, backgroundColor: isDarkMode ? 'black' : 'white' }}>
                  <Button
                    id={`check_${0}_${0}`}
                    fullWidth={true}
                    size="small"
                    color="primary"
                    onClick={() => {
                      if (readOnly) {
                        toast.warning(t('Cannot lock timeslots in read only mode'));
                        return;
                      }
                      handleSelectAllClick();
                    }}
                  >
                    {t('Time')}
                  </Button>
                </TableCell>
                {days.map((_, d_index) => {
                  return (
                    <TableCell
                      translate="no"
                      key={`day_${d_index}`}
                      align="center"
                      sx={{ minWidth: 160, backgroundColor: isDarkMode ? 'black' : 'white' }}
                    >
                      <Button
                        value="check"
                        id={`check_${d_index}:0`}
                        translate="no"
                        fullWidth
                        size="small"
                        sx={{ color: 'primary' }}
                        onClick={() => {
                          if (readOnly) {
                            toast.warning(t('Cannot lock timeslots in read only mode'));
                            return;
                          }
                          handleSelectAllDayClick(selectedRes + DAY_TIME_SEPARATOR + d_index + ':*');
                        }}
                      >
                        {days[d_index]}
                      </Button>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {times.map((_, t_index) => {
                return (
                  <TableRow key={`time_${t_index}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{ height: 72, minWidth: 80, backgroundColor: isDarkMode ? 'black' : 'white' }}>
                      <Button
                        id={`check_${0}:${t_index}`}
                        translate="no"
                        fullWidth
                        size="small"
                        color="primary"
                        sx={{
                          height: '100%',
                        }}
                        onClick={() => {
                          if (readOnly) {
                            toast.warning(t('Cannot lock timeslots in read only mode'));
                            return;
                          }
                          handleSelectAllTimeClick(selectedRes + ':*:' + t_index);
                        }}
                      >
                        {times[t_index]}
                      </Button>
                    </TableCell>

                    {days.map((_, d_index) => {
                      const isItemSelected = isCellSelected(
                        selectedRes + DAY_TIME_SEPARATOR + d_index + DAY_TIME_SEPARATOR + t_index
                      );
                      const cell = getAssignmentCellTextColor(selectedRes, d_index + DAY_TIME_SEPARATOR + t_index);
                      const cellText = cell[0];
                      const cellColor = cell[1];
                      return (
                        <TableCell
                          key={`cell_${d_index}_${t_index}`}
                          align="center"
                          style={{ maxHeight: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                          <Card sx={{ height: 72, minWidth: 160 }}>
                            {cellText === UNAVAILABLE_TIME_MARKER ? (
                              <ToggleButton
                                value="check"
                                id={`check_${d_index}_${t_index}`}
                                fullWidth
                                size="small"
                                sx={{
                                  pointerEvents: 'none',
                                  height: '100%',
                                }}
                                selected
                              >
                                <Close sx={{ color: grey[600] }} />
                              </ToggleButton>
                            ) : cellText === UNDESIRED_TIME_MARKER ? (
                              <ToggleButton
                                value="check"
                                id={`check_${d_index}_${t_index}`}
                                fullWidth
                                size="small"
                                sx={{
                                  pointerEvents: 'none',
                                  height: '100%',
                                }}
                                selected
                              >
                                <Remove sx={{ color: grey[600] }} />
                              </ToggleButton>
                            ) : cellText === AVAILABLE_TIME_MARKER ? (
                              <ToggleButton
                                value="check"
                                id={`check_${d_index}_${t_index}`}
                                fullWidth
                                translate="no"
                                size="small"
                                sx={{
                                  pointerEvents: 'none',
                                  textTransform: 'none',
                                  fontWeight: 'normal',
                                  height: '100%',
                                }}
                                selected={false}
                              ></ToggleButton>
                            ) : (
                              <ToggleButton
                                value="check"
                                id={`check_${d_index}_${t_index}`}
                                fullWidth
                                translate="no"
                                size="small"
                                sx={{
                                  textTransform: 'none',
                                  fontWeight: 'normal',
                                  // fontSize: 12,
                                  height: '100%',
                                  color: '#ffffff',
                                  '&:hover': {
                                    backgroundColor: cellColor === '' ? 'primary' : cellColor,
                                    filter: 'brightness(1.2)',
                                  },
                                  backgroundColor: cellColor === '' ? 'primary' : cellColor,
                                }}
                                onClick={() => {
                                  if (readOnly) {
                                    toast.warning(t('Cannot lock timeslots in read only mode'));
                                    return;
                                  }
                                  handleTimetableCellClick(
                                    selectedRes + DAY_TIME_SEPARATOR + d_index + DAY_TIME_SEPARATOR + t_index
                                  );
                                }}
                                selected={isItemSelected}
                              >
                                <Typography fontSize={12} translate="no" style={{ whiteSpace: 'normal' }}>
                                  {cellText
                                    .replace(SUBJECT_CLASS_SEPARATOR + selectedRes, '')
                                    .replaceAll(SUBJECT_CLASS_SEPARATOR, ' • ')}
                                </Typography>
                                {isItemSelected && (
                                  <Lock
                                    sx={{
                                      fontSize: 14,
                                      marginLeft: 0.25,
                                      justifyContent: 'flex-end',
                                      alignSelf: 'flex-end',
                                    }}
                                  />
                                )}
                              </ToggleButton>
                              // </Tooltip>
                            )}
                          </Card>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Grid container spacing={1} justifyContent="flex-end" alignItems="flex-end" paddingTop={1}>
        <Grid item xs={6} md={2}>
          <Button
            className="defects-button"
            startIcon={<Error />}
            variant="text"
            size="small"
            onClick={() => setDefectsOpen(true)}
            color={solution.defects.length !== 0 ? 'error' : 'primary'}
            fullWidth
          >
            {`${t('Defects')}${solution.defects.length !== 0 ? ` (${solution.defects.length})` : ''}`}
          </Button>
        </Grid>
        <Grid item xs={6} md={2}>
          <Button
            className="lock-button"
            startIcon={<Lock />}
            variant="text"
            size="small"
            disabled={readOnly}
            onClick={() => setLockConfirmationOpen(true)}
            color="primary"
            fullWidth
          >
            {t('Lock') + (selectedCells.length === 0 ? '' : ` (${selectedCells.length})`)}
          </Button>
        </Grid>
        <Grid item xs={6} md={2}>
          <Button
            startIcon={<LockOpen />}
            variant="text"
            size="small"
            disabled={readOnly}
            onClick={() => {
              if (selectedCells.length === 0) toast.warning(t('All assignments are already unlocked'));
              else toast.success(t('All assignments unlocked'));
              setSelectedCells([]);
            }}
            color="primary"
            fullWidth
          >
            {t('Unlock')}
          </Button>
        </Grid>
        <Grid item xs={6} md={2}>
          <Button
            startIcon={<FileUpload />}
            variant="text"
            size="small"
            disabled={readOnly}
            onClick={() => setImportOpen(true)}
            color="primary"
            fullWidth
          >
            {t('Import')}
          </Button>
        </Grid>
        <Grid item xs={6} md={2}>
          <Button
            startIcon={<FileDownload />}
            variant="text"
            size="small"
            disabled={readOnly}
            onClick={() => setExportOpen(true)}
            color="primary"
            fullWidth
          >
            {t('Export')}
          </Button>
        </Grid>
        <Grid item xs={6} md={2}>
          <Button
            className="configs-button"
            startIcon={<DisplaySettings />}
            variant="text"
            size="small"
            disabled={readOnly}
            onClick={() => setConfigsOpen(true)}
            color="primary"
            fullWidth
          >
            {t('Configs')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
