import { Box, colors, Grid, Paper, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { testimonials } from 'util/homeUtils';
import TestmonialCard from './TestmonialCard';

export default function Testmonials() {
  const { t } = useTranslation();
  return (
    <Grid container item xs={12} justifyContent="center">
      <Box sx={{ backgroundColor: colors.grey[100] }} paddingInline={'10%'} paddingTop={'64px'} paddingBottom={'80px'}>
        <Grid container spacing={4} padding={4} justifyContent="space-around">
          <Grid container item xs={12} paddingTop={4} justifyContent="center">
            <Typography variant="h3" color={grey[900]} align="center" display="inline">
              {t('What our users say')}
            </Typography>
          </Grid>
          {testimonials.map((testmonial, idx) => {
            return (
              <TestmonialCard
                key={idx}
                t={t}
                imgPath={testmonial.imgPath}
                personName={testmonial.pearsonName}
                position={testmonial.position}
                message={testmonial.message}
              />
            );
          })}
        </Grid>
      </Box>
    </Grid>
  );
}
