import { grey } from '@mui/material/colors';
import { SessionContext } from 'contexts';
import { useContext } from 'react';
import { TFunction } from 'react-i18next';
import { theme } from 'styles';
import {
  AVAILABLE_TIME_MARKER,
  cellIsUnavailableOrUndesired,
  EXCEL_UNAVAILABLE_TIME_MARKER,
  EXCEL_UNDESIRED_TIME_MARKER,
  SUBJECT_CLASS_SEPARATOR,
  UNAVAILABLE_TIME_MARKER,
  UNDESIRED_TIME_MARKER,
} from 'util/solutionUtils';

type Props = {
  t: TFunction<'translation', undefined>;
  resourceName: string;
  getAssignmentCellTextColor: (resourceName: string, cell: string) => string[];
};

export default function ResourceExcelTimetable({ t, resourceName: resource, getAssignmentCellTextColor }: Props) {
  const { file } = useContext(SessionContext);

  return (
    <table id="toExcel">
      <thead>
        <tr>
          <th
            align="center"
            colSpan={1 + file!.days.length}
            style={{
              minWidth: 80 + file!.days.length * 160,
              backgroundColor: theme.palette.primary.main,
              color: grey[50],
              border: '1px solid black',
            }}
          >
            {resource}
          </th>
        </tr>
        <tr>
          <th align="center" style={{ minWidth: 80, color: theme.palette.primary.main }}>
            {t('TIME')}
          </th>
          {file!.days.map((day, d_index) => {
            return (
              <th key={`day_${d_index}`} align="center" style={{ minWidth: 160, color: theme.palette.primary.main }}>
                {file!.days[d_index]}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {file!.times.map((time, t_index) => {
          return (
            <tr key={`time_${t_index}`}>
              <th style={{ height: 72, minWidth: 80, color: theme.palette.primary.main }}>{file!.times[t_index]}</th>
              {file!.days.map((day, d_index) => {
                const cell = getAssignmentCellTextColor(resource, d_index + ':' + t_index);
                const cellText = cell[0];
                const cellColor = cell[1];
                return (
                  <th
                    key={`cell_${d_index}_${t_index}`}
                    align="center"
                    style={{
                      height: 72,
                      minWidth: 160,
                      border: '1px solid black',
                      padding: 1,
                      backgroundColor: cellIsUnavailableOrUndesired(cellText) ? grey[200] : cellColor,
                      color: cellIsUnavailableOrUndesired(cellText) ? grey[600] : grey[50],
                    }}
                  >
                    {cellText === UNAVAILABLE_TIME_MARKER
                      ? EXCEL_UNAVAILABLE_TIME_MARKER
                      : cellText === UNDESIRED_TIME_MARKER
                      ? EXCEL_UNDESIRED_TIME_MARKER
                      : cellText === AVAILABLE_TIME_MARKER
                      ? ' '
                      : cellText
                          .replace(SUBJECT_CLASS_SEPARATOR + resource, '')
                          .replaceAll(SUBJECT_CLASS_SEPARATOR, ' • ')}
                  </th>
                );
              })}
            </tr>
          );
        })}
      </tbody>
      <tr></tr>
    </table>
  );
}
