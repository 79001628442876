import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import SelectAllTransferList from 'components/SelectAllTransferList';
import DialogContainer from 'containers/DialogContainer';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  title: string;
  message: string;
  selected: string[];
  setSelected: Dispatch<SetStateAction<string[]>>;
  options: string[];
};
export default function SelectAllDialog({ open, setOpen, title, message, selected, setSelected, options }: Props) {
  const { t } = useTranslation();

  const previouslySelected = [...selected];

  const handleClose = () => {
    setSelected(previouslySelected);
    setOpen(false);
  };

  const handleConfirm = () => {
    setOpen(false);
  };

  return (
    <div>
      <DialogContainer maxWidth="lg" open={open} onClose={handleClose}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText paddingBlock={1}>{message}</DialogContentText>
          <SelectAllTransferList selected={selected} setSelected={setSelected} options={options} />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            {t('Close')}
          </Button>
          <Button autoFocus onClick={handleConfirm}>
            {t('Confirm')}
          </Button>
        </DialogActions>
      </DialogContainer>
    </div>
  );
}
