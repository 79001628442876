import { grey } from '@mui/material/colors';
import { SessionContext } from 'contexts';
import React, { useContext } from 'react';
import { TFunction } from 'react-i18next';
import { theme } from 'styles';
import {
  AVAILABLE_TIME_MARKER,
  cellIsUnavailableOrUndesired,
  DAY_TIME_SEPARATOR,
  EXCEL_UNAVAILABLE_TIME_MARKER,
  EXCEL_UNDESIRED_TIME_MARKER,
  SUBJECT_CLASS_SEPARATOR,
  UNAVAILABLE_TIME_MARKER,
  UNDESIRED_TIME_MARKER,
} from 'util/solutionUtils';

type Props = {
  t: TFunction<'translation', undefined>;
  resourceType: string;
  getAssignmentCellTextColor: (resourceName: string, cell: string) => string[];
};

export default function ResourceTypeExcelTimetable({ t, resourceType, getAssignmentCellTextColor }: Props) {
  const { file, resources, subjects } = useContext(SessionContext);

  return (
    <table id="toExcel">
      <thead>
        <tr>
          <th
            align="center"
            colSpan={1 + file!.days.length * file!.times.length}
            style={{
              minWidth: 80 + file!.days.length * 160,
              backgroundColor: theme.palette.primary.main,
              color: grey[50],
              border: '1px solid black',
            }}
          >
            {t(resourceType).toUpperCase()}
          </th>
        </tr>
        <tr>
          <th align="center" style={{ minWidth: 32, borderRight: `2px solid ${grey[300]}` }}>
            {''}
          </th>
          {file!.days.map((day, d_index) => {
            return (
              <th
                translate="no"
                key={`header${d_index}`}
                align="center"
                style={{
                  minWidth: 32 * file!.times.length,
                  borderRight: `2px solid ${grey[300]}`,
                  color: theme.palette.primary.main,
                }}
                colSpan={file!.times.length}
              >
                {file!.days[d_index]}
              </th>
            );
          })}
        </tr>
        <tr>
          <th
            align="center"
            style={{ minWidth: 32, borderRight: `2px solid ${grey[300]}`, color: theme.palette.primary.main }}
          >
            {t(resourceType).toUpperCase()}
          </th>
          {file!.days.map((day, d_index) => {
            return file!.times.map((time, t_index) => {
              return (
                <th
                  key={`header${d_index}_${t_index}`}
                  align="center"
                  style={{
                    minWidth: 32,
                    borderRight: t_index + 1 === file!.times.length ? `2px solid ${grey[300]}` : '',
                    color: theme.palette.primary.main,
                  }}
                >
                  {file!.times[t_index]}
                </th>
              );
            });
          })}
        </tr>
      </thead>
      <tbody>
        {resources
          .filter((res) => res.type === resourceType)
          .map((res, r_index) => {
            return (
              <tr key={`res_${r_index}`}>
                <th
                  style={{
                    // height: 32,
                    minWidth: 32,
                    borderRight: `2px solid ${grey[300]}`,
                    color: theme.palette.primary.main,
                  }}
                >
                  {res.short}
                </th>
                {file!.days.map((day, d_index) => {
                  return file!.times.map((time, t_index) => {
                    const cell = getAssignmentCellTextColor(res.name, d_index + DAY_TIME_SEPARATOR + t_index);
                    const cellText = cell[0];
                    const cellColor = cell[1];
                    return (
                      <th
                        key={`cell_${d_index}_${t_index}`}
                        align="center"
                        style={{
                          // height: 32,
                          minWidth: 160,
                          border: '1px solid black',
                          padding: 1,
                          backgroundColor: cellIsUnavailableOrUndesired(cellText) ? grey[200] : cellColor,
                          color: cellIsUnavailableOrUndesired(cellText) ? grey[600] : grey[50],
                        }}
                      >
                        {cellText === UNAVAILABLE_TIME_MARKER
                          ? EXCEL_UNAVAILABLE_TIME_MARKER
                          : cellText === UNDESIRED_TIME_MARKER
                          ? EXCEL_UNDESIRED_TIME_MARKER
                          : cellText === AVAILABLE_TIME_MARKER
                          ? ' '
                          : subjects.find((sub) => sub.name === cellText.split(SUBJECT_CLASS_SEPARATOR)[0])?.short}
                      </th>
                    );
                  });
                })}
              </tr>
            );
          })}
        <tr></tr>
      </tbody>
    </table>
  );
}
