import { TFunction } from 'react-i18next';
import { toast } from 'react-toastify';

export const invalidCharsForName = ['/', '\\', '|', ';', '#', '+', '=', '_', '{', '}', '&'];
export const MAX_NAME_LENGTH = 45;

export function validateNameInput(
  t: TFunction<'translation', undefined>,
  name: string,
  previousName: string,
  exsitingNames: string[]
): boolean {
  if (name.length === 0) {
    toast.error(t('Name cannot be empty'));
    return false;
  } else if (name.length >= MAX_NAME_LENGTH) {
    toast.error(`${t('Name cannot have more than')} ${MAX_NAME_LENGTH} ${t('chars!')}`);
    return false;
  } else if (previousName.length === 0 && exsitingNames.includes(name)) {
    toast.error(t('Unique name already registered!'));
    return false;
  } else if (invalidCharsForName.some((char) => name.includes(char))) {
    toast.error(`${'Name cannot contain'}: ${invalidCharsForName.join(' ')}`);
    return false;
  } else return true;
}

export interface IConstrBounds {
  minWorkingTimes: number;
  maxWorkingTimes: number;
  minWorkingDays: number;
  maxWorkingDays: number;
  minIdleWindow: number;
  maxIdleWindow: number;
  minDailyWorkload: number;
  maxDailyWorkload: number;
  minRestBetweenDays: number;
  maxRestBetweenDays: number;
  minRoomsPerDay: number;
  maxRoomsPerDay: number;
  minConsecutiveTimes: number;
  maxConsecutiveTimes: number;
  minDistinctSubjects: number;
  maxDistinctSubjects: number;
  minDaysBetweenLessons: number;
  maxDaysBetweenLessons: number;
  minTravelTime: number;
}

export const getConstrBounds = (numDays: number, numTimes: number) => {
  const CONSTR_BOUNDS: IConstrBounds = {
    minWorkingTimes: 0,
    maxWorkingTimes: numDays * numTimes,
    minWorkingDays: 0,
    maxWorkingDays: numDays,
    minIdleWindow: 0,
    maxIdleWindow: numDays * (numTimes - 2),
    minDailyWorkload: 0,
    maxDailyWorkload: numTimes,
    minRestBetweenDays: 0,
    maxRestBetweenDays: numTimes * 2 - 2,
    minRoomsPerDay: 0,
    maxRoomsPerDay: numTimes - 1,
    minConsecutiveTimes: 0,
    maxConsecutiveTimes: numTimes,
    minDistinctSubjects: 0,
    maxDistinctSubjects: numDays * numTimes,
    minDaysBetweenLessons: 0,
    maxDaysBetweenLessons: numDays - 1,
    minTravelTime: 0,
  };
  return CONSTR_BOUNDS;
};
