'use client';
import { Grid, Typography, useMediaQuery, Box, colors } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function Footer() {
  const { t } = useTranslation();
  const currentDate: Date = new Date();
  const widthLessThan600 = useMediaQuery('(max-width:600px)');

  return (
    <Grid container item xs={12} justifyContent="center" alignContent={'center'} paddingTop={8}>
      <Box width="100vw" padding={1} borderTop={`1px solid ${colors.grey[800]}`}>
        <Grid container spacing={1} padding={2} justifyContent="space-between" alignContent={'center'}>
          <Grid item xs={4}>
            <Typography variant="body2" color={colors.grey[400]}>
              © 2024 Tiza. All rights reserved.
            </Typography>
          </Grid>
          <Grid container item xs={4} justifyContent="flex-end">
            <Typography variant="body2" sx={{ color: grey[200] }}>
              <Link to={'terms'} style={{ color: 'inherit', paddingRight: 12 }}>
                {t('Terms of Service')}
              </Link>
              <Link to={'privacy'} style={{ color: 'inherit' }}>
                {t('Privacy')}
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}
