import React, { useContext, useEffect, useMemo, useState } from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { toast } from 'react-toastify';
import { doc, setDoc, deleteDoc, updateDoc, getDoc, Timestamp } from '@firebase/firestore';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import ptBR from 'dayjs/locale/pt-br';

import { db } from 'services';
import { SessionContext } from 'contexts';
import DraggableDialog from 'components/Dialog/DraggableDialog';
import LinearWithValueLabel from 'components/LinearWithValueLabel';
import DefectsDialog from './components/DefectsDialog';
import ConfigsDialog, { DisplayTypesMap, SolverTypesMap } from './components/ConfigsDialog';
import ResourcesSoln from './components/ResourcesSoln';
import ExportDialog from './components/ExportDialog';
import PlanInfoDialog from 'components/Dialog/PlanInfoDialog';
import { BASIC, COURTESY, FREE, planFeatures } from 'configs/planFeatures';
import VideoPlayerDialog from 'components/Dialog/VideoPlayerDialog';
import { ArrowBack, AutoAwesome, Check, Error, QuestionMark, Warning, WorkspacePremium } from '@mui/icons-material';
import {
  useMediaQuery,
  Box,
  Grid,
  Paper,
  Typography,
  Tooltip,
  IconButton,
  Divider,
  Button,
  Accordion,
  AccordionSummary,
  DialogContentText,
  AccordionDetails,
  Card,
  CardContent,
  Tabs,
  Tab,
  colors,
  Alert,
  Stack,
} from '@mui/material';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import {
  arraysHaveSameElements,
  autoDownloadFile,
  AVAILABLE_TIME_MARKER,
  COMPACT,
  DAY_TIME_SEPARATOR,
  EMPTY,
  FAST,
  getPlanRuntimeSuggestion,
  getRuntimeSuggestion,
  handleExportCsv,
  handleExportXls,
  INDIVIDUAL_TABLES,
  TIZA,
  OPTIMAL,
  OUTDATED,
  PROCESSING,
  SUBJECT_CLASS_SEPARATOR,
  UNAVAILABLE_TIME_MARKER,
  UNDESIRED_TIME_MARKER,
  UP_TO_DATE,
} from 'util/solutionUtils';
import { IResource } from 'pages/Resources/Resource';
import Onboarding from 'components/Onboarding';
import { SOLUTION_CREATION_KEY, useSolutionOnboarding } from './utils/useSolutionOnboarding';
import { customColors } from 'styles';
import IndividualTimetable from './components/IndividualTimetable';
import CompactTimetable from './components/CompactTimetable';
import useTimeslotSelection from 'util/useTimeslotSelection';
import TimetableHeader from './components/TimetableHeader';
import EmptyTimetable from './components/EmptyTimetable';
import ImportStepperDialog from 'components/Dialog/ImportStepperDialog';
import { LoadingButton } from '@mui/lab';
import { mapRunningTimeSecondsToLabels } from 'util/configUtils';
import MiniDrawer from 'components/Drawer/MiniDrawer';
import PageContainer from 'containers/PageContainer';
import { darkTheme, lightTheme } from 'styles/material';
import { environment } from 'configs/environment';
import { darkModeScrollBar } from 'util/themeUtils';
import TooltipIconButton from 'components/Button/TooltipIconButton';

export interface IPublicSolution {
  fileName: string;
  solutionUrl: string;
  url: string;
}

export interface IAssignment {
  lessonRef: string;
  resources: string[];
  timeslots: string[];
  // timeslotsLocked: string[];
}

export interface IAssignmentImport {
  lessonRef: string;
  subject: string[];
  classes: string[];
  teachers: string[];
  rooms: string[];
  timeslots: string[];
}

export interface IDefect {
  appliesTo: string;
  description: string;
  type: string;
  times: number;
  weight: number;
}

export interface ICustomConstraint {
  type: string;
  appliesTo: string;
  priority: string;
  costFunction: string;
  entity: string;
}
export interface ISolution {
  assignments: IAssignment[];
  lockedAssignments: string[];
  defects: IDefect[];

  cost: number;
  creator: string;
  randomSeed: number;
  remarks: string;
  runningTime: number;
  solver: string;
  threads: number;
  warmStartCost: number;
  status: string;
  lowerBound: number; // Model proven minimum possible cost
  numConstraints: number;
  display: string;

  name: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;

  clashesClasses: string;
  workloadClasses: string;
  workingDaysClasses: string;
  idleWindowClasses: string;
  dailyWorkloadClasses: string;
  distinctSubjectsClasses: string;
  forbiddenCombinationClasses: string;
  unavailableTimesClasses: string;
  undesiredTimesClasses: string;

  clashesTeachers: string;
  workloadTeachers: string;
  workingDaysTeachers: string;
  idleWindowTeachers: string;
  dailyWorkloadTeachers: string;
  distinctSubjectsTeachers: string;
  forbiddenCombinationTeachers: string;
  unavailableTimesTeachers: string;
  undesiredTimesTeachers: string;

  clashesRooms: string;
  workloadRooms: string;
  workingDaysRooms: string;
  idleWindowRooms: string;
  dailyWorkloadRooms: string;
  distinctSubjectsRooms: string;
  forbiddenCombinationRooms: string;
  unavailableTimesRooms: string;
  undesiredTimesRooms: string;

  unavailableTimesSubjects: string;
  undesiredTimesSubjects: string;
  assignTimes: string;
  assignResources: string;
  split: string;
  daysBetweenLessons: string;
  predefinedTimes: string;
  simultaneousWith: string;
  notSimultaneousWith: string;
  occurBefore: string;

  restBetweenDaysClasses: string;
  roomChangesDayClasses: string;
  consecutiveTimesClasses: string;
  restBetweenDaysTeachers: string;
  roomChangesDayTeachers: string;
  consecutiveTimesTeachers: string;
  consecutiveTimesRooms: string;
  travelTimeRooms: string;

  customConstraints?: ICustomConstraint[];
}

interface RouteParams {
  solution_name: string;
}
export default function Solution() {
  const { t } = useTranslation();
  const history = useHistory();
  const { user, file, resources, subjects, lessons, solutions, ownerEmail, shareMode, userPlan, isDarkMode } =
    useContext(SessionContext);
  if (!file) {
    history.push('/files');
    toast.warning(t('Select a project first'));
  }
  const theme = isDarkMode ? darkTheme : lightTheme;
  const { solution_name } = useParams<RouteParams>();

  const priorityOptions = ['Disabled', 'Very low', 'Low', 'Average', 'High', 'Very high'];
  const emptySolution: ISolution = {
    assignments: [],
    lockedAssignments: [],
    defects: [],

    cost: -1,
    creator: TIZA,
    randomSeed: -1,
    remarks: '',
    runningTime: getPlanRuntimeSuggestion(userPlan, lessons.length),
    solver: FAST,
    threads: -1,
    warmStartCost: -1,
    status: EMPTY,
    lowerBound: 0,
    numConstraints: -1,
    display: INDIVIDUAL_TABLES,

    name: solution_name,
    createdAt: Timestamp.now(),
    updatedAt: Timestamp.now(),

    clashesClasses: priorityOptions[5],
    workloadClasses: priorityOptions[5],
    workingDaysClasses: priorityOptions[3],
    idleWindowClasses: priorityOptions[3],
    dailyWorkloadClasses: priorityOptions[3],
    distinctSubjectsClasses: priorityOptions[3],
    forbiddenCombinationClasses: priorityOptions[3],
    unavailableTimesClasses: priorityOptions[5],
    undesiredTimesClasses: priorityOptions[3],
    restBetweenDaysClasses: priorityOptions[3],
    roomChangesDayClasses: priorityOptions[3],
    consecutiveTimesClasses: priorityOptions[3],

    clashesTeachers: priorityOptions[5],
    workloadTeachers: priorityOptions[5],
    workingDaysTeachers: priorityOptions[3],
    idleWindowTeachers: priorityOptions[3],
    dailyWorkloadTeachers: priorityOptions[3],
    distinctSubjectsTeachers: priorityOptions[3],
    forbiddenCombinationTeachers: priorityOptions[3],
    unavailableTimesTeachers: priorityOptions[3],
    undesiredTimesTeachers: priorityOptions[3],
    restBetweenDaysTeachers: priorityOptions[3],
    roomChangesDayTeachers: priorityOptions[3],
    consecutiveTimesTeachers: priorityOptions[3],

    clashesRooms: priorityOptions[5],
    workloadRooms: priorityOptions[5],
    workingDaysRooms: priorityOptions[3],
    idleWindowRooms: priorityOptions[3],
    dailyWorkloadRooms: priorityOptions[3],
    distinctSubjectsRooms: priorityOptions[3],
    forbiddenCombinationRooms: priorityOptions[3],
    unavailableTimesRooms: priorityOptions[5],
    undesiredTimesRooms: priorityOptions[3],
    consecutiveTimesRooms: priorityOptions[3],
    travelTimeRooms: priorityOptions[3],

    unavailableTimesSubjects: priorityOptions[5],
    undesiredTimesSubjects: priorityOptions[3],
    assignTimes: priorityOptions[5],
    assignResources: priorityOptions[5],
    split: priorityOptions[5],
    daysBetweenLessons: priorityOptions[5],
    predefinedTimes: priorityOptions[5],
    simultaneousWith: priorityOptions[5],
    notSimultaneousWith: priorityOptions[5],
    occurBefore: priorityOptions[5],
  };

  const loadedSolution = solutions.find((solution) => solution.name === solution_name) || emptySolution;

  const [solution, setSolution] = React.useState<ISolution>(loadedSolution);
  const [showPlanLimitDialog, setShowPlanLimitDialog] = useState(false);
  const [showRegenerateLimitDialog, setShowRegenerateLimitDialog] = useState(false);
  const widthLessThan1500 = useMediaQuery('(max-width:1500px)');
  const { runSolutionCreationOnboarding, solutionCreationSteps } = useSolutionOnboarding(t, file!);
  const [isLoadingCopy, setIsLoadingCopy] = useState(false);

  const readOnly = ownerEmail !== user?.email && shareMode === 'read';

  const fileId = file?.id || file?.name;
  const solutionsPath = 'users/' + ownerEmail + '/files/' + fileId + '/solutions';

  const days = file?.days!;
  const times = file?.times!;

  const [selectedRes, setSelectedRes] = React.useState<string>(
    resources === undefined || resources.filter((res) => res.type === 'class').length === 0
      ? ''
      : resources
          .filter((res) => res.type === 'class')
          .sort((a, b) => {
            return a.name > b.name ? 1 : -1;
          })[0].name
  );

  const [previousName, setPreviousName] = React.useState<string>(
    solution && solution.name
      ? solution.name
      : `${t('Auto')} ${
          user?.countryCode === 'BR'
            ? dayjs(new Date().toString()).locale(ptBR).format('DD [de] MMMM [de] YYYY [às] HH:mm[h]')
            : dayjs(new Date().toString()).format('MMMM DD,YY[ at ]hh:mma')
        }`
  );

  const runtimeSuggestion = getRuntimeSuggestion(lessons.length);

  const checkRuntimeSuggestion = () => {
    if (userPlan === COURTESY) return;
    if (
      !showPlanLimitDialog &&
      // !planLimitDialogShown &&
      ((userPlan === FREE && runtimeSuggestion > planFeatures.free.maxRuntime) ||
        (userPlan === BASIC && runtimeSuggestion > planFeatures.basic.maxRuntime))
    ) {
      setShowPlanLimitDialog(true);
    }
  };

  let solverTypesMap: SolverTypesMap = {};
  solverTypesMap[t('Optimal')] = OPTIMAL;
  solverTypesMap[t('Fast')] = FAST;

  let displayTypesMap: DisplayTypesMap = {};
  displayTypesMap[t('Compact')] = COMPACT;
  displayTypesMap[t('Individual tables')] = INDIVIDUAL_TABLES;

  function afterSolve() {
    const docRef = doc(db, solutionsPath, solution.name);
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        const newSolution = docSnap.data() as ISolution;
        localStorage.removeItem(newSolution.name);

        if (newSolution.status === EMPTY || newSolution.status === PROCESSING) {
          toast.error(t('Something went wrong, we could not generate the timetables'));
          if (newSolution.assignments.length === 0) {
            newSolution.status = EMPTY;
          }
          setSolution(newSolution);
          setDoc(docRef, newSolution);
          return;
        }
        toast.success(t('Your timetable is ready'));
        setSolution(newSolution);
      } else {
        toast.error(t('No such document'));
      }
    });
  }

  async function runSolver() {
    const path = (solutionsPath + '/' + solution.name).replaceAll(' ', '_');
    const axios = require('axios').default;
    axios
      .get(`${environment.solverApiUrl}/?path=${path}`)
      .then(function (response: any) {
        // handle success
        // afterSolve();
      })
      .catch(function (error: any) {
        // handle error
        // afterSolve('ERROR');
      })
      .finally(() => {
        // always executed
        afterSolve();
      });
  }

  const solve = () => {
    localStorage.setItem(solution.name, new Date().getTime() + '');
    runSolver();
    toast.success(
      t('Timetable will be displayed within') + ' ' + t(mapRunningTimeSecondsToLabels[solution.runningTime])
    );
  };

  const copy = () => {
    setIsLoadingCopy(true);
    try {
      //Validation and newName adjustments
      const newName: string = t('Copy of ') + solution.name;
      if (solutions.map((solution) => solution.name).includes(newName)) {
        toast.error(newName + t(' already registered'));
        return;
      }
      let newSolution: ISolution = { ...solution };
      newSolution.createdAt = Timestamp.now();
      newSolution.updatedAt = Timestamp.now();
      newSolution.name = newName;

      setDoc(doc(db, solutionsPath, newName), newSolution).then(() => {
        toast.success(solution.name + t(' copied sucessfully'));
        history.push('/solutions');
      });
      // Update file stats
      updateDoc(doc(db, `users/${ownerEmail}/files/${user?.selectedFile}`), {
        updatedAt: new Date(),
      });
    } catch (error: any) {
      toast.error(`${error?.message?.split(':').slice(-1)[0].trim() ?? t('An error has occurred')}`);
      console.error({ error });
    }
  };

  //#region States and Tables
  const [selectedTab, setSelectedTab] = useState(0);

  const cells = useMemo(() => {
    let updatedCells: string[] = [];
    resources.forEach((res, _) => {
      if (solution.display === COMPACT) {
        times.forEach((_, t_index) => {
          days.forEach((_, d_index) => {
            updatedCells.push(res.name + DAY_TIME_SEPARATOR + d_index + DAY_TIME_SEPARATOR + t_index);
          });
        });
      } else {
        if (res.name === selectedRes) {
          times.forEach((_, t_index) => {
            days.forEach((_, d_index) => {
              updatedCells.push(res.name + DAY_TIME_SEPARATOR + d_index + DAY_TIME_SEPARATOR + t_index);
            });
          });
        }
      }
    });
    return updatedCells;
  }, [resources, solution.display, times, days, selectedRes]);

  const {
    selectedCells,
    setSelectedCells,
    handleSelectAllCellsClick,
    handleSelectAllDayClick,
    handleTimetableCellClick,
    handleSelectAllResources,
    handleSelectAllDayTimeClick,
    handleSelectAllTimeClick,
  } = useTimeslotSelection(cells, solution);

  //#endregion

  const [configsOpen, setConfigsOpen] = useState(solution.status === EMPTY);
  const [configsConfirmed, setConfigsConfirmed] = useState(false);

  const checkConfigsConfirmation = () => {
    if (configsConfirmed) {
      setConfigsConfirmed(false);
      setDoc(doc(db, solutionsPath, solution.name), solution).then(() => {
        updateDoc(doc(db, solutionsPath + '/' + solution.name), {
          updatedAt: new Date(),
        }).then(() => {
          //Delete old record and update references if name has changed
          if (previousName !== '' && previousName !== solution.name) {
            deleteDoc(doc(db, solutionsPath, previousName));
          }
          setPreviousName(solution.name);
        });
      });
      solve();
    }
  };

  checkConfigsConfirmation();

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);

  const checkDeleteConfirmation = () => {
    if (deleteConfirmed) {
      deleteDoc(doc(db, solutionsPath, solution.name));
      setDeleteConfirmed(false);
      toast.success(solution.name + t(' deleted sucessfully'));
      history.push('/solutions');
      // Update file stats
      updateDoc(doc(db, `users/${ownerEmail}/files/${user?.selectedFile}`), {
        updatedAt: new Date(),
      });
    }
  };

  checkDeleteConfirmation();

  const [lockConfirmationOpen, setLockConfirmationOpen] = useState(false);
  const [lockConfirmed, setLockConfirmed] = useState(false);

  const checkLockConfirmation = () => {
    if (lockConfirmed) {
      handleSelectAllCellsClick();
      setLockConfirmed(false);
      toast.success(t('All assignments locked'));
    }
  };

  checkLockConfirmation();

  const [defectsOpen, setDefectsOpen] = useState(false);

  let resourcesDict: string[] = [];

  function processResourceAvailability(resource: IResource) {
    const resourceAvailability: string[][] = [];
    days.forEach((_, d_index) => {
      const dayAvailability: string[] = [];
      times.forEach((_, t_index) => {
        const dayTime: string = d_index + DAY_TIME_SEPARATOR + t_index;
        if (resource.unavailableTimes.includes(dayTime)) {
          dayAvailability.push(UNAVAILABLE_TIME_MARKER);
        } else if (resource.undesiredTimes.includes(dayTime)) {
          dayAvailability.push(UNDESIRED_TIME_MARKER);
        } else {
          dayAvailability.push(AVAILABLE_TIME_MARKER);
        }
      });
      resourceAvailability.push(dayAvailability);
    });
    return resourceAvailability;
  }

  function processAvailability() {
    const resourcesAvailability: string[][][] = [];
    resources.forEach((resource) => {
      resourcesDict.push(resource.name);
      const resourceAvailability = processResourceAvailability(resource);
      resourcesAvailability.push(resourceAvailability);
    });
    return resourcesAvailability;
  }

  function processAssignments(resourcesAvailability: string[][][]) {
    solution.assignments.forEach((assignment) => {
      const lessonRef: string = assignment.lessonRef;
      assignment.resources.forEach((resource) => {
        assignment.timeslots.forEach((timeslot) => {
          // Process a single assignment
          if (timeslot.split(DAY_TIME_SEPARATOR).length > 1) {
            const dayIdx = Number(timeslot.split(DAY_TIME_SEPARATOR)[0]);
            const timeIdx = Number(timeslot.split(DAY_TIME_SEPARATOR)[1]);
            const resIdx = Number(resourcesDict.indexOf(resource));
            if (resIdx >= 0) resourcesAvailability[resIdx][dayIdx][timeIdx] = lessonRef;
          }
        });
      });
    });
    return resourcesAvailability;
  }

  const resourcesAvailability = processAvailability();
  const timetableAssignments = processAssignments(resourcesAvailability);

  function getAssignmentCellTextColor(resourceName: string, cell: string): string[] {
    const res: string[] = [];
    const day = cell.split(DAY_TIME_SEPARATOR)[0] as unknown as number;
    const time = cell.split(DAY_TIME_SEPARATOR)[1] as unknown as number;
    var cellText = timetableAssignments[resourcesDict.indexOf(resourceName)][day][time];

    // To print all resources assigned to a lesson in each cell
    solution.assignments
      .filter((assignment) => assignment.lessonRef === cellText)
      .forEach((assignment, a_index) => {
        assignment.resources.forEach((resource, r_index) => {
          if (resource === resourceName) {
            assignment.resources.forEach((resource2, r2_index) => {
              if (!cellText.includes(resource2)) cellText += SUBJECT_CLASS_SEPARATOR + resource2;
            });
          }
        });
      });

    res.push(cellText);
    if (
      cellText === '' ||
      cellText === AVAILABLE_TIME_MARKER ||
      cellText === UNDESIRED_TIME_MARKER ||
      cellText === UNAVAILABLE_TIME_MARKER
    ) {
      res.push('');
      return res;
    }
    const cellColor = subjects.filter((subject) => subject.name === cellText.split(SUBJECT_CLASS_SEPARATOR)[0])[0]
      .color;
    res.push(cellColor);
    return res;
  }

  //Tabs
  function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        key={`simple-tabpanel-${index}`}
        id={`simple-tabpanel-${index}`}
        style={{ maxWidth: '100%', width: '100%' }}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ paddingRight: 0 }}>{children}</Box>}
      </div>
    );
  }

  // const [selectedTab, setSelectedTab] = React.useState(0);
  const [pageClass, setPageClass] = React.useState(0);
  const [pageTeacher, setPageTeacher] = React.useState(0);
  const [pageRoom, setPageRoom] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const [exportOpen, setExportOpen] = useState(false);
  const [exportConfirmed, setExportConfirmed] = useState(false);
  const [selectedFormat, setSelectedFormat] = useState<string>(t('MS Excel'));
  const [publishTimetable, setPublishTimetable] = useState(false);

  const handleExportJson = async () => {
    const json = JSON.stringify(solution, null, 2);
    autoDownloadFile(json, 'json', solution.name);
  };

  const publishTimetableToApp = async () => {
    await setDoc(doc(db, 'publicSolutions', ownerEmail + SUBJECT_CLASS_SEPARATOR + fileId), {
      filename: file?.name,
      solutionUrl: solutionsPath + '/' + solution.name,
      url: solutionsPath.replace('/solutions', ''),
    });
    toast.success(t('Your timetable has been published to the Tiza app'));
  };

  const withdrawTimetableFromApp = async () => {
    const publicSolutionDoc = await getDoc(doc(db, 'publicSolutions', ownerEmail + SUBJECT_CLASS_SEPARATOR + fileId));
    if (publicSolutionDoc.exists()) {
      await deleteDoc(doc(db, 'publicSolutions', ownerEmail + SUBJECT_CLASS_SEPARATOR + fileId));
      toast.success(t('Your timetable has been withdrawn from Tiza app'));
    }
  };

  const checkExportConfirmation = () => {
    if (exportConfirmed) {
      setExportConfirmed(false);
      if (selectedFormat === t('MS Excel')) handleExportXls(t, file!, solution, resources, getAssignmentCellTextColor);
      else if (selectedFormat === t('Json')) handleExportJson();
      else if (selectedFormat === t('Csv')) handleExportCsv(t, solution, resources);
      if (publishTimetable) {
        publishTimetableToApp();
      } else {
        withdrawTimetableFromApp();
      }
    }
  };

  checkExportConfirmation();

  const [importOpen, setImportOpen] = useState(false);

  const handleNavToUpgradePlan = () => {
    setShowPlanLimitDialog(false);
    history.push('/settings');
  };

  const now = new Date().getTime();
  // const updatedAt = (solution.updatedAt as any).seconds ? (solution.updatedAt as any).seconds * 1000 : now;

  const [videoPlayerDialogOpen, setVideoPlayerDialogOpen] = useState(false);

  const handleViewModeChange = (event: React.MouseEvent<HTMLElement>, newViewMode: string | null) => {
    if (newViewMode !== null) {
      setSolution({ ...solution, display: newViewMode });
    }
  };

  const handleGenerateClick = (isRegenerate: boolean) => {
    if (isRegenerate && userPlan === FREE) {
      setShowRegenerateLimitDialog(true);
      return;
    }
    setConfigsOpen(true);
  };

  useEffect(() => {
    if (!arraysHaveSameElements(solution.lockedAssignments, selectedCells)) {
      const newSolution = { ...solution, lockedAssignments: selectedCells, updatedAt: Timestamp.now() };
      setSolution(newSolution);
      setDoc(doc(db, solutionsPath, newSolution.name), newSolution);
    }
  }, [selectedCells]);

  useEffect(() => {
    if (configsOpen) {
      checkRuntimeSuggestion();
    }
  }, [configsOpen]);

  return (
    <PageContainer>
      {configsOpen && (
        <Onboarding
          run={runSolutionCreationOnboarding}
          steps={solutionCreationSteps}
          storageFlagKey={SOLUTION_CREATION_KEY}
        />
      )}
      <VideoPlayerDialog
        open={videoPlayerDialogOpen}
        setOpen={setVideoPlayerDialogOpen}
        videoURL={t('video_url_help_solution')}
      ></VideoPlayerDialog>
      <PlanInfoDialog
        open={showRegenerateLimitDialog}
        positiveLabel={t('Upgrade plan')}
        negativeLabel={t('Cancel')}
        positiveAction={handleNavToUpgradePlan}
        negativeAction={() => {
          setShowRegenerateLimitDialog(false);
        }}
        title={
          <>
            <WorkspacePremium sx={{ fontSize: 20, color: customColors.premium, marginRight: 1 }} />
            {t('Regenerate timetables')}
          </>
        }
        message={t(
          `The free plan does not include the generation a new timetable based on an existing one. Please upgrade your plan to access this feature.`
        )}
      />
      {configsOpen && (
        <PlanInfoDialog
          open={showPlanLimitDialog}
          positiveLabel={t('Upgrade plan')}
          negativeLabel={t('Continue anyway')}
          positiveAction={handleNavToUpgradePlan}
          negativeAction={() => {
            setShowPlanLimitDialog(false);
          }}
          title={
            <>
              <WorkspacePremium sx={{ fontSize: 20, color: customColors.premium, marginRight: 1 }} />
              {t('Runtime recommendation')}
            </>
          }
          message={t(
            `Your project have a lot of resources and constraints. Tiza suggests at least ${
              runtimeSuggestion / 60
            } minutes of runtime to build timetables for this project. We recommend you to upgrade your Tiza plan to have a better experience.`
          )}
        />
      )}
      <ImportStepperDialog
        open={importOpen}
        setOpen={setImportOpen}
        title={t('Import solution')}
        type={'assignment'}
        solution={solution}
      />
      <ExportDialog
        open={exportOpen}
        setOpen={setExportOpen}
        title={t('Export Options')}
        message={t('Select the export file format:')}
        setConfirmed={setExportConfirmed}
        selectedFormat={selectedFormat}
        setSelectedFormat={setSelectedFormat}
        publishTimetable={publishTimetable}
        setPublishTimetable={setPublishTimetable}
      />
      <DraggableDialog
        open={deleteConfirmationOpen}
        setOpen={setDeleteConfirmationOpen}
        setConfirmed={setDeleteConfirmed}
        title={t('Warning')}
        message={solution.name + t(' will be permanently deleted from this project. Are you sure?')}
      />
      <ConfigsDialog
        open={configsOpen}
        setOpen={setConfigsOpen}
        setConfirmed={setConfigsConfirmed}
        title={t('Configurations')}
        message={t('Select the priority for each requirement:')}
        solution={solution}
        setSolution={setSolution}
        priorityOptions={priorityOptions}
        userPlan={userPlan}
        runtimeSuggestion={getRuntimeSuggestion(lessons.length)}
        previousName={previousName}
      />
      <DraggableDialog
        open={lockConfirmationOpen}
        setOpen={setLockConfirmationOpen}
        setConfirmed={setLockConfirmed}
        title={t('Warning')}
        message={t('Lock warning')}
      />
      <DefectsDialog
        open={defectsOpen}
        setOpen={setDefectsOpen}
        title={t('Defects')}
        selectedResource={selectedRes}
        solution={solution}
        message={t('The constraints below were not attended in this timetable:')}
      />
      <MiniDrawer />
      <Grid container justifyContent="center">
        <Paper elevation={3} sx={{ width: '100%', maxWidth: widthLessThan1500 ? 'lg' : '80vw' }}>
          <Grid container item xs={12} justifyContent={'space-between'} padding={2} spacing={1} flexDirection={'row'}>
            <Grid item container xs={12} spacing={1} padding={1}>
              <Grid item flexGrow={1}>
                <Typography variant="body1" gutterBottom color={theme.palette.primary.main}>
                  <Tooltip title={t('Back')}>
                    <IconButton
                      style={{ height: 24, width: 24, marginRight: 4 }}
                      color="primary"
                      onClick={() => history.push('/solutions')}
                    >
                      <ArrowBack style={{ height: 20, width: 20 }} />
                    </IconButton>
                  </Tooltip>
                  {solution && solution.name ? t('Update ') + t('timetable') : t('New ') + t('timetable')}
                </Typography>
                <Divider color={theme.palette.primary.main} />
              </Grid>
              <TooltipIconButton
                tooltip={t('Watch a helpful video')}
                Icon={QuestionMark}
                color={theme.palette.primary.main}
                onClick={() => setVideoPlayerDialogOpen(true)}
              />
              {solution.assignments.length > 0 ? (
                <>
                  <Grid item xs={2}>
                    <Button
                      variant="outlined"
                      size="small"
                      disabled={readOnly}
                      onClick={() => setDeleteConfirmationOpen(true)}
                      color="error"
                      fullWidth
                    >
                      {t('Delete')}
                    </Button>
                  </Grid>
                  <Grid item xs={2}>
                    <LoadingButton
                      variant="outlined"
                      size="small"
                      disabled={readOnly}
                      onClick={copy}
                      color="primary"
                      fullWidth
                      startIcon={null}
                      loadingPosition="start"
                      loading={isLoadingCopy}
                    >
                      {t('Copy')}
                    </LoadingButton>
                  </Grid>
                  <Grid item xs={3} md={2}>
                    <LoadingButton
                      className="generate-button"
                      variant="contained"
                      size="small"
                      disabled={solution.status === PROCESSING || readOnly}
                      fullWidth
                      onClick={() => handleGenerateClick(true)}
                      color="primary"
                      startIcon={<AutoAwesome />}
                      loadingPosition="start"
                      loading={solution.status === PROCESSING}
                    >
                      {solution.status === PROCESSING ? t('Regenerating...') : t('Regenerate')}
                    </LoadingButton>
                  </Grid>
                </>
              ) : (
                <Grid item xs={3} md={2}>
                  <LoadingButton
                    className="generate-button"
                    variant="contained"
                    size="small"
                    disabled={solution.status === PROCESSING || readOnly}
                    fullWidth
                    onClick={() => handleGenerateClick(false)}
                    color="primary"
                    startIcon={<AutoAwesome />}
                    loadingPosition="start"
                    loading={solution.status === PROCESSING}
                  >
                    {solution.status === PROCESSING ? t('Generating...') : t('Generate')}
                  </LoadingButton>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<GridExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <DialogContentText>{t('Timetable Info')}</DialogContentText>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    className="quality-metrics"
                    container
                    spacing={1}
                    item
                    xs={12}
                    justifyContent="space-between"
                    paddingTop={1}
                  >
                    {/* Info */}
                    <Grid item xs={12} sm={6} lg={3}>
                      <Card>
                        <CardContent>
                          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            {t('Status')}
                          </Typography>
                          <Box height={150} sx={{ overflow: 'auto', ...(isDarkMode ? darkModeScrollBar : {}) }}>
                            {solution.status === OPTIMAL && (
                              <Alert icon={<Check fontSize="inherit" />} severity="success">
                                <Stack spacing={1}>
                                  <Typography>{t(solution.status)}</Typography>
                                  <Typography variant="body2">
                                    {t(
                                      'This is the best possible timetable for this input data. If it is not the ideal solution, please review your input requirements and priorities.'
                                    )}
                                  </Typography>
                                </Stack>
                              </Alert>
                            )}
                            {solution.status === UP_TO_DATE && (
                              <Alert icon={<Check fontSize="inherit" />} severity="success">
                                <Stack spacing={1}>
                                  <Typography>{t(solution.status)}</Typography>
                                  <Typography variant="body2">
                                    {t(
                                      'Timetable is up to date with the input data but there might be a better timetable if more runtime is allowed.'
                                    )}
                                  </Typography>
                                </Stack>
                              </Alert>
                            )}
                            {solution.status === PROCESSING && (
                              <Alert icon={<Check fontSize="inherit" />} severity="info">
                                <Stack spacing={1}>
                                  <Typography>{t(solution.status)}</Typography>
                                  <Typography variant="body2">
                                    {t(
                                      'Timetable generation is in progress. Please wait while we are generating the timetable.'
                                    )}
                                  </Typography>
                                </Stack>
                              </Alert>
                            )}
                            {solution.status === OUTDATED && (
                              <Alert icon={<Warning fontSize="inherit" />} severity="warning">
                                <Stack spacing={1}>
                                  <Typography>{t(solution.status)}</Typography>
                                  <Typography variant="body2">
                                    {t(
                                      'Any input data has changed, so the timetables might be inconsistent to the new requirements and priorities.'
                                    )}
                                  </Typography>
                                </Stack>
                              </Alert>
                            )}
                            {solution.status === EMPTY && (
                              <Alert icon={<Error fontSize="inherit" />} severity="error">
                                <Stack spacing={1}>
                                  <Typography>{t(solution.status)}</Typography>
                                  <Typography variant="body2">
                                    {t(
                                      'Timetable has not been generated yet, please click GENERATE button to create one.'
                                    )}
                                  </Typography>
                                </Stack>
                              </Alert>
                            )}
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                      <Card>
                        <CardContent>
                          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            {t('Assignments')}
                          </Typography>
                          <PieChart
                            margin={{ top: 0, bottom: 0, left: 0, right: 250 }}
                            slotProps={{
                              legend: {
                                padding: { top: 0, right: 80, bottom: 0, left: 0 },
                                labelStyle: { fontSize: '14px' },
                              },
                            }}
                            series={[
                              {
                                data: [
                                  {
                                    id: 0,
                                    value: solution.assignments.length,
                                    color: colors.green[100],
                                    label: `${t('Assigned')} (${(
                                      100 -
                                      ((lessons.length - solution.assignments.length) / lessons.length) * 100
                                    ).toFixed(1)}%)`,
                                  },
                                  {
                                    id: 1,
                                    value: lessons.length - solution.assignments.length,
                                    color: colors.red[100],
                                    label: `${t('Not assigned')} (${(
                                      ((lessons.length - solution.assignments.length) / lessons.length) *
                                      100
                                    ).toFixed(1)}%)`,
                                  },
                                ],
                                innerRadius: 20,
                                outerRadius: 50,
                              },
                            ]}
                            width={380}
                            height={150}
                          />
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                      <Card>
                        <CardContent>
                          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            {t('Constraints')}
                          </Typography>
                          <PieChart
                            margin={{ top: 0, bottom: 0, left: 0, right: 250 }}
                            slotProps={{
                              legend: {
                                padding: { top: 0, right: 80, bottom: 0, left: 0 },
                                labelStyle: { fontSize: '14px' },
                              },
                            }}
                            series={[
                              {
                                data: [
                                  {
                                    id: 0,
                                    value:
                                      solution.assignments.length === 0
                                        ? 0
                                        : solution.numConstraints - solution.defects.length,
                                    color: colors.green[100],
                                    label: `${t('Met')} (${
                                      solution.assignments.length === 0
                                        ? '0.0'
                                        : (
                                            ((solution.numConstraints - solution.defects.length) /
                                              solution.numConstraints) *
                                            100
                                          ).toFixed(1)
                                    }%)`,
                                  },
                                  {
                                    id: 1,
                                    value: solution.assignments.length === 0 ? 100 : solution.defects.length,
                                    color: colors.red[100],
                                    label: `${t('Not met')} (${
                                      solution.assignments.length === 0
                                        ? '100.0'
                                        : ((solution.defects.length / solution.numConstraints) * 100).toFixed(1)
                                    }%)`,
                                  },
                                ],
                                innerRadius: 20,
                                outerRadius: 50,
                              },
                            ]}
                            width={380}
                            height={150}
                          />
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                      <Card>
                        <CardContent>
                          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            {t('Optimum closeness')}
                          </Typography>
                          {solution.cost === 0 ? (
                            <Box height={150}>
                              <Alert icon={<Check fontSize="inherit" />} severity="success">
                                <Stack spacing={1}>
                                  <Typography>{t('No defects')}</Typography>
                                  <Typography variant="body2">
                                    {t('Timetable has no defects, so it is considered optimal.')}
                                  </Typography>
                                </Stack>
                              </Alert>
                            </Box>
                          ) : (
                            <PieChart
                              margin={{ top: 0, bottom: 0, left: 0, right: 250 }}
                              slotProps={{
                                legend: {
                                  padding: { top: 0, right: 80, bottom: 0, left: 0 },
                                  labelStyle: { fontSize: '14px' },
                                },
                              }}
                              series={[
                                {
                                  data: [
                                    {
                                      id: 0,
                                      value: solution.assignments.length === 0 ? 0 : solution.lowerBound,
                                      color: colors.green[100],
                                      label: `${t('Min possible')} (${(solution.cost === 0
                                        ? 100
                                        : (solution.lowerBound / solution.cost) * 100
                                      ).toFixed(1)}%)`,
                                    },
                                    {
                                      id: 1,
                                      value:
                                        solution.assignments.length === 0 ? 100 : solution.cost - solution.lowerBound,
                                      color: colors.red[100],
                                      label: `${t('Above min')} (${(
                                        (solution.cost === 0
                                          ? 0
                                          : (solution.cost - solution.lowerBound) / solution.cost) * 100
                                      ).toFixed(1)}%)`,
                                    },
                                  ],
                                  innerRadius: 20,
                                  outerRadius: 50,
                                },
                              ]}
                              width={380}
                              height={150}
                            />
                          )}
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            {solution.status === EMPTY ? (
              <EmptyTimetable t={t} />
            ) : (
              <>
                {solution.status === PROCESSING && (
                  <Grid container item spacing={1} xs={12} alignItems="center" paddingTop={1}>
                    <Grid item xs={12}>
                      <LinearWithValueLabel
                        maxTime={solution.runningTime}
                        initialProgress={
                          ((now - solution.updatedAt.toDate().getTime()) / 1000 / solution.runningTime) * 100
                        }
                      />
                    </Grid>
                  </Grid>
                )}
                {solution.display !== COMPACT ? (
                  <Grid container paddingTop={1} spacing={1}>
                    <Grid item container xs={12}>
                      <TimetableHeader
                        t={t}
                        label={t('Timetable to ') + selectedRes}
                        solution={solution}
                        handleViewModeChange={handleViewModeChange}
                      />
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      md={3}
                      alignItems={'stretch'}
                      alignContent={'start'}
                      sx={{ paddingTop: '0px !important' }}
                    >
                      <Paper elevation={3} sx={{ width: '100%' }}>
                        <Grid item xs={12} marginTop={0} paddingTop={0}>
                          <Box sx={{ width: '100%' }}>
                            <Tabs
                              value={selectedTab}
                              onChange={handleTabChange}
                              variant="scrollable"
                              allowScrollButtonsMobile
                              aria-label="wrapped label tabs example"
                            >
                              <Tab label={file?.studentsSetting !== 0 ? t('Classes') : t('Students')} />
                              <Tab label={t('Teachers')} />
                              <Tab label={t('Rooms')} />
                            </Tabs>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <TabPanel value={selectedTab} index={0}>
                            <ResourcesSoln
                              type={'class'}
                              selected={selectedRes}
                              setSelected={setSelectedRes}
                              solution={solution}
                              page={pageClass}
                              setPage={setPageClass}
                              numTimes={times.length}
                              defects={solution.defects}
                            />
                          </TabPanel>
                          <TabPanel value={selectedTab} index={1}>
                            <ResourcesSoln
                              type={'teacher'}
                              selected={selectedRes}
                              setSelected={setSelectedRes}
                              solution={solution}
                              page={pageTeacher}
                              setPage={setPageTeacher}
                              numTimes={times.length}
                              defects={solution.defects}
                            />
                          </TabPanel>
                          <TabPanel value={selectedTab} index={2}>
                            <ResourcesSoln
                              type={'room'}
                              selected={selectedRes}
                              setSelected={setSelectedRes}
                              solution={solution}
                              page={pageRoom}
                              setPage={setPageRoom}
                              numTimes={times.length}
                              defects={solution.defects}
                            />
                          </TabPanel>
                        </Grid>
                      </Paper>
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      md={9}
                      spacing={1}
                      alignItems={'stretch'}
                      justifyContent={'space-evenly'}
                      alignContent={'start'}
                      sx={{ paddingTop: '0px !important' }}
                    >
                      <IndividualTimetable
                        t={t}
                        solution={solution}
                        selectedRes={selectedRes}
                        selectedCells={selectedCells}
                        setSelectedCells={setSelectedCells}
                        readOnly={readOnly}
                        handleSelectAllClick={handleSelectAllCellsClick}
                        handleSelectAllDayClick={handleSelectAllDayClick}
                        handleSelectAllTimeClick={handleSelectAllTimeClick}
                        handleTimetableCellClick={handleTimetableCellClick}
                        getAssignmentCellTextColor={getAssignmentCellTextColor}
                        setDefectsOpen={setDefectsOpen}
                        setLockConfirmationOpen={setLockConfirmationOpen}
                        setConfigsOpen={setConfigsOpen}
                        setImportOpen={setImportOpen}
                        setExportOpen={setExportOpen}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container paddingTop={1} spacing={1}>
                    <TimetableHeader
                      t={t}
                      label={
                        selectedTab === 0 && file?.studentsSetting !== 0
                          ? t('Timetable to Classes')
                          : selectedTab === 0 && file?.studentsSetting === 0
                          ? t('Timetable to Students')
                          : selectedTab === 1
                          ? t('Timetable to Teachers')
                          : t('Timetable to Rooms')
                      }
                      solution={solution}
                      handleViewModeChange={handleViewModeChange}
                    />
                    <CompactTimetable
                      t={t}
                      solution={solution}
                      selectedCells={selectedCells}
                      setSelectedCells={setSelectedCells}
                      selectedTab={selectedTab}
                      setSelectedTab={setSelectedTab}
                      readOnly={readOnly}
                      handleSelectAllDayClick={handleSelectAllDayClick}
                      handleTimetableCellClick={handleTimetableCellClick}
                      handleSelectAllDayTimeClick={handleSelectAllDayTimeClick}
                      handleSelectAllCellsClick={handleSelectAllCellsClick}
                      handleSelectAllResources={handleSelectAllResources}
                      getAssignmentCellTextColor={getAssignmentCellTextColor}
                      setDefectsOpen={setDefectsOpen}
                      setLockConfirmationOpen={setLockConfirmationOpen}
                      setConfigsOpen={setConfigsOpen}
                      setImportOpen={setImportOpen}
                      setExportOpen={setExportOpen}
                    />
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Paper>
      </Grid>
    </PageContainer>
  );
}
