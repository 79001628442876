import { ISolution } from 'pages/Solutions/Solution';
import { useState } from 'react';
import { DAY_TIME_SEPARATOR } from './solutionUtils';

export default function useTimeslotSelection(cells: string[], solution: ISolution) {
  const [selectedCells, setSelectedCells] = useState<string[]>(solution.lockedAssignments);
  const handleSelectAllDayTimeClick = (resDayTime: string) => {
    let newSelected = [...selectedCells];
    cells.forEach((element) => {
      let cellDay = element.split(DAY_TIME_SEPARATOR)[1];
      let nameDay = resDayTime.split(DAY_TIME_SEPARATOR)[1];
      let cellTime = element.split(DAY_TIME_SEPARATOR)[2];
      let nameTime = resDayTime.split(DAY_TIME_SEPARATOR)[2];
      if (cellDay === nameDay && cellTime === nameTime) {
        const selectedIndex = selectedCells.indexOf(element);
        if (selectedIndex === -1) {
          newSelected.push(element);
        } else {
          newSelected = newSelected.filter((item) => item !== element);
        }
      }
    });
    setSelectedCells(newSelected);
  };

  const handleSelectAllDayClick = (resDayTime: string) => {
    let newSelected = [...selectedCells];
    cells.forEach((element) => {
      let cellDay = element.split(DAY_TIME_SEPARATOR)[1];
      let nameDay = resDayTime.split(DAY_TIME_SEPARATOR)[1];
      if (cellDay === nameDay) {
        const selectedIndex = selectedCells.indexOf(element);
        if (selectedIndex === -1) {
          newSelected.push(element);
        } else {
          newSelected = newSelected.filter((item) => item !== element);
        }
      }
    });
    setSelectedCells(newSelected);
  };

  const handleSelectAllTimeClick = (resDayTime: string) => {
    let newSelected = [...selectedCells];
    cells.forEach((element) => {
      let cellTime = element.split(DAY_TIME_SEPARATOR)[2];
      let nameTime = resDayTime.split(DAY_TIME_SEPARATOR)[2];
      if (cellTime === nameTime) {
        const selectedIndex = selectedCells.indexOf(element);
        if (selectedIndex === -1) {
          newSelected.push(element);
        } else {
          newSelected = newSelected.filter((item) => item !== element);
        }
      }
    });
    setSelectedCells(newSelected);
  };

  const handleSelectAllResources = (resDayTime: string) => {
    let newSelected = [...selectedCells];
    cells.forEach((element) => {
      let cellRes = element.split(DAY_TIME_SEPARATOR)[0];
      let nameRes = resDayTime.split(DAY_TIME_SEPARATOR)[0];
      if (cellRes === nameRes) {
        const selectedIndex = selectedCells.indexOf(element);
        if (selectedIndex === -1) {
          newSelected.push(element);
        } else {
          newSelected = newSelected.filter((item) => item !== element);
        }
      }
    });
    setSelectedCells(newSelected);
  };

  const handleSelectAllCellsClick = () => {
    let newSelected = [...selectedCells];
    cells.forEach((element) => {
      const selectedIndex = selectedCells.indexOf(element);
      if (selectedIndex === -1) {
        newSelected.push(element);
      } else {
        newSelected = newSelected.filter((item) => item !== element);
      }
    });
    setSelectedCells(newSelected);
  };

  const handleTimetableCellClick = (name: string) => {
    const selectedIndex = selectedCells.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedCells, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedCells.slice(1));
    } else if (selectedIndex === selectedCells.length - 1) {
      newSelected = newSelected.concat(selectedCells.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selectedCells.slice(0, selectedIndex), selectedCells.slice(selectedIndex + 1));
    }
    setSelectedCells(newSelected);
  };

  return {
    selectedCells,
    setSelectedCells,
    handleSelectAllDayTimeClick,
    handleSelectAllDayClick,
    handleSelectAllTimeClick,
    handleSelectAllResources,
    handleSelectAllCellsClick,
    handleTimetableCellClick,
  };
}
