import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  FormControl,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  Stack,
} from '@mui/material';
import isEqual from 'lodash/isEqual';
import { SessionContext } from 'contexts';
import { TFunction } from 'i18next';
import { ILesson } from 'pages/Lessons/utils/types';
import { IResource } from 'pages/Resources/Resource';
import { ICustomConstraint, ISolution } from 'pages/Solutions/Solution';
import { ISubject } from 'pages/Subjects/Subject';
import { Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { priorityOptionsMap } from 'util/configUtils';
import Linear from 'components/Icons/Linear';
import {
  CONSECUTIVE_TIMES,
  DAILY_WORKLOAD,
  DAYS_BETWEEN_MEETINGS,
  DISTINCT_SUBJECTS,
  FORBIDDEN_COMBINATION,
  IDLE_WINDOW,
  NOT_SIMULTANEOUS_WITH,
  OCCUR_BEFORE,
  PREDEFINED_TIMES,
  REST_BETWEEN_DAYS,
  ROOMS_PER_DAY,
  SIMULTANEOUS_WITH,
  SQUARE_SUM as QUADRATIC,
  STEP,
  SUBJECT_UNAVAILABLE_TIMES,
  SUBJECT_UNDESIRED_TIMES,
  SUM as LINEAR,
  TRAVEL_TIME,
  UNAVAILABLE_TIMES,
  UNDESIRED_TIMES,
  WORKING_DAYS,
  WORKING_TIMES,
} from 'util/constraintUtils';
import { getConstrBounds, IConstrBounds } from 'util/validationUtils';
import InfoDialog from './InfoDialog';
import CostFunctionCard from 'components/Card/CostFunctionCard';
import { customColors } from 'styles';
import Quadratic from 'components/Icons/Quadratic';
import Step from 'components/Icons/Step';
import { getPriorityIcon } from 'util/priorityUtils';
import DialogContainer from 'containers/DialogContainer';
import { SUBJECT_CLASS_SEPARATOR } from 'util/solutionUtils';

export const constrAppliesToLesson = (
  lesson: ILesson,
  subjects: ISubject[],
  constrType: string,
  constrBounds: IConstrBounds
) => {
  const subject = subjects.find((sub) => sub.name === lesson.subject[0]);
  switch (constrType) {
    case DAYS_BETWEEN_MEETINGS:
      return (
        lesson.minGapLessons !== constrBounds.minDaysBetweenLessons ||
        lesson.maxGapLessons !== constrBounds.maxDaysBetweenLessons
      );
    case PREDEFINED_TIMES:
      return lesson.predefinedTimes.length > 0;
    case SIMULTANEOUS_WITH:
      return lesson.simultaneousWith.length > 0;
    case NOT_SIMULTANEOUS_WITH:
      return lesson.notSimultaneousWith.length > 0;
    case OCCUR_BEFORE:
      return lesson.occurBefore.length > 0 && lesson.occurBeforeMinDays !== 0;
    case SUBJECT_UNAVAILABLE_TIMES:
      return subject?.unavailableTimes.length;
    case SUBJECT_UNDESIRED_TIMES:
      return subject?.undesiredTimes.length;
  }
};

export const getConstrPriority = (solution: ISolution, entity: string, constrType: string) => {
  switch (entity) {
    case 'class':
      switch (constrType) {
        case WORKING_TIMES:
          return solution.workloadClasses;
        case WORKING_DAYS:
          return solution.workingDaysClasses;
        case DAILY_WORKLOAD:
          return solution.dailyWorkloadClasses;
        case IDLE_WINDOW:
          return solution.idleWindowClasses;
        case DISTINCT_SUBJECTS:
          return solution.distinctSubjectsClasses;
        case FORBIDDEN_COMBINATION:
          return solution.forbiddenCombinationClasses;
        case UNAVAILABLE_TIMES:
          return solution.unavailableTimesClasses;
        case UNDESIRED_TIMES:
          return solution.undesiredTimesClasses;
        case REST_BETWEEN_DAYS:
          return solution.restBetweenDaysClasses;
        case ROOMS_PER_DAY:
          return solution.roomChangesDayClasses;
        case CONSECUTIVE_TIMES:
          return solution.consecutiveTimesClasses;
        default:
          return 'Average';
      }
    case 'teacher':
      switch (constrType) {
        case WORKING_TIMES:
          return solution.workloadTeachers;
        case WORKING_DAYS:
          return solution.workingDaysTeachers;
        case DAILY_WORKLOAD:
          return solution.dailyWorkloadTeachers;
        case IDLE_WINDOW:
          return solution.idleWindowTeachers;
        case DISTINCT_SUBJECTS:
          return solution.distinctSubjectsTeachers;
        case FORBIDDEN_COMBINATION:
          return solution.forbiddenCombinationTeachers;
        case UNAVAILABLE_TIMES:
          return solution.unavailableTimesTeachers;
        case UNDESIRED_TIMES:
          return solution.undesiredTimesTeachers;
        case REST_BETWEEN_DAYS:
          return solution.restBetweenDaysTeachers;
        case ROOMS_PER_DAY:
          return solution.roomChangesDayTeachers;
        case CONSECUTIVE_TIMES:
          return solution.consecutiveTimesTeachers;
        default:
          return 'Average';
      }
    case 'room':
      switch (constrType) {
        case WORKING_TIMES:
          return solution.workloadRooms;
        case WORKING_DAYS:
          return solution.workingDaysRooms;
        case DAILY_WORKLOAD:
          return solution.dailyWorkloadRooms;
        case IDLE_WINDOW:
          return solution.idleWindowRooms;
        case DISTINCT_SUBJECTS:
          return solution.distinctSubjectsRooms;
        case FORBIDDEN_COMBINATION:
          return solution.forbiddenCombinationRooms;
        case UNAVAILABLE_TIMES:
          return solution.unavailableTimesRooms;
        case UNDESIRED_TIMES:
          return solution.undesiredTimesRooms;
        case CONSECUTIVE_TIMES:
          return solution.consecutiveTimesRooms;
        case TRAVEL_TIME:
          return solution.travelTimeRooms;
        default:
          return 'Average';
      }
    case 'lesson':
      switch (constrType) {
        case DAYS_BETWEEN_MEETINGS:
          return solution.daysBetweenLessons;
        case PREDEFINED_TIMES:
          return solution.predefinedTimes;
        case SIMULTANEOUS_WITH:
          return solution.simultaneousWith;
        case NOT_SIMULTANEOUS_WITH:
          return solution.notSimultaneousWith;
        case OCCUR_BEFORE:
          return solution.occurBefore;
        case SUBJECT_UNAVAILABLE_TIMES:
          return solution.unavailableTimesSubjects;
        case SUBJECT_UNDESIRED_TIMES:
          return solution.undesiredTimesSubjects;
      }
  }
};

export const constrAppliesToRes = (res: IResource, constrType: string, constrBounds: IConstrBounds) => {
  switch (constrType) {
    case WORKING_TIMES:
      return res.minWorkload !== constrBounds.minWorkingTimes || res.maxWorkload !== constrBounds.maxWorkingTimes;
    case WORKING_DAYS:
      return res.minWorkingDays !== constrBounds.minWorkingDays || res.maxWorkingDays !== constrBounds.maxWorkingDays;
    case DAILY_WORKLOAD:
      return (
        res.minDailyWorkload !== constrBounds.minDailyWorkload || res.maxDailyWorkload !== constrBounds.maxDailyWorkload
      );
    case IDLE_WINDOW:
      return res.minIdleWindow !== constrBounds.minIdleWindow || res.maxIdleWindow !== constrBounds.maxIdleWindow;
    case DISTINCT_SUBJECTS:
      return (
        res.minDistinctSubjects !== constrBounds.minDistinctSubjects ||
        res.maxDistinctSubjects !== constrBounds.maxDistinctSubjects
      );
    case FORBIDDEN_COMBINATION:
      return res.forbiddenCombination.length > 0;
    case UNAVAILABLE_TIMES:
      return res.unavailableTimes.length > 0;
    case UNDESIRED_TIMES:
      return res.undesiredTimes.length > 0;
    case REST_BETWEEN_DAYS:
      return (
        res.minRestBetweenDays !== constrBounds.minRestBetweenDays ||
        res.maxRestBetweenDays !== constrBounds.maxRestBetweenDays
      );
    case ROOMS_PER_DAY:
      return (
        res.minRoomChangesDay !== constrBounds.minRoomsPerDay || res.maxRoomChangesDay !== constrBounds.maxRoomsPerDay
      );
    case CONSECUTIVE_TIMES:
      return (
        res.minConsecutiveTimes !== constrBounds.minConsecutiveTimes ||
        res.maxConsecutiveTimes !== constrBounds.maxConsecutiveTimes
      );
    case TRAVEL_TIME:
      return res.travelTimeRooms.length > 0 && res.minTravelTime !== constrBounds.minTravelTime;
  }
};

const getStepTooltipComponent = (t: TFunction) => {
  return (
    <div>
      {`${t('Step cost function')}`}
      {/* <hr />
      {`${t('Activates the penalty or not, regardless of the intensity of deviation')}`} */}
    </div>
  );
};

const getLinearTooltipComponent = (t: TFunction) => {
  return (
    <div>
      {`${t('Linear cost function')}`}
      {/* <hr />
      {`${t('The penalty increases linearly with the intensity of deviation')}`} */}
    </div>
  );
};

const getQuadraticTooltipComponent = (t: TFunction) => {
  return (
    <div>
      {`${t('Quadratic cost function')}`}
      {/* <hr />
      {`${t('The penalty increases quadratically with the intensity of deviation')}`} */}
    </div>
  );
};

type StateMap = {
  [key: string]: string;
};

type Props = {
  open: boolean;
  positiveLabel: string;
  negativeLabel: string;
  negativeAction: () => void;
  constraintType: string;
  entity: string;
  solution: ISolution;
  setSolution: Dispatch<SetStateAction<ISolution>>;
  colors: string[];
  backgroundColors: string[];
};

export default function AdvancedConstraintsDialog({
  open,
  positiveLabel,
  negativeLabel,
  negativeAction,
  constraintType,
  entity,
  solution,
  setSolution,
  colors,
  backgroundColors,
}: Props) {
  const { file, resources, subjects, lessons, isDarkMode } = useContext(SessionContext);
  const { t } = useTranslation();
  const days = file ? file.days : [];
  const times = file ? file.times : [];
  const constrPriority = getConstrPriority(solution, entity, constraintType);

  const getNamesByEntityAndConstraint = () => {
    switch (entity) {
      case 'class':
      case 'teacher':
      case 'room':
        return resources
          .filter((res) => res.type === entity && constrAppliesToRes(res, constraintType, constrBounds))
          .map((res) => res.name);
      case 'lesson':
        return lessons
          .filter((les) => constrAppliesToLesson(les, subjects, constraintType, constrBounds))
          .map((res) => res.name);
      default:
        return [];
    }
  };

  const constrBounds = useMemo(() => {
    return getConstrBounds(days.length, times.length);
  }, [days]);

  const names: string[] = useMemo(() => {
    return getNamesByEntityAndConstraint();
  }, [entity, constraintType]);

  const getCustomConstrOfType = () => {
    const customConstrsOfType: ICustomConstraint[] = [];
    Object.keys(priority).forEach((name) => {
      if (priority[name] !== constrPriority || costFunction[name] !== LINEAR) {
        customConstrsOfType.push({
          type: constraintType,
          appliesTo: constraintType === 'Subject Unavailable Times' ? name.split(SUBJECT_CLASS_SEPARATOR)[0] : name,
          priority: priorityOptionsMap[priority[name]],
          costFunction: costFunction[name],
          entity: entity,
        });
      }
    });
    return customConstrsOfType;
  };
  const handleConfirm = () => {
    const customConstrsOfType = getCustomConstrOfType();
    const solutionCustomConstrsWithoutType = solution.customConstraints
      ? solution.customConstraints.filter((constr) => constr.type !== constraintType)
      : [];
    const updatedCustomConstrs = [...customConstrsOfType, ...solutionCustomConstrsWithoutType];
    if (!isEqual(solution.customConstraints, updatedCustomConstrs))
      toast.success(t('Individual constraint(s) set successfully'));
    setSolution({ ...solution, customConstraints: updatedCustomConstrs });
    negativeAction();
  };

  const initializeState = (type: 'priority' | 'costFunction', defaultValue: string): StateMap => {
    return names.reduce((acc, name) => {
      const customConstraint = solution.customConstraints
        ? solution.customConstraints.find(
            (constraint) =>
              constraint.appliesTo === name && constraint.type === constraintType && constraint.entity === entity
          )
        : undefined;
      return {
        ...acc,
        [name]: customConstraint?.[type] || defaultValue,
      };
    }, {} as StateMap);
  };

  const [priority, setPriority] = useState<StateMap>(initializeState('priority', constrPriority || 'Average'));
  const [costFunction, setCostFunction] = useState<StateMap>(initializeState('costFunction', LINEAR));

  // Function to handle priority change
  const handlePriorityChange = (name: string, value: string) => {
    setPriority((prevPriority) => ({
      ...prevPriority,
      [name]: value,
    }));
  };

  // Function to handle costFunction change
  const handleCostFunctionChange = (name: string, value: string) => {
    setCostFunction((prevCostFunction) => ({
      ...prevCostFunction,
      [name]: value,
    }));
  };

  const priorityOptions = [t('Disabled'), t('Very low'), t('Low'), t('Average'), t('High'), t('Very high')];
  const label = t('Priority');

  const [infoDialogOpen, setInfoDialogOpen] = useState(false);

  return (
    <div>
      <InfoDialog
        open={infoDialogOpen}
        setOpen={setInfoDialogOpen}
        title={t('Cost function and priority')}
        message={
          <Stack spacing={1}>
            <Typography>
              {t(
                'For each constraint that is not attended, the deviation is the ammount that deviates from the ideal. For example, consider teacher John should have at most 2 working days, and was scheduled to teach 5 days:'
              )}
            </Typography>
            <img
              src={'/img/examples/costFunction.jpg'}
              width={'50%'}
              alt={t('Example of penalty calculation')}
              loading="lazy"
            />
            <Typography>
              {t(
                'Then we have a deviation of 3 from the maximum. The deviations is used to calculate how good a timetable is. The fewer, the better. A cost function applies for each deviation. It can affect heavily the definition of a good timetable:'
              )}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <CostFunctionCard
                  title={t('Step')}
                  message={
                    <Stack>
                      <Typography>
                        {t(
                          'It activates the penalty or not, regardless of the intensity of deviation. In the given example, the penalty would be only 1.'
                        )}
                      </Typography>
                    </Stack>
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <CostFunctionCard
                  title={t('Linear')}
                  message={
                    <Stack>
                      <Typography>
                        {t(
                          'The penalty increases linearly with the intensity of deviation. In the given example, the penalty would be 3.'
                        )}
                      </Typography>
                    </Stack>
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <CostFunctionCard
                  title={t('Quadratic')}
                  message={
                    <Stack>
                      <Typography>
                        {t(
                          'The penalty increases quadratically with the intensity of deviation. In the given example, the penalty would be 9 (3²).'
                        )}
                      </Typography>
                    </Stack>
                  }
                />
              </Grid>
            </Grid>
            <Typography>
              {t(
                'Finally, the value of the cost function applied to the deviation is multiplied by the priority of the constraint. Such that:'
              )}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6} md={2}>
                <CostFunctionCard title={t('Disabled')} message={<Typography>{`${t('Multiplier')}: 0`}</Typography>} />
              </Grid>
              <Grid item xs={6} md={2}>
                <CostFunctionCard title={t('Very low')} message={<Typography>{`${t('Multiplier')}: 1`}</Typography>} />
              </Grid>
              <Grid item xs={6} md={2}>
                <CostFunctionCard title={t('Low')} message={<Typography>{`${t('Multiplier')}: 2`}</Typography>} />
              </Grid>
              <Grid item xs={6} md={2}>
                <CostFunctionCard title={t('Average')} message={<Typography>{`${t('Multiplier')}: 5`}</Typography>} />
              </Grid>
              <Grid item xs={6} md={2}>
                <CostFunctionCard title={t('High')} message={<Typography>{`${t('Multiplier')}: 10`}</Typography>} />
              </Grid>
              <Grid item xs={6} md={2}>
                <CostFunctionCard
                  title={t('Very high')}
                  message={<Typography>{`${t('Multiplier')}: 100`}</Typography>}
                />
              </Grid>
            </Grid>
          </Stack>
        }
      />
      <DialogContainer open={open} maxWidth="lg" onClose={negativeAction}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {t('Advanced settings for ') + t(constraintType)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography component={'span'}>{t('Setup the individual ')}</Typography>
            <Typography
              component={'span'}
              onClick={() => setInfoDialogOpen(true)}
              sx={{
                paddingInline: 0.5,
                color: 'primary.main',
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              {t('priority and cost function')}
            </Typography>
            <Typography component={'span'}>{`${t('for the elements that ')} ${t(constraintType)} ${t(
              'applies to'
            )}:`}</Typography>
          </DialogContentText>
          {names.map((name) => (
            <Grid key={name} container spacing={2} marginTop={1}>
              <Grid
                item
                xs={4}
                sx={{
                  textAlign: 'right',
                  justifyContent: 'flex-end',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {name}
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-select-small">{label}</InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={t(priority[name])}
                    label={label}
                    onChange={(e: SelectChangeEvent) => handlePriorityChange(name, e.target.value)}
                  >
                    {priorityOptions.map((option, index) => {
                      return (
                        <MenuItem key={`${label}_${index}`} value={option}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            {getPriorityIcon(index, isDarkMode ? 'white' : customColors.lightText)}
                            <span>{option}</span>
                          </div>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <ToggleButtonGroup
                  value={costFunction[name]}
                  exclusive
                  size="small"
                  onChange={(event, newValue) => handleCostFunctionChange(name, newValue)}
                  disabled={[OCCUR_BEFORE, TRAVEL_TIME, FORBIDDEN_COMBINATION].includes(constraintType)}
                  aria-label="cost function"
                >
                  <Tooltip title={getStepTooltipComponent(t)}>
                    <ToggleButton value={STEP} aria-label="step">
                      <Step width="24px" height="24px" color={isDarkMode ? 'white' : customColors.lightText} />
                    </ToggleButton>
                  </Tooltip>
                  <Tooltip title={getLinearTooltipComponent(t)}>
                    <ToggleButton value={LINEAR} aria-label="linear">
                      <Linear width="24px" height="24px" color={isDarkMode ? 'white' : customColors.lightText} />
                    </ToggleButton>
                  </Tooltip>
                  <Tooltip title={getQuadraticTooltipComponent(t)}>
                    <ToggleButton value={QUADRATIC} aria-label="quadratic">
                      <Quadratic width="24px" height="24px" color={isDarkMode ? 'white' : customColors.lightText} />
                    </ToggleButton>
                  </Tooltip>
                </ToggleButtonGroup>
              </Grid>
            </Grid>
          ))}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={negativeAction}>
            {negativeLabel}
          </Button>
          <Button onClick={handleConfirm}>{positiveLabel}</Button>
        </DialogActions>
      </DialogContainer>
    </div>
  );
}
