import React, { Dispatch, SetStateAction, useContext, useRef, useState } from 'react';
import { Box, DialogContentText, Stack, Typography } from '@mui/material';
import * as XLSX from 'xlsx';
import { useTranslation } from 'react-i18next';
import { HighlightAlt, UploadFile } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import { darkTheme, lightTheme } from 'styles/material';
import { SessionContext } from 'contexts';

interface FileUploadProps {
  setFileData: Dispatch<SetStateAction<any[]>>;
}

const FileUpload: React.FC<FileUploadProps> = ({ setFileData }: FileUploadProps) => {
  const { t } = useTranslation();
  const [file, setFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { isDarkMode } = useContext(SessionContext);
  const theme = isDarkMode ? darkTheme : lightTheme;

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = event.target.files?.[0] || null;
    setFile(uploadedFile);
    if (onFileSelect) {
      onFileSelect(uploadedFile!);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    setFile(droppedFile);
    if (onFileSelect) {
      onFileSelect(droppedFile);
    }
  };

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const onFileSelect = (file: File) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const binaryStr = event.target?.result;
        const workbook = XLSX.read(binaryStr, { type: 'binary' });
        const sheetName = workbook.SheetNames[0]; // Read the first sheet
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: '' });
        setFileData(jsonData); // Set the parsed data
      };
      reader.readAsBinaryString(file); // Read file as binary string
    }
  };

  return (
    <Box paddingInline={'15%'}>
      <DialogContentText paddingBlock={4}>{t('Upload your file below:')}</DialogContentText>
      <Box
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onClick={handleClick}
        justifyContent={'center'}
        alignItems={'center'}
        sx={{
          height: '200px',
          border: '2px dashed #ccc',
          borderRadius: '8px',
          padding: '20px',
          textAlign: 'center',
          cursor: 'pointer',
          '&:hover': {
            borderColor: 'primary.main',
            backgroundColor: isDarkMode ? grey[900] : grey[50],
          },
        }}
      >
        {file ? (
          <>
            <Stack height="100%" alignItems="center" justifyContent="center">
              <UploadFile style={{ color: theme.palette.primary.main, textAlign: 'center' }} />
              <Typography color={theme.palette.primary.main} textAlign="center">
                {file.name}
              </Typography>
            </Stack>
          </>
        ) : (
          <Stack height="100%" alignItems="center" justifyContent="center">
            <HighlightAlt style={{ color: grey[500], textAlign: 'center' }} />
            <Typography color={grey[500]} textAlign="center">
              {t('Drag & drop a file here, or click to upload')}
            </Typography>
          </Stack>
        )}
      </Box>
      <input ref={fileInputRef} type="file" onChange={handleFileChange} style={{ display: 'none' }} />
    </Box>
  );
};

export default FileUpload;
