import * as React from 'react';
import { DataGrid, GridColDef, ptBR } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

import { ISolution } from '../Solution';
import { SessionContext } from 'contexts';
import {
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  Stack,
  Typography,
  DialogActions,
  Button,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { HighlightAlt, SearchOff } from '@mui/icons-material';
import { PaperComponent } from 'util/dialogUtils';
import { darkModeScrollBar } from 'util/themeUtils';

interface DefectData {
  id: string;
  type: string;
  appliesTo: string;
  description: string;
  times: number;
  weight: number;
}

function createData(
  id: string,
  type: string,
  appliesTo: string,
  description: string,
  times: number,
  weight: number
): DefectData {
  return {
    id,
    type,
    appliesTo,
    description,
    times,
    weight,
  };
}

export default function DefectsDialog(props: any) {
  const { t } = useTranslation();
  const open = props.open;
  const setOpen = props.setOpen;
  const title = props.title;
  const message = props.message;
  const solution: ISolution = props.solution;
  const widthLessThan900 = useMediaQuery('(max-width:900px)');
  const widthLessThan600 = useMediaQuery('(max-width:600px)');
  const [pageSize, setPageSize] = React.useState<number>(100);
  const { user, isDarkMode } = React.useContext(SessionContext);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: t('Id'),
      flex: 2,
      minWidth: 150,
      hide: true,
    },
    {
      field: 'type',
      headerName: t('Type'),
      flex: 3,
      minWidth: 100,
    },
    {
      field: 'appliesTo',
      headerName: t('Applies To'),
      flex: 4,
      minWidth: 100,
    },
    {
      field: 'description',
      headerName: t('Description'),
      flex: 9,
      minWidth: 150,
      hide: widthLessThan900,
    },
    {
      field: 'times',
      headerName: t('Count'),
      type: 'number',
      flex: 1,
      minWidth: 90,
      hide: widthLessThan600,
      headerAlign: 'right',
      align: 'right',
    },
    {
      field: 'weight',
      headerName: t('Weight'),
      type: 'number',
      flex: 1,
      minWidth: 90,
      hide: widthLessThan600,
      headerAlign: 'right',
      align: 'right',
    },
  ];

  let rows: any[] = [];
  solution.defects.map(function (defect, index) {
    return rows.push(
      createData(
        defect.type + ' ' + defect.appliesTo + ' ' + defect.description,
        t(defect.type),
        defect.appliesTo,
        defect.description,
        defect.times,
        defect.weight
      )
    );
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="xl"
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
          <Grid container item xs={12} sx={{ height: '70vh', width: '100%', ...(isDarkMode ? darkModeScrollBar : {}) }}>
            <DataGrid
              rows={rows}
              columns={columns}
              // autoPageSize
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[20, 50, 100]}
              // hideFooterPagination
              density={'compact'}
              // checkboxSelection
              disableSelectionOnClick
              // onSelectionModelChange={(ids) => {
              //   setSelectionModel(ids);
              // }}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'weight', sort: 'desc' }],
                },
              }}
              localeText={user?.countryCode === 'BR' ? ptBR.components.MuiDataGrid.defaultProps.localeText : undefined}
              // onRowClick={(params, event) => handleClick(event, params.id.toString())}
              components={{
                NoRowsOverlay: () => (
                  <Stack height="100%" alignItems="center" justifyContent="center">
                    <HighlightAlt style={{ color: grey[500], textAlign: 'center' }} />
                    <Typography color={grey[500]} textAlign="center">
                      {t('This timetable has no DEFECTS')}
                    </Typography>
                  </Stack>
                ),
                NoResultsOverlay: () => (
                  <Stack height="100%" alignItems="center" justifyContent="center">
                    <SearchOff style={{ color: grey[500], textAlign: 'center' }} />
                    <Typography color={grey[500]} textAlign="center">
                      {t('Local FILTER returns no data')}
                    </Typography>
                  </Stack>
                ),
              }}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirm}>{t('Close')}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
