import { colors, Grid, Typography } from '@mui/material';
import TeaserActions from './TeaserActions';
import { useTranslation } from 'react-i18next';

type ITeaser = {
  handleGetStarted: () => void;
};

export default function Teaser({ handleGetStarted }: ITeaser) {
  const { t } = useTranslation();
  return (
    <Grid item xs={12} lg={4}>
      <Grid container item xs={12} padding={4} justifyContent="flex-start">
        <Typography variant="h2" fontWeight="bold" color="white" component="span" display="inline">
          {t('Catch phrase 1')}&nbsp;
        </Typography>
        <Typography variant="h2" fontWeight="bold" color="white" component="span" display="inline">
          {t('Catch phrase 2')}&nbsp;
        </Typography>
        {/* <Typography variant="h2" fontWeight="bold" color="white" component="span" display="inline">
          {t('Catch phrase aux')}&nbsp;
        </Typography> */}
        <Typography variant="h2" fontWeight="bold" color="white" component="span" display="inline">
          {t('Catch phrase 3')}
        </Typography>
      </Grid>
      <Grid container item xs={12} padding={4} justifyContent="center">
        <Typography variant="subtitle1" fontSize={24} lineHeight={1.2} color={colors.grey[300]}>
          {t('Tiza description')}
        </Typography>
      </Grid>
      <TeaserActions handleGetStarted={handleGetStarted} />
    </Grid>
  );
}
