import React from 'react';

type Props = {
  color?: string;
  width: string;
  height: string;
  style?: any;
};

export default function PriorityAverage({ width, height, color = 'white', style = {} }: Props) {
  return (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox="0 0 168 168"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M84 16L84 8" stroke={color} strokeWidth="12" strokeLinecap="round" />
      <path d="M134 39.6567L139.657 33.9999" stroke={color} strokeWidth="12" strokeLinecap="round" />
      <path d="M33.3516 38.9463L27.9998 32.9999" stroke={color} strokeWidth="12" strokeLinecap="round" />
      <path d="M151 80L159 80" stroke={color} strokeWidth="12" strokeLinecap="round" />
      <path d="M8 80H16" stroke={color} strokeWidth="12" strokeLinecap="round" />
      <path
        d="M147.648 128.567C150.498 130.563 154.449 129.881 156.226 126.889C163.03 115.431 167.026 102.489 167.843 89.1281C168.789 73.6655 165.44 58.2425 158.168 44.5644C150.895 30.8862 139.981 19.4857 126.633 11.6232C113.285 3.76064 98.0231 -0.257567 82.534 0.0127933C67.0449 0.283153 51.9322 4.83154 38.8669 13.1551C25.8015 21.4787 15.2923 33.2531 8.50132 47.1768C1.7103 61.1004 -1.09807 76.6308 0.386714 92.051C1.66973 105.376 6.11523 118.17 13.3151 129.383C15.195 132.311 19.1672 132.855 21.946 130.761V130.761C24.7248 128.667 25.2536 124.73 23.4126 121.777C17.5898 112.439 13.9889 101.854 12.9287 90.8433C11.6666 77.7362 14.0538 64.5354 19.8261 52.7003C25.5985 40.8652 34.5313 30.8569 45.6368 23.7818C56.7424 16.7068 69.5882 12.8407 82.7539 12.6109C95.9197 12.3811 108.893 15.7965 120.238 22.4797C131.584 29.1628 140.861 38.8533 147.042 50.4797C153.224 62.1062 156.071 75.2157 155.267 88.3589C154.592 99.4001 151.362 110.104 145.869 119.64C144.132 122.655 144.798 126.571 147.648 128.567V128.567Z"
        fill={color}
      />
      <path d="M84 99L84 49" stroke={color} strokeWidth="12" strokeLinecap="round" />
      <path d="M78 99.6272L84.0935 49.9999" stroke={color} strokeWidth="12" strokeLinecap="round" />
      <path d="M90.0933 99.6272L83.9998 49.9999" stroke={color} strokeWidth="12" strokeLinecap="round" />
      <circle cx="84" cy="100" r="12" fill={color} />
    </svg>
  );
}
