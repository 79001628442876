import { CircularProgress } from '@mui/material';

type Props = {
  height?: string;
};

function LoadingIndicator({ height = '160px' }: Props) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: height }}>
      <CircularProgress color="primary" />
    </div>
  );
}

export default LoadingIndicator;
