import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import MiniDrawer from 'components/Drawer/MiniDrawer';
import { DataGrid, GridSelectionModel, ptBR } from '@mui/x-data-grid';
import { SessionContext } from 'contexts';
import { doc, Timestamp, writeBatch } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

import { db } from 'services';
import DraggableDialog from 'components/Dialog/DraggableDialog';
import SimpleBackdrop from 'components/Loading/SimpleBackdrop';
import { useTranslation } from 'react-i18next';
import VideoPlayerDialog from 'components/Dialog/VideoPlayerDialog';
import { Delete, Download, HighlightAlt, QuestionMark, SearchOff } from '@mui/icons-material';
import { Grid, Typography, Paper, Divider, Button, Stack } from '@mui/material';
import { grey } from '@mui/material/colors';
import PageContainer from 'containers/PageContainer';
import { createData, useDataGrid } from './utils/useDataGrid';
import Onboarding from 'components/Onboarding';
import {
  EMPTY_CLASSES_KEY,
  EMPTY_ROOMS_KEY,
  EMPTY_TEACHERS_KEY,
  useResourcesOnboarding,
} from './utils/useResourcesOnboarding';
import ImportStepperDialog from 'components/Dialog/ImportStepperDialog';
import useEnterKey from 'hooks/useEnterKey';
import { darkTheme, lightTheme } from 'styles/material';
import { darkModeScrollBar } from 'util/themeUtils';
import { getConstrBounds } from 'util/validationUtils';
import TooltipIconButton from 'components/Button/TooltipIconButton';
import { handleDownloadResourcesCSV } from 'util/exportUtil';
import { SUBJECT_CLASS_SEPARATOR } from 'util/solutionUtils';
import { ILesson } from 'pages/Lessons/utils/types';

export default function Resources(props: any) {
  const { t } = useTranslation();
  const location = useLocation();
  const type = props.type;
  const history = useHistory();
  const { resources, file, user, lessons, solutions, ownerEmail, shareMode, isDarkMode } = useContext(SessionContext);
  if (!file) {
    history.push('/files');
    toast.warning(t('Select a project first'));
  }
  const theme = isDarkMode ? darkTheme : lightTheme;
  const fileId = file?.id || file?.name;

  const [pageSize, setPageSize] = React.useState<number>(100);
  const { columns } = useDataGrid(t, file!, user!, type, resources);
  const {
    runEmptyClassesOnboarding,
    runEmptyTeachersOnboarding,
    runEmptyRoomsOnboarding,
    emptyClassesSteps,
    emptyTeachersSteps,
    emptyRoomsSteps,
  } = useResourcesOnboarding(t);

  const readOnly = ownerEmail !== user?.email && shareMode === 'read';

  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([]);
  const resourcesPath = 'users/' + ownerEmail + '/files/' + fileId + '/resources';
  const lessonsPath = `users/${ownerEmail}/files/${fileId}/lessons`;
  const solutionsPath = `users/${ownerEmail}/files/${fileId}/solutions`;
  const days = file?.days ? file?.days : [];
  const times = file?.times ? file?.times : [];
  const constrBounds = getConstrBounds(days.length, times.length);

  let rows: any[] = [];
  resources
    .filter((res) => res.type === type)
    .map(function (resource, index) {
      return rows.push(
        createData(
          resource.short,
          resource.color,
          resource.photoURL,
          resource.name,
          t(resource.priority),
          (resource.minWorkload === constrBounds.minWorkingTimes ? '↓' : resource.minWorkload) +
            ' - ' +
            (resource.maxWorkload === constrBounds.maxWorkingTimes ? '↑' : resource.maxWorkload),
          (resource.minWorkingDays === constrBounds.minWorkingDays ? '↓' : resource.minWorkingDays) +
            ' - ' +
            (resource.maxWorkingDays === constrBounds.maxWorkingDays ? '↑' : resource.maxWorkingDays),
          (resource.minIdleWindow === constrBounds.minIdleWindow ? '↓' : resource.minIdleWindow) +
            ' - ' +
            (resource.maxIdleWindow === constrBounds.maxIdleWindow ? '↑' : resource.maxIdleWindow),
          (resource.minDailyWorkload === constrBounds.minDailyWorkload ? '↓' : resource.minDailyWorkload) +
            ' - ' +
            (resource.maxDailyWorkload === constrBounds.maxDailyWorkload ? '↑' : resource.maxDailyWorkload),

          (resource.minRestBetweenDays === constrBounds.minRestBetweenDays ? '↓' : resource.minRestBetweenDays) +
            ' - ' +
            (resource.maxRestBetweenDays === constrBounds.maxRestBetweenDays ? '↑' : resource.maxRestBetweenDays),

          (resource.minRoomChangesDay === constrBounds.minRoomsPerDay ? '↓' : resource.minRoomChangesDay) +
            ' - ' +
            (resource.maxRoomChangesDay === constrBounds.maxRoomsPerDay ? '↑' : resource.maxRoomChangesDay),

          (resource.minConsecutiveTimes === constrBounds.minConsecutiveTimes ? '↓' : resource.minConsecutiveTimes) +
            ' - ' +
            (resource.maxConsecutiveTimes === constrBounds.maxConsecutiveTimes ? '↑' : resource.maxConsecutiveTimes),

          (resource.minDistinctSubjects === constrBounds.minDistinctSubjects ? '↓' : resource.minDistinctSubjects) +
            ' - ' +
            (resource.maxDistinctSubjects === constrBounds.maxDistinctSubjects ? '↑' : resource.maxDistinctSubjects),
          resource.travelTimeRooms,
          resource.forbiddenCombination.map((dayNum) => {
            return days[dayNum];
          }),
          resource.unavailableTimes.length * 100 + resource.undesiredTimes.length,
          resource.unavailableTimes,
          resource.undesiredTimes,
          (resource.updatedAt as unknown as Timestamp).toDate(),
          resource.type
        )
      );
    });

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    history.push(`/${getRouteByType(type)}/${name}`);
  };

  function chooseLabel() {
    if (type === 'class') {
      return file?.studentsSetting !== 0 ? t('Classes') : t('Students');
    } else if (type === 'teacher') {
      return t('Teachers');
    } else if (type === 'room') {
      return t('Rooms');
    } else {
      return t('Resources');
    }
  }

  function getNumberFromType() {
    if (type === 'class') {
      return '2. ';
    } else if (type === 'teacher') {
      return '3. ';
    } else if (type === 'room') {
      return '4. ';
    } else {
      return '';
    }
  }

  const [loadingOpen, setLoadingOpen] = React.useState(false);

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);

  const lessonHasResource = (lesson: ILesson, name: string) => {
    if (type === 'class') {
      for (let i = 0; i < lesson.classes.length; ++i) {
        if (
          lesson.classes[i] === name ||
          (lesson.classes[i].split(SUBJECT_CLASS_SEPARATOR).length > 1 && lesson.classes[i].includes(name + ' |')) ||
          lesson.classes[i].includes('| ' + name)
        )
          return true;
      }
    } else if (type === 'teacher') {
      for (let i = 0; i < lesson.teachers.length; ++i) {
        if (
          lesson.teachers[i] === name ||
          (lesson.teachers[i].split(SUBJECT_CLASS_SEPARATOR).length > 1 && lesson.teachers[i].includes(name + ' |')) ||
          lesson.teachers[i].includes('| ' + name)
        )
          return true;
      }
    } else if (type === 'room') {
      for (let i = 0; i < lesson.rooms.length; ++i) {
        if (
          lesson.rooms[i] === name ||
          (lesson.rooms[i].split(SUBJECT_CLASS_SEPARATOR).length > 1 && lesson.rooms[i].includes(name + ' |')) ||
          lesson.rooms[i].includes('| ' + name)
        )
          return true;
      }
      return false;
    }
  };

  const checkDeleteConfirmation = () => {
    if (deleteConfirmed) {
      setLoadingOpen(true);
      setDeleteConfirmed(false);
      const batch = writeBatch(db);
      selectionModel.forEach((selectedId) => {
        batch.delete(doc(db, resourcesPath, selectedId.toString()));
        //Delete related lessons
        lessons
          .filter((lesson) => lessonHasResource(lesson, selectedId.toString()))
          .forEach((lesson) => batch.delete(doc(db, lessonsPath, lesson.name)));
        //Set all solutions to outdated
        solutions.forEach((solution) => {
          batch.update(doc(db, `${solutionsPath}/${solution.name}`), {
            status: 'Outdated',
          });
        });
      });
      // Update file stats
      batch.update(doc(db, `users/${ownerEmail}/files/${user?.selectedFile}`), {
        updatedAt: new Date(),
      });
      batch.commit().then(() => {
        setLoadingOpen(false);
        toast.success(selectionModel.length + ' ' + chooseLabel() + t(' deleted sucessfully'));
      });
    }
  };

  checkDeleteConfirmation();

  const [importOpen, setImportOpen] = useState(false);

  const navigateBackwards = () => {
    if (type === 'class') {
      history.push({ pathname: '/file', state: file });
    } else if (type === 'teacher') {
      history.push('/classes');
    } else if (type === 'room') {
      history.push('/teachers');
    }
  };

  const navigateForward = () => {
    if (type === 'class') {
      history.push('/teachers');
    } else if (type === 'teacher') {
      if (file?.roomsSetting !== 0) {
        history.push('/rooms');
      } else history.push('/subjects');
    } else if (type === 'room') {
      history.push('/subjects');
    }
  };

  const getRouteByType = (type: string) => {
    switch (type) {
      case 'class':
        return 'classes';
      case 'teacher':
        return 'teachers';
      case 'room':
        return 'rooms';
      default:
        return 'classes';
    }
  };

  const handleImportClick = async () => {
    setImportOpen(true);
  };

  const [videoPlayerDialogOpen, setVideoPlayerDialogOpen] = useState(false);

  useEnterKey(handleCreate);

  function handleCreate() {
    history.push(`/${getRouteByType(type)}/_NEW_`);
  }

  return (
    <PageContainer>
      {type === 'class' ? (
        <Onboarding steps={emptyClassesSteps} run={runEmptyClassesOnboarding} storageFlagKey={EMPTY_CLASSES_KEY} />
      ) : type === 'teacher' ? (
        <Onboarding steps={emptyTeachersSteps} run={runEmptyTeachersOnboarding} storageFlagKey={EMPTY_TEACHERS_KEY} />
      ) : (
        <Onboarding steps={emptyRoomsSteps} run={runEmptyRoomsOnboarding} storageFlagKey={EMPTY_ROOMS_KEY} />
      )}
      <DraggableDialog
        open={deleteConfirmationOpen}
        setOpen={setDeleteConfirmationOpen}
        setConfirmed={setDeleteConfirmed}
        title={t('Warning')}
        message={selectionModel.length + ' ' + chooseLabel() + t('Delete resource warning')}
      />
      {loadingOpen && <SimpleBackdrop open={loadingOpen} setOpen={setLoadingOpen} />}
      <VideoPlayerDialog
        open={videoPlayerDialogOpen}
        setOpen={setVideoPlayerDialogOpen}
        videoURL={
          type === 'class'
            ? t('video_url_help_classes')
            : type === 'teacher'
            ? t('video_url_help_teachers')
            : type === 'room'
            ? t('video_url_help_rooms')
            : t('video_url_help_resources')
        }
      ></VideoPlayerDialog>
      <ImportStepperDialog
        open={importOpen}
        setOpen={setImportOpen}
        title={`${t('Import')} ${chooseLabel()}`}
        type={type}
      />
      <MiniDrawer currentPage={location.pathname} />
      <Grid container justifyContent="center">
        <Paper elevation={3} sx={{ width: '100%', maxWidth: 'lg' }}>
          <Grid container item xs={12} justifyContent={'space-between'} padding={2} spacing={1} flexDirection={'row'}>
            <Grid item flexGrow={1}>
              <Typography variant="body1" gutterBottom color={theme.palette.primary.main}>
                {getNumberFromType() + chooseLabel()}
              </Typography>
              <Divider color={theme.palette.primary.main} />
            </Grid>
            <TooltipIconButton
              tooltip={t('Watch a helpful video')}
              Icon={QuestionMark}
              color={theme.palette.primary.main}
              onClick={() => setVideoPlayerDialogOpen(true)}
            />
            <TooltipIconButton
              tooltip={t('Download')}
              Icon={Download}
              color={theme.palette.primary.main}
              onClick={() => handleDownloadResourcesCSV(t, type, resources, file!, file!.name + '_' + type)}
            />
            {selectionModel.length !== 0 ? (
              <Grid item xs={3} md={2}>
                <Button
                  startIcon={<Delete />}
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled={readOnly}
                  onClick={() => setDeleteConfirmationOpen(true)}
                  color="error"
                >
                  {`${t('Delete')} (${selectionModel.length})`}
                </Button>
              </Grid>
            ) : (
              <Grid item xs={3} md={2}>
                <Button
                  className="import-button"
                  // startIcon={<FileDownload />}
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled={readOnly}
                  onClick={() => handleImportClick()}
                  color="primary"
                >
                  {t('Import')}
                </Button>
              </Grid>
            )}
            <Grid item xs={3} md={2}>
              <Button
                className="create-button"
                // startIcon={<AddCircle />}
                variant="contained"
                size="small"
                fullWidth
                disabled={readOnly}
                onClick={handleCreate}
                color="primary"
              >
                {t('Create')}
              </Button>
            </Grid>
            {/* </Grid> */}
          </Grid>
          <Grid
            className="resources-list"
            item
            xs={12}
            sx={{ height: '80vh', width: '100%', ...(isDarkMode ? darkModeScrollBar : {}) }}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              // autoPageSize
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[20, 50, 100]}
              // hideFooterPagination
              density={'compact'}
              // loading={rows.length === 0}
              checkboxSelection
              disableSelectionOnClick
              onSelectionModelChange={(ids) => {
                setSelectionModel(ids);
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'id', sort: 'asc' }],
                },
              }}
              localeText={user?.countryCode === 'BR' ? ptBR.components.MuiDataGrid.defaultProps.localeText : undefined}
              onRowClick={(params, event) => handleClick(event, params.id.toString())}
              components={{
                NoRowsOverlay: () => (
                  <Stack height="100%" alignItems="center" justifyContent="center">
                    <HighlightAlt style={{ color: grey[500], textAlign: 'center' }} />
                    <Typography color={grey[500]} textAlign="center">
                      {`${t('Click on IMPORT or CREATE to add')} ${chooseLabel()}`}
                    </Typography>
                  </Stack>
                ),
                NoResultsOverlay: () => (
                  <Stack height="100%" alignItems="center" justifyContent="center">
                    <SearchOff style={{ color: grey[500], textAlign: 'center' }} />
                    <Typography color={grey[500]} textAlign="center">
                      {t('Local FILTER returns no data')}
                    </Typography>
                  </Stack>
                ),
              }}
              sx={{
                '& .MuiDataGrid-row:hover': {
                  cursor: 'pointer',
                },
              }}
            />
          </Grid>
          <Grid item container xs={12} spacing={1} justifyContent={'center'} paddingY={1}>
            <Grid item xs={2}>
              <Button variant="outlined" size="small" fullWidth onClick={() => navigateBackwards()} color="primary">
                {t('Back')}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button variant="outlined" size="small" fullWidth onClick={() => navigateForward()} color="primary">
                {t('Next')}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </PageContainer>
  );
}
