import { HighlightAlt } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { t } from 'i18next';
import React from 'react';
import { TFunction } from 'react-i18next';

type Props = { t: TFunction };

export default function EmptyTimetable({ t }: Props) {
  return (
    <Box height={'50vh'} flexGrow={1}>
      <Stack height="100%" alignItems="center" justifyContent="center">
        <HighlightAlt style={{ color: grey[500], textAlign: 'center' }} />
        <Typography color={grey[500]} textAlign="center">
          {t('Click on GENERATE to create a new timetable')}
        </Typography>
      </Stack>
    </Box>
  );
}
